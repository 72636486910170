import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {requestGetChat, requestMyInfo, requestSendChat, requestClearChat} from "../../api";
import {chatModel, MyInfo} from "../../dataset";
import moment from "moment";
import useScreenOrientation, {MEDIA_MOBILE_LANDSCAPE, MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {unreadChatCount} from "../../recoil/Chat";
import {useRecoilValue} from "recoil";
import {playSFX, Sounds} from "../../utils/sound";

const CustomerCenterWrapper = styled.div`
  width: 420px;
  top: 0;
  background: rgba(22,22,22,0.85);
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 400px) {
    width: 320px;
  }
  @media (max-width: 310px) {
    width: 280px;
  }

  @media ${MEDIA_DESKTOP} {
    width: 800px;
  }
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    width: 80%;
    min-width: initial;
    min-height: initial;
    overflow-y: scroll;
    margin: 0px auto;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
  }

  > .content-wrapper {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    height: 400px;
    padding-top: 20px;
    *::-webkit-scrollbar {
      display: block;
    }
    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding: 0 20px;
    }

    @media ${MEDIA_MOBILE_LANDSCAPE} {
      height: initial;
      overflow-y: initial;
    }
  }
`
const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  background: rgba(105,203,255,0.15);
  
  > .wrap {
    width: 100%;
    height: 48px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${MEDIA_DESKTOP} {
      max-width: 1440px;
      height: 57px;
    }
    > .clearHistory {
      color: rgb(80 80 80);
      cursor: pointer;
      border-radius: 6px;
      position: absolute;
      right: 5px;
      top: 0;
      display: flex;
      align-items: center;
      padding: 4px;
      padding-left: 10px;
      padding-right: 10px;
      background: transparent;
      width: 48px;
      height: 48px;
      >img {
        width: 24px;
        margin-right: 5px;
        @media ${MEDIA_DESKTOP} {
          width: 28px;
        }
      }
      @media ${MEDIA_DESKTOP} {
        width: initial;
        height: initial;
        top: 11px;
        right: 0px;
      }
    }
    > .close {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      cursor: pointer;
    }
      @media ${MEDIA_DESKTOP} {
        font-size: 14px;
        color: var(--Black, #181818);
      }
  
    > .title {
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
  
      > .close {
        display: none;
      }
    }
  }
`
const Footer = styled.div`
  width: 100%;
  height: 90px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .input-wrapper {
    padding: 8px 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;

    > input {
      flex-grow: 1;
      background: none;
      outline: none;
      border-radius: 20px;
      border: 1px solid #ababab;
      color: #FFF;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.7px;
      padding: 10px 12px;

      &::placeholder {
        color: #FFF;
        opacity: 0.5;
      }
    }

    > .send-button {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 8px;
      border: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
`
const AdminMessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;

  > .title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;

    > .admin {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.165px;
    }

    > .date {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
      opacity: 0.5;
    }
  }

  > .message {
    max-width: 300px;
    color: #FFF;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.165px;
    border-radius: 8px;
    background: #25262D;
    padding: 12px;
    word-break: break-all;
    white-space: pre-line;
  }
`
const UserMessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 8px;

  > .message {
    max-width: 300px;
    padding: 12px;
    color: #FFF;
    text-align: right;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.165px;
    background: #8D7AFF;
    border-radius: 8px;
    word-break: break-all;
    white-space: pre-line;
  }

  > .date {
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    opacity: 0.5;
  }
`
const CustomerCenterInGame = ({onClose}: {
    onClose: () => void;
  }) => {
  const [showSendButton, setShowSendButton] = useState<boolean>(false);
  const [chatList, setChatList] = useState<chatModel[]>([]);
  const [myInfo, setMyInfo] = useState<MyInfo|null>(null);
  const chatDiv = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const ChatCount = useRecoilValue(unreadChatCount);
  const orientation = useScreenOrientation();

  useEffect(() => {
    requestMyInfo().then((response) => {
      setMyInfo(response.info);
    })

    playSFX(Sounds.SFX_CLICK, true);

    const mobileKeywords = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone'];
    const userAgent = window.navigator.userAgent;

    if(!mobileKeywords.some(keyword => userAgent.includes(keyword)))
      inputRef && inputRef.current?.focus();
  
    return () => {
      playSFX(Sounds.SFX_WINDOW_CLOSE, true);
    };
  }, []);

  useEffect(()=>{
    if (myInfo ) {
      requestGetChat(0).then((response:any) => {
        setChatList(response.list);

        setTimeout(()=>{
          if(chatDiv.current){
            chatDiv.current.scrollTop = chatDiv.current.scrollHeight+1000000;
          }
        },100);
      });
    }
  },[ChatCount, myInfo]);

  const handleSendChat = () => {
    const message = document.getElementById('message-input') as HTMLInputElement;
    if(message.value.length === 0){
      return;
    }
    requestSendChat(message.value).then((response) => {
      if(response.result){

        setChatList(prev => [...prev, {
          chatId: -1,
          userId: myInfo?.userId as number,
          sendUserId: myInfo?.userId as number,
          message: message.value, //Todo : message
          addedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        }]);
        message.value = '';

        setTimeout(()=>{
          if(chatDiv.current){
            chatDiv.current.scrollTop = chatDiv.current.scrollHeight+1000000;
          }
        },100);
      }
    })
  }
  const replaceLinks = (text:string) => {
    const regex = /(https?:\/\/[^\s]+)/g;
    const replacedText = text.replace(regex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
    return replacedText;
  };
  const onClickClearChat = () => {
    requestClearChat().then((v)=> {
      setChatList([]);
    });
  };

  return <CustomerCenterWrapper>
    <Header>
      <div className='wrap'>
        <img className='close' src='/images/ic_close.png' onClick={() => onClose()}/>
        <div className='title'><img onClick={() => onClose()} className={'close'} src='/images/pc/Back.png'/>고객센터</div>
        <div className='clearHistory' onClick={onClickClearChat}>
          <img src={`/images/nes/cleanMobile.png`} />
        </div>
      </div>
    </Header>
    <div className='content-wrapper' ref={chatDiv}>
      <div className='content'>
        {
        myInfo !== null &&
          chatList.map((_, index) => {
              if (_.sendUserId === myInfo.userId){ //유저
                return <UserMessageContainer>
                  <div className='message' style={{whiteSpace:"pre-line"}}>{_.message}</div>
                  <div className='date'>{_.addedAt.replace("T"," ").split(".")[0]}</div>
                </UserMessageContainer>
              }else{
                return <AdminMessageContainer>
                  <div className='title-row'>
                    <div className='admin'>관리자</div>
                    <div className='date'>{_.addedAt.replace("T"," ").split(".")[0]}</div>
                  </div>
                  <div className='message' style={{whiteSpace:"pre-line"}} 
                    dangerouslySetInnerHTML={{
                      __html: replaceLinks(
                        _.message
                      ),
                    }}
                  />
                </AdminMessageContainer>
              }
            })
        }
      </div>
    </div>
    <Footer>
      <div className='input-wrapper'>
        <input ref={inputRef} id='message-input' type='text' placeholder='메시지를 입력하세요.' onKeyUp={
          (event) => {
            if (event.key === 'Enter') {
              handleSendChat();
            }
          }
        } onChange={(event) => {
          if (event.target.value.length > 0) {
            setShowSendButton(true);
          } else {
            setShowSendButton(false);
          }
        }}/>
        {
          showSendButton && <img src='/images/ic_send.png' className='send-button' onClick={handleSendChat}/>
        }
      </div>
    </Footer>
  </CustomerCenterWrapper>
}
export default CustomerCenterInGame;

