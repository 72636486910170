import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {connectionData, requestDirectLinkLogin, requestLogin, useSocketIsConnect} from "../api";
import {sha256} from "js-sha256";
import useDialog from "../hooks/useDialog";
import useQueryParams from "../hooks/useQueryParams";
import {useNavigate} from "react-router-dom";
import useScreenOrientation from "../hooks/useScreenOrientation";

const Wrapper = styled.div`
  text-align:center;
`;

function DirectLink() {
  const queryParams = useQueryParams();
  const navigate = useNavigate();
  const [conn] = useSocketIsConnect();
  const orientation = useScreenOrientation();

  useEffect(function(){
    let query = queryParams.get("q");
    if(conn.isConnected) {
      requestDirectLinkLogin(query).then(v=>{
        if(v.login_token){
          window.location.href = '/lobby';
        }else{
          alert("이미 만료된 입장입니다. 다시 입장해주세요.");
          // window.location.href = '/';
          return ;
        }
      })
    }
  },[conn.isConnected, conn.isLogin, queryParams])

  return  <Wrapper>
    접속 중...
  </Wrapper>
}

export default DirectLink;