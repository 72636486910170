import React, {useCallback, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import moment from "moment";
import {parseDatetime, parseLocalDateTime} from "../../constants/moment";
import {calcLevel, calcPlayTime, calcElapsedForRound, determineRestTime} from "../../utils/tournament";
import {requestGetTournamentGroup, requestRebuyinTournament, requestRegisterOrJoinTournament, requestCancelTournament} from "../../api";
import TournamentStatus from "./TournamentStatus";
import Flex from "../common/Flex";
import Prize from "./Prize";
import PlayerCount from "./PlayerCount";
import useDialog from "../../hooks/useDialog";
import {useNavigate} from "react-router-dom";
import {convertMinutes} from "../../utils/common";
import WinnerListModal from "../WinnerListModal";
import ModalContainer from "../common/ModalContainer";
import {GroupInfo} from "../../dataset";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {useSetRecoilState} from "recoil";
import {globalLoadingState} from "../../recoil/Loading";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #101012;
  overflow-y: auto;

  @media ${MEDIA_DESKTOP} {
    width: 640px;
  }

  .header {
    height: 48px;
    display: flex;
    align-items: center;

    @media ${MEDIA_DESKTOP} {
      height: 72px;
    }

    > img.back {
      padding: 14px 20px;
      cursor: pointer;

      @media ${MEDIA_DESKTOP} {
        padding: 24px;
      }
    }

    > .title {
      flex: 1;
      padding: 4px 55px 4px 0;
      color: #FFF;
      font-size: 12px;
      font-weight: 500;

      @media ${MEDIA_DESKTOP} {
        font-size: 14px
      }
    }
  }

  .gray-box {
    background: #17171B;
    color: #FFF;
    padding: 16px 20px;
    font-size: 12px;

    @media ${MEDIA_DESKTOP} {
      font-size: 14px;
    }

    .flexbox {
      display: flex;

      &.v-center {
        align-items: center;
      }
    }

    .start-info {
      margin-left: 12px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      > div {
        color: #FFF;

        > span {
          color: #FFF;
          opacity: 0.5;
          margin-right: 2px;

          @media ${MEDIA_DESKTOP} {
            margin-right: 4px;
          }
        }
      }
    }

    > .border {
      width: 100%;
      min-height: 1px;
      height: 1px;
      background-color: #2D2D2D;
      margin: 12px 0 16px 0;
    }

    .level-blind {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4px;
      margin: 0 16px;

      > .description {
        color: #FFF;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
        opacity: 0.5;
        background: none !important;
      }

      > * {
        border-radius: 8px;
        background: rgba(0, 0, 0, 0.30);
        color: #FFF;
        text-align: center;
        padding: 8px 10px;
      }

      > .level {
        > div {
          color: #FFF;

          &:first-child {
            font-size: 12px;
            font-weight: 600;
            opacity: 0.5;
            margin-bottom: 4px;

            @media ${MEDIA_DESKTOP} {
              font-size: 14px;
            }
          }

          &:last-child {
            font-size: 24px;
          }
        }
      }

      > .blind {
        color: #FFF;
        font-size: 10px;
        font-weight: 600;

        > span {
          color: #FFF;
          font-size: 10px;
          font-weight: 600;
          opacity: 0.5;
          margin-right: 4px;
        }
      }
    }
  }

  ul {
    padding: 16px 20px;


    li {
      color: #FFF;
      font-size: 12px;
      font-weight: 500;
      padding: 8px 10px;
      border-bottom: 1px solid #2D2D2D;

      @media ${MEDIA_DESKTOP} {
        font-size: 14px;
        padding: 12px 10px;
      }

      > span {
        color: #FFF;
        font-size: 12px;
        font-weight: 500;
        opacity: 0.5;
        margin-right: 4px;
      }
    }
  }
`;

const RegisterButtonWrap = styled.div`
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: right;

  @media ${MEDIA_DESKTOP} {
    width: 260px;
  }
`;

const RegisterButton = styled.div<{
  disabled?: boolean
  isCancel?: boolean
}>`
  display: flex;
  height: 46px;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.00) 100%), linear-gradient(116deg, #2F99FB 23.2%, #2464C3 98.42%);
  cursor: pointer;
  width: 100%;

  max-width: 120px;
  @media ${MEDIA_DESKTOP} {
    max-width: 180px;
  }

  ${p => p.disabled && `
    opacity: 0.5;
    background: rgba(255, 255, 255, 0.20);
    cursor: initial;
  `};

  ${p => p.isCancel && `
    background: linear-gradient(rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(116deg, #fb2f2f 23.2%, #c32424 98.42%);
  `}

  &+& {
    margin-left: 6px;
  }
`;

const WinnerButton = styled.div`{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 108px;
  height: 42px;
  color: #2F99FB;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
  border-radius: 8px;
  background: rgba(47, 153, 251, 0.10);
}`;

const StructureWrapper = styled.div<{
  isOpen: boolean
}>`{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  @media ${MEDIA_DESKTOP} {
    padding: 0 16px;
    font-size: 14px;
  }

  > .title-row {
    cursor: pointer;
    width: 100%;
    padding: 8px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    background: #17171B;

    > .title {
      color: #FFF;
      font-weight: 500;
    }

    > .arrow {
      width: 12px;
      height: 12px;
      object-fit: contain;
      transition: all 0.3s;
      ${p => p.isOpen ? `
        transform: rotate(0deg);
      ` : `
        transform: rotate(180deg);
      `}
    }
  }

  > .content {
    ${p => p.isOpen ? `
      height: fit-content;
      padding: 12px 20px;
    ` : `
      height: 0;
      overflow: hidden;
      max-height: 0;
      padding: 0 20px;
    `}
    transition: all 0.3s;
    width: 100%;

    > .row {
      padding: 5px 0 10px;
      border-bottom: 1px solid #2D2D2D;
      height: 23px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #FFF;
      font-family: Pretendard;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.2px;

      > .title {
        opacity: 0.5;
      }

    }

    > table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 5px;

      tr {
        height: 18px;

        th {
          padding-bottom: 7px;
          border-bottom: 1px solid #2D2D2D;

          &:first-child {
            text-align: left;
          }
        }

        td {
          &:first-child {
            text-align: left;
          }
        }
      }

      .title {
        opacity: 0.5;
        color: #FFF;
        font-weight: 500;
      }

      .value {
        color: #FFF;
        font-weight: 500;
      }

      .fixed {
        width: 203px;
        text-align: center;
      }

      .level {
        width: 40px;
        text-align: left;
      }

      .blind {
        width: 140px;
        text-align: center;
      }

      .entity {
        width: 60px;
        text-align: center;
      }

      .minute {
        width: 60px;
        text-align: center;
      }

    }
  }
}`;


function TournamentDetail({
  groupId,
  onClose
} : {
  groupId: number
  onClose?: () => void
}) {
  const navigate = useNavigate();
  const {openDialog} = useDialog();

  const setGlobalLoading = useSetRecoilState(globalLoadingState);

  const [group, setGroup] = useState<GroupInfo>();
  const [currentLevel, setCurrentLevel] = useState<number>(1);
  const [isIngRest, setIsIngRest] = useState<boolean>(false);
  const [levelTimeText, setLevelTimeText] = useState<string>('--:--');
  const [restTimeText, setRestTimeText] = useState<string>('--:--');
  const [isRegistered, setRegistered] = useState<boolean>(false);
  const [stackSize, setStackSize] = useState<number>(0);
  const [isOpenPrize, setIsOpenPrize] = useState<boolean>(false);
  const [isOpenBlind, setIsOpenBlind] = useState<boolean>(false);
  const [status, setStatus] = useState<'참가' | '참가종료' | '미참가종료'>('참가');
  const [showWinner, setShowWinner] = useState<boolean>(false);
  const [rankingData, setRankingData] = useState<{
    ranking: number;
    totalRegisterCount: number;
  }>({
    ranking: 0,
    totalRegisterCount: 0
  });
  const [timerText, setTimerText] = useState<string>('');
  const [isTournamentStarted, setTournamentStarted] = useState<boolean>(false);
  const [isClosed, setClosed] = useState(false);

  useEffect(() => {
    if (!groupId) {
      return;
    }

    setGlobalLoading(true);
    requestGetTournamentGroup(Number(groupId)).then((res) => {
      setGlobalLoading(false);
      
      if (res.info) {
        setGroup(res.info);
        setRegistered(res.info.isRegister);

        if (res.info.stackSize) {
          setStackSize(res.info.stackSize);
        }
      } else {
        openDialog({
          title: '오류',
          text: '존재하지 않는 토너먼트입니다.',
          onConfirm: () => {
            navigate('/');
          }
        });
      }
    });
  }, [groupId]);

  useEffect(() => {
    if (!group?.data) {
      return;
    }

    function update(): boolean {
      if (!group?.data) {
        return true;
      }

      const startedAt = parseDatetime(group.data.startedAt);
      const {
        playTimeSeconds,
        restTimeSeconds
      } = group.data.timeStructure;
      const isStarted = moment().utc().isSameOrAfter(startedAt);

      let leftSec = 0;

      if (!isStarted) {
        setCurrentLevel(1);
        setLevelTimeText('--:--');
        leftSec = startedAt.unix() - moment().unix();

        if (leftSec >= 0) {
          const duration = moment.duration(leftSec, 'seconds');
          const mins = String(Math.floor(duration.asMinutes())).padStart(2, '0');
          const secs = String(duration.seconds()).padStart(2, '0');
  
          setTimerText(`${mins}:${secs}`);
        } else {
          setClosed(true);
          setTimerText('');
        }
        
        return false;
      }

      if (!isTournamentStarted) {
        setTournamentStarted(true);
      }

      const isIngRestTime = determineRestTime(startedAt, playTimeSeconds, restTimeSeconds);

      if (isIngRestTime !== isIngRest) {
        setIsIngRest(isIngRestTime);
      }

      const playTime = calcPlayTime(startedAt, playTimeSeconds, restTimeSeconds, isIngRestTime);
      const level = calcLevel(playTime, group.data.blindStructure);

      setCurrentLevel(level + 1);

      let closeTime = 0;
      for (let i = 0; i < group.data.availableRegisterLevel ; ++i) {
        closeTime += group.data.blindStructure[i][3]
      }

      leftSec = closeTime - playTime;

      if (leftSec >= 0) {
        const duration = moment.duration(leftSec, 'seconds');
        const mins = String(Math.floor(duration.asMinutes())).padStart(2, '0');
        const secs = String(duration.seconds()).padStart(2, '0');

        setTimerText(`${mins}:${secs}`);
      } else {
        setClosed(true);
        setTimerText('');
      }
      
      const elapsedForRound = calcElapsedForRound(startedAt, playTimeSeconds, restTimeSeconds);

      if (!isIngRestTime) {
        const leftSecLevel = playTimeSeconds - elapsedForRound;
        setLevelTimeText(moment.unix(leftSecLevel).format(leftSecLevel > 3600 ? 'hh:mm:ss' : 'mm:ss'));
      }
      else {
        const leftSecLevel = playTimeSeconds + restTimeSeconds - elapsedForRound;
        setRestTimeText(moment.unix(leftSecLevel).format(leftSecLevel > 3600 ? 'hh:mm:ss' : 'mm:ss'));
      }

      return false;
    }

    update();
    const interval = setInterval(() => {
      if (update()) {
        clearInterval(interval);
      }
    }, 700);

    return () => {
      clearInterval(interval);
    };
  }, [group?.data, isIngRest]);

  const {
    blind,
    nextBlind
  } = useMemo<{
    blind: number[],
    nextBlind: number[]
  }>(() => {
    const ret = {
      blind: [],
      nextBlind: []
    };

    if (group?.data) {
      ret.blind = group.data.blindStructure[currentLevel - 1];
      if (currentLevel < group.data.blindStructure.length) {
        ret.nextBlind = group.data.blindStructure[currentLevel];
      }
    }

    return ret;
  }, [currentLevel, group?.data]);

  const printZeroToFree = (num:number|null|undefined) => {
    if (num) {
      return num.toLocaleString();
    }

    return '무료';
  };

  const descItems = useMemo<{
    label: string,
    value: string
  }[]>(() => {
    if (!group?.data) {
      return [];
    }

    const {
      startedAt,
      beginChip,
      blindStructure,
      rebuyinLimit,
      preRegisterFirstComeCount,
      preRegisterBenefitChip,
      timeStructure,
      rebuyinChip,
      addOnChip
    } = group.data;
    const beginBigBlind = blindStructure[0][1];
    const playTime = moment.unix(timeStructure.playTimeSeconds).format('m분');
    const restTime = moment.unix(timeStructure.restTimeSeconds).format('m분');
    const restTimeSec = moment.unix(timeStructure.restTimeSeconds).format('s초');

    return [
      {
        label: "시작",
        value: parseLocalDateTime(startedAt).format('MM/DD, HH:mm')
      },
      {
        label: "등록금액",
        value: printZeroToFree(group.data.buyinPrice)
      },
      {
        label: "시작칩",
        value: `${beginChip.toLocaleString()} (${beginChip / beginBigBlind}BB)`
      },
      {
        label: "추가등록 레벨 제한",
        value: `${group.data.availableRegisterLevel.toLocaleString()} Level 부터 등록불가`
      },
      {
        label: "리바인금액",
        value: printZeroToFree(group.data.rebuyinPrice)
      },
      {
        label: "리바인칩",
        value: `${rebuyinChip.toLocaleString()} (${rebuyinChip / beginBigBlind}BB)`
      },
      {
        label: "리바인 레벨 제한",
        value: `${group.data.rebuyinLimitLevel.toLocaleString()} Level 부터 리바인불가`
      },
      {
        label: "리바인 횟수 제한",
        value: rebuyinLimit === -1 || rebuyinLimit === 999 ? '무제한' : rebuyinLimit + '회 제한'
      },
      {
        label: "애드온 사용가능 레벨",
        value: `${group.data.addonOnLevel.toLocaleString()} Level 휴식 시간에 애드온 1회 사용 가능`
      },
      {
        label: "애드온 사용 금액",
        value: `${printZeroToFree(group.data.addonPrice)}`
      },
      {
        label: "애드온칩",
        value: `${addOnChip.toLocaleString()} (${addOnChip / beginBigBlind}BB)`
      },
      {
        label: "테이블 타입",
        value: `테이블 당 최대 ${group.data.maxTableMember}명, 파이널 테이블 ${group.data.finalTableMember}명`
      },
      {
        label: "최소 / 최대 플레이어",
        value: `${group.data.minTotalMember}/${group.data.maxTotalMember}명`
      },
      {
        label: "휴식시간",
        value: `${playTime} 플레이 / ${restTime !== '0분' ? restTime : ''} ${restTimeSec !== '0초' ? restTimeSec : ''} 휴식`
      },
      {
        label: "예약베네핏",
        value: `선착순 ${preRegisterFirstComeCount}명, 등록 시 ${preRegisterBenefitChip.toLocaleString()} 추가 칩`
      },
    ];
  }, [group?.data]);

  const [registerButtonText, disableJoinButton] = useMemo<[string, boolean]>(() => {
    if (isTournamentStarted && isRegistered && stackSize > 0) {
      return ['입장하기', false];
    } else if (isTournamentStarted && isRegistered && stackSize == 0) {
      return ['리바이인', false];
    } else if (!isTournamentStarted && isRegistered) {
      return ['입장 대기', false];
    } else if (isClosed) {
      return ['등록 불가', true];
    }

    return ['등록', false];
  }, [isTournamentStarted, isClosed, isRegistered, stackSize]);

  const handleCancelRegister = useCallback(async() => {
    console.log('isClosed');
    console.log(isClosed);
    console.log(isRegistered);
    console.log(group);

    if (!group || !group.data) {
      return;
    }

    if (isClosed && !isRegistered) {
      return;
    }

    const errorHandle = (result: number) => {
      switch (result) {
        case -1: {
          openDialog({
            title: '취소 불가 안내',
            text: `로그인 정보를 읽어올 수 없습니다. 로그아웃 후 다시 시도해주세요. 문제가 반복되면 고객센터로 연락바랍니다.`
          });
          break;
        }
        case -2: {
          openDialog({
            title: '취소 불가 안내',
            text: `없는 토너먼트 리그입니다. 고객센터로 연락바랍니다.`
          });
          break;
        }
        case -3: {
          openDialog({
            title: '취소 불가 안내',
            text: `해당 토너먼트에 등록되어있지 않기 때문에 취소 불가합니다.`
          });
          break;
        }
        case -4: {
          openDialog({
            title: '취소 불가 안내',
            text: `이미 시작한 토너먼트는 시작할 수 없습니다.`
          });
          break;
        }
        case -999: {
          openDialog({
            title: '취소 불가 안내',
            text: `알 수 없는 오류가 발생하였습니다. 고객센터로 연락바랍니다.`
          });
          break;
        }
      }
    };

    openDialog({
      title: '등록취소 안내',
      text: `토너먼트 등록을 취소하시겠습니까?<br/>시작 전에만 취소하실 수 있으며,<br/>등록 취소시에는 등록에 사용한 금액이 환불처리 됩니다.`,
      confirm: true,
      confirmText: '등록취소',
      cancelText: '취소',
      onConfirm: async () => {
        const r = await requestCancelTournament(group.groupId);
        
        if (r.result < 1) {
          errorHandle(r.result);
        }
        else {
          setRegistered(false);

          openDialog({
            title: '등록 취소 성공 안내',
            text: `토너먼트 등록이 취소되었습니다. 등록비용이 환불처리 되었습니다.`
          });
        }
      }
    });
  }, [group?.groupId, group?.data, isRegistered, isClosed]);

  
  const handleRegister = useCallback(async () => {
    if (!group || !group.data) {
      return;
    }

    if (isClosed && !isRegistered) {
      return;
    }

    const errorHandle = (result: number) => {
      switch (result) {
        case -1: {
          openDialog({
            title: '등록 불가 안내',
            text: `현재 블라인드 레벨이 참여 가능한 레벨이 아닙니다.`
          });
          break;
        }
        case -2: {
          openDialog({
            title: '등록 불가 안내',
            text: `바이인할 머니가 부족합니다.`
          });
          break;
        }
        case -3: {
          openDialog({
            title: '안내',
            text: `게임 시작 10분전 부터 입장할 수 있습니다.`
          });
          break;
        }
        case -4: {
          openDialog({
            title: '등록 불가 안내',
            text: `한번에 한 토너먼트만 등록 가능합니다.`
          });
          break;
        }
        case -5: {
          openDialog({
            title: '등록 불가 안내',
            text: `해당 토너먼트는 종료되었습니다.`
          });
          break;
        }
        case -6: {
          openDialog({
            title: '리바이인 불가 안내',
            text: `이미 리바이인 제한 횟수만큼 리바이인 하셨습니다.`
          });
          break;
        }
        case -7: {
          openDialog({
            title: '입장 불가 안내',
            text: `칩이 없어 입장할 수 없습니다. 리바이인 해주세요.`
          });
          break;
        }
        case -8: {
          openDialog({
            title: '입장 불가 안내',
            text: `칩이 남아있어 리바이인 할 수 없습니다.`
          });
          break;
        }
      }
    };

    if (isRegistered && stackSize == 0) {
      openDialog({
        title: '리바이인 안내',
        text: `리바이인 시 ${group.data.rebuyinChip.toLocaleString()} 을 얻고,<br/>${group.data.rebuyinPrice.toLocaleString()} 를 지출하게 됩니다.`,
        confirm: true,
        confirmText: '리바인',
        cancelText: '취소',
        onConfirm: async () => {
          let r = await requestRebuyinTournament(group.groupId);
          if (typeof (r.result) == "number") {
            if (r.result < 1) {
              errorHandle(r.result);
            } else {
              setStackSize(1);
              navigate("/game?id=" + r.result);
            }
          }
        }
      });
    } else if (isRegistered) {
      let r = await requestRegisterOrJoinTournament(group.groupId);
      if (typeof (r.result) == "number") {
        if (r.result < 1) {
          errorHandle(r.result);
        } else {
          navigate("/game?id=" + r.result);
        }
      } else if (r.result === true) {
        setRegistered(true);
        setStackSize(1);
      }
    } else {
      let isGo = await new Promise(r => {
        const message = group.data.buyinPrice === 0 ? 
        `토너먼트에 등록하시겠습니까?<br/>무료 토너먼트라 비용 없이 바로 등록이 가능합니다.<br/>(리바인은 금액이 필요할 수 있습니다.)` :
        `토너먼트에 등록하시겠습니까?<br/>등록을 위해서는 ${group.data.buyinPrice.toLocaleString()}머니가 필요합니다.`;

        openDialog({
          title: '등록 안내',
          text: message,
          disableBackdropClick: true,
          confirm: true,
          confirmText: "등록",
          cancelText: "취소",
          onConfirm: () => {
            r(true);
          },
          onCancel: () => {
            r(false);
          }
        });
      });

      if (!isGo) return;

      let data = await requestRegisterOrJoinTournament(group.groupId);
      if (data.result === true) {
        openDialog({
          title: '안내',
          text: `토너먼트 등록 완료했습니다.`
        });
        if (!!data.isBenefit && data.isBenefit) {
          openDialog({
            title: '베네핏',
            text: `선착순으로 빨리 등록하셔서,<br/>토너먼트 시작 시 추가칩(${group.data.preRegisterBenefitChip.toLocaleString()})을 받습니다.`
          });
        }
        setRegistered(true);
        setStackSize(1);
      } else if (typeof (data.result) === "number" && data.result > 0) {
        if (!!data.isBenefit && data.isBenefit) {
          openDialog({
            title: '베네핏',
            text: `선착순으로 빨리 등록하셔서,<br/>토너먼트 시작 시 추가칩(${group.data.preRegisterBenefitChip.toLocaleString()})을 받습니다.`
          });
        }
        
        navigate("/game?id=" + data.result);
      } else {
        errorHandle(Number(data.result));
      }
    }
  }, [group?.groupId, group?.data, isRegistered, isClosed]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      navigate("/main/holdem?type=4");
    }
  }, [onClose]);

  useEffect(() => {
    if (!group) {
      return;
    }

    if (group.isEnd && group.isRegister) {
      setStatus('참가종료');
    } else if (group.isEnd && !group.isRegister) {
      setStatus('미참가종료');
    } else if (!group.isEnd && group.isRegister) {
      setStatus('참가');
    }
  }, [group?.isEnd, group?.isRegister]);


  useEffect(() => {
    if (group && group.isEnd) {
      requestGetTournamentGroup(group.groupId).then(data => {
        setRankingData({
          ranking: data.info.ranking,
          totalRegisterCount: data.info.totalRegisterCount
        });
        setGroup(data.info);
        setRegistered(data.info.isRegister);
        if (data.info.stackSize) setStackSize(data.info.stackSize);
      });
    }
  }, [group?.groupId, group?.isEnd]);

  if (!group) {
    return null;
  }

  return <Wrapper>
    <ModalContainer show={showWinner}>
      <WinnerListModal
        prizeData={group.data.prize}
        groupId={group.groupId}
        onClose={() => setShowWinner(false)}/>
    </ModalContainer>
    <div className="header">
      <img className="back" src="/images/ic_header_back.svg" onClick={handleClose}/>
      <div className="title">{group.data.name}</div>
    </div>
    {
      status === '참가' && <div className="gray-box">
        <div className="flexbox v-center">
          <TournamentStatus detail ended={group.isEnd} started={isTournamentStarted} timerText={timerText}/>
          <div className="start-info">
            <div>
              <span>시작</span>{descItems[0].value}
            </div>
            <div>
              <span>시작칩</span>{descItems[2].value}
            </div>
            <div>
              <span>토너먼트 등록금액</span>{printZeroToFree(Number(descItems[1].value))}
            </div>
          </div>
          <Flex/>
          <RegisterButtonWrap>
            <RegisterButton onClick={handleRegister} disabled={disableJoinButton}>
              {registerButtonText}
            </RegisterButton>
            {
              isRegistered ? (
                <RegisterButton onClick={handleCancelRegister} isCancel={true}>
                  등록취소
                </RegisterButton>
              ) : <></>
            }
          </RegisterButtonWrap>
        </div>
        <div className="border"/>
        <div className="flexbox">
          <Prize prizes={group.data.prize} detail/>
          <div className="level-blind">
            <div className="level">
              {
                isIngRest ? (
                  <>
                    <div>레벨 {currentLevel} (휴식중)</div>
                    <div>{restTimeText}</div>
                  </>
                ) : (
                  <>
                    <div>레벨 {currentLevel}</div>
                    <div>{levelTimeText}</div>
                  </>
                )
              }
            </div>
            {
              blind.length === 2 && (
                <div className="blind">
                  <span>블라인드</span>
                  {blind[0].toLocaleString()} / {blind[1].toLocaleString()}
                </div>
              )
            }
            {
              nextBlind.length === 2 && (
                <div className="blind">
                  <span>다음 블라인드</span>
                  {nextBlind[0].toLocaleString()} / {nextBlind[1].toLocaleString()}
                </div>
              )
            }
          </div>
          <PlayerCount count={group.totalRegisterCount ?? 0} max={group.data.maxTotalMember}/>
        </div>
      </div>
    }
    {
      status === '참가종료' && <div className="gray-box">
        <div className="flexbox v-center">
          <TournamentStatus detail ended={group.isEnd} started={isTournamentStarted} timerText={timerText}/>
          <div className="start-info">
            <div>
              <span>시작</span>{descItems[0].value}
            </div>
            <div>
              <span>시작칩</span>{descItems[2].value}
            </div>
            <div>
              <span>바이인</span>{descItems[1].value}
            </div>
          </div>
          <Flex/>
          {
            !group.isForceEnd ? (
              <WinnerButton onClick={() => setShowWinner(true)}>
                우승자 리스트 보기
              </WinnerButton>
            ) : <></>
          }
        </div>
        <div className="border"/>
        <div className="flexbox">
          <Prize prizes={group.data.prize} detail/>
          <div className="level-blind">
            {
              group.isForceEnd ? (
                <div className="level">
                  <div>강제종료</div>
                </div>
              ) : (
                <div className="level">
                  <div>내 순위</div>
                  <div>{rankingData.ranking} / {rankingData.totalRegisterCount.toLocaleString()}</div>
                </div>
              )
            }
            {
              blind.length === 2 && (
                <div className="blind">
                  <span>블라인드</span>
                  {blind[0].toLocaleString()} / {blind[1].toLocaleString()}
                </div>
              )
            }
            {
              nextBlind.length === 2 && (
                <div className="blind">
                  <span>다음 블라인드</span>
                  {nextBlind[0].toLocaleString()} / {nextBlind[1].toLocaleString()}
                </div>
              )
            }
          </div>
          <PlayerCount count={group.totalRegisterCount} max={group.data.maxTotalMember}/>
        </div>
      </div>
    }
    {
      status === '미참가종료' && <div className="gray-box">
        <div className="flexbox v-center">
          <TournamentStatus detail ended={group.isEnd} started={isTournamentStarted} timerText={timerText}/>
          <div className="start-info">
            <div>
              <span>시작</span>{descItems[0].value}
            </div>
            <div>
              <span>시작칩</span>{descItems[2].value}
            </div>
            <div>
              <span>바이인</span>{descItems[1].value}
            </div>
          </div>
          <Flex/>
          {
            !group.isForceEnd ? (
              <WinnerButton onClick={() => setShowWinner(true)}>
                우승자 리스트 보기
              </WinnerButton>
            ) : <></>
          }
        </div>
        <div className="border"/>
        <div className="flexbox">
          <Prize prizes={group.data.prize}/>
          <div className="level-blind">
            <div className="description">
              참여하지 않은 토너먼트입니다.
            </div>
            {
              blind.length === 2 && (
                <div className="blind">
                  <span>블라인드</span>
                  {blind[0].toLocaleString()} / {blind[1].toLocaleString()}
                </div>
              )
            }
            {
              nextBlind.length === 2 && (
                <div className="blind">
                  <span>다음 블라인드</span>
                  {nextBlind[0].toLocaleString()} / {nextBlind[1].toLocaleString()}
                </div>
              )
            }
          </div>
          <PlayerCount count={group.totalRegisterCount} max={group.data.maxTotalMember}/>
        </div>
      </div>
    }
    <ul>
      {
        descItems.map((item, i) => (
          <li key={i}>
            <span>{item.label}</span>{item.value}
          </li>
        ))
      }
    </ul>
    {
      status === '참가' && <>
        <StructureWrapper isOpen={isOpenPrize} onClick={() => setIsOpenPrize(!isOpenPrize)}>
          <div className="title-row">
            <span className="title">상금 구조</span>
            <img className="arrow" src="/images/ic_fold.svg"/>
          </div>
          <div className="content">
            <table>
              <thead>
              <tr className="title bottom">
                <th>등수</th>
                <th className="fixed">상금</th>
              </tr>
              </thead>
              <tbody>
              {
                group.data.prize.map((item: number, index: number) => {
                  return (
                    <tr key={index}>
                      <td className="title">{index + 1}등</td>
                      <td className="fixed value">{item.toLocaleString()}</td>
                    </tr>
                  );
                })
              }
              </tbody>
            </table>
          </div>
        </StructureWrapper>
        <StructureWrapper isOpen={isOpenBlind} onClick={() => setIsOpenBlind(!isOpenBlind)}>
          <div className="title-row">
            <span className="title">블라인드 구조</span>
            <img className="arrow" src="/images/ic_fold.svg"/>
          </div>
          <div className="content">
            <div className="row">
              <span className="title">시작 칩</span>
              <span>10,000 (100BB)</span>
            </div>
            <table>
              <thead>
              <tr className="title top bottom">
                <th className="level">레벨</th>
                <th className="blind">블라인드</th>
                <th className="entity">앤티</th>
                <th className="minute">분</th>
              </tr>
              </thead>
              <tbody>
              {
                group.data.blindStructure.map((item: number[], index: number) => {
                  return <tr key={index}>
                    <td className="level title">{index + 1}</td>
                    <td className="blind value">{item[0].toLocaleString()} / {item[1].toLocaleString()}</td>
                    <td className="entity value">{item[2].toLocaleString()}</td>
                    <td className="minute value">{convertMinutes(item[3])}</td>
                  </tr>;
                })
              }
              </tbody>
            </table>
          </div>
        </StructureWrapper>
      </>
    }
  </Wrapper>;
}

export default TournamentDetail;
