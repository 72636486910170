import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import ToggleSwitch from "../../components/ToggleSwitch";
import {useRecoilState} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import {
  hasCacheGameDataState
} from "../../recoil/Game";
import {playBGM, playSFX, Sounds, stopBGM} from "../../utils/sound";
import {throttle} from "lodash";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const StyleSettingWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: #151515;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media ${MEDIA_DESKTOP} {
    top: 60px;
    height: calc(100% - 60px);
    background: white;
  }

  > .content-wrapper {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    @media ${MEDIA_DESKTOP} {
      max-width: 470px;
      padding-top: 24px;
    }

    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px 20px;
      gap: 20px;
      @media ${MEDIA_DESKTOP} {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`
const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  @media ${MEDIA_DESKTOP} {
    border-bottom: 1px solid #DDD;
    background: #F5F5F5;
    height: 57px;
  }

  > .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
  }

  > .title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media ${MEDIA_DESKTOP} {
      position: relative;
      width: 470px;
      color: var(--Black, #181818);
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 20px */
    }

    > .close {
      display: none;
      @media ${MEDIA_DESKTOP} {
        cursor: pointer;
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }
    }
  }
`

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  border-bottom: 1px solid #25262D;
  padding-top: 16px;
  padding-bottom: 16px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border: none;
  }

  > .title-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      color: #FFF;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
      @media ${MEDIA_DESKTOP} {
        color: var(--Black, #181818);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.5px;
      }

      > .percent {
        color: #E31D1D;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          color: var(--Point-Mint, #169E7A);
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }
    }

    > .radio-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 20px;

      > .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;
        cursor: pointer;

        span {
          color: #FFF;
          text-align: center;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.2px;
        }
      }
    }
  }

  > .sound-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;

    > .slider-wrapper {
      width: 100%;
      padding: 0 4px;
    }

    .rc-slider {
      padding: 2px 0 !important;
    }

    .rc-slider-handle {
      opacity: 1 !important;
      box-shadow: none !important;
    }


    > .toggle {
      flex-shrink: 0;
      height: 24px;
      width: 64px;
    }
  }

  > .content {
    @media ${MEDIA_DESKTOP} {
      width: 100%;
    }

    .swiper {
      width: 91px !important;
    }

    .swiper-slide {
      width: 91px !important;
    }

    .swiper.card {
      width: 192px !important;
      @media ${MEDIA_DESKTOP} {
        width: 380px !important;
      }
    }

    .swiper-slide.card {
      width: 192px !important;
      @media ${MEDIA_DESKTOP} {
        width: 380px !important;
      }
    }

    .swiper-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;

      > .desc {
        border-radius: 8px;
        background: #25262D;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 96px;
        height: 40px;
        color: #FFF;
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 16.8px */
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          width: 150px;
          border-radius: 8px;
          background: #F6F6F6;
          color: var(--Black, #181818);
          text-align: center;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 19.6px */
          letter-spacing: -0.5px;
          height: 44px;
        }
      }

      > .nav {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;

      }

      .arrow-button {
        width: 24px;
        height: 100%;
        cursor: pointer;
      }

      .arrow-button.next {
        transform: rotate(180deg);
      }


      .card-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
        @media ${MEDIA_DESKTOP} {
          gap: 20px;
          > img {
            width: 80px;
            height: 112px;
          }
        }
      }

      .table-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > img {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    .swiper-container.card {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 16px;
    }
  }

  > .content.table {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

interface StyleSettingProps {
  onClose: () => void;
}

const RadioButton = ({checked}: { checked: boolean }) => {
  return checked ? <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <circle cx="8" cy="8.5" r="8" fill="#E31D1D"/>
    <circle cx="8" cy="8.5" r="4" fill="white"/>
  </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
    <circle cx="8" cy="8.5" r="7.5" stroke="#272727"/>
  </svg>
}

const StyleSetting = ({onClose}: StyleSettingProps) => {
  const [hasCacheGameData, setHasCacheGameData] = useRecoilState(hasCacheGameDataState);
  const orientation = useScreenOrientation();
  const [setting, setSetting] = useRecoilState(gameOptionState);
  const [cardSwiper, setCardSwiper] = useState<any>(null);
  const [tableSwiper1, setTableSwiper1] = useState<any>(null);
  const [tableSwiper2, setTableSwiper2] = useState<any>(null);
  const [tableSwiper3, setTableSwiper3] = useState<any>(null);
  const playUp = useCallback(throttle(() => {
    playSFX(Sounds.SFX_BUY_IN_UP);
  }, 100), []);
  const playDown = useCallback(throttle(() => {
    playSFX(Sounds.SFX_BUY_IN_DOWN);
  }, 100), []);
  return <StyleSettingWrapper>
    <Header>
      <img className='close' src='/images/ic_arrow_back.png' onClick={() => onClose()}/>
      <div className='title'><img onClick={() => onClose()} className={'close'} src='/images/pc/Back.png'/>스타일 설정</div>
    </Header>
    <div className='content-wrapper'>
      <div className='content'>
        <Section>
          <div className='title-wrapper'>
            <div className='title'>카드 디자인</div>
          </div>
          <div className='content'>
            <div className='swiper-container card'>
              <img className='arrow-button' onClick={() => {
                if (cardSwiper) {
                  playSFX(Sounds.SFX_TOGGLE, true);
                  cardSwiper.slidePrev();
                }
              }} src={orientation === 'portrait' ? '/images/ic_arrow_back.png' : '/images/pc/Back.png'}/>
              <Swiper
                onSwiper={(swiper: any) => setCardSwiper(swiper)}
                scrollbar={{
                  hide: true,
                }}
                loop={true}
                className="card"
                initialSlide={setting.cardStyle - 1}
                onSlideChange={() => {
                  if (cardSwiper) {
                    setSetting({...setting, cardStyle: cardSwiper.realIndex + 1})
                  }
                }}
              >
                {
                  Array.from({length: 3}).map((_, index) => {
                    return <SwiperSlide className='card' key={index}>
                      <div className='card-wrapper'>
                        <img src={`/images/card/style-${index + 1}/38.svg`}/>
                        <img src={`/images/card/style-${index + 1}/51.svg`}/>
                        <img src={`/images/card/style-${index + 1}/25.svg`}/>
                        <img src={`/images/card/style-${index + 1}/12.svg`}/>
                      </div>
                    </SwiperSlide>
                  })
                }
              </Swiper>
              <img className='arrow-button next' onClick={() => {
                if (cardSwiper) {
                  cardSwiper.slideNext();
                  playSFX(Sounds.SFX_TOGGLE, true);
                }
              }} src={orientation === 'portrait' ? '/images/ic_arrow_back.png' : '/images/pc/Back.png'}/>
            </div>
          </div>
        </Section>
        <Section>
          <div className='title-wrapper'>
            <div className='title'>테이블 디자인</div>
          </div>
          <div className='content table'>
            <div className='swiper-container'>
              <div className='desc'>
                200-1,000
              </div>
              <Swiper
                initialSlide={setting.tableStyle_LV1 - 1}
                onSwiper={(swiper: any) => setTableSwiper1(swiper)}
                scrollbar={{
                  hide: true,
                }}
                loop={true}
                onSlideChange={() => {
                  if (tableSwiper1) {
                    setSetting({...setting, tableStyle_LV1: tableSwiper1.realIndex + 1})
                    setHasCacheGameData(0);
                  }
                }}
              >
                {
                  Array.from({length: 3}).map((_, index) => {
                    return <SwiperSlide key={index}>
                      <div className='table-wrapper'>
                        <img src={`/images/game/table_portrait_type_${index + 1}.png`}/>
                      </div>
                    </SwiperSlide>
                  })
                }
              </Swiper>
              <div className='nav'>
                <img className='arrow-button' onClick={() => {
                  if (tableSwiper1) {
                    tableSwiper1.slidePrev();
                    playSFX(Sounds.SFX_TOGGLE, true);
                  }
                }} src={orientation === 'portrait' ? '/images/ic_arrow_back.png' : '/images/pc/Back.png'}/>
                <img className='arrow-button next' onClick={() => {
                  if (tableSwiper1) {
                    tableSwiper1.slideNext();
                    playSFX(Sounds.SFX_TOGGLE, true);
                  }
                }} src={orientation === 'portrait' ? '/images/ic_arrow_back.png' : '/images/pc/Back.png'}/>
              </div>
            </div>
            <div className='swiper-container'>
              <div className='desc'>
                1,000-10,000
              </div>
              <Swiper
                initialSlide={setting.tableStyle_LV2 - 1}
                onSwiper={(swiper: any) => setTableSwiper2(swiper)}
                scrollbar={{
                  hide: true,
                }}
                loop={true}
                onSlideChange={() => {
                  if (tableSwiper2) {
                    setSetting({...setting, tableStyle_LV2: tableSwiper2.realIndex + 1})
                    setHasCacheGameData(0);
                  }
                }}
              >
                {
                  Array.from({length: 3}).map((_, index) => {
                    return <SwiperSlide key={index}>
                      <div className='table-wrapper'>
                        <img src={`/images/game/table_portrait_type_${index + 1}.png`}/>
                      </div>
                    </SwiperSlide>
                  })
                }
              </Swiper>
              <div className='nav'>
                <img className='arrow-button' onClick={() => {
                  if (tableSwiper2) {
                    tableSwiper2.slidePrev();
                    playSFX(Sounds.SFX_TOGGLE, true);
                  }
                }} src={orientation === 'portrait' ? '/images/ic_arrow_back.png' : '/images/pc/Back.png'}/>
                <img className='arrow-button next' onClick={() => {
                  if (tableSwiper2) {
                    tableSwiper2.slideNext();
                    playSFX(Sounds.SFX_TOGGLE, true);
                  }
                }} src={orientation === 'portrait' ? '/images/ic_arrow_back.png' : '/images/pc/Back.png'}/>
              </div>
            </div>
            <div className='swiper-container'>
              <div className='desc'>
                10,000-20,000
              </div>
              <Swiper
                initialSlide={setting.tableStyle_LV3 - 1}
                onSwiper={(swiper: any) => setTableSwiper3(swiper)}
                scrollbar={{
                  hide: true,
                }}
                loop={true}
                onSlideChange={() => {
                  if (tableSwiper3) {
                    setSetting({...setting, tableStyle_LV3: tableSwiper3.realIndex + 1})
                    setHasCacheGameData(0);
                  }
                }}
              >
                {
                  Array.from({length: 3}).map((_, index) => {
                    return <SwiperSlide key={index}>
                      <div className='table-wrapper'>
                        <img src={`/images/game/table_portrait_type_${index + 1}.png`}/>
                      </div>
                    </SwiperSlide>
                  })
                }
              </Swiper>
              <div className='nav'>
                <img className='arrow-button' onClick={() => {
                  if (tableSwiper3) {
                    tableSwiper3.slidePrev();
                    playSFX(Sounds.SFX_TOGGLE, true);
                  }
                }} src={orientation === 'portrait' ? '/images/ic_arrow_back.png' : '/images/pc/Back.png'}/>
                <img className='arrow-button next' onClick={() => {
                  if (tableSwiper3) {
                    tableSwiper3.slideNext();
                    playSFX(Sounds.SFX_TOGGLE, true);
                  }
                }} src={orientation === 'portrait' ? '/images/ic_arrow_back.png' : '/images/pc/Back.png'}/>
              </div>
            </div>
          </div>
        </Section>
        <Section>
          <div className='title-wrapper'>
            <div className='title'>배경음악
              <div className='percent'>
                {setting.backgroundMusic?.volume ?? 100}%
              </div>
            </div>
          </div>
          <div className='sound-content'>
            <div className='slider-wrapper'>
              <Slider
                min={0}
                max={100}
                value={setting.backgroundMusic?.volume ?? 100}
                onChange={(value) => {
                  if ((setting.backgroundMusic?.volume ?? 100) > (value as number)) {
                    playDown();
                  } else {
                    playUp();
                  }
                  setSetting({
                    ...setting,
                    backgroundMusic: {
                      ...setting.backgroundMusic,
                      volume: value as number
                    }
                  });
                }}
                trackStyle={{
                  backgroundColor: orientation === 'portrait' ? '#E31D1D' : '#169E7A',
                  height: 10
                }}
                handleStyle={{
                  border: 'none',
                  height: 14,
                  width: 14,
                  marginLeft: 0,
                  marginTop: -2,
                  background: 'linear-gradient(180deg, #EDEDED 0%, #BEBEBE 100%)',
                }}
                railStyle={{
                  backgroundColor: '#25262D',
                  height: 10
                }}
              />
            </div>
            <ToggleSwitch
              checked={!setting?.backgroundMusic?.muted}
              onChange={(value) => {
                setSetting({
                  ...setting,
                  backgroundMusic: {
                    ...setting.backgroundMusic,
                    muted: !value
                  }
                });
              }}
            />
          </div>
        </Section>
        <Section>
          <div className='title-wrapper'>
            <div className='title'>효과음
              <div className='percent'>
                {setting?.effectSound?.volume ?? 100}%
              </div>
            </div>
          </div>
          <div className='sound-content'>
            <div className='slider-wrapper'>
              <Slider
                min={0}
                max={100}
                value={setting?.effectSound?.volume ?? 100}
                onChange={(value) => {
                  if ((setting.effectSound?.volume ?? 100) > (value as number)) {
                    playDown();
                  } else {
                    playUp();
                  }
                  setSetting({
                    ...setting,
                    effectSound: {
                      ...setting.effectSound,
                      volume: value as number
                    }
                  });
                }}
                trackStyle={{
                  backgroundColor: orientation === 'portrait' ? '#E31D1D' : '#169E7A',
                  height: 10
                }}
                handleStyle={{
                  border: 'none',
                  height: 14,
                  width: 14,
                  marginLeft: 0,
                  marginTop: -2,
                  background: 'linear-gradient(180deg, #EDEDED 0%, #BEBEBE 100%)',
                }}
                railStyle={{
                  backgroundColor: '#25262D',
                  height: 10
                }}
              />
            </div>
            <ToggleSwitch
              checked={!setting?.effectSound?.muted}
              onChange={(value) => {
                setSetting({
                  ...setting,
                  effectSound: {
                    ...setting.effectSound,
                    muted: !value
                  }
                });
              }}
            />
          </div>
        </Section>
        {/*  <Section>
          <div className='title-wrapper'>
            <div className='title'>보이스 효과</div>
            <ToggleSwitch
              checked={setting?.voiceEffect}
              onChange={(value) => {
                setSetting({
                  ...setting,
                  voiceEffect: value
                });
              }}
            />
          </div>
          <div className='content'></div>
        </Section>*/}
        {/*<Section>
          <div className='title-wrapper'>
            <div className='title'>칩 단위</div>
            <div className='radio-wrapper'>
              <div className='item' onClick={() => {
                setSetting({...setting, alwaysBB: false})
              }}>
                <RadioButton checked={
                  !setting.alwaysBB
                }/>
                <span>금액 표시</span>
              </div>
              <div className='item' onClick={() => {
                setSetting({...setting, alwaysBB: true})
              }}>
                <RadioButton checked={
                  setting.alwaysBB
                }/>
                <span>BB 표시</span>
              </div>
            </div>
          </div>
        </Section>*/}
      </div>
    </div>
  </StyleSettingWrapper>
}

export default StyleSetting;
