import styled from "styled-components";
import {
  requestGetPointHistory,
  requestGetRecommandUserList,
  requestMyInfo,
  requestRecomPointHistory,
  requestSwapRecommandPoint
} from "../../api";
import React, {useEffect, useState} from "react";
import {startLoading, stopLoading} from "../../components/Loading";
import useDialog from "../../hooks/useDialog";
import {MyInfo, PointHistoryInfo, RecommendedUser, RecommendPointSwapHistory, TX_TYPE} from "../../dataset";
import {copyClipboard} from "../../utils/common";
import moment from "moment";
import {playSFX, Sounds} from "../../utils/sound";
import useScreenOrientation, { MEDIA_DESKTOP } from "../../hooks/useScreenOrientation";

const ReferralWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: #151515;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media ${MEDIA_DESKTOP} {
    top: 60px;
    height: calc(100% - 60px);
    background: white;
  }
  > .content-wrapper {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    @media ${MEDIA_DESKTOP} {
      padding-top: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 4px;
      @media ${MEDIA_DESKTOP} {
        padding-left: 0;
        padding-right: 0;
        gap: 0;
      }
    }
  }
`
const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  @media ${MEDIA_DESKTOP} {
    border-bottom: 1px solid #DDD;
    background: #F5F5F5;
    height: 57px;
  }

  > .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
  }

  > .title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media ${MEDIA_DESKTOP} {
      position: relative;
      width: 470px;
      color: var(--Black, #181818);
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 20px */
    }

    > .close {
      display: none;
      @media ${MEDIA_DESKTOP} {
        cursor: pointer;
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }
    }
  }
`
const MyPointWrapper = styled.div`
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 12px;
  @media ${MEDIA_DESKTOP} {
    max-width: 470px;
    padding-top: 16px;
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
  }

  > .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > .vertical-bar {
      width: 1px;
      height: 24px;
      background: #25262D;
      flex-shrink: 0;
      @media ${MEDIA_DESKTOP} {
        background: #ddd;
      }
    }

    > .item {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;

      &:last-child {
        padding: 12px;
      }

      > .title {
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        opacity: 0.4;
        @media ${MEDIA_DESKTOP} {
          color: var(--Black, #181818);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }

      > .value-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;

        > .value {
          color: #FFF;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 19.6px */
          letter-spacing: -0.5px;
          @media ${MEDIA_DESKTOP} {
            color: var(--Black, #181818);
            font-family: Pretendard;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 22.4px */
            letter-spacing: -0.5px;
          }
        }

        > .copy {
          display: flex;
          height: 20px;
          padding: 0px 2px;
          justify-content: center;
          align-items: center;
          border-radius: 4px;
          background: #262838;
          color: #AAA;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;

          @media ${MEDIA_DESKTOP} {
            border-radius: 4px;
            background: rgba(22, 158, 122, 0.10);
            color: var(--Point-Mint, #169E7A);
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.5px;
          }
          > img {
            width: 16px;
            height: 16px;
            object-fit: contain;
          }
        }
      }
    }
  }

  > .benefit {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 0;
    border-radius: 8px;
    background: #25262D;
    @media ${MEDIA_DESKTOP} {
      padding: 20px 0 ;
      border-radius: 8px;
      background: #F6F6F6;
    }

    > .title {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 2px;
      @media ${MEDIA_DESKTOP} {
        color: var(--Black, #181818);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: 2px;
      }
    }

    > .description {
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.5px;
      @media ${MEDIA_DESKTOP} {
        color: var(--Black, #181818);
        text-align: center;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 22.4px */
        letter-spacing: -0.5px;
      }
      > .bold {
        font-weight: 600;
        color: #E31D1D;
      }
    }
  }

`
const CurrentPointWrapper = styled.div`
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  @media ${MEDIA_DESKTOP} {
    width: 100%;
    max-width: 470px;
    padding-top: 0px;
    padding-left: 0;
    padding-right: 0;
  }
  > .current-point {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    > .title {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
      opacity: 0.4;
      @media ${MEDIA_DESKTOP} {
        color: var(--Black, #181818);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
      }
    }

    > .value {
      color: var(--Point-Red, #E31D1D);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 28px */
      letter-spacing: -0.5px;
      @media ${MEDIA_DESKTOP} {
        color: var(--Point-Mint, #169E7A);
        font-family: Pretendard;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 33.6px */
        letter-spacing: -0.5px;
      }
    }
  }

  > .button {
    cursor: pointer;
    width: 100%;
    height: 42px;
    border-radius: 6px;
    background: var(--Gradient-Red, linear-gradient(180deg, #DF3535 0%, #A51818 100%));
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media ${MEDIA_DESKTOP} {
      border-radius: 22px;
      width: 240px;
      height: 44px;
      background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
      color: #FFF;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  > .button.disabled {
    opacity: 0.5;
    background: #25262D;
  }

  > .description {
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    opacity: 0.4;
    @media ${MEDIA_DESKTOP} {
      color: var(--Black, #181818);
      text-align: center;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }
`
const PointListWrapper = styled.div`
  padding: 24px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  @media ${MEDIA_DESKTOP} {
    width: 100%;
    background: #F5F5F5;
    flex-grow: 1;
    padding-right: 0;
    padding-left: 0;
    align-items: center;
  }
  >.title-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > .title {
      color: #FFF;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
      text-align: left;
      @media ${MEDIA_DESKTOP} {
        color: #181818;
      }
    }
  }

  > .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 12px;
    @media ${MEDIA_DESKTOP} {
      max-width: 470px;
    }

    > .horizontal-bar {
      width: 100%;
      height: 1px;
      background: #25262D;
      @media ${MEDIA_DESKTOP} {
        background: #ddd;
      }
    }

    > .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      > .item {
        flex: 1;
        color: #FFF;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          color: var(--Black, #181818);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.2px;
        }
      }

      > .item.head {
        color: #B4B4B4;
      }

      > .item.red {
        color: #E31D1D;
      }

      .right {
        text-align: right;
      }

      .center {
        text-align: center;
      }

      .left {
        text-align: left;
      }
    }
  }
`
const CategoryWrapper = styled.div`
  width: 100%;
  @media ${MEDIA_DESKTOP} {
    background: #F5F5F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  >.inner {
    padding-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media ${MEDIA_DESKTOP} {
      background: #F5F5F5;
      width: 100%;
      max-width: 470px;
    }
    > .item {
      cursor: pointer;
      padding-bottom: 14px;
      border-bottom: 1px solid #25262D;
      flex: 1;
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media ${MEDIA_DESKTOP} {
        padding-bottom: 20px;
        border-bottom: 1px solid #F5F5F5;
        color: var(--Black, #181818);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    > .selected {
      border-bottom: 1px solid #FFF;
      @media ${MEDIA_DESKTOP} {
        border-bottom: 1px solid var(--Point-Mint, #169E7A);
      }
    }
  }
`

const HorizontalBarWrapper = styled.div`
  width: 100%;
  height: 12px;
  flex-shrink: 0;
  background: #25262D;
  @media ${MEDIA_DESKTOP} {
    display: none;
  }
`
const EmptyWrapper = styled.div`
  margin-top: 48px;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
  @media ${MEDIA_DESKTOP} {
    color: var(--Black, #181818);
    font-size: 16px;
  }
`


interface NoteHistoryProps {
  onClose: () => void;
}

const Referral = ({onClose}: NoteHistoryProps) => {
  const orientation = useScreenOrientation()
  const {openDialog} = useDialog()
  const [myInfo, setMyInfo] = useState<MyInfo| null>(null);
  const [pointList, setPointList] = useState<RecommendedUser[]>([]);
  const [pointHistoryList, setPointHistoryList] = useState<RecommendPointSwapHistory[]>([]);
  const [refresh, setRefresh] = useState(new Date().getTime());
  const [selectPointHistoryType, setSelectPointHistoryType] = useState<'referral'|'history'>('referral');
  useEffect(() => {
    requestMyInfo().then((res) => {
      setMyInfo(res.info)
    })
  }, [refresh]);
  useEffect(() => {
    requestGetRecommandUserList().then(res => {
      setPointList(res.list)
    })
    requestRecomPointHistory(0).then(res => {
      setPointHistoryList(res.list)
    })
  }, [refresh, selectPointHistoryType]);
  const handleSwapPoint = () => {
    if(myInfo == null) return
    if (myInfo.recomPoint < 10000) return
    const point = myInfo.recomPoint
    const loading = startLoading()
    requestSwapRecommandPoint(point).then((res) => {
      if (res.result) {
        openDialog({
          title: '포인트 전환',
          text: `${point.toLocaleString()} 포인트를 전환하였습니다.`,
          disableBackdropClick: true,
        })
      } else {
        openDialog({
          title: '포인트 전환',
          text: '포인트 전환에 실패하였습니다.',
          disableBackdropClick: true,
        })
      }
    }).finally(() => {
      stopLoading(loading)
      setRefresh(new Date().getTime())
    })
  }
  return <ReferralWrapper>
    <Header>
      <img className='close' src='/images/ic_arrow_back.png' onClick={() => onClose()}/>
      <div className='title'><img onClick={() => onClose()} className={'close'} src='/images/pc/Back.png'/>추천코드 포인트</div>
    </Header>
    {
      myInfo && <div className='content-wrapper'>
        <MyPointWrapper>
          <div className='row'>
            <div className='item'>
              <div className='title'>내 추천코드</div>
              <div className='value-row'>
                <div className='value'>고객센터 문의</div>
              </div>

            </div>
            <div className='vertical-bar'/>
            <div className='item'>
              <div className='title'>총 획득 포인트</div>
              <div className='value-row'>
                <div className='value'>{(myInfo.totRecomPoint ?? 0).toLocaleString()}</div>
              </div>
            </div>
          </div>
          <div className='benefit'>
            <div className='title'>BENEFIT</div>
            <div className='description'>
              추천코드를 입력한 유저 배팅금액의<br/>
              <span className='bold'>{myInfo.recommendPointRate}%</span> 포인트 지급
            </div>
          </div>
        </MyPointWrapper>
        <HorizontalBarWrapper/>
        <CurrentPointWrapper>
          <div className='current-point'>
            <div className='title'>
              현재 보유 포인트
            </div>
            <div className='value'>
              {myInfo.recomPoint.toLocaleString()}
            </div>
          </div>
          <div className={'button ' + (myInfo.recomPoint < 10000 ? 'disabled' : '')} onClick={handleSwapPoint}>포인트 전환</div>
          <div className='description'>
            * 10,000 포인트 이상부터 전환 가능합니다.
          </div>
        </CurrentPointWrapper>
        <HorizontalBarWrapper/>
        <CategoryWrapper>
          <div className='inner'>
            <div className={'item ' + (selectPointHistoryType === 'referral' ? 'selected' : '')} onClick={() => {
              playSFX(Sounds.SFX_TOGGLE, true)
              setSelectPointHistoryType('referral')
            }}>
              추천코드 포인트 내역
            </div>
            <div className={'item ' + (selectPointHistoryType === 'history' ? 'selected' : '')} onClick={() => {
              playSFX(Sounds.SFX_TOGGLE, true)
              setSelectPointHistoryType('history')
            }}>
              포인트 전환 내역
            </div>
          </div>
        </CategoryWrapper>
        <PointListWrapper>
          {
            selectPointHistoryType === 'referral' && <>
              <div className='list'>
                <div className='row'>
                  <div className='item head left'>
                    닉네임
                  </div>
                  <div className='item head left'>
                    최근 접속
                  </div>
                  <div className='item head center'>
                    총 배팅
                  </div>
                  <div className='item head right'>
                    포인트
                  </div>
                </div>
                <div className='horizontal-bar'/>
                {
                  pointList.map((recommendUser, i) => {
                    return <div className='row' key={i}>
                      <div className='item left'>
                        {recommendUser.nickname}
                      </div>
                      <div className='item left'>
                        {recommendUser.lastLogin.replace("T"," ").split(".")[0]}
                      </div>
                      <div className='item center'>
                        {Number(recommendUser.totalBet).toLocaleString()}
                      </div>
                      <div className='item right red'>
                        {Number(recommendUser.totalPoint).toLocaleString()}
                      </div>
                    </div>
                  })
                }
                {
                  pointList.length === 0 && <EmptyWrapper>
                    포인트 내역이 없습니다.
                  </EmptyWrapper>
                }
              </div>
            </>
          }
          {
            selectPointHistoryType === 'history' && <>
              <div className='list'>
                <div className='row'>
                  <div className='item head left'>
                    전환 일시
                  </div>
                  <div className='item head center'>
                    금액
                  </div>
                </div>
                <div className='horizontal-bar'/>
                {
                  pointHistoryList.map((item, i) => {
                    return <div className='row' key={i}>
                      <div className='item left'>
                        {item.addedAt.replace("T"," ").split(".")[0]}
                      </div>
                      <div className='item center'>
                        {Number(item.amount).toLocaleString()}
                      </div>
                    </div>
                  })
                }
                {
                  pointHistoryList.length === 0 && <EmptyWrapper>
                    포인트 전환 내역이 없습니다.
                  </EmptyWrapper>
                }
              </div>
            </>
          }

        </PointListWrapper>
      </div>
    }

  </ReferralWrapper>
}

export default Referral;
