/* eslint-disable */
//auto gen
import {
  BET_TYPE,
  GAME_STATUS,
  MyPlayStatus,
  ROOM_JOIN_STATUS,
  ROOM_STATUS,
  ROOM_TYPE,
  RoomInfo,
  RoomStatus,
  winnerModel,
  RAKE_TYPE,
  GroupInfo,
  emitAfterBetModel,
  requestBetModel,
  handHistoryModel,
  GamePlayer,
  userProfileModel,
  tournamentRankingModel,
  winnerListModel,
  TX_TYPE,
  SimpleTournamentUser,
  CreateRingRoomInterface,
  CreateTournamentRoomInterface,
  chatModel,
  UserTransactionData,
  VAccInfo,
  PointHistoryInfo,
  AttendanceHistoryInfo,
  UserMoneyUpdateType,
  NoticeSimpleInfo,
  NoticeInfo,
  BannerInfo,
  RecommendedUser,
  MainBannerInfo
} from '../dataset/common'
import { client, SockClientOpt, OnRecvData } from "./index";
import { useEffect, useState, useLayoutEffect } from "react";

const hash = require('object-hash');
type StoreType = {
  [_: string]: { result: handHistoryModel[]; blind: number[]; endDate: string; roomId: number; }
}
type StoreFunc = (data: { result: handHistoryModel[]; blind: number[]; endDate: string; roomId: number; }) => void

let store: StoreType = {};
let subscribeEvent: { [_: string]: StoreFunc[] } = {}
export const request = function(groupId:number ,roomId:number ,gameNumber:number ,opt?:SockClientOpt) : Promise<{ result: handHistoryModel[]; blind: number[]; endDate: string; roomId: number; }>{
  return new Promise((resolve, reject) => {
    client.emit("game:getHandHistory", groupId,roomId,gameNumber, async function ack(data:any){
      resolve(await OnRecvData(data));

      let key = opt?.useKey ? hash([opt.useKey]) : hash([groupId,roomId,gameNumber]);
      if(opt?.useStore){
        store[key] = data;
      }

      if (subscribeEvent[key]) {
        for (let evt of subscribeEvent[key]) {
          evt(data);
        }
      }
    });
  })
}


function subscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  subscribeEvent[key].push(func)
}
function unsubscribe(key: string, func: StoreFunc) {
  subscribeEvent[key] = subscribeEvent[key] || [];
  let idx = subscribeEvent[key].findIndex(e => e == func);
  if (idx >= 0) subscribeEvent[key].splice(idx, 1)
}
setTimeout(()=>{
  client.on("game:getHandHistory", (data:any)=>{
    for(let key of Object.keys(subscribeEvent)){
      for(let evt of subscribeEvent[key]){
        evt(data);
      }
    }
  });
}, 100);

export default function (groupId:number ,roomId:number ,gameNumber:number ,opt?:SockClientOpt) : [{ result: handHistoryModel[]; blind: number[]; endDate: string; roomId: number; } | null, Function] {
  
  let key = opt?.useKey ? hash([opt.useKey]) : hash([groupId,roomId,gameNumber]);
  const [Data, setData] = useState<{ result: handHistoryModel[]; blind: number[]; endDate: string; roomId: number; } | null>(opt?.useStore ? (store[key]) : null);

  useLayoutEffect(() => {
    const handleChange = (data: { result: handHistoryModel[]; blind: number[]; endDate: string; roomId: number; })=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[groupId]);

  useEffect(() => {
    const handleChange = (data: { result: handHistoryModel[]; blind: number[]; endDate: string; roomId: number; })=>setTimeout(()=>setData(data), 0)

    subscribe(key, handleChange);
    return () => {
      unsubscribe(key, handleChange);
    };
  },[groupId]);

  return [Data, () => request(groupId,roomId,gameNumber,opt)]

}
