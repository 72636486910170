import {useCallback, useEffect, useMemo, useState} from "react";
import styled from "styled-components";
import produce from 'immer';
import Modal from "./common/Modal";
import {requestGetUserProfile, requestModifyUserProfile, requestToggleEmojiBan, requestModifyUserProfileMemo, requestMyInfo, requestReport} from "../api";
import {userProfileModel} from "../dataset";
import useDialog from "../hooks/useDialog";
import {useRecoilState, useSetRecoilState} from "recoil";
import {myInfoState} from "../recoil/MyInfo";
import numbro from "numbro";
import {globalLoadingState} from "../recoil/Loading";
import useScreenOrientation, {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";
import ModalContainer from "../components/common/ModalContainer";
import UserStatusHelpModal from "../components/UserStatusHelpModal";
const Content = styled.div`
  padding: 8px;
  color: #FFF;

  >.decx {
    display: flex;
    margin-bottom: 16px;
    align-items: center;
    >.item {
      background: #225f47;
      cursor: pointer;
      margin-right: 8px;
      padding-right: 9px;
      border-radius: 10px;
      font-size: 12px;
      font-weight: 500;
      align-items: center;
      display: flex;
      >.img-wrapper {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: #262838;
        @media ${MEDIA_DESKTOP} {
          background: rgba(255, 255, 255, 0.70);
        }
        > img {
          width: 16px;
          height: 16px;
        }
      }
      >span {
        padding-left: 6px;
      }
    }
    >.emoji {
      background: rgb(235 64 52);
      >.img-wrapper {
        @media ${MEDIA_DESKTOP} {
          background: #f8f9f3;
        }
      }
    }
    >.off {
      background: rgb(99 61 59);
      >.img-wrapper {
        border: 3px solid #eb4034;
      }
    }
  }
`;
const MemoWrap = styled.div`
  textarea {
    width: 100%;
    background: rgba(0,0,0,0.2);
    border: 1px solid #2d2d2d;
    padding: 8px;
    font-size: 13px;
    color: #fff;
    min-height: 65px;
    -webkit-user-select : auto;
  }
`;


const Border = styled.div`
  width: 100%;
  min-height: 1px;
  background-color: #2D2D2D;
`;

const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;

  > .image {
    width: 54px;
    height: 54px;
    border-radius: 50%;
    object-fit: contain;
    border: 2px solid #cfcfcf;
  }

  > .info {
    flex: 1;

    > .name {
      font-size: 16px;
      font-weight: 600;
    }

    > .hand {
      font-size: 12px;
      margin-top: 4px;

      > span {
        opacity: 0.5;
        margin-right: 4px;
      }

    }
  }
`;

const ModifyButton = styled.div`
  width: 66px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 8px;
  border-radius: 6px;
  background: #1F1F1F;
`;

const ReportButton = styled(ModifyButton)`
  color: #FF2525;
  border: 1px solid #FF2525;
  background: transparent;
`;

const Stats = styled.div`
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 30px;

  > .grid {
    display: grid;
    grid-template-columns: repeat(3, 85px);
    grid-auto-rows: auto;
    grid-gap: 20px 8px;
    padding: 16px 4px;
  }

  > .caption {
    opacity: 0.5;
  }
`;

const StatItem = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  > div {
    &:first-child {
      font-size: 12px;
      opacity: 0.5;
    }
  }
`;

const StatGauge = styled.div<{
  percentage: string
}>`
  width: 52px;
  height: 4px;
  background: #2D2D2D;
  border-radius: 2px;
  overflow: hidden;
  position: relative;

  &::after {
    position: absolute;
    left: 0;
    top: 0;
    width: ${p => p.percentage}%;
    height: 100%;
    border-radius: 2px;
    background: #E31D1D;
    content: ' ';
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 11px;

  > div {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;

    &:nth-child(1) {
      background: #2D2D2D;
    }

    &:nth-child(2) {
      background: var(--Primary-blue, #2F99FB);
    }
  }
`;

const ModalTitle = styled.div`
  font-size: 16px;
  margin-top: 8px;
`;

const NicknameForm = styled.div`
  margin-top: 24px;
  margin-bottom: 8px;

  > div {
    &:first-child {
      font-size: 12px;
      opacity: 0.5;
      margin-bottom: 8px;
    }

    &:last-child {
      display: flex;

      > input {
        flex: 1;
        color: #FFF;
        font-size: 16px;
        font-weight: 600;
        background: transparent;
        border: none;
        outline: none;
      }

      > .limit {
        font-size: 12px;
        font-weight: 500;
        opacity: 0.5;
      }
    }
  }


`;

const SelectImage = styled.div`
  margin-top: 20px;
  margin-bottom: 30px;

  > div {
    &:first-child {
      font-size: 12px;
      font-weight: 500;
      opacity: 0.5;
    }
  }

  > .grid {
    display: grid;
    grid-template-columns: repeat(4, 64px);
    grid-auto-rows: auto;
    grid-gap: 8px;
    padding: 16px 0;

    > .profile-image-wrapper {
      position: relative;
      width: 64px;
      height: 64px;

      > .profile-image {
        object-fit: contain;
        width: 100%;
        border-radius: 50%;
        z-index: 1;
        //border: 2px solid transparent;
      }

      > .selected {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        border-radius: 50%;
        height: 100%;
        z-index: 5;
        border: 2px solid #2F99FB;
        background: rgba(16, 16, 18, 0.50);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

const ReportWrapper = styled.div`
  min-width: 311px;
  min-height: 180px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--Point-Mint, #169E7A);
  background: var(--Black, #181818);
  padding: 16px;
  display: flex;
  flex-direction: column;
  >.head {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    padding: 8px;
    word-break: break-word;
  }
  >.content {
    flex: 1 1 0%;
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    word-break: break-word;
    .tw {
      padding-top: 20px;
      width: 100%;
      >textarea {
        background: transparent;
        color: rgb(255, 255, 255);
        width: 95%;
        height: 125px;
        border: 1px solid #666;
        padding: 10px;
        font-size: 13px;
        -webkit-user-select : auto;
      }
    }
  }
  .button {
    margin-top: 8px;
    display: flex;
    gap: 11px;
    >.bt {
      flex: 1 1 0%;
      color: rgb(255, 255, 255);
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      padding: 16px;
      transition: all 0.1s linear 0s;
      cursor: pointer;
      border-radius: 24px;
      background: rgb(37, 38, 45);
    }
    >.bt1 {
      flex: 1 1 0%;
      color: rgb(255, 255, 255);
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      padding: 16px;
      transition: all 0.1s linear 0s;
      cursor: pointer;
      border-radius: 24px;
      background: var(--Point-Mint, #169E7A);
    }
  }
`;


const toPercent = (v: number | null): string => {
  return numbro((v || 0) * 100).format({
    trimMantissa: true,
    mantissa: 4
  });
};

function ProfileModal(
  {
    groupId,
    userId,
    onClose
  }: {
    groupId?: number,
    userId: number,
    onClose: () => void;
  }
) {
  const orientation = useScreenOrientation();
  const [myInfo, setMyInfo] = useRecoilState(myInfoState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);

  const [modify, setModify] = useState<boolean>(false);
  const [profile, setProfile] = useState<userProfileModel>();
  const [showHelp, setShowHelp] = useState(false);
  const [reportId, setReportId] = useState<string>("");
  const [reportMemo, setReportMemo] = useState<string>("");
  const [memo, setMemo] = useState<string>("");
  const {openDialog} = useDialog();

  const isMe = useMemo(() => {
    return userId === myInfo?.userId;
  }, [userId, myInfo?.userId]);

  const profileImg = useMemo<string>(() => {
    return profile?.profileImg ?? "/images/default_profile.png"
  }, [profile?.profileImg]);

  const handleConfirm = useCallback(async () => {
    if (!profile?.nickname) {
      openDialog({
        title: '오류',
        text: '닉네임을 입력해주세요.'
      });
      return;
    }

    setGlobalLoading(true);
    const res = await requestModifyUserProfile(profile.nickname, profile.profileImg);
    if (!res) {
      openDialog({
        title: '오류',
        text: `프로필을 변경하는 중 오류가 발생했습니다.`
      });
    }
    requestMyInfo().then((v: any) => setMyInfo(v.info));
    setGlobalLoading(false);
    setModify(false);
  }, [profile]);

  const handleChange = useCallback((e: any) => {
    if (e.target.value.length > 8) {
      return;
    }

    setProfile(produce(d => {
      if (d != null) {
        d.nickname = e.target.value;
      }
    }));
  }, []);

  const handleSelectImage = useCallback((idx: number) => {
    setProfile(produce(d => {
      if (d) {
        d.profileImg = idx.toString();
      }
    }));
  }, []);

  const handleReport = useCallback(() => {
    if (!profile || !groupId) {
      return;
    }
    setReportId(profile.nickname);
  }, [profile]);

  const handleEmojiToggle = useCallback(async (userId:number) => {
    const r = await requestToggleEmojiBan(userId);
    const oldMyInfo = JSON.parse(JSON.stringify(myInfo));

    if (oldMyInfo) {
      oldMyInfo.emojiBans = r.result;
      setMyInfo(oldMyInfo);
    }

  }, [profile]);

  const handleReportConfirm = useCallback(async () => {
    if (!profile || !groupId) {
      return;
    }

    setGlobalLoading(true);
    let r = await requestReport(groupId, profile.userId, reportMemo);

    if (r.result === -1) {
      openDialog({
        title: '오류',
        text: `신고할 내용을 반드시 입력해주셔야 합니다.`
      });
    }
    else {
      openDialog({
        title: "안내",
        text: "신고를 완료했습니다.",
      });

      setReportId('');
      setReportMemo('');
    }
    
    setGlobalLoading(false);
  }, [profile, reportId, reportMemo]);

  const isBanEmojiUserId = useCallback((userId:number) => {
    if (myInfo && myInfo.emojiBans) {
      return myInfo.emojiBans.indexOf(userId) > -1;
    }

    return false;
  }, [myInfo]);

  const handleSaveAndClose = useCallback(async () => {
    if (profile && profile.memo !== memo) {
      await requestModifyUserProfileMemo(profile.userId, memo);
    }

    onClose && onClose();
  }, [profile, memo]);


  useEffect(() => {
    if (userId === -1) {
      return;
    }

    setGlobalLoading(true);
    requestGetUserProfile(userId).then((profile: userProfileModel) => {
      if (!profile.nickname) {
        onClose && onClose();
        return;
      }

      setMemo(profile.memo);
      setProfile(profile);
    }).finally(() => {
      setGlobalLoading(false);
    });
  }, [userId]);

  if (!profile) {
    return null;
  }

  return <Modal style={{
    borderRadius: '12px',
    border: '1px solid var(--Point-Red, #E31D1D)',
    background: 'var(--Black, #181818)'
  }}>
    <ModalContainer show={showHelp} onBackdropClick={() => setShowHelp(false)}>
      <UserStatusHelpModal
        onClose={() => setShowHelp(false)}
      />
    </ModalContainer>

    <ModalContainer show={reportId.length > 0} onBackdropClick={() => setReportId("")}>
      <ReportWrapper>
        <div className="head">유저신고</div>
        <div className="content">
          <div>{reportId}님을 신고하시겠습니까?</div>
          <div className="tw">
            <textarea value={reportMemo} onChange={(e:any) => {
              setReportMemo(e.target.value);
            }}/>
          </div>
        </div>
        <div className="button">
          <button className="bt1" onClick={handleReportConfirm}>신고</button>  
          <button className="bt" onClick={()=>setReportId("")}>취소</button>  
        </div>
      </ReportWrapper>
    </ModalContainer>
    {
      !modify ? (
        <Content>
          <Profile>
            <img className="image" src={profileImg}/>
            <div className="info">
              <div className="name">{profile.nickname}</div>
              <div className="hand"><span>핸드</span>{profile.handCount}</div>
            </div>
            {
              isMe ? (
                <div></div>
                /*<ModifyButton onClick={() => setModify(true)}>변경</ModifyButton>*/
              ) : (
                <ReportButton onClick={handleReport}>신고</ReportButton>
              )
            }
          </Profile>
          <div className="decx">
            <div className="item" onClick={() => setShowHelp(true)}>
              <div className='img-wrapper'>
                {orientation === 'portrait' ? <img src='/images/Question.png'/> : <img src='/images/pc/Question.png'/>}
              </div>
              <span>도움말</span>
            </div>
            {
              isMe ? (
                <></>
              ) : <div className={`item emoji ${isBanEmojiUserId(profile.userId) ? 'off': ''}`} onClick={() => handleEmojiToggle(profile.userId)}>
              {
                isBanEmojiUserId(profile.userId) ? (
                <>
                  <div className='img-wrapper'>
                    {orientation === 'portrait' ? <img src='/images/nes/emoji-toggle-off.png'/> : <img src='/images/nes/emoji-toggle-off.png'/>}
                  </div>
                  <span>이모티콘 효과 OFF</span>
                </>
                ) : (
                <>
                  <div className='img-wrapper'>
                    {orientation === 'portrait' ? <img src='/images/nes/emoji-toggle.png'/> : <img src='/images/nes/emoji-toggle.png'/>}
                  </div>
                  <span>이모티콘 효과 ON</span>
                </>
                )
              }
            </div>
            }
          </div>
          {
            isMe ? <></> : (
              <MemoWrap>
                <textarea value={memo} placeholder="메모를 입력해서 저장할 수 있습니다!!" onChange={(e:any) => {
                  setMemo(e.target.value);
                }}/>
              </MemoWrap>
            )
          }
          <Stats>
            <div className="grid">
              <StatItem>
                <div>참여율(VPIP)</div>
                <div>{toPercent(profile.vpip)}%</div>
                <StatGauge percentage={toPercent(profile.vpip)}/>
              </StatItem>
              <StatItem>
                <div>참여 승률</div>
                <div>{toPercent(profile.winRate)}%</div>
                <StatGauge percentage={toPercent(profile.winRate)}/>
              </StatItem>
              <StatItem>
                <div>PFR</div>
                <div>{toPercent(profile.pfr)}%</div>
                <StatGauge percentage={toPercent(profile.pfr)}/>
              </StatItem>
              <StatItem>
                <div>3BET</div>
                <div>{toPercent(profile.bet3)}%</div>
                <StatGauge percentage={toPercent(profile.bet3)}/>
              </StatItem>
              <StatItem>
                <div>ATS</div>
                <div>{toPercent(profile.ats)}%</div>
                <StatGauge percentage={toPercent(profile.ats)}/>
              </StatItem>
            </div>
            <div className="caption">최근 30일 데이터 기준</div>
          </Stats>
          <ButtonWrapper>
            <div onClick={handleSaveAndClose}>저장 및 닫기</div>
          </ButtonWrapper>
        </Content>
      ) : (
        <Content>
          <ModalTitle>정보 변경</ModalTitle>
          <NicknameForm className="form">
            <div>닉네임</div>
            <div>
              <input value={profile.nickname} onChange={handleChange}/>
              <div className="limit">{profile.nickname.length}/8</div>
            </div>
          </NicknameForm>
          <Border/>
          <SelectImage>
            <div>프로필 이미지</div>
            <div className="grid">
              {
                Array.from({length: 20}).map((_, i) => (
                  <div key={i} className="profile-image-wrapper">
                    {
                      (Number(profile.profileImg || 0) === i) && <div className='selected'>
                        <img src='/images/ic_option_checked.svg'/>
                      </div>
                    }
                    <img className='profile-image' src={`/images/profile/${i}.png`}
                         data-selected={Number(profile.profileImg || 0) === i}
                         onClick={() => handleSelectImage(i)}/>
                  </div>
                ))
              }
            </div>
          </SelectImage>
          <ButtonWrapper>
            <div onClick={() => setModify(false)}>취소</div>
            <div onClick={handleConfirm}>완료</div>
          </ButtonWrapper>
        </Content>
      )
    }
  </Modal>;
}

export default ProfileModal;
