import "./overrides";
import React, {useLayoutEffect} from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {ThemeProvider} from 'styled-components';
import {SnackbarProvider} from 'notistack'
import {RecoilRoot} from "recoil";
import theme from './theme';
import Container from './Container';
import RejoinCheck from "./components/RejoinCheck";
import UnreadChatChecker from "./components/UnreadChatChecker";
import Game from "./pages/Game";
import Tournament from "./pages/Tournament";
import DirectLink from "./pages/DirectLink";
import Welcome from "./pages/Welcome";
import Main from "./pages/Main";
import FromServerToast from "./components/FromServerToast";

const App: React.FC = () => {
  return (<ThemeProvider theme={theme}>
    <BrowserRouter>
      <RecoilRoot>
        <SnackbarProvider className="snackbar" anchorOrigin={{horizontal: "center", vertical: "top"}}>
          <Container>
            <RejoinCheck/>
            <FromServerToast />
            <UnreadChatChecker />
            <Routes>
              <Route path={'/'} element={<Welcome/>} />
              <Route path={'/welcome'} element={<Welcome/>} />
              <Route path={'/main'} element={<Main/>} />
              <Route path={'/main/:type'} element={<Main/>} />
              <Route path={'/main/:type/:parameter'} element={<Main/>} />
              <Route path={'/tournament/:groupId'} element={<Tournament/>} />
              <Route path={'/game'} element={<Game/>} />
              <Route path={'/link'} element={<DirectLink/>} />
            </Routes>
          </Container>
        </SnackbarProvider>
      </RecoilRoot>
    </BrowserRouter>
  </ThemeProvider>);
};
export default App;
