import React, {useEffect, useState} from "react";
import styled from "styled-components";
import Home from "../../Home";
import Holdem from "../../Holdem";
import Trade from "../../Trade";
import MyPage from "../../MyPage";
import CustomerCenter from "../../CustomerCenter";
import {requestMyInfo, useSocketIsConnect} from "../../../api";
import {MyInfo} from "../../../dataset";
import {playBGM, playSFX, Sounds, stopBGM} from "../../../utils/sound";
import useBGM from "../../../hooks/useBGM";
import ModalContainer from "../../../components/common/ModalContainer";
import TradePointModal from "../../../components/TradePointModal";
import {useParams, useNavigate } from "react-router-dom";
import {unreadChatCount} from "../../../recoil/Chat";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {gameOptionState} from '../../../recoil/GameOption';
import {unreadMemoCount} from "../../../recoil/Memo";

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000;
`
const Header = styled.div`
  padding: 16px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #000;
  z-index: 3;

  > .logo-wrapper {
    cursor: pointer;
    position: relative;
    width: 110px;
    height: 100%;

    > .logo {
      position: absolute;
      top: -1px;
      left: 50%;
      transform: translateX(-50%);
      width: 110px;
      object-fit: contain;
    }
  }


  > .left-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    >.nickname-print-wrapper {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: fit-content;
      height: fit-content;
      gap: 3px;
      position: relative;
      top: 6px;
      right: 0;
      margin-left: 4px;
      >img {
        width: 16px;
        height: 16px;
      }
      >span {
        color: rgb(255, 255, 255);
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 72px;
        @media (max-width: 300px) {
          width: 42px;
        }
      }
    }
    >.mute-wrapper {
      width: 16px;
      height: 16px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      position: relative;
      top: 6px;
      cursor: pointer;
      >img {
        width: 100%;
        height: 100%;
      }
    }
    >.money-point-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      width: fit-content;
      height: fit-content;
      gap: 6px;
      margin-left: 8px;
      > .money-wrapper {
        width: 132px;
        height: 28px;
        border-radius: 6px;
        background: #262838;
        padding-left: 4px;
        padding-right: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        @media (max-width: 300px) {
          width: 78px;
        }
        > .money {
          color: #FFF;
          text-align: center;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.2px;
        }

        > .icon {
          width: 16px;
          height: 16px;
        }
      }
      >.second-line {
        display: flex;
        >.point-wrapper {
          cursor: pointer;
          padding-right: 4px;
          color: #169E7A;
          text-align: center;
          leading-trim: both;
          text-edge: cap;
          font-family: Pretendard;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.2px;
        }
        >.memo-wrapper {
          color: #fff;
          cursor: pointer;
          padding-right: 4px;
          color: rgb(22, 158, 122);
          text-align: center;
          font-family: Pretendard;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.2px;
          border-left: 1px solid #606060;
          padding-left: 4px;
          >img {
            width: 12px;
            vertical-align: middle;
            position: relative;
            top: -1px;
            margin-right: 2px;
          }
        }
      }
      
    }

    > .cs-button {
      position: relative;
      cursor: pointer;
      width: 68px;
      height: 28px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      border: 1px solid var(--Point-Mint, #169E7A);
      background: rgba(1, 189, 156, 0.50);
      color: #FFF;
      leading-trim: both;
      text-edge: cap;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      >.badge {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 3px;
        right: 6px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        background: #fff;
        text-align: center;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding-bottom: 1.5px;
    
      }
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 162px);
  max-height: calc(100% - 162px);
  overflow: scroll;
`

const Footer = styled.div`
  flex-shrink: 0;
  padding-top: 8px;
  height: 84px;
  min-height: 84px;
  width: 100%;
  background: radial-gradient(68.45% 68.45% at 50% 0%, #2C1414 0%, #151515 74.48%);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-evenly;
`

const TabItem = styled.div<{ isSelected: boolean }>`
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0px rgba(227, 29, 29, 0.7);
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    100% {
      box-shadow: 0 0 0 10px rgba(227, 29, 29, 0);
      opacity: 1;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  position: relative;

  .badge {
    animation: 2s cubic-bezier(0.4, 0, 0.6, 1) 0s infinite normal none running pulse;
    position: absolute;
    top: 1px;
    right: 2px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: rgb(227, 29, 29);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 8px;
    color: rgb(255, 255, 255);
  }
  ${p => p.isSelected ? `
    opacity: 1; 
  ` : `
    opacity: 0.5;
  `}
  > .title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  > .img-wrapper {
    width: 32px;
    height: 32px;
    position: relative;
    > img {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
  }
`

const MobileMain = () => {
  const [conn] = useSocketIsConnect();
  const params: {type?: string} = useParams();
  const type = params?.type;
  const navigate = useNavigate();
  const [section, setSection] = useState<'home' | 'holdem' | 'trade' | 'mypage' | null>(null);
  //const [showCustomerCenter, setShowCustomerCenter] = useState<boolean>(false);
  const [myInfo, setMyInfo] = useState<MyInfo | null>(null);
  const [refresh, setRefresh] = useState<number>(new Date().getTime());
  const [showTradePointModal, setShowTradePointModal] = useState<boolean>(false);
  const [unreadChat, setUnreadChat] = useState<number>(0);
  const [userId, setUserId ]= useState<number>(0);
  const ChatCount = useRecoilValue(unreadChatCount);
  const [setting, setSetting] = useRecoilState(gameOptionState);
  const MemoCount = useRecoilValue(unreadMemoCount);

  useBGM(Sounds.BGM_WAIT, true)

  useEffect(() => {
    if (
      type && (type === 'home' || type === 'holdem' || type === 'trade' || type === 'mypage')
    ) {
      setSection(type)
    } else {
      setSection('home')
      navigate(`/main/home`)
    }
    window.scrollTo(0, 0)
  }, [window.location.href])

  useEffect(() => {
    if(!conn.isLogin) return;
    requestMyInfo().then((res) => {
      setMyInfo(res.info);
      setUserId(res.info.userId)
    })
  }, [refresh, window.location.href, conn.isLogin ]);

  useEffect(() => {
    setUnreadChat(ChatCount);
  }, [ChatCount]);


  return <MainWrapper>
    {
      /*showCustomerCenter && <CustomerCenter onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        setShowCustomerCenter(false);
        setRefresh(new Date().getTime())
      }}/>*/
    }
    <Header>
      <div className='logo-wrapper' onClick={()=>{
        playSFX(Sounds.SFX_CLICK, true)
        setSection('home')
        navigate(`/main/home`)
      }}>
        <img className='logo' src='/images/logo1.png'/>
      </div>
      <div className='left-row'>
        <div className='mute-wrapper' onClick={()=> {
          const isMuteded = setting.backgroundMusic.muted && setting.effectSound.muted;

          setSetting({
            ...setting,
            effectSound: {
              ...setting.effectSound,
              muted: !isMuteded
            },
            backgroundMusic: {
              ...setting.backgroundMusic,
              muted: !isMuteded
            }
          });
        }}
        >
          <img alt='mute' src={`/images/Icon/mute1${setting.backgroundMusic.muted && setting.effectSound.muted ? 'on' : 'off'}.png`}/>
        </div>
        <div className='nickname-print-wrapper' onClick={() => {
          playSFX(Sounds.SFX_CLICK, true)
          setSection('mypage')
          navigate(`/main/mypage`)
        }}>
          <img src='/images/ic_user2.png'/>
          <span>{myInfo && myInfo.nickname}</span>
        </div>
        <div className='money-point-wrapper'>
          <div className='money-wrapper' onClick={() => {
            setRefresh(new Date().getTime());
            playSFX(Sounds.SFX_CLICK, true)
            setSection('trade')
            navigate(`/main/trade`);
          }}>
            <img className='icon' src='/images/ic_money.png'/>
            <div className='money'>{myInfo ? myInfo.money.toLocaleString() : 'loading...'}</div>
          </div>
          <div className='second-line'>
            <div className='point-wrapper' onClick={()=>{
              playSFX(Sounds.SFX_WINDOW_OPEN, true);
              setShowTradePointModal(true)
            }}>
              포인트 {myInfo ? (myInfo.point < 0 ? 0 : myInfo.point).toLocaleString() : 'loading...'} P
            </div>
            <div className='memo-wrapper' onClick={()=>{
              playSFX(Sounds.SFX_WINDOW_OPEN, true);
              navigate('/main/mypage/memo');
            }}>
              <img src="/images/nes/mail.png" />
              쪽지 {MemoCount > 0 ? `(${MemoCount})` : ''}
            </div>
          </div>
        </div>
      </div>
    </Header>
    <Content>
      {
        section === 'home' && <Home/>
      }
      {
        section === 'holdem' && <Holdem />
      }
      {
        section === 'trade' && <Trade showCustomerCenter={() => navigate('/main/mypage/cs')}/>
      }
      {
        section === 'mypage' && <MyPage/>
      }
    </Content>
    <Footer>
      <TabItem isSelected={section === 'home'} onClick={() => {
        playSFX(Sounds.SFX_CLICK, true)
        setSection('home')
        navigate(`/main/home`)
      }}>
        <div className='img-wrapper'>
          {
            section === 'home' ? <img src='/images/Icon/Icon=Home,%20Case=On.png'/> :
              <img src='/images/Icon/Icon=Home,%20Case=Off.png'/>
          }
        </div>

        <div className='title'>
          홈
        </div>
      </TabItem>
      <TabItem isSelected={section === 'holdem'} onClick={() => {
        playSFX(Sounds.SFX_CLICK, true)
        setSection('holdem')
        navigate(`/main/holdem`)
      }}>
        <div className='img-wrapper'>
          {
            section === 'holdem' ? <img src='/images/Icon/Icon=Holdem,%20Case=On.png'/> :
              <img src='/images/Icon/Icon=Holdem,%20Case=Off.png'/>
          }
        </div>
        <div className='title'>
          홀덤
        </div>
      </TabItem>
      <TabItem isSelected={section === 'trade'} onClick={() => {
        playSFX(Sounds.SFX_CLICK, true)
        setSection('trade')
        navigate(`/main/trade`)
      }}>
        <div className='img-wrapper'>
          {
            section === 'trade' ? <img src='/images/Icon/Icon=Trade,%20Case=On.png'/> :
              <img src='/images/Icon/Icon=Trade,%20Case=Off.png'/>
          }
        </div>
        <div className='title'>
          충전/환전
        </div>
      </TabItem>
      <TabItem isSelected={section === 'mypage'} onClick={() => {
        playSFX(Sounds.SFX_CLICK, true)
        setSection('mypage')
        navigate(`/main/mypage`)
      }}>
        <div className='img-wrapper'>
          {
            section === 'mypage' ? <img src='/images/Icon/Icon=User,%20Case=On.png'/> :
              <img src='/images/Icon/Icon=User,%20Case=Off.png'/>
          }
        </div>
        <div className='title'>
          내정보
        </div>
      </TabItem>
      <TabItem isSelected={section === null} onClick={() => {
        playSFX(Sounds.SFX_WINDOW_OPEN, true);
        navigate('/main/mypage/cs')
      }}>
        <div className='img-wrapper'>
          {
            section === 'mypage' ? <img src='/images/Icon/Icon=Customer,Case=On.png'/> :
              <img src='/images/Icon/Icon=Customer,Case=Off.png'/>
          }
        </div>
        <div className='title'>
          {ChatCount ? (<div className='badge'>{ChatCount}</div>) : <></>}
          고객센터
        </div>
      </TabItem>
    </Footer>
    <ModalContainer show={showTradePointModal}>
      <TradePointModal onClose={()=>{
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        setShowTradePointModal(false)
        setRefresh(new Date().getTime())
      }}/>
    </ModalContainer>
  </MainWrapper>
}

export default MobileMain;
