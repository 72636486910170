import styled from "styled-components";
import {HorizontalBar} from "../Trade";
import React, {useEffect, useState} from "react";
import {requestGetAttendanceHistory} from "../../api";
import {AttendanceHistoryInfo, UserMoneyUpdateType} from "../../dataset";
import moment from "moment";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
const PointHistoryWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: #151515;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .fsafasfa {
    margin-right: 4px;
    color: red;
  }
  .daffnas {
    color: #32ff00;
  }
  @media ${MEDIA_DESKTOP} {
    top: 60px;
    height: calc(100% - 60px);
    background: white;
  }
  > .content-wrapper {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    @media ${MEDIA_DESKTOP} {
      max-width: 470px;
      padding-top: 24px;
    }
    > .content {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 16px 20px;
      gap: 20px;
      @media ${MEDIA_DESKTOP} {
        padding-left: 0;
        padding-right: 0;
        gap: 0;
      }
      >.empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        >.wrapper {
          position: relative;
          >.center {
            width: 84px;
            height: 84px;
            object-fit: contain;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          >.bottom {
            width: 100%;
            position: absolute;
            bottom: 3px;
            left: 50%;
            transform: translateX(-50%);
            color: rgba(255, 255, 255, 0.8);
            text-align: center;
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.2px;
          }
          >.background {
            width: 220px;
            height: 220px;
            object-fit: contain;
          }
        }
      }
    }
  }
`


const PointHistoryRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  >.info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 4px;
    @media ${MEDIA_DESKTOP} {
      gap: 8px;
    }
    >.title {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
      opacity: 0.4;
      @media ${MEDIA_DESKTOP} {
        color: var(--Black, #181818);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
      }
    }
    >.value {
      color: #FFF;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.5px;
      @media ${MEDIA_DESKTOP} {
        color: var(--Black, #181818);
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 22.4px */
        letter-spacing: -0.5px;
      }
    }
  }
  >.date {
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    opacity: 0.4;
    @media ${MEDIA_DESKTOP} {
      color: var(--Black, #181818);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }
  padding: 16px 0;
  border-bottom: 1px solid #25262D;
  @media ${MEDIA_DESKTOP} {
    border-bottom: 1px solid #ddd;
    padding: 20px 0;
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child{
    border-bottom: none;
  }
`
const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  @media ${MEDIA_DESKTOP} {
    border-bottom: 1px solid #DDD;
    background: #F5F5F5;
    height: 57px;
  }

  > .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
  }

  > .title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media ${MEDIA_DESKTOP} {
      position: relative;
      width: 470px;
      color: var(--Black, #181818);
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 20px */
    }

    > .close {
      display: none;
      @media ${MEDIA_DESKTOP} {
        cursor: pointer;
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }
    }
  }
`

interface PointHistoryProps {
  onClose: () => void;
}

const AttendanceHistory = ({onClose}: PointHistoryProps) => {
  const [attendanceList, setAttendanceList] = useState<AttendanceHistoryInfo[]>([]);
  useEffect(() => {
    requestGetAttendanceHistory(0, 100).then((res) => {
      if (res && res.list)
        setAttendanceList(res.list)
    })
  }, []);
  return <PointHistoryWrapper>
    <Header>
      <img className='close' src='/images/ic_arrow_back.png' onClick={() => onClose()}/>
      <div className='title'><img onClick={() => onClose()} className={'close'} src='/images/pc/Back.png'/>출석체크 내역</div>
    </Header>
    <div className='content-wrapper'>
      <div className='content'>
        {
          attendanceList.map((_, index) => {
            return <PointHistoryRow key={index}>
              <div className='info-wrapper'>
                <div className='title'>
                    출석체크 
                </div>
                <div className='value'>{`${_.stack}회차`}</div>
              </div>
              <div className='date'>
                <span className={`fsafasfa ${_.status === 1 ? 'daffnas' : ''}`} >{_.status === 1 ? '보너스 지급' : '보너스 지급대기'}</span>
                <span>{_.date.split("T")[0]}</span>
              </div>
            </PointHistoryRow>
          })
        }
        {
          attendanceList.length === 0 &&  <div className='empty'>
            <div className='wrapper'>
              <img className='center' src='/images/empty_point.png' alt='empty_point.png' />
              <div className='bottom'>출석체크 내역이 없습니다.</div>
              <img className='background' src='/images/empty_bg.png' alt='empty'/>
            </div>
          </div>
        }
      </div>
    </div>
  </PointHistoryWrapper>
}

export default AttendanceHistory;
