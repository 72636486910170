import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styled from "styled-components";
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {gameOptionState} from "../../recoil/GameOption";

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;

  .loading-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .loading-modal > .wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background: #151515;
  }

  .loading-modal > .wrapper > .inner {
    width: 100%;
    max-width: 480px;
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
  }

  .loading-modal > .wrapper > .inner > .inner-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    position: relative;
    background: #151515;
  }

  .loading-modal > .wrapper > .inner > .inner-wrapper > .top {
    width: 100%;
    height: 476px;
    mix-blend-mode: soft-light;
    position: relative;
  }

  .loading-modal > .wrapper > .inner > .inner-wrapper > .top > .background {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  }

  .loading-modal > .wrapper > .inner > .inner-wrapper > .top > .pattern {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: url(/images/background_pattern.png), 50% center / cover no-repeat lightgray;
  }

  .loading-modal > .wrapper > .inner > .inner-wrapper > .background {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .loading-modal > .wrapper > .inner > .inner-wrapper > .logo {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
    width: 340px;
    object-fit: contain;
    z-index: 2;
  }

  .loading-modal > .wrapper > .inner > .inner-wrapper > .content {
    padding: 30px 20px 48px;
    width: 100%;
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    gap: 6px;
    z-index: 2;
  }

  .loading-modal > .wrapper > .inner > .inner-wrapper > .content > .loading-wrapper {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 13px;
  }

  .loading-modal > .wrapper > .inner > .inner-wrapper > .content > .loading-wrapper > .progress-bar {
    width: 274px;
    height: 4px;
    border-radius: 4px;
    background: var(--Black, #181818);
    position: relative;
    overflow: hidden;
  }

  .loading-modal > .wrapper > .inner > .inner-wrapper > .content > .loading-wrapper > .progress-bar > .progress {
    width: 0%;
    transition: width 0.1s ease 0s;
    height: 100%;
    background: rgb(227, 29, 29);
    position: absolute;
    top: 0px;
    left: 0px;
  }

  .loading-modal > .wrapper > .inner > .inner-wrapper > .content > .loading-wrapper > .loading-text {
    color: rgb(255, 255, 255);
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
`;

function LoadingGameResource(
  {orientation, tableStyle, onDownloadDone}: {
    orientation: string,
    tableStyle: number,
    onDownloadDone: ()=>void
  }
) {
  /*const imageUrls = [
    '/images/nes/timerSprite.png',
    '/images/nes/profile/1.png',
    '/images/nes/profile/2.png',
    '/images/nes/profile/3.png',
    '/images/nes/profile/4.png',
    '/images/nes/profile/5.png',
    '/images/nes/profile/6.png',
    '/images/nes/profile/7.png',
    '/images/nes/profile/8.png',
    '/images/nes/profile/9.png',
    '/images/nes/profile/10.png',
    '/images/nes/profile/11.png',
    '/images/nes/profile/12.png',
    '/images/nes/profile/13.png',
    '/images/nes/profile/14.png',
    '/images/nes/profile/15.png',
    '/images/nes/game-account-batge.png',
    '/images/nes/game_account_detail1.png',
    '/images/nes/game_account_profile.png',
    '/images/nes/mobileBackground.jpg',
    '/images/nes/pcBackground.jpg',
    '/images/game/card_back_1.png',
    '/images/chips/Blue.png',
    '/images/chips/White.png',
    '/images/chips/Pink.png',
    '/images/chips/Orange.png',
    '/images/chips/Green.png',
    '/images/chips/Black.png',
    '/images/chips/Red.png',
    '/images/chips/Purple.png',
    '/images/logo1.png',
    '/images/emoji/1.gif?v=353528555',
    '/images/emoji/2.gif?v=353528555',
    '/images/emoji/3.gif?v=353528555',
    '/images/emoji/4.gif?v=353528555',
    '/images/emoji/5.gif?v=353528555',
    '/images/emoji/6.gif?v=353528555',
    '/images/emoji/7.gif?v=353528555',
    '/images/emoji/8.gif?v=353528555',
    '/images/emoji/9.gif?v=353528555',
    '/images/emoji/10.gif?v=353528555',
    '/images/emoji/11.gif?v=353528555',
    '/images/emoji/12.gif?v=353528555',
    '/images/emoji/13.gif?v=353528555',
    '/images/emoji/14.gif?v=353528555',
    '/images/emoji/15.gif?v=353528555',
    '/images/emoji/16.gif?v=353528555',
    '/images/emoji/17.gif?v=353528555',
    '/images/emoji/18.gif?v=353528555',
    '/images/btn_seat_bg.png',
    '/images/Emoji.png',
    '/images/ic_chip_dealer.png',
  ];
  */

  const imageUrls = [
    '/images/logo1.png',
    '/images/popupNotice.png',
    '/images/chips/Blue.png',
    '/images/chips/White.png',
    '/images/chips/Pink.png',
    '/images/chips/Orange.png',
    '/images/chips/Green.png',
    '/images/chips/Black.png',
    '/images/chips/Red.png',
    '/images/chips/Purple.png',
    '/images/default_profile.png',
    '/images/nes/rabbitCard5.png',
    '/images/nes/rabbitIcon.png',
    '/images/nes/won.png',
    '/images/nes/cardView.png',
    '/images/nes/game_status.png',
    '/images/nes/empty3.png',
    '/images/nes/profile/1.png',
    '/images/nes/profile/2.png',
    '/images/nes/profile/3.png',
    '/images/nes/profile/4.png',
    '/images/nes/profile/5.png',
    '/images/nes/profile/6.png',
    '/images/nes/profile/7.png',
    '/images/nes/profile/8.png',
    '/images/nes/profile/9.png',
    '/images/nes/profile/10.png',
    '/images/nes/profile/11.png',
    '/images/nes/profile/12.png',
    '/images/nes/profile/13.png',
    '/images/nes/profile/14.png',
    '/images/nes/profile/15.png',
    '/images/emoji/1.gif?v=353528555',
    '/images/emoji/2.gif?v=353528555',
    '/images/emoji/3.gif?v=353528555',
    '/images/emoji/4.gif?v=353528555',
    '/images/emoji/5.gif?v=353528555',
    '/images/emoji/6.gif?v=353528555',
    '/images/emoji/7.gif?v=353528555',
    '/images/emoji/8.gif?v=353528555',
    '/images/emoji/9.gif?v=353528555',
    '/images/emoji/10.gif?v=353528555',
    '/images/emoji/11.gif?v=353528555',
    '/images/emoji/12.gif?v=353528555',
    '/images/emoji/13.gif?v=353528555',
    '/images/emoji/14.gif?v=353528555',
    '/images/emoji/15.gif?v=353528555',
    '/images/emoji/16.gif?v=353528555',
    '/images/emoji/17.gif?v=353528555',
    '/images/emoji/18.gif?v=353528555',
    '/images/btn_seat_bg.png',
    '/images/Emoji.png',
    '/images/ic_chip_dealer.png'
  ];
  const gameOption = useRecoilValue(gameOptionState);
  const [countListNeedDownload, setCountListNeedDownload] = useState<number>(0);
  const [countDownloaded, setCountDownloaded] = useState<number>(0);
  const [isDownloadStart, setIsDownloadStart] = useState<boolean>(false);
  const [isDownloadEnd, setIsDownloadEnd] = useState<boolean>(false);


  const sleep2 = (ms: number): Promise<void> => {
    return new Promise((resolve:any) => setTimeout(resolve, ms));
  };

  useEffect(() => {
    setCountListNeedDownload(0);
    setCountDownloaded(0);

    setIsDownloadStart(false);
    setIsDownloadEnd(false);
  }, []);

  useEffect(() => {
    if (orientation && tableStyle && !isDownloadStart) {
      const rtableStyle = tableStyle < 0 ? 1 : tableStyle;
      const c = JSON.parse(JSON.stringify(imageUrls));
      const cardStyle = gameOption.cardStyle ? gameOption.cardStyle : 1;

      c.push(`/images/game/table_${orientation}_type_${rtableStyle}.png`);
      c.push(`/images/nes/card_back_${rtableStyle}.png`);

      for (let i = 0; i <= 51; i++) {
        c.push(`/images/nes/card/style-${cardStyle}/${i}.svg`);
      }

      setIsDownloadStart(true);
      setCountListNeedDownload(c.length);
      loadImages(c);
    }
  }, [orientation, tableStyle, gameOption, isDownloadStart]);

  useEffect(() => {
    if (isDownloadStart && !isDownloadEnd && countDownloaded >= countListNeedDownload) {
      setIsDownloadEnd(true);
      onDownloadDone();
    }
  }, [isDownloadStart, isDownloadEnd, countDownloaded, countListNeedDownload]);

  const onImageDownloaded = useCallback((url:string) => {

    setCountDownloaded((prevCount) => {
      const newCount = prevCount + 1;
      return newCount;
    });
  }, [countDownloaded, setCountDownloaded]);

  const downloadImage = (url: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        onImageDownloaded(url);
        resolve();
      };
      img.onerror = () => reject(new Error(`Failed to download image: ${url}`));
    });
  };

  const loadImages = async (targetImageList:any) => {
    for (const url of targetImageList) {
      try {
        await downloadImage(url);
        await sleep2(5);
      } catch (error) {
        onImageDownloaded(url);
      }
    }
  };

  return <Wrapper>
    <div className="loading-modal">
      <div className="wrapper">
        <div className="inner">
          <div className="inner-wrapper">
            <img className="logo" src="/images/logo1.png"/>
            <div className="content">
              <div className="loading-wrapper ">
                <div className="progress-bar">
                  <div className="progress" style={{width: `${Math.round(countDownloaded/countListNeedDownload * 100)}%`}}>
                  </div>
                </div>
                <div className="loading-text">게임에 필요한 데이터를 다운로드하고 있습니다...</div>
                {
                  countListNeedDownload > 0 && (
                    <div className="loading-text">[{countDownloaded}/{countListNeedDownload}]</div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Wrapper>
}

export default LoadingGameResource;
