import {gameOptionState} from "../recoil/GameOption";

export const Sounds = {
  BGM_WAIT: 'bgm_wait2.mp3',
  SFX_BET_1: 'sfx_chip_betting_1.wav',
  SFX_BET_2: 'sfx_chip_betting_2.wav',
  SFX_BET_3: 'sfx_chip_betting_3.wav',
  SFX_BET_4: 'sfx_chip_betting_4.wav',
  SFX_GAME_IN: 'sfx_game_in.wav',
  SFX_GAME_OUT: 'sfx_game_out.wav',
  SFX_FOLD: 'sfx_fold.wav',
  SFX_CHECK_1: 'sfx_call_check_1.wav',
  SFX_CHECK_2: 'sfx_call_check_2.wav',
  SFX_ALLIN: 'sfx_allin.wav',
  SFX_RIVER_CARD_OPEN: 'sfx_river_card_open.wav',
  SFX_TIMER_ALERT: 'sfx_timer_alert.wav',
  SFX_CARD_DIS: 'sfx_dealing.wav',
  SFX_DEALER_BUTTON_MOVE: 'sfx_dealer_button_move.wav',
  SFX_CLICK_INGAME: 'sfx_in_game_click.mp3',
  SFX_WINDOW_CLOSE: 'sfx_window_close.wav',
  SFX_WINDOW_OPEN: 'sfx_window_open.wav',
  SFX_CLICK: 'sfx_click_1.wav',
  SFX_TOGGLE: 'sfx_toggle.wav',
  SFX_FLOP: 'sfx_flop.wav',
  SFX_ON_FIRE: 'sfx_on_fire.wav',
  SFX_BUY_IN_UP: 'sfx_buy_in_up.wav',
  SFX_BUY_IN_DOWN: 'sfx_buy_in_down.wav',
  SFX_CHIP_LEADER: 'sfx_chip_leader.wav',
  SFX_TOURNAMENT_WIN: 'sfx_tournament_win.mp3',
  SFX_TOURNAMENT_LOSE: 'sfx_tournament_lose.wav',
  SFX_WIN_FOLD: 'sfx_win_fold.wav',
  SFX_FIELD_POT_UP: 'sfx_field_pot_up.wav',
  SFX_FOLD_LOSE: 'sfx_fold_lose.wav',
  SFX_OPEN_CARD: 'sfx_open_card.wav',
  SFX_OPEN_LOSE: 'sfx_open_lose.wav',
  SFX_OPEN_WIN: 'sfx_open_win.wav',
  SFX_ACTION_CALL: 'sfx_action_call.wav',
  SFX_ACTION_CHECK: 'sfx_action_check.wav',
  SFX_ACTION_FOLD: 'sfx_action_fold.wav',
  SFX_ACTION_RAISE: 'sfx_action_raise.wav',
  SFX_ACTION_ALLIN: 'sfx_action_allin.wav',
  SFX_SHOW_HIDE_CARDS: 'sfx_show_hide_cards.wav',
  SFX_EMOJI_1: 'sfx_emoji_1.wav',
  SFX_EMOJI_2: 'sfx_emoji_2.wav',
  SFX_EMOJI_3: 'sfx_emoji_3.wav',
  SFX_EMOJI_4: 'sfx_emoji_4.wav',
  SFX_EMOJI_5: 'sfx_emoji_5.wav',
  SFX_EMOJI_6: 'sfx_emoji_6.wav',
  SFX_EMOJI_7: 'sfx_emoji_7.wav',
  SFX_EMOJI_8: 'sfx_emoji_8.wav',
  SFX_EMOJI_9: 'sfx_emoji_9.wav',
  SFX_EMOJI_10: 'sfx_emoji_10.wav',
  SFX_EMOJI_11: 'sfx_emoji_11.wav',
  SFX_EMOJI_12: 'sfx_emoji_12.wav',
  SFX_EMOJI_13: 'sfx_emoji_13.wav',
  SFX_EMOJI_14: 'sfx_emoji_14.wav',
  SFX_EMOJI_15: 'sfx_emoji_15.wav',
  SFX_EMOJI_16: 'sfx_emoji_16.wav',
  SFX_EMOJI_17: 'sfx_emoji_17.wav',
  SFX_EMOJI_18: 'sfx_emoji_18.wav'
}

const sources: { [key: string]: AudioBuffer } = {}
const nodes: { [key: string]: AudioBufferSourceNode } = {};
let bgmGainNode: null | GainNode = null
const audioCtx = new AudioContext();

export async function initSounds() {
  const promises: Promise<void>[] = [];

  Object.values(Sounds).forEach((file) => {
    const url = `/sounds/${file}`;
    const promise = new Promise<void>(async (r) => {
      try {
        // const res = await fetch(url,{
        //   cache: 'force-cache',
        //   headers: {
        //     'Cache-Control': 'max-age=31536000',
        //     'Pragma': 'cache'
        //   }
        // });
        // const data = await res.arrayBuffer();

        let data = await new Promise(resolve => {
          var xhr = new XMLHttpRequest();
          xhr.onload = function(){
            resolve(xhr.response);
          }
          xhr.open('GET', url, true);
          xhr.setRequestHeader("Pragma", "cache");
          xhr.setRequestHeader("cache-control", "max-age=31536000");
          xhr.responseType='arraybuffer';
          xhr.send();
        });

        sources[file] = await audioCtx.decodeAudioData(data as any);
      } catch (e) {
        console.log('failed to load sound', url, e);
      }

      r();
    });

    promises.push(promise)
  });

  await Promise.allSettled(promises);
}

function getGameOptions() {
  let gameOption;

  try {
    gameOption = JSON.parse(localStorage.getItem(gameOptionState.key)!);
  } catch (e) {
  }

  if (!gameOption || typeof gameOption !== 'object') {
    gameOption = {};
  }

  if (!gameOption.hasOwnProperty('backgroundMusic')) {
    gameOption.backgroundMusic = {
      volume: 100,
      muted: false
    };
  }
  if (!gameOption.hasOwnProperty('effectSound')) {
    gameOption.effectSound = {
      volume: 100,
      muted: false
    };
  }

  return gameOption;
}

export function playBGM(sound: string) {
  const opt = getGameOptions();
  if (opt.backgroundMusic.muted) {
    return;
  }
  //stopBGM();
  if(nodes[sound]){
    // 이미 재생중인 BGM이면 볼륨만 변경한다.
    if(bgmGainNode){
      bgmGainNode.gain.value = opt.backgroundMusic.volume * 0.01;
      return;
    }
  }


  if (!isPlaying(sound)) {
    playSound(sound, opt.backgroundMusic.volume, false, true);
  }
}

export function playSFX(sound: string, force?: boolean, isEmoji?: boolean) {
  const opt = getGameOptions();
  if (opt.effectSound.muted) {
    return;
  }
  let innerVolume = opt.effectSound.volume ?? 0;
  if([Sounds.SFX_CHECK_1, Sounds.SFX_CHECK_2].includes(sound)){
    innerVolume += 15;
  }

  if (isEmoji) {
    innerVolume = 10;
  }

  playSound(sound, innerVolume, Boolean(force), false);
}

export function playSFXIgnoreMute(sound: string, force?: boolean) {
  const opt = getGameOptions();

  playSound(sound, opt.effectSound.volume, Boolean(force), false);
}

function isPlaying(sound: string) {
  return nodes[sound] !== undefined;
}

function isBGM(sound: string) {
  return sound.includes('bgm_');
}

function playSound(sound: string, volume: number, force: boolean, loop: boolean = false) {
  const source = sources[sound];
  if (!source) {
    return;
  }
  // 이미 재생중인 소스라면 재생하지 않는다. force 플래그가 true면 무시하고 재생한다.
  if (isPlaying(sound) && !force) {
    return;
  }
  // 만약 SFX 사운드면 suspended 상태에서는 재생 안함
  if (audioCtx.state !== 'suspended' || isBGM(sound)) {
    const sourceNode = new AudioBufferSourceNode(audioCtx, {
      buffer: source,
      loop: loop,
    });

    if(isBGM(sound)){
      if(bgmGainNode === null){
        bgmGainNode = audioCtx.createGain();
        bgmGainNode.gain.value = volume * 0.01;
        bgmGainNode.connect(audioCtx.destination);
      }
      sourceNode.connect(bgmGainNode);
    }else{
      const gainNode = audioCtx.createGain();
      gainNode.gain.value = volume * 0.01;

      sourceNode.connect(gainNode);
      gainNode.connect(audioCtx.destination);
    }


    sourceNode.onended = () => {
      if (nodes[sound] === sourceNode) {
        delete nodes[sound];
      }
    }

    sourceNode.start();
    nodes[sound] = sourceNode;
  }

  if (audioCtx.state === 'suspended') {
    audioCtx.resume();

  }
}


export function stopSound(sound: string) {
  const node = nodes[sound];
  if (node) {
    node.stop();
    delete nodes[sound];
  }
}

export function stopAllSound() {
  for (let sound in nodes) {
    // BGM 사운드는 멈추지 않는다.
    if (isBGM(sound)) {
      continue;
    }

    stopSound(sound);
  }
}

export function stopBGM() {
  const sound = Object.keys(nodes).find(x => isBGM(x));
  if (sound) {
    stopSound(sound);
  }
}
