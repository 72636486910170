import styled from "styled-components";
import 'swiper/css';
import {useCallback, useEffect, useMemo, useState} from "react";
import {
  requestJoinRingGroup,
  requestRingGroupList,
  requestTournamentGroupList,
  requestGetKeepAwayMyList,
  useSocketIsConnect
} from "../api";
import useRingGroupListRefresh from "../api/from_server_game_refreshRingGroupList";
import useTournamentGroupListRefresh from "../api/from_server_game_refreshTournamentGroupList";
import {useRecoilState, useSetRecoilState} from 'recoil';
import {
  hasCacheGameDataState
} from "../recoil/Game";
import {globalLoadingState} from "../recoil/Loading";
import useDialog from "../hooks/useDialog";
import {useNavigate, useLocation} from "react-router-dom";
import queryString from "query-string";
import moment from "moment";
import {playSFX, Sounds} from "../utils/sound";
import {toEasternNumeral} from "../utils/common";
import {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";
import TabEffect from "../components/TabEffect";
import {gameOptionState} from '../recoil/GameOption';
import {calcPlayTime} from "../utils/tournament";
import {parseDatetime} from "../constants/moment";
import KeepAwayManagerModal from "../components/KeepAwayManagerModal";
import KeepAwayManagerInformModal from "../components/KeepAwayManagerInformModal";
import ModalContainer from "../components/common/ModalContainer";

const STRBadge = styled.div`
  width: 29px;
  height: 13px;
  position: relative;
  >.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 21px;
    object-fit: contain;
  }
`
const HoldemWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 20px;
  position: relative;

  >.keep-away-button-mobile {
    cursor: pointer;
    background: #d63333;
    background: linear-gradient(90deg, rgba(206,108,108,1) 0%, rgba(182,52,52,1) 35%);
    width: 100%;
    text-align: center;
    padding-top: 4px;
    padding-bottom: 4px;
    color: #fff;
    >.top {
      font-size: 14px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;

      >img {
        width: 14px;
        height: 14px;
      }
      >span {
        margin-left: 5px;
      }
    }
    >.bottom {
      font-size: 10px;
    }

    @media ${MEDIA_DESKTOP} {
      display: none;
    }
  }

  > .background {
    position: fixed;
    width: 355vw;
    aspect-ratio: 1527/587;
    left: 50%;
    top: -15%;
    transform: translateX(-50%);
    pointer-events: none;
    z-index: 0;
    @media ${MEDIA_DESKTOP} {
      position: absolute;
    }

    > img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      object-fit: contain;
      opacity: 0;
      animation: _0dfdb03 2s ease-out .5s forwards, _b341c19 20s ease-in-out 2.5s infinite alternate;
    }

    > .mobile {
      @media ${MEDIA_DESKTOP} {
        display: none;
      }
    }

    > .pc {
      display: none;
      @media ${MEDIA_DESKTOP} {
        display: block;
      }
    }
  }

  @keyframes _0dfdb03 {
    0% {
      opacity: 0;
      transform: scale(.95);
    }
    100% {
      opacity: .6;
      transform: scale(1.1);
    }
  }
  @keyframes _b341c19 {
    0% {
      opacity: .6;
      transform: scale(1.1);
    }
    100% {
      opacity: .6;
      transform: scale(.95);
    }
  }

  > .banner-wrapper {
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
    height: 120px;
    object-fit: contain;
  }

  .swiper {
    width: 100%;
    flex-shrink: 0;
  }

  .swiper-wrapper {
    width: 100%;
  }

  .swiper-pagination {
    bottom: 4px;

    .swiper-pagination-bullet {
      margin: 0 2px;
      width: 20px;
      height: 2px;
      border-radius: 0;
      opacity: 0.3;
      background: white;
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
    }
  }
`

const EnterButtonWrapper = styled.div`
  width: 100%;
  max-width: 480px;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 3;
  @media ${MEDIA_DESKTOP} {
    display: none;
  }

  &.active {
    position: fixed;
    bottom: 100px;
  }
`
const EnterButton = styled.div`
  display: flex;
  width: 280px;
  height: 48px;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 24px;
  background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
  cursor: pointer;
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media ${MEDIA_DESKTOP} {
    width: 100%;
  }
`

const CategoryTabWrapper = styled.div`
  margin-top: 16px;
  @media ${MEDIA_DESKTOP} {
    margin-top: 44px;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 8px;
  transition: all 0.2s;
  z-index: 2;

  > .item {
    position: relative;
    cursor: pointer;
    color: #FFF;
    position: relative;
    text-align: center;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 13.2px */
    padding: 4px 10px;
    border-radius: 25px;
    @media ${MEDIA_DESKTOP} {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 17.6px */
      padding: 6px 16px;
    }
    > .afnvnxv {
      position: absolute;
      right: -10px;
      top: -6px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #ff4200;
      border-radius: 20px;
      font-size: 12px;
      @media ${MEDIA_DESKTOP} {
        right: -6px;
        font-size: 13px;
      }
    }
    > .effect {
      display: none;
      @media ${MEDIA_DESKTOP} {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        //transform: translate(calc(-50% - 30%), calc(-50% + 5%));
        z-index: -1;
        svg {
          cursor: default;
          top: 50%;
          left: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
          width: 190%;
          min-width: 282px;
        }
      }
    }    
  }

  > .selected.blue {
    border-top: 1px solid rgba(255, 255, 255, 0.50);
    background: #0896FC;
    box-shadow: 0px 2px 6px 0px rgba(8, 150, 252, 0.50);
  }

  > .selected.yellow {
    border-radius: 25px;
    border-top: 1px solid rgba(255, 255, 255, 0.50);
    background: #F1AF03;
    box-shadow: 0px 2px 6px 0px rgba(241, 175, 3, 0.50);
  }

  > .selected.red {
    border-radius: 25px;
    border-top: 1px solid rgba(255, 255, 255, 0.50);
    background: var(--Point-Red, #E31D1D);
    box-shadow: 0px 2px 6px 0px rgba(227, 29, 29, 0.50);
  }

  > .selected.purple {
    border-radius: 25px;
    border-top: 1px solid rgba(255, 255, 255, 0.50);
    background: #7B61FF;
    box-shadow: 0px 2px 6px 0px rgba(123, 97, 255, 0.50);
  }
`
const RingCardList = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 24px;
  justify-items: center;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 30px;
  z-index: 2;
  @media ${MEDIA_DESKTOP} {
    padding-bottom: 60px;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }
`;
const RingCard = styled.div<{ selected: boolean, type: 'blue' | 'yellow' | 'red' }>`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 26.1vw;
  max-width: 125px;
  aspect-ratio: 94/128;
  flex-shrink: 0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;

  ${p => p.selected ? `
    width: 28.8vw;
    aspect-ratio: 104/142;
    @media ${MEDIA_DESKTOP} {
      width: 26.1vw;
      max-width: 125px;
      aspect-ratio: 94/128;
    }
    border-radius: 8px;
    border: 3px solid var(--Point-Mint, #169E7A);
    box-shadow: 0px 0px 4px 0px #01BD9C;
  ` : ``}
  > .background {
    width: 103%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    ${p => p.selected ? `
      transform: translate(-50%, -50%) scale(1.1);
      @media ${MEDIA_DESKTOP} {
        transform: translate(-50%, -50%);
      }
    ` : ``}
  }

  > .content {
    width: 100%;
    flex-grow: 1;
    padding: 8px;
    z-index: 1;

    > .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      > .groupId {
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
      }

      > .member {
        color: #FFF;
        text-align: right;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        > img {
          width: 10px;
          height: 10px;
          backdrop-filter: drop-shadow(0px 0px 4px #000);
        }
      }
      >.live {
        color: #ffdc46;
        text-shadow: 0 0 3px #000;
      }
    }

    > .body {
      margin-top: 21%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 2px;

      > .title {
        color: #FFF;
        text-align: center;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        opacity: 0.78;
      }

      > .buyin {
        color: #FFF;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.5px;
      }
    }
  }

  > .footer {
    width: 100%;
    
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;
    gap: 2px;
    ${p => p.selected ? `
    padding: 10px 8px;
    height: 52px;
    @media ${MEDIA_DESKTOP} {
      padding: 8px;
      height: 56px; 
    }
    ` : `
    padding: 8px;
    height: 50px;
    @media ${MEDIA_DESKTOP} {
      padding: 8px;
      height: 56px; 
    }
    `}
    > .blind {
      z-index: 1;
      color: #FFF;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.5px;
      @media ${MEDIA_DESKTOP} {
        font-size: 13px;
      }
    }
    >.ante-wrapper{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      z-index: 1;
      > .ante {
        color: #B4B4B4;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          font-size: 12px;
        }
      }
    }
    
  }
`
const CategoryWrapper = styled.div`
  padding-top: 16px;

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > .item {
    cursor: pointer;
    padding-bottom: 14px;
    border-bottom: 1px solid #25262D;
    flex: 1;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  > .selected {
    border-bottom: 1px solid #FFF;
  }
`
const RoomListWrapper = styled.div`
  margin-top: 12px;
  width: 100%;
  flex-grow: 1;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  @media ${MEDIA_DESKTOP} {
    margin-top: 40px;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    align-items: flex-start;
  }

  > .room-list {
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 8px;
    z-index: 2;
    padding-bottom: 30px;
    @media ${MEDIA_DESKTOP} {
      width: calc(100% - 290px);
      padding-bottom: 0;
    }

    > .empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      @media ${MEDIA_DESKTOP} {
        justify-content: flex-start;
        margin-top: 100px;
      }
      > .wrapper {
        position: relative;

        > .center {
          width: 84px;
          height: 84px;
          object-fit: contain;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        > .bottom {
          width: 100%;
          position: absolute;
          bottom: 3px;
          left: 50%;
          transform: translateX(-50%);
          color: rgba(255, 255, 255, 0.8);
          text-align: center;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.2px;
        }

        > .background {
          width: 220px;
          height: 220px;
          object-fit: contain;
        }
      }
    }
  }

  > .pc-banner-wrapper {
    width: 266px;
    z-index: 3;
    display: none;
    @media ${MEDIA_DESKTOP} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;

      position: fixed;
      left: calc(50% + 365px);
      transform: translateX(-50%);
      width: 270px;
    }
    >.keep-away-button {
      cursor: pointer;
      background: #d63333;
      background: linear-gradient(90deg, rgba(206,108,108,1) 0%, rgba(182,52,52,1) 35%);
      width: 100%;
      text-align: center;
      padding-top: 8px;
      padding-bottom: 8px;
      color: #fff;
      >.top {
        font-size: 15px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;

        >img {
          width: 20px;
          height: 20px;
        }
        >span {
          margin-left: 5px;
        }
      }
      >.bottom {
        font-size: 11px;
      }
    }
    > .pc-banner {
      width: 266px;
      object-fit: contain;
      flex-shrink: 0;
    }
  }

`
const TournamentItem = styled.div`
  cursor: pointer;
  width: 100%;
  height: 72px;
  border-radius: 2px;
  padding: 0 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) -17.5%, rgba(255, 255, 255, 0.00) 60%), linear-gradient(90deg, #1A1C2B 41.13%, #5B3C25 100%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 12px;
  margin-top: 8px;
  &.end {
    background: linear-gradient(rgba(255, 255, 255, 0.1) -17.5%, rgba(255, 255, 255, 0) 80%), linear-gradient(90deg, rgb(9 10 16) 41.13%, rgb(41 26 16) 100%);
  }
  &.isRegister {
    margin-top: 16px;
  }
  > .fnsdnf {
    position: absolute;
    color: #fff;
    inset: 0px;
    border: 2px solid #f9af84;
    > div {
      position: relative;
      top: -11px;
      background: rgb(255 0 0);
      display: inline-block;
      font-size: 12px;
      padding: 3px 6px;
      border-radius: 4px;
      left: -2px;
    }
  }
  > .background {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 290px;
    object-fit: contain;
    z-index: 0;
  }

  &:last-child {
    margin-bottom: 8px;
  }

  > .status-wrapper {
    z-index: 1;
    width: 44px;
    height: 44px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > span {
      color: #FFF;
      text-align: center;
      leading-trim: both;
      text-edge: cap;
      font-family: Pretendard;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.2px;
    }

    > .background {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
  }

  > .info {
    z-index: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;

    > .top {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > .title {
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        max-width: 30%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @media ${MEDIA_DESKTOP} {
          max-width: 190px;
        }
      }

      > .startedAt {
        color: rgba(255, 255, 255, 0.9);
        font-size: 12px;
        display: none;
        align-items: center;
        gap: 4px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        > img {
          width: 18px;
          height: 18px;
          @media ${MEDIA_DESKTOP} {
            width: 24px;
            height: 24px;
          }
        }
        @media ${MEDIA_DESKTOP} {
          font-size: 14px;
          display: flex;
        }
      }

      > .time {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4px;

        > .time {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }

        > span {
          color: #B4B4B4;
          text-align: center;
          font-family: Pretendard;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }
    }
    > .middle {
      width: 100%;
      display: flex;
      flex-direction: row;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      justify-content: space-between;

      > .left {
        > .box {
          background: #0000001c;
          border: 1px solid #ffe700;
          border-radius: 10px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 12px;
          padding-top: 2px;
          padding-bottom: 2px;
          color: #ffe700;
          border: solid;
          border-image: linear-gradient(to top, #a59014, #ffd100) 1 / 1px;
          &.disabled {
            border-image: linear-gradient(to top, rgb(108 108 108), rgb(167 167 167)) 1 / 1px / 0 stretch;
            color: #9d9d9d;
            text-decoration: line-through;
          }
        }
        > .text {
          display: none;
          font-size: 11px;
          color: #ffe700;
          margin-left: 4px;

          @media ${MEDIA_DESKTOP} {
            display: inline;
          }

          &.disabled {
            display: none;
          }
        }
      }
      > .right {
        > .r1 {
          color: #fff;
          font-size: 13px;
        }
        > .r2 {
          font-size: 15px;
          margin-left: 8px;
          color: #ffe803;
          font-weight: 500;
          letter-spacing: -1px;
        }
      }
    }
    > .bottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      > .sub-title {
        color: #B4B4B4;
        text-align: center;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        display: flex;
        gap: 10px;

        > .buy-in-price {
          display: flex;
          align-items: center;
          gap: 4px; 
          > img {
            width: 12px;
            height: 12px;
          }
        }
        > .player-count {
          display: flex;
          align-items: center;
          gap: 4px; 
          > img {
            width: 12px;
            height: 12px;
          }
        }
      }

      > .right-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 12px;
        > .status-ribbon {
          --f: 5px;
          --r: 15px;
          --t: 53px;
          position: absolute;
          inset: var(--t) calc(-1* var(--f)) auto auto;
          padding: 0 10px var(--f) calc(10px + var(--r));
          clip-path: polygon(0 0, 100% 0, 100% calc(100% - var(--f)), calc(100% - var(--f)) 100%, calc(100% - var(--f)) calc(100% - var(--f)), 0 calc(100% - var(--f)), var(--r) calc(50% - var(--f) / 2));
          background: rgb(189 120 21);
          box-shadow: 0 calc(-1* var(--f)) 0 inset #0005;
          color: #000;
          font-size: 13px;
          height: 24px;
          display: flex;
          align-items: center;
        }
        > .participants {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;

          > span {
            color: #E31D1D;
            text-align: center;
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.5px;
          }

          > .user {
            width: 12px;
            height: 12px;
            object-fit: contain;
          }
        }

        > .prize {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 4px;

          > .title {
            color: #FFF;
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-family: Pretendard;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.2px;
          }

          > .amount {
            text-align: center;
            leading-trim: both;
            text-edge: cap;
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.2px;
            background: linear-gradient(180deg, #FDFAF1 0%, #EDCC72 79.17%, #E2B748 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }
`
const RoomItem = styled.div`
  cursor: pointer;
  width: 100%;
  height: 72px;
  min-height: 72px;
  max-height: 72px;
  border-radius: 2px;
  padding: 0 12px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.10) -17.5%, rgba(255, 255, 255, 0.00) 60%), #1A1C2B;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 17px;

  > .background {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 150px;
    object-fit: contain;
    z-index: 0;
  }

  &:last-child {
    margin-bottom: 8px;
  }

  > .index {
    width: 24px;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
    z-index: 1;
  }

  > .info-wrapper {
    z-index: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .wrapper-info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 12px;

      > .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 4px;

        > .title {
          color: #FFF;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
        }

        > .sub-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 4px;

          > .sub-title {
            width: 66px;
            max-width: 66px;

            &:first-child {
              width: 80px;
              max-width: 80px;
            }

            color: #B4B4B4;
            text-align: left;
            font-family: Pretendard;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.5px;
          }
        }
      }
    }


    > .participants {
      z-index: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      > span {
        color: #E31D1D;
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
      }

      > .user {
        width: 12px;
        height: 12px;
        object-fit: contain;
      }
    }
  }


  > .button {
    z-index: 1;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 68px;
    height: 32px;
    border-radius: 8px;
    background: var(--Gradient-Red, linear-gradient(180deg, #DF3535 0%, #A51818 100%));
    color: #FFF;
    leading-trim: both;
    text-edge: cap;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`

interface HoldemProps {
  showLogin?: () => void;
}

const Holdem = ({
  showLogin
} : HoldemProps) => {
  const [conn] = useSocketIsConnect();
  const navigate = useNavigate();
  const location1 = useLocation();
  const query = queryString.parse(location1.search);
  const {openDialog} = useDialog();
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [ringGroups] = useRingGroupListRefresh();
  const [tournamentGroups] = useTournamentGroupListRefresh();
  const [selectedType, setSelectedType] = useState<'200-1000' | '1000-10000' | '10000-100000' | 'tournament'>('200-1000');
  const [selectRingGroup, setSelectRingGroup] = useState<number>(-1);
  const [hasCacheGameData, setHasCacheGameData] = useRecoilState(hasCacheGameDataState);
  const [setting, setSetting] = useRecoilState(gameOptionState);
  const [isInitialzeDone, setIsInitialzeDone] = useState(false);
  const [availableCount1, setAvailableCount1] = useState(0);
  const [availableCount2, setAvailableCount2] = useState(0);
  const [availableCount3, setAvailableCount3] = useState(0);
  const [availableCount4, setAvailableCount4] = useState(0);
  const [serverTime, setServerTime] = useState<string|null>(null);
  const [keepAwayLength, setKeepAwayLength] = useState<number>(0);
  const [showKeepAwayManagerModal, setShowKeepAwayManagerModal] = useState<boolean>(false);
  const [showKeepAwayHelpModal, setShowKeepAwayHelpModal] = useState<boolean>(false);

  const refreshKeepAwayLength = useCallback(async () => {
    const keepAwayData = await requestGetKeepAwayMyList();

    if (keepAwayData.result) {
      setKeepAwayLength(keepAwayData.list.length);

      if (keepAwayData.list.length > 0 && !showKeepAwayHelpModal) {
        //새로고침 후 한번만 표시되게 하는 작업입니다.
        setShowKeepAwayHelpModal(true);
      }
    }
  }, [keepAwayLength, setKeepAwayLength]);

  useEffect(() => {
    let _availableCount1:number = 0;
    let _availableCount2:number = 0;
    let _availableCount3:number = 0;
    
    ringGroups?.list.map((val) => {
      if (val.isOpen && Number(val.totalPlayerCount) > 0) {
        if (val.data.blind[0] >= 200 && val.data.blind[1] <= 1000) {
          _availableCount1 = _availableCount1 ? _availableCount1 + 1 : 1;
        }
        else if (val.data.blind[0] >= 1000 && val.data.blind[1] <= 10000) {
          _availableCount2 = _availableCount2 ? _availableCount2 + 1 : 1;
        }
        else if (val.data.blind[0] >= 10000 && val.data.blind[1] <= 100000) {
          _availableCount3 = _availableCount3 ? _availableCount3 + 1 : 1;
        }
      }
    });

    if (_availableCount1 !== availableCount1)
      setAvailableCount1(_availableCount1);
    if (_availableCount2 !== availableCount2)
      setAvailableCount2(_availableCount2);
    if (_availableCount3 !== availableCount3)
      setAvailableCount3(_availableCount3);
  }, [ringGroups]);

  useEffect(() => {
    let _availableCount4:number = 0;

    tournamentGroups?.list.map((val) => {
      if (val.isOpen && !val.isEnd) {
        _availableCount4 = _availableCount4 ? _availableCount4 + 1 : 1;
      }
    });

    if (_availableCount4 !== availableCount4)
      setAvailableCount4(_availableCount4);

    if (tournamentGroups?.serverTime) {
      setServerTime(tournamentGroups.serverTime);
    }
  }, [tournamentGroups]);

  const queryParseStates = (query:any) => {
    query.type ? 
      query.type === '1' ?
        setSelectedType('200-1000') :
      query.type === '2' ?
        setSelectedType('1000-10000') :
      query.type === '3' ?
        setSelectedType('10000-100000') :
      query.type === '4' ?
        setSelectedType('tournament') :
      setSelectedType('200-1000')
    : setSelectedType('200-1000');
  };

  //initialze
  useEffect(() => {
    const query = queryString.parse(location.search);
    queryParseStates(query);

    setTimeout(()=> {
      setIsInitialzeDone(true);
    }, 10);
  }, []);

  //when changed url params;
  useEffect(() => {
    if (isInitialzeDone) {
      const query = queryString.parse(location.search);
      queryParseStates(query);
    }
  }, [location.search]);

  useEffect(() => {
    (async () => {
      await requestRingGroupList();
      await requestTournamentGroupList();
      await refreshKeepAwayLength();
    })();

    setSelectRingGroup(-1);
    let type = 'red';
    if (selectedType === '200-1000') {
     type = 'blue'
    }else if(selectedType === '1000-10000') {
      type = 'yellow'
    }else if(selectedType === '10000-100000') {
      type = 'red'
    }else if(selectedType === 'tournament') {
      type = 'purple'
    }
    const svgElement = document.getElementById('effect-'+type);
    const animations = svgElement?.getAnimations({subtree: true});
    if (animations?.length && animations[0].playState !== 'running') {
      animations.forEach((animation) => animation.play());
    }
  }, [selectedType])

  const handleClickJoin = useCallback(async function (groupId: number) {
    let sele = selectedType === '200-1000' ? 1 : selectedType === '1000-10000' ? 2 : '10000-100000' ? 3 : 0;

    if (sele === 1 && setting.tableStyle_LV1) {
      sele = setting.tableStyle_LV1;
    }
    else if (sele === 2 && setting.tableStyle_LV2) {
      sele = setting.tableStyle_LV2;
    }
    else if (sele === 3 && setting.tableStyle_LV3) {
      sele = setting.tableStyle_LV3;
    }

    if (hasCacheGameData !== sele) {
      setHasCacheGameData(0);
    }

    if (!conn.isLogin) {
      showLogin && showLogin();
      return;
    }
    setGlobalLoading(true);
    let r = await requestJoinRingGroup(groupId, 0, 0, false);
    
    setGlobalLoading(false);
    if (typeof (r.result) == "number") {
      if (r.result == -1) {
        openDialog({
          title: '오류',
          text: '현재 진행중인 게임이 있어 입장할 수 없습니다.',
          onConfirm: () => {
            location.reload();
          }
        });
      }
      else if (r.result === -2) {
        openDialog({
          title: '오류',
          text: '인원이 꽉 찬 방에는 입장할 수 없습니다.',
          onConfirm: () => {
            location.reload();
          }
        });
      } 
      else
        navigate("/game?id=" + r.result);
    }
  }, [conn.isLogin, selectedType, hasCacheGameData]);

  const printZeroToKrwText = (val:number) => {
    if (!val)
      return '무료';
    else
      return val.toLocaleString();
  };

  const onClickKeepAwayManager = () => {
    setShowKeepAwayManagerModal(true);
  };

  return <HoldemWrapper>
    <img className='banner-wrapper' src='/images/HoldemBanner.png'/>
    {
      keepAwayLength > 0 ? (
        <div className='keep-away-button-mobile' onClick={onClickKeepAwayManager}>
          <div className="top">
            <img src="/images/nes/profit.png" alt="정산"/>
            <span>미정산 내역이 {keepAwayLength}건 존재합니다.</span>
          </div>
          <div className="bottom">(클릭시 조회가능합니다.)</div>
        </div>
      ) : <></>
    }
    <CategoryTabWrapper>
      <div className={'item ' + (selectedType === '200-1000' ? 'selected blue' : '')} onClick={() => {
        playSFX(Sounds.SFX_TOGGLE, true);
        navigate(`/main/holdem?type=1`);
      }}>
        200-1,000
        {
          availableCount1 ? (
            <div className="afnvnxv">{availableCount1}</div>
          ) : <></>
        }
       <TabEffect type={'blue'}/>
      </div>
      <div className={'item ' + (selectedType === '1000-10000' ? 'selected yellow' : '')} onClick={() => {
        playSFX(Sounds.SFX_TOGGLE, true)
        navigate(`/main/holdem?type=2`);
      }}>
        1,000-10,000
        {
          availableCount2 ? (
            <div className="afnvnxv">{availableCount2}</div>
          ) : <></>
        }
        <TabEffect type={'yellow'}/>
      </div>
      <div className={'item ' + (selectedType === '10000-100000' ? 'selected red' : '')} onClick={() => {
        playSFX(Sounds.SFX_TOGGLE, true)
        navigate(`/main/holdem?type=3`);
      }}>
        10,000-100,000
        {
          availableCount3 ? (
            <div className="afnvnxv">{availableCount3}</div>
          ) : <></>
        }
        <TabEffect type={'red'}/>
      </div>
      <div className={'item ' + (selectedType === 'tournament' ? 'selected purple' : '')} onClick={() => {
        playSFX(Sounds.SFX_TOGGLE, true)
        navigate(`/main/holdem?type=4`);
      }}>
        토너먼트
        {
          availableCount4 ? (
            <div className="afnvnxv">{availableCount4}</div>
          ) : <></>
        }
        <TabEffect type={'purple'}/>
      </div>
    </CategoryTabWrapper>
    <RoomListWrapper>
      <div className='room-list'>
        {
          selectedType === 'tournament' ? <>
            {
              (tournamentGroups && tournamentGroups.list.length === 0) && <div className='empty'>
                <div className='wrapper'>
                  <img className='center' src='/images/img_3.png' alt='empty_point.png'/>
                  <div className='bottom'>현재 진행중인 토너먼트가 없습니다.</div>
                  <img className='background' src='/images/empty_bg.png' alt='empty'/>
                </div>
              </div>
            }
            {
              (tournamentGroups && tournamentGroups.list.length > 0 && serverTime) &&
              tournamentGroups?.list.map((e, index) => {
                const isRealStartTournament = moment(e.data.startedAt).diff(moment.unix(parseInt(serverTime)), 'seconds') <= 0;
                let status = ""
                let status2 = "";

                //종료, 사전등록, 진행중, 대기중
                if (e.isEnd) {
                  status = "종료";
                  status2 = "종료";
                }
                else if (e.isOngame) {
                  status = "진행중"
                  status2 = "등록가능";
                }
                else if (e.isOpen && e.totalRegisterCount < e.data.preRegisterFirstComeCount) {
                  status = "등록중"
                  status2 = "예약가능";
                } 
                else if (!e.isStarted) {
                  status = "등록중";
                  status2 = "예약가능";
                }
                else if (!isRealStartTournament) {
                  status = '대기중';
                }
                else if (e.isStarted) {
                  status = "진행중";
                  status2 = "등록가능";
                }

                let timerText = ""
                const {
                  playTimeSeconds,
                  restTimeSeconds
                } = e.data.timeStructure;

                const totalPrize = e.data.prize.reduce((accumulator:number, currentValue:number) => accumulator + currentValue, 0);
                const benefitPercent = Math.floor(e.data.preRegisterBenefitChip / e.data.beginChip * 100);
                const isBenefitDisabled = (e.totalRegisterCount >= e.data.preRegisterFirstComeCount) || e.isOngame || e.isEnd;

                if (e.isOpen || e.isRegister) {
                  const startedAt = parseDatetime(e.data.startedAt);
                  const isStarted = moment().isSameOrAfter(startedAt);

                  let leftSec = 0;

                  if (isStarted) {
                    const playTime = calcPlayTime(startedAt, playTimeSeconds, restTimeSeconds);

                    let closeTime = 0;
                    for(let i = 0; i < e.data.availableRegisterLevel ; ++i) {
                      closeTime += e.data.blindStructure[i][3]
                    }

                    leftSec = closeTime - playTime;
                  }
                  else {
                    leftSec = startedAt.unix() - moment().unix();
                  }

                  if (status === '사전예약') {
                    if (e.totalRegisterCount >= e.data.preRegisterFirstComeCount) {
                      timerText = '사전예약보상 마감';
                    }
                    else {
                      const left = e.data.preRegisterFirstComeCount - e.totalRegisterCount;
                      timerText = '사전예약보상 ' + left + '명 남음';
                    }
                  }
                  else {
                    if (leftSec > 0) {
                      const timerHour = Math.floor(leftSec / 3600);
                      const timerMin = Math.floor((leftSec - (timerHour * 3600)) / 60);
                      const timerSec = Math.floor(leftSec % 60);
  
                      if (e.isOngame) {
                        if (timerMin === 0) {
                          timerText = '등록마감 ' + timerSec + '초 남음'
                        }
                        else {
                          timerText = '등록마감 ' + (timerHour > 0 ? timerHour + '시간 ' : '') + timerMin + '분 남음'
                        }
                      }
                      else {
                        timerText = (timerHour > 0 ? timerHour + '시간 ' : '') + timerMin + '분 후 시작'
                      }
                    }
                    else {
                      if (e.isOngame) {
                        timerText = '등록마감';
                      }
                    }
                  }
                }

                return <TournamentItem className={`${e.isEnd ? 'end' : ''} ${e.isRegister ? 'isRegister' : ''}` }key={index} onClick={() => {
                  navigate(`/tournament/${e.groupId}`)
                }}> 
                  {
                    e.isRegister ? (
                      <div className='fnsdnf'>
                        <div>참여</div>
                      </div>
                    ) : <></>
                  }
                  <img className='background' src='/images/background_deep.png'/>
                  <div className='status-wrapper'>
                    <span>{status}</span>
                    {(status === '사전예약' || status === '등록중' || status === '대기중') &&
                      <svg className='background' xmlns="http://www.w3.org/2000/svg" width="44" height="44"
                           viewBox="0 0 44 44" fill="none">
                        <circle cx="22" cy="22" r="21.5" fill="#101012" fillOpacity="0.3"
                                stroke="url(#paint0_linear_108_5888)"/>
                        <defs>
                          <linearGradient id="paint0_linear_108_5888" x1="22" y1="0" x2="22" y2="44"
                                          gradientUnits="userSpaceOnUse">
                            <stop stopColor="white"/>
                            <stop offset="0.713542" stopColor="#EB8F4C"/>
                          </linearGradient>
                        </defs>
                      </svg>
                    }
                    {status === '진행중' &&
                      <svg className='background' xmlns="http://www.w3.org/2000/svg" width="44" height="44"
                           viewBox="0 0 44 44" fill="none">
                        <circle cx="22" cy="22" r="21.5" fill="#101012" fillOpacity="0.3"
                                stroke="url(#paint0_linear_994_9447)"/>
                        <defs>
                          <linearGradient id="paint0_linear_994_9447" x1="22" y1="0" x2="22" y2="44"
                            gradientUnits="userSpaceOnUse">
                            <stop stopColor="white"/>
                            <stop offset="0.713542" stopColor="#E31D1D"/>
                          </linearGradient>
                        </defs>
                      </svg>
                    }
                    {
                      status === '종료' &&
                      <svg className='background' xmlns="http://www.w3.org/2000/svg" width="44" height="44"
                           viewBox="0 0 44 44" fill="none">
                        <circle cx="22" cy="22" r="21.5" fill="#101012" fillOpacity="0.3" stroke="#8D8D8D"/>
                      </svg>
                    }
                  </div>
                  <div className='info'>
                    <div className='top'>
                      <div className='title'>
                        {e.data.name}
                      </div>
                      <div className="startedAt">
                        <img src="/images/nes/start2.png"/>
                        <span>{e.data.startedAt}</span>
                      </div>
                      {
                        (status === '사전예약' || status === '등록중' || status === '대기중' || status === '진행중') && <div className='time'>
                          <img className='time' src='/images/ic_clock.png'/>
                          <span>{timerText}</span>
                        </div>
                      }
                    </div>
                    <div className="middle">
                      <div className="left">
                        <span className={`box ${isBenefitDisabled ? 'disabled' : ''}`}>사전 예약보너스 가능 {e.totalRegisterCount}/{e.data.preRegisterFirstComeCount}</span>
                        <span className={`text ${isBenefitDisabled ? 'disabled' : ''}`}>+{benefitPercent}%</span>
                      </div>
                      <div className="right">
                        <span className="r1">총상금</span>
                        <span className="r2">{totalPrize.toLocaleString()}</span>
                      </div>
                    </div>
                    <div className='bottom'>
                      <div className='sub-title'>
                        <div className="buy-in-price">
                          <img src="/images/nes/chip3.png"/>
                          <span>필요 바이인 금액 {printZeroToKrwText(e.data.buyinPrice)}</span>
                        </div>
                        <div className="buy-in-price">
                          <img src="/images/nes/chip2.png"/>
                          <span>필요 리바이인 금액 {e.data.rebuyinPrice ? printZeroToKrwText(e.data.rebuyinPrice) : 0}</span>
                        </div>
                        <div className="player-count">
                          <img src="/images/nes/player23.png"/>
                          <span>{e.totalRegisterCount}</span>
                        </div>
                        
                      </div>
                      <div className='right-row'>
                        {
                          status2 !== '' && status2 !== '종료' ? (
                            <div className="status-ribbon">
                              {status2}
                            </div>
                          ) : <></>
                        }
                      </div>
                    </div>
                  </div>
                </TournamentItem>
              })
            }
          </> : <>
            {
              (ringGroups && ringGroups.list.length === 0) && <div className='empty'>
                <div className='wrapper'>
                  <img className='center' src='/images/img_3.png' alt='empty_point.png'/>
                  <div className='bottom'>현재 진행중인 게임이 없습니다.</div>
                  <img className='background' src='/images/empty_bg.png' alt='empty'/>
                </div>
              </div>
            }
            {
              (ringGroups && ringGroups.list.length > 0) && <>
                {
                  <RingCardList>
                    {
                      ringGroups?.list.filter(e => {
                        const smallBlind = e.data.blind[0]
                        const bigBlind = e.data.blind[1]
                        
                        if (selectedType === '200-1000') {
                          return (smallBlind >= 200 && bigBlind <= 1000)
                        } else if (selectedType === '1000-10000') {
                          return (smallBlind >= 1000 && bigBlind <= 10000)
                        } else if (selectedType === '10000-100000') {
                          return (smallBlind >= 10000 && bigBlind <= 100000)
                        } else {
                          return false
                        }
                      }).map((e, index) => {
                        let color: 'blue' | 'yellow' | 'red' = 'blue'
                        const smallBlind = e.data.blind[0]
                        const bigBlind = e.data.blind[1]
                        if (smallBlind >= 10000) {
                          color = 'red'
                        } else if (smallBlind >= 1000) {
                          color = 'yellow'
                        } else {
                          color = 'blue'
                        }

                        return <RingCard key={index} selected={selectRingGroup === e.groupId} type={color}
                                onClick={() => {
                                  playSFX(Sounds.SFX_CLICK, true)

                                  if (selectRingGroup === e.groupId)
                                    setSelectRingGroup(-1)
                                  else
                                    setSelectRingGroup(e.groupId)
                                }}>

                          {color === 'blue' && <img className='background' src='/images/BG_Pattern/BLUE.png'/>}
                          {color === 'yellow' && <img className='background' src='/images/BG_Pattern/YELLOW.png'/>}
                          {color === 'red' && <img className='background' src='/images/BG_Pattern/RED.png'/>}
                          <div className='content'>
                            <div className='header'>
                              <div className='groupId'>
                                {e.groupId}
                              </div>
                              <div className={`member ${e && e.totalPlayerCount > 0 ? 'live' : ''}`}>
                                {
                                  e && e.totalPlayerCount > 0 && (e.totalPlayerCount / e.data.maxTableMember) > 0.3 ? (
                                    <img src='/images/nes/hot.png' />
                                  ) : <></>
                                }
                                <img src={`${e && e.totalPlayerCount > 0 ? '/images/nes/user_live.png' : '/images/User.png'}`}/>{e.totalPlayerCount}/{e.data.maxTableMember}
                              </div>
                            </div>
                            <div className='body'>
                              <div className='title'>
                                Buy-In
                              </div>
                              <div className='buyin'>
                                {toEasternNumeral(e.data.minBuyin)} 이상
                              </div>
                            </div>
                          </div>
                          <div className='footer'>
                            <div className='blind'>{toEasternNumeral(smallBlind)} - {toEasternNumeral(bigBlind)}</div>
                            <div className='ante-wrapper'>
                              <div className='ante'>Ante | {e.data.isAnte ? toEasternNumeral(e.data.ante) : '없음'}</div>
                              {
                                e.data.isStraddle && <STRBadge>
                                  <img className='icon' src='/images/ic_str.png'/>
                                </STRBadge>
                              }
                            </div>
                          </div>
                        </RingCard>
                      })
                    }
                  </RingCardList>
                }
                <EnterButtonWrapper className={`${selectRingGroup !== -1 ? 'active' : ''}`}>
                  <EnterButton onClick={() => {
                    playSFX(Sounds.SFX_CLICK, true)
                    handleClickJoin(selectRingGroup)
                  }}>
                    입장하기
                  </EnterButton>
                </EnterButtonWrapper>
              </>
            }
          </>
        }
      </div>
      <div className='pc-banner-wrapper'>
        {
          keepAwayLength > 0 ? (
            <div className='keep-away-button' onClick={onClickKeepAwayManager}>
              <div className="top">
                <img src="/images/nes/profit.png" alt="정산"/>
                <span>미정산 내역이 {keepAwayLength}건 존재합니다.</span>
              </div>
              <div className="bottom">(클릭시 조회가능합니다.)</div>
            </div>
          ) : <></>
        }
        <img src='/images/pc/Holdem_pc_banner.png' className='pc-banner'/>
        <EnterButton onClick={() => {
          playSFX(Sounds.SFX_CLICK, true)
          handleClickJoin(selectRingGroup)
        }}>
          {conn.isLogin ? `입장하기`: `Login to Play`}
        </EnterButton>
      </div>
    </RoomListWrapper>
    {
      selectedType === 'tournament' ? <div className='background'>
        {selectedType === 'tournament' && <img className='pc' src='/images/LIST_Pattern/bg-purple.svg'/>}
      </div> : <div className='background'>
        {selectedType === '200-1000' && <img className='mobile' src='/images/LIST_Pattern/Blue.png'/>}
        {selectedType === '1000-10000' && <img className='mobile' src='/images/LIST_Pattern/Yellow.png'/>}
        {selectedType === '10000-100000' && <img className='mobile' src='/images/LIST_Pattern/Red.png'/>}
        {selectedType === '200-1000' && <img className='pc' src='/images/LIST_Pattern/bg-blue.svg'/>}
        {selectedType === '1000-10000' && <img className='pc' src='/images/LIST_Pattern/bg-yellow.svg'/>}
        {selectedType === '10000-100000' && <img className='pc' src='/images/LIST_Pattern/bg-red.svg'/>}
      </div>
    }
    <ModalContainer 
      show={showKeepAwayManagerModal}
      noDim2={true}
      onBackdropClick={()=> {
        setShowKeepAwayManagerModal(false);
      }}
    >
      <KeepAwayManagerModal onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        setShowKeepAwayManagerModal(false);
      }}/>
    </ModalContainer>

    {
      showKeepAwayHelpModal ? (
        <ModalContainer 
          show={showKeepAwayHelpModal}
          noDim2={true}
          onBackdropClick={()=> {
            setShowKeepAwayHelpModal(false);
          }}
        >
          <KeepAwayManagerInformModal onClose={() => {
            playSFX(Sounds.SFX_WINDOW_CLOSE, true);
            setShowKeepAwayHelpModal(false);
          }}/>
        </ModalContainer>
      ) : <></>
    }
    
  </HoldemWrapper>
}

export default Holdem;
