import styled from "styled-components";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import {playSFX, Sounds} from "../../utils/sound";
import useDialog from "../../hooks/useDialog";
import {useNavigate} from "react-router-dom";
import {
  requestJoinRingGroup,
  requestRingGroupListHandShake,
  requestTournamentGroupList,
  requestMoveRingReserve
} from "../../api";
import {useRecoilState, useSetRecoilState} from 'recoil';
import Loading from "../common/Loading";
import {toEasternNumeral} from "../../utils/common";
import {
  hasCacheGameDataState
} from "../../recoil/Game";
import {globalLoadingState} from "../../recoil/Loading";

import {
  GamePlayer
} from '../../dataset';

const Wrapper = styled.div`
  width: 264px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #101012;
  border-left: 1px solid #E31D1D;
  color: #FFF;

  @media ${MEDIA_DESKTOP} {
    width: 640px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  background: rgba(227, 29, 29, 0.3);

  @media ${MEDIA_DESKTOP} {
    height: 72px;
  }

  > .back {
    width: 48px;
    height: 48px;
    padding: 12px;
    cursor: pointer;

    @media ${MEDIA_DESKTOP} {
      width: 72px;
      height: 72px;
      padding: 24px;
    }
  }

  > .title {
    flex: 1;
    font-size: 16px !important;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .gap {
    width: 48px;

    @media ${MEDIA_DESKTOP} {
      width: 72px;
    }
  }
`;

const RingCardList = styled.div`
  width: 100%;
  display: flex;
  -webkit-box-flex: unset;
  flex-wrap: wrap;
  justify-content: center;
  @media ${MEDIA_DESKTOP} {
    row-gap: 13px;
  }
`;

const RingCard = styled.div<{ selected: boolean, type: 'blue' | 'yellow' | 'red' | 'black' }>`
  position: relative;
  overflow: hidden;
  cursor: pointer;
  width: 26.1vw;
  max-width: 100px;
  aspect-ratio: 94/128;
  flex-shrink: 0;
  border-radius: 8px;
  margin: 2px;
  @media ${MEDIA_DESKTOP} {
    margin: 7px;
    max-width: 125px;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;

  ${p => p.selected ? `
    border-radius: 8px;
    border: 3px solid var(--Point-Mint, #169E7A);
    box-shadow: 0px 0px 4px 0px #01BD9C;
  ` : ``}
  > .background {
    width: 103%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  > .content {
    width: 100%;
    flex-grow: 1;
    padding: 8px;
    z-index: 1;

    > .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      > .groupId {
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
      }

      > .dgsngns {
        font-size: 8px;
        background: #ffcd00;
        color: #000;
        padding: 2px;
      }
      > .member {
        color: #FFF;
        text-align: right;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        > img {
          width: 10px;
          height: 10px;
          backdrop-filter: drop-shadow(0px 0px 4px #000);
        }
      }
      >.live {
        color: #ffdc46;
        text-shadow: 0 0 3px #000;
      }
    }

    > .body {
      margin-top: 21%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 2px;

      > .title {
        color: #FFF;
        text-align: center;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        opacity: 0.78;
      }

      > .buyin {
        color: #FFF;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.5px;
      }
    }
  }

  > .footer {
    width: 100%;
    
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;
    gap: 2px;
    ${p => p.selected ? `
    padding: 10px 8px;
    height: 52px;
    @media ${MEDIA_DESKTOP} {
      padding: 8px;
      height: 56px; 
    }
    ` : `
    padding: 8px;
    height: 50px;
    @media ${MEDIA_DESKTOP} {
      padding: 8px;
      height: 56px; 
    }
    `}
    > .blind {
      z-index: 1;
      color: #FFF;
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.5px;
      @media ${MEDIA_DESKTOP} {
        font-size: 13px;
      }
    }
    >.ante-wrapper{
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      z-index: 1;
      > .ante {
        color: #B4B4B4;
        font-family: Roboto;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          font-size: 12px;
        }
      }
    }
    
  }
`

const STRBadge = styled.div`
  width: 29px;
  height: 13px;
  position: relative;
  >.icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 21px;
    object-fit: contain;
  }
`

const CategoryTabWrapper = styled.div`
  margin-top: 16px;
  @media ${MEDIA_DESKTOP} {
    margin-top: 44px;
  }
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 8px;
  transition: all 0.2s;
  z-index: 2;

  > .item {
    position: relative;
    cursor: pointer;
    color: #FFF;
    position: relative;
    text-align: center;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%; /* 13.2px */
    padding: 4px 10px;
    border-radius: 25px;
    @media ${MEDIA_DESKTOP} {
      color: #FFF;
      text-align: center;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.20);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 17.6px */
      padding: 6px 16px;
    }
    > .effect {
      display: none;
      @media ${MEDIA_DESKTOP} {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        //transform: translate(calc(-50% - 30%), calc(-50% + 5%));
        z-index: -1;
        svg {
          cursor: default;
          top: 50%;
          left: 50%;
          position: absolute;
          transform: translate(-50%, -50%);
          width: 190%;
          min-width: 282px;
        }
      }
    }    
  }

  > .selected.blue {
    border-top: 1px solid rgba(255, 255, 255, 0.50);
    background: #0896FC;
    box-shadow: 0px 2px 6px 0px rgba(8, 150, 252, 0.50);
  }

  > .selected.yellow {
    border-radius: 25px;
    border-top: 1px solid rgba(255, 255, 255, 0.50);
    background: #F1AF03;
    box-shadow: 0px 2px 6px 0px rgba(241, 175, 3, 0.50);
  }

  > .selected.red {
    border-radius: 25px;
    border-top: 1px solid rgba(255, 255, 255, 0.50);
    background: var(--Point-Red, #E31D1D);
    box-shadow: 0px 2px 6px 0px rgba(227, 29, 29, 0.50);
  }

  > .selected.purple {
    border-radius: 25px;
    border-top: 1px solid rgba(255, 255, 255, 0.50);
    background: #7B61FF;
    box-shadow: 0px 2px 6px 0px rgba(123, 97, 255, 0.50);
  }
`
const RoomListWrapper = styled.div`
  margin-top: 12px;
  width: 100%;
  margin-top: 20px;
  padding: 0;
  padding: 20px;
  padding-top: 0;
  @media ${MEDIA_DESKTOP} {
    margin-top: 40px;
  }

  > .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    @media ${MEDIA_DESKTOP} {
      justify-content: flex-start;
      margin-top: 100px;
    }
    > .wrapper {
      position: relative;
      margin-top: 40px;
      @media ${MEDIA_DESKTOP} {
        margin-top: 0;
      }
      > .center {
        width: 84px;
        height: 84px;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      > .bottom {
        width: 100%;
        position: absolute;
        bottom: 3px;
        left: 50%;
        transform: translateX(-50%);
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
      }

      > .background {
        width: 180px;
        height: 220px;
        object-fit: contain;
        @media ${MEDIA_DESKTOP} {
          width: 220px;
          height: 220px;
        }
      }
    }
  }
  
  > .room-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 8px;
    z-index: 2;
    padding-bottom: 30px;
    @media ${MEDIA_DESKTOP} {
      width: 706px;
      max-width: 706px;
      padding-bottom: 0;
    }
  }

  > .pc-banner-wrapper {
    width: 266px;
    z-index: 3;
    display: none;
    @media ${MEDIA_DESKTOP} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
    }

    > .pc-banner {
      width: 266px;
      object-fit: contain;
      flex-shrink: 0;
    }
  }
`

const EnterButtonWrapper = styled.div`
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  -webkit-box-pack: center;
  justify-content: center;
  z-index: 3;
  margin-top: 20px;

  @media ${MEDIA_DESKTOP} {
    margin-top: 40px;
  }
`
const EnterButton = styled.div`
  display: flex;
  width: 100%;;
  height: 48px;
  padding: 10px 13px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 24px;
  background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
  cursor: pointer;
  color: #FFF;
  leading-trim: both;
  text-edge: cap;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  @media ${MEDIA_DESKTOP} {
    max-width: 380px;
  }
`

function OtherRingStatus(
  {
    roomId,
    groupId,
    groupData,
    onClose,
    onMoveReserved,
    isOpen,
    myInfo
  }: {
    roomId: number;
    groupId: number;
    groupData: any;
    onClose?: () => void;
    onMoveReserved?: () => void;
    isOpen: boolean;
    myInfo?: any | undefined;
  }
) {
  const {openDialog} = useDialog();
  const navigate = useNavigate();
  const [selectedType, setSelectedType] = useState<'200-1000' | '1000-10000' | '10000-100000' | 'tournament'>('200-1000');
  const [selectRingGroup, setSelectRingGroup] = useState<number>(-1);
  const [ringList, setRingList] = useState<any>(null);
  const [previousOpen, setPreviousOpen] = useState<boolean>(false);
  const [hasCacheGameData, setHasCacheGameData] = useRecoilState(hasCacheGameDataState);
  const [setting, setSetting] = useRecoilState(gameOptionState);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);

  useEffect(()=> {
    if (!previousOpen && isOpen) {
      const { blind } = groupData;

      if (blind[0] >= 200 && blind[1] <= 1000) {
        setSelectedType('200-1000');
      }
      else if (blind[0] > 1000 && blind[1] <= 10000) {
        setSelectedType('1000-10000');
      }
      else if (blind[0] > 10000 && blind[1] <= 100000) {
        setSelectedType('10000-100000');
      }
      else {
        setSelectedType('200-1000');
      }

      requestRingGroupListHandShake().then((v:any) => {
        if (v.code === 0 && typeof v.list === 'object') {
          setRingList(v.list);
        }
      });
    }

    setPreviousOpen(isOpen);
  }, [groupData, previousOpen, isOpen]);
  
  useEffect(() => {
    (async () => {
      if (selectedType !== 'tournament') {
        const a = await requestRingGroupListHandShake();
      } else {
        await requestTournamentGroupList();
      }
    })()
    setSelectRingGroup(-1);
    let type = 'red';
    if (selectedType === '200-1000') {
     type = 'blue'
    }else if(selectedType === '1000-10000') {
      type = 'yellow'
    }else if(selectedType === '10000-100000') {
      type = 'red'
    }else if(selectedType === 'tournament') {
      type = 'purple'
    }
    const svgElement = document.getElementById('effect-'+type);
    const animations = svgElement?.getAnimations({subtree: true});
    if (animations?.length && animations[0].playState !== 'running') {
      animations.forEach((animation) => animation.play());
    }
  }, [selectedType])

  const handleClickJoin = useCallback(async function (groupId: number) {
    let sele = selectedType === '200-1000' ? 1 : selectedType === '1000-10000' ? 2 : '10000-100000' ? 3 : 0;

    if (sele === 1 && setting.tableStyle_LV1) {
      sele = setting.tableStyle_LV1;
    }
    else if (sele === 2 && setting.tableStyle_LV2) {
      sele = setting.tableStyle_LV2;
    }
    else if (sele === 3 && setting.tableStyle_LV3) {
      sele = setting.tableStyle_LV3;
    }

    if (hasCacheGameData !== sele) {
      setHasCacheGameData(0);
    }

    let additionalWinAmount = 0;
    let additionalHandsCount = 0;

    if (myInfo.isMovedRoom && myInfo.winAmount > 0) {
      additionalWinAmount = myInfo.winAmount;
      additionalHandsCount = myInfo.handsCount;
    }

    setGlobalLoading(true);
    let r = await requestJoinRingGroup(groupId, additionalWinAmount, additionalHandsCount, true);
    setGlobalLoading(false);

    if (typeof (r.result) == "number") {
      if (r.result == -1) {
        openDialog({
          title: '오류',
          text: '진행중인 게임이 있어 입장할 수 없습니다.<br/>현재 진행중인 방에서 한 게임이 종료되면,<br/>이동이 되게끔 예약을 걸어두시겠습니까?<br/><br/>[이동 시 핸디카운트가 낮아도 수익금 차감이 되지 않으며<br/>현재 수익금과 핸디카운트가 이동 될 방에 그대로 저장됩니다.]',
          confirm: true,
          confirmText: '이동 예약',
          onConfirm: async () => {
            playSFX(Sounds.SFX_WINDOW_OPEN, true);
            const ret = await requestMoveRingReserve(groupId);
            
            if (ret.result === 1) {
              onMoveReserved && onMoveReserved();
              onClose && onClose();
            }
            else if (ret.result === -1) {
              openDialog({
                title: '오류',
                text: '현재 바이인했거나 관전중인 방이 없는데 이동 예약을 하셨습니다.',
                onConfirm: () => {
                }
              });
            }
            else if (ret.result === -2) {
              openDialog({
                title: '오류',
                text: '현재 접속중인 방 정보를 읽어오는데 실패하였습니다.',
                onConfirm: () => {
                }
              });
            }
            else if (ret.result === -3) {
              openDialog({
                title: '오류',
                text: '이동할 방 정보를 읽어오는데 실패하였습니다',
                onConfirm: () => {
                }
              });
            }
            else if (ret.result === -4) {
              openDialog({
                title: '오류',
                text: '방 이동 예약에 실패하였습니다.',
                onConfirm: () => {
                }
              });
            }
            else if (ret.result === -5) {
              openDialog({
                title: '오류',
                text: '인원이 꽉 찬 방에는 입장할 수 없습니다.',
                onConfirm: () => {
                }
              });
            }
          },
          onCancel: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE, true);
          }
        });
      }
      else if (r.result === -2) {
        openDialog({
          title: '오류',
          text: '인원이 꽉 찬 방에는 입장할 수 없습니다.',
          onConfirm: () => {
          }
        });
      } 
      else if (r.result === -3) {
        openDialog({
          title: '오류',
          text: '자리비움 상태에서는 방 이동을 할 수 없습니다. 기존 방에 다시 바이인해서 시도하시거나 정산 후에 시도해주세요.',
          onConfirm: () => {
          }
        });
      }
      else {
        playSFX(Sounds.SFX_WINDOW_OPEN, true);
        navigate("/game?id=" + r.result);
        onClose && onClose();
      }
    }
  }, [selectedType, hasCacheGameData, myInfo]);

  return <Wrapper>
    <Header>
      <img className="back" src="/images/ic_header_back.svg" onClick={onClose}/>
      <div className="title">테이블 이동</div>
      <div className="gap"/>
    </Header>
    
    <CategoryTabWrapper>
      <div className={'item ' + (selectedType === '200-1000' ? 'selected blue' : '')} onClick={() => {
        playSFX(Sounds.SFX_TOGGLE, true)
        setSelectedType('200-1000')
      }}>
        200-1,000
      </div>
      <div className={'item ' + (selectedType === '1000-10000' ? 'selected yellow' : '')} onClick={() => {
        playSFX(Sounds.SFX_TOGGLE, true)
        setSelectedType('1000-10000')
      }}>
        1,000-10,000
      </div>
      <div className={'item ' + (selectedType === '10000-100000' ? 'selected red' : '')} onClick={() => {
        playSFX(Sounds.SFX_TOGGLE, true)
        setSelectedType('10000-100000')
      }}>
        10,000-100,000
      </div>
    </CategoryTabWrapper>
    <RoomListWrapper>
      {
        ringList === null ? 
        <Loading/> :
        ringList?.filter((e:any) => {
          const smallBlind = e.data.blind[0]
          const bigBlind = e.data.blind[1]
          
          if (selectedType === '200-1000') {
            return (smallBlind >= 200 && bigBlind <= 1000)
          } else if (selectedType === '1000-10000') {
            return (smallBlind >= 1000 && bigBlind <= 10000)
          } else if (selectedType === '10000-100000') {
            return (smallBlind >= 10000 && bigBlind <= 100000)
          } else {
            return false
          }
        }).length === 0 ?
        <div className='empty'>
          <div className='wrapper'>
            <img className='center' src='/images/img_3.png' alt='empty_point.png'/>
            <div className='bottom'>현재 진행중인 게임이 없습니다.</div>
            <img className='background' src='/images/empty_bg.png' alt='empty'/>
          </div>
        </div> :
        <>
          <RingCardList>
          {
            ringList?.filter((e:any) => {
              const smallBlind = e.data.blind[0]
              const bigBlind = e.data.blind[1]
              
              if (selectedType === '200-1000') {
                return (smallBlind >= 200 && bigBlind <= 1000)
              } else if (selectedType === '1000-10000') {
                return (smallBlind >= 1000 && bigBlind <= 10000)
              } else if (selectedType === '10000-100000') {
                return (smallBlind >= 10000 && bigBlind <= 100000)
              } else {
                return false
              }
            }).map((e:any, index:number) => {
              let color: 'blue' | 'yellow' | 'red' | 'black' = 'blue'
              const smallBlind = e.data.blind[0]
              const bigBlind = e.data.blind[1]

              if (groupId === e.groupId) {
                color = 'black'
              } else if (smallBlind >= 10000) {
                color = 'red'
              } else if (smallBlind >= 1000) {
                color = 'yellow'
              } else {
                color = 'blue'
              }

              return <RingCard key={index} selected={selectRingGroup === e.groupId} type={color}
                onClick={() => {
                  playSFX(Sounds.SFX_CLICK, true)

                  if (groupId !== e.groupId) {
                    setSelectRingGroup(e.groupId)
                  }
                }}
              >
                {color === 'black' && <img className='background' src='/images/nes/BLACK.png'/>}
                {color === 'blue' && <img className='background' src='/images/BG_Pattern/BLUE.png'/>}
                {color === 'yellow' && <img className='background' src='/images/BG_Pattern/YELLOW.png'/>}
                {color === 'red' && <img className='background' src='/images/BG_Pattern/RED.png'/>}
                <div className='content'>
                  <div className='header'>
                    <div className='groupId'>
                      {e.groupId}
                    </div>
                    {
                      e.groupId === groupId ? (
                      <span className='dgsngns'>접속중인방</span>
                      ) : 
                      <div className={`member ${e && e.totalPlayerCount > 0 ? 'live' : ''}`}>
                      {
                        e && e.totalPlayerCount > 0 && (e.totalPlayerCount / e.data.maxTableMember) > 0.3 ? (
                          <img src='/images/nes/hot.png' />
                        ) : <></>
                      }
                      <img src={`${e && e.totalPlayerCount > 0 ? '/images/nes/user_live.png' : '/images/User.png'}`}/>{e.totalPlayerCount}/{e.data.maxTableMember}
                    </div>
                    }
                  </div>
                  <div className='body'>
                    <div className='title'>
                      Buy-In
                    </div>
                    <div className='buyin'>
                      {toEasternNumeral(e.data.minBuyin)} 이상
                    </div>
                  </div>
                </div>
                <div className='footer'>
                  <div className='blind'>{toEasternNumeral(smallBlind)} - {toEasternNumeral(bigBlind)}</div>
                  <div className='ante-wrapper'>
                    <div className='ante'>Ante | {e.data.isAnte ? toEasternNumeral(e.data.ante) : '없음'}</div>
                    {
                      e.data.isStraddle && <STRBadge>
                        <img className='icon' src='/images/ic_str.png'/>
                      </STRBadge>
                    }
                  </div>
                </div>
              </RingCard>
            })
          }
        </RingCardList>

        <EnterButtonWrapper>
          <EnterButton onClick={() => {
            playSFX(Sounds.SFX_CLICK, true)
            handleClickJoin(selectRingGroup)
          }}>
            입장하기
          </EnterButton>
        </EnterButtonWrapper>
      </>
      }
    </RoomListWrapper>
  </Wrapper>;
}

export default OtherRingStatus;
