import styled from "styled-components";
import {useEffect, useState} from "react";
import {requestGetBanners, requestGetNoticelist} from "../api";
import {MainBannerType, NoticeSimpleInfo} from "../dataset";
import NoticeDetail from "./NoticeDetail";
import {playSFX, Sounds} from "../utils/sound";
import useScreenOrientation from "../hooks/useScreenOrientation";

const MobileHomeWrapper = styled.div`
  width: 100%;
  height: 100%;
  >.content-wrapper {
    width: 100%;
    height: calc(100% - 51px);
    overflow-y: scroll;
    > .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 0 8px;
      gap: 8px;
      >.empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        >.wrapper {
          position: relative;
          >.center {
            width: 84px;
            height: 84px;
            object-fit: contain;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          >.bottom {
            width: 100%;
            position: absolute;
            bottom: 3px;
            left: 50%;
            transform: translateX(-50%);
            color: rgba(255, 255, 255, 0.8);
            text-align: center;
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.2px;
          }
          >.background {
            width: 220px;
            height: 220px;
            object-fit: contain;
          }
        }
      }
    }
  }
`
const PCHomeWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  >.content-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    >.content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      >.main-banner {
        width: 100%;
        aspect-ratio: 1920/580;
        object-fit: contain;
      }
      >.notice-list {
        padding: 40px 0 120px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        background: white;
        >.title {
          color: var(--Black, #181818);
          text-align: center;
          font-family: Pretendard;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        >.sub-title {
          margin-top: 16px;
          color: var(--Black, #181818);
          text-align: center;
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        >.empty {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 1240px;
          margin-top: 40px;
          color: var(--Black, #181818);
          text-align: center;
          font-family: Pretendard;
          font-size: 32px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        >.list {
          width: fit-content;
          max-width: 1240px;
          margin-top: 40px;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 30px;
          justify-items: center;
          >.item {
            background: green;
            cursor: pointer;
            width: 47.5vw;
            max-width: 605px;
            aspect-ratio: 605/140.698;
          }
        }
      }
    }
  }
  
`
const Title = styled.div`
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #FFF;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  gap: 8px;
`

const NoticeItemWrapper = styled.div`
  width: 100%;
  height: fit-content;
  background: #909090;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #777;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
const MainBannerMobileImg = styled.img`
  width: 100%;
  aspect-ratio: 344/240;
  object-fit: contain;
`
const MainBannerMobileVideo = styled.video`
  width: 100%;
  aspect-ratio: 344/240;
  object-fit: contain;
`

const Home = () => {
  const orientation = useScreenOrientation()
  const [selectNoticeId, setSelectNoticeId] = useState<number | null>(null);
  const [noticeList, setNoticeList] = useState<NoticeSimpleInfo[]>([]);
  const [mainBanner, setMainBanner] = useState<{
    mobile: string;
    pc: string;
  } | null>(null);
  useEffect(() => {
    requestGetNoticelist().then((res) => {
      setNoticeList(res.list);
    })
    requestGetBanners().then(res => {
      setMainBanner({
        mobile: res.mainBanners.find( _ => Number(_.type) === MainBannerType.NoticeMainBannerMobile)?.url || '',
        pc: res.mainBanners.find( _ => Number(_.type) === MainBannerType.NoticeMainBannerPC)?.url || ''
      })
    })
  }, []);
  return orientation === 'portrait' ?  <MobileHomeWrapper>
    {
      selectNoticeId && <NoticeDetail noticeId={selectNoticeId} onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true)
        setSelectNoticeId(null);
      }}/>
    }
    <Title>공지사항</Title>
    <div className="content-wrapper">
      <div className="content">
        {
          (mainBanner && mainBanner.mobile) && <>
            {
              mainBanner.mobile.includes('.mp4') ?
                <MainBannerMobileVideo loop={true} src={mainBanner.mobile} muted={true} autoPlay={true} playsInline={true}/> :
                <MainBannerMobileImg src={mainBanner.mobile} alt='main-banner' />
            }
          </>

        }
        {
          noticeList.map((_, index) => {
            return <NoticeItemWrapper key={index} onClick={()=>{
              playSFX(Sounds.SFX_WINDOW_OPEN, true)
              setSelectNoticeId(_.noticeId)
            }}>
              <img src={_.thumb}/>
            </NoticeItemWrapper>
          })
        }
        {
          noticeList.length === 0 && <div className='empty'>
            <div className='wrapper'>
              <img className='center' src='/images/megaphone.png' alt='megaphone' />
              <div className='bottom'>공지사항이 없습니다.</div>
              <img className='background' src='/images/empty_bg.png' alt='empty'/>
            </div>
          </div>
        }
      </div>
    </div>
  </MobileHomeWrapper> : <PCHomeWrapper>
    {
      selectNoticeId && <NoticeDetail noticeId={selectNoticeId} onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true)
        setSelectNoticeId(null);
      }}/>
    }
    <div className='content-wrapper'>
      <div className='content'>
        {
          (mainBanner && mainBanner.pc) && <>
          {
            mainBanner.pc.includes('.mp4') ?
              <video className='main-banner' loop={true} src={mainBanner.pc} muted={true} autoPlay={true} playsInline={true}/> :
              <img className='main-banner' src={mainBanner.pc} alt='main-banner' />
          }
          </>
        }
        <div className='notice-list'>
        <div className='title'>
            Welcome Offers
          </div>
          <div className='sub-title'>
            Make sure to use our available welcome offers when you register!
          </div>
          {
            noticeList.length === 0 && <div className='empty'>
              Empty Notice
            </div>
          }
          {
            noticeList.length > 0 && <div className='list'>
              {
                noticeList.map((_, index) => {
                  return <img src={_.thumb} key={index} className='item' onClick={() => {
                    playSFX(Sounds.SFX_WINDOW_OPEN, true)
                    setSelectNoticeId(_.noticeId)
                  }}/>
                })
              }
            </div>
          }
        </div>
      </div>
    </div>
  </PCHomeWrapper>
}

export default Home;
