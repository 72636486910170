import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {requestGetNoticeDetail} from "../api";
import {NoticeInfo} from "../dataset";
import {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";

const NoteWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: #151515;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media ${MEDIA_DESKTOP} {
    top: 60px;
    height: calc(100% - 60px);
    background: white;
  }
  > .content-wrapper {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    @media ${MEDIA_DESKTOP} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
    }
    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @media ${MEDIA_DESKTOP} {
        width: 100%;
        max-width: 470px;
        padding: 0;
        gap: 16px;
        //background: #F5F5F5;
        background: transparent;
        justify-content: flex-start;
        flex-grow: 1;
      }
      > img {
        width: 100%;
        object-fit: contain;
      }
      >.description {
        width: 100%;
        padding: 20px;
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 16.8px */
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          padding: 18px 0 0;
          color: var(--Black, #181818);
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 22.4px */
          letter-spacing: -0.5px;
        }
      }
    }
  }
`
const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  @media ${MEDIA_DESKTOP} {
    border-bottom: 1px solid #DDD;
    background: #F5F5F5;
    height: 57px;
  }

  > .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
  }

  > .title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media ${MEDIA_DESKTOP} {
      position: relative;
      width: 470px;
      color: var(--Black, #181818);
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 20px */
    }

    > .close {
      display: none;
      @media ${MEDIA_DESKTOP} {
        cursor: pointer;
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }
    }
  }
`
interface NoteHistoryProps {
  noticeId: number;
  onClose: () => void;
}

const NoticeDetail = ({noticeId, onClose}: NoteHistoryProps) => {
  const [noticeData, setNoticeData] = useState<NoticeInfo | null>(null);
  useEffect(() => {
    requestGetNoticeDetail(noticeId).then(res => {
      setNoticeData(res.info)
    })
  }, [noticeId]);
  return <NoteWrapper>
    <Header>
      <img className='close' src='/images/ic_arrow_back.png' onClick={() => onClose()}/>
      <div className='title'><img onClick={() => onClose()} className={'close'} src='/images/pc/Back.png'/>공지사항
      </div>
    </Header>
    <div className='content-wrapper'>
      {
        noticeData && <div className='content'>
          <img src={noticeData.image}/>
          <div className='description'>
            {
              noticeData.text
            }
          </div>
        </div>
      }
    </div>
  </NoteWrapper>
}

export default NoticeDetail;
