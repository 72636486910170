import styled from "styled-components";
import {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";

const UserStatusHelpModalWrapper = styled.div`
  width: 310px;
  border-radius: 12px;
  border: 1px solid var(--Point-Red, #E31D1D);
  background: var(--Black, #181818);
  padding: 20px 20px 16px;
  @media ${MEDIA_DESKTOP} {
    padding: 0;
    border-radius: 4px;
    background: #FFF;
    border: none;
    width: 470px;
  }
  > .header {
    display: none;
    @media ${MEDIA_DESKTOP} {
      position: relative;
      width: 100%;
      height: 57px;
      border-bottom: 1px solid #DDD;
      background: #F5F5F5;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      > .title {
        color: var(--Black, #181818);
        text-align: center;
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 20px */
      }

      > .close {
        position: absolute;
        top: 50%;
        right: 16px;
        transform: translateY(-50%);
        cursor: pointer;
        width: 24px;
        height: 24px;
      } 
    }
  }
  >.content {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    gap: 12px;
    @media ${MEDIA_DESKTOP} {
      padding: 40px 48px;
    }
    >.item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 8px;
      >.title {
        color: #FFF;
        text-align: left;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.2px;
        @media ${MEDIA_DESKTOP} {
          color: var(--Black, #181818);
          text-align: center;
          font-family: Pretendard;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.2px;
        }
      }
      >.desc {
        opacity: 0.5;
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.2px;
        @media ${MEDIA_DESKTOP} {
          color: var(--Black, #181818);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.2px;
        }
      }
    }
  }
  >.button {
    width: 100%;
    height: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #FFF;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
    border-radius: 8px;
    background: #25262D;
    margin-top: 24px;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
  }
`

interface UserStatusHelpModalProps {
  onClose: () => void;
}
const UserStatusHelpModal = ({onClose}: UserStatusHelpModalProps) => {
  return <UserStatusHelpModalWrapper>
    <div className='header'>
      <div className='title'>
        전적
      </div>
      <img src='/images/pc/Close.png' className='close' onClick={onClose}/>
    </div>
    <div className='content'>
      <div className='item'>
        <div className='title'>참여율(VPIP)</div>
        <div className='desc'>프리플랍에서 팟에 참여하는 빈도의 비율</div>
      </div>
      <div className='item'>
        <div className='title'>참여 승률</div>
        <div className='desc'>팟에 참여한 횟수 대비 승리한 비율</div>
      </div>
      <div className='item'>
        <div className='title'>PFR</div>
        <div className='desc'>프리플랍에서 레이즈빈도의 비율</div>
      </div>
      <div className='item'>
        <div className='title'>3BET</div>
        <div className='desc'>레이즈 이후 상대방의 레이즈에 다시 리레이즈하는 빈도의 비율</div>
      </div>
      <div className='item'>
        <div className='title'>ATS</div>
        <div className='desc'>컷오프 이후 자리에서 블라인드 스틸 시도
          비율
        </div>
      </div>
    </div>
    <div className='button' onClick={() => onClose()}>
      닫기
    </div>
  </UserStatusHelpModalWrapper>
}
export default UserStatusHelpModal;
