import styled from "styled-components";
import React, {useMemo} from "react";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div<{button?: boolean, center?: boolean, detail?: boolean}>`
  min-width: 72px;
  position: relative;
  overflow: hidden;
  padding: 7px;
  
  ${p => p.button && `
    border-radius: 8px;
    border-top: 1px solid rgba(255, 255, 255, 0.00);
    background: radial-gradient(95.31% 95.31% at 50% 100%, rgba(182, 182, 182, 0.20) 0%, rgba(0, 0, 0, 0.00) 100%), #181A1D;
    
    &::before {
      width: 100%;
      height: 0.5px;
      //transform: scale(1, 0.5);
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      background-image: linear-gradient(90deg, transparent, white, transparent);
      opacity: 0.5;
    }
  `};
  
  ${p => p.center && `
    text-align: center;
  `};
  
  @media ${MEDIA_DESKTOP} {
    ${p => !p.detail && `
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 9px 10px;
    `};
  }
  
  > div:first-child {
    font-size: 10px;
    font-weight: 500;
    color: #FFF;
    margin-bottom: 2px;
    
    img {
      margin-right: 2px;
    }

    @media ${MEDIA_DESKTOP} {
      font-size: 14px;
      
      ${p => !p.detail && `
        margin-bottom: 0;
        font-size: 0;
        
        img {
          margin-right: 4px;
        }
      `}
    }
  }

  > div:last-child {
    font-size: 12px;
    font-weight: 600;
    background: linear-gradient(180deg, #FDFAF1 0%, #EDCC72 79.17%, #E2B748 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;


    @media ${MEDIA_DESKTOP} {
      ${p => p.detail && `
        font-size: 14px;
        margin-top: 4px;
      `};
    }
  }
`;

function Prize({prizes, button, center, detail}: {
  prizes: number[];
  button?: boolean;
  center?: boolean;
  detail?: boolean;
}) {
  const totalPrize = useMemo(() => {
    return prizes.reduce((a: number, v: number) => a + v, 0);
  }, [prizes]);

  const totalPrizeText = useMemo(() => {
    let chunks = [];
    let units = ['조', '억', '만'];

    let num = totalPrize / 10000;
    while (num > 0) {
      const mod = num % 10000;
      const modStr = mod.toString().replace(/(\d)(\d{3})/, '$1,$2');
      num = Math.floor(num / 10000);
      if (mod === 0) {
        units.pop();
        continue;
      }
      chunks.unshift(`${modStr}${units.pop()}`);
    }

    return chunks.slice(0, 2).join(' ');
  }, [totalPrize]);


  return <Wrapper button={button} center={center} detail={detail}>
    <div><img src="/images/ic_prize_chip.svg"/>상금</div>
    <div className="prize-text">{totalPrizeText}</div>
  </Wrapper>;
}

export default Prize;