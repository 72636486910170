import styled from "styled-components";
import {useCallback, useEffect, useRef} from "react";
import {playSFX, Sounds} from "../../utils/sound";
import { throttle} from "lodash";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div`
  position: absolute;
  right: 10px;
  bottom: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  @media ${MEDIA_DESKTOP} {
    bottom: 218px;
    right: 30px;
    gap: 32px;
  }
  > .button-col {
    > .button-line {
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      margin-top: 2px;
      gap: 6px;
      @media ${MEDIA_DESKTOP} {
        margin-top: 6px;
      }
    }
  }
`;

const Gauge = styled.div`
  width: 8px;
  margin: 0px 10px;
  height: 140px;
  background-image: url(/images/bet_gauge_v_bg.svg);
  position: relative;
  @media ${MEDIA_DESKTOP} {
    width: 22px;
    height: 385px;
    background-size: cover;
  }
`;

const Fill = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 8px;
  height: 0;
  will-change: height;
  background-image: url(/images/bet_gauge_v_fill.svg);
  background-position: center bottom;
  background-repeat: no-repeat;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.6));
  pointer-events: none;
  user-select: none;
  @media ${MEDIA_DESKTOP} {
    width: 22px;
    background-size: cover;
  }
`;

const Thumb = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  will-change: bottom;
  left: -10px;
  text-align: center;
  transform: translateY(50%);
  @media ${MEDIA_DESKTOP} {
    left: -22px;
  }
  > .chip {
    position: relative;
    width: 28px;
    height: 28px;
    background-image: url(/images/ic_knob.png);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;
    cursor: pointer;
    @media ${MEDIA_DESKTOP} {
      width: 66px;
      height: 66px;
    }

    > .label {
      position: absolute;
      bottom: -4px;
      left: 50%;
      transform: translate(-50%, 100%);
      padding: 2px 8px;
      border-radius: 15px;
      background: rgba(24, 26, 29, 0.50);
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12) inset;
      color: #FFF;
      font-size: 12px;
      font-weight: 600;
    }
  }
`;

const Button = styled.div<{disabled: boolean}>`
  cursor: pointer;
  display: flex;
  width: 76px;
  height: 30px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid rgb(96 55 15);
  background: linear-gradient(rgb(205 91 18) 23.26%, rgb(220 161 72) 88.19%);
  box-shadow: rgba(255, 255, 255, 0.6) 0px 0.5px 0.3px 0px inset;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
  transition: transform .25s;
  @media (max-width: 376px) {
    width: 65px;
    height: 25px;
    font-size: 10px;
  }
  &:active {
    transform: scale(.92);
  }
  ${p => p.disabled ? `
  opacity: 0.5;
  `: ``}
  @media ${MEDIA_DESKTOP} {
    width: 160px;
    height: 66px;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.165px;
    border-radius: 33px;
    border: 6px solid rgb(96 55 15);
    box-shadow: 0px 0.3px 0.3px 0px rgba(255, 255, 255, 0.60) inset;
  }
}
`;

const Button2 = styled.div`
  cursor: pointer;
  display: flex;
  width: 76px;
  height: 30px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 0;
  background: #9624b3;
  box-shadow: rgba(255, 255, 255, 0.6) 0px 0.5px 0.3px 0px inset;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
  transition: transform .25s;
  @media (max-width: 376px) {
    width: 65px;
    height: 25px;
    font-size: 10px;
  }
  &:active {
    transform: scale(.92);
  }
  @media ${MEDIA_DESKTOP} {
    width: 160px;
    height: 66px;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.165px;
    border-radius: 33px;
    border: 6px solid #5c166e;
    box-shadow: 0px 0.3px 0.3px 0px rgba(255, 255, 255, 0.60) inset;
  }
}
`;

const Button3 = styled.div`
  cursor: pointer;
  display: flex;
  width: 76px;
  height: 30px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 0;
  background: #245fb3;
  box-shadow: rgba(255, 255, 255, 0.6) 0px 0.5px 0.3px 0px inset;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
  transition: transform .25s;
  @media (max-width: 376px) {
    width: 65px;
    height: 25px;
    font-size: 10px;
  }
  &:active {
    transform: scale(.92);
  }
  @media ${MEDIA_DESKTOP} {
    width: 160px;
    height: 66px;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.165px;
    border-radius: 33px;
    border: 6px solid #173c73;
    box-shadow: 0px 0.3px 0.3px 0px rgba(255, 255, 255, 0.60) inset;
  }
}
`;

function BetGaugeVertical(
  {
    min,
    max,
    onChange,
    pot,
    stack,
    raiseAmount,
    currentBet,
    callAmount,
    bb,
    showGauge
  }: {
    pot: number;
    min: number;
    max: number;
    raiseAmount: number;
    onChange: (amount: number) => void;
    onCancel: () => void;
    stack: number;
    currentBet: number;
    callAmount: number;
    bb: number;
    showGauge: boolean;
  }
) {
  const orientation = useScreenOrientation();
  const ref = useRef<HTMLImageElement>(null);
  const thumbRef = useRef<HTMLDivElement>(null);
  const fillRef = useRef<HTMLDivElement>(null);
  const prevState = useRef<any>(null);
  const innerAmount = useRef(min);

  const playUp = useCallback(throttle(()=>{
    playSFX(Sounds.SFX_BUY_IN_UP);
  },100),[]);
  const playDown = useCallback(throttle(()=>{
    playSFX(Sounds.SFX_BUY_IN_DOWN);
  },100),[]);

  useEffect(() => {
    if (!ref.current || !thumbRef.current) {
      return;
    }
    const margin = (orientation === 'landscape' ? 32 : 12);
    const gaugeHeight = ref.current.offsetHeight - (margin*2);

    const handleMouseEvent = (e: any) => {
      if (!thumbRef.current || !fillRef.current) {
        return;
      }

      e.stopPropagation();

      if (e.type === 'mousedown' || e.type === 'touchstart') {
        const thumbBottom = Number((thumbRef.current.style.bottom || '0').replace('px', ''));
        if (!prevState.current) {
          const clientY = e.touches ? e.touches[0].clientY : e.clientY;
          prevState.current = {
            thumbBottom: thumbBottom,
            originY: clientY
          };
        }
      } else if (e.type === 'mousemove' || e.type === 'touchmove') {
        if (prevState.current) {
          const clientY = e.touches ? e.touches[0].clientY : e.clientY;
          const dy = prevState.current.originY - clientY;
          const newBottom = Math.max(Math.min(prevState.current.thumbBottom + dy, gaugeHeight), 0);
          thumbRef.current.style.bottom = newBottom + margin + 'px';
          fillRef.current.style.height = newBottom + margin + 'px';

          const portion = Math.max(Math.min(newBottom / gaugeHeight, 1), 0);
          const amount = Math.floor(min + (max - min) * portion);

          if(amount < innerAmount.current){
            playDown();
          }
          if(amount > innerAmount.current){
            playUp();
          }
          innerAmount.current = amount;
          onChange && onChange(amount);
        }
      } else if (e.type === 'mouseup' || e.type === 'touchend') {
        if (prevState.current) {
          prevState.current = null;
        }
      }
    };

    thumbRef.current.addEventListener('mousedown', handleMouseEvent);
    thumbRef.current.addEventListener('touchstart', handleMouseEvent);
    window.addEventListener('mousemove', handleMouseEvent);
    window.addEventListener('touchmove', handleMouseEvent);
    window.addEventListener('mouseup', handleMouseEvent);
    window.addEventListener('touchend', handleMouseEvent);

    return () => {
      if (thumbRef.current) {
        thumbRef.current.removeEventListener('mousedown', handleMouseEvent);
        thumbRef.current.removeEventListener('touchstart', handleMouseEvent);
        window.removeEventListener('mousemove', handleMouseEvent);
        window.removeEventListener('touchmove', handleMouseEvent);
        window.removeEventListener('mouseup', handleMouseEvent);
        window.removeEventListener('touchend', handleMouseEvent);
      }
    };
  }, [min, max, onChange, orientation]);

  useEffect(()=>{
    setGauge(min, max, min);
    onChange && onChange(min);
  }, [min, max, orientation]);

  const calculateRatio = (min:number, max:number, value:number) => {
    const range = max - min;
    const distanceFromMain = value - min;
    const nomralizedRatio = distanceFromMain / range;

    return nomralizedRatio;
  };

  const setGauge = (min:number, max:number, amount:number) => {
    if(ref.current){
      const margin = (orientation === 'landscape' ? 32 : 12);
      const gaugeHeight = ref.current.offsetHeight - (margin*2);
      const portion = calculateRatio(min, max, amount);
      const newBottom = Math.max(Math.min(portion * gaugeHeight, gaugeHeight), 0);
      thumbRef.current?.style.setProperty('bottom', newBottom + margin  + 'px');
      fillRef.current?.style.setProperty('height', newBottom + margin + 'px');
    }
  };

  const handleClickButton2 = useCallback((amount: number) => {
    let new_amount = raiseAmount;

    if (amount === -1) { //max
      new_amount = max;
    }
    else if (amount === -2) { //min
      new_amount = min;
    }
    else {
      new_amount += amount;
    }

    if(max < new_amount) new_amount = max;

    playSFX(Sounds.SFX_CLICK_INGAME);
    setGauge(min, max, new_amount);
    onChange && onChange(new_amount);
  }, [min, max, raiseAmount, onChange, stack, orientation,bb]);

  return <Wrapper ref={ref} className='raise-gauge-wrapper'>
    <div className='button-col'>
      <div className='button-line'>
        <Button3
          onClick={() => handleClickButton2(-1)}
        >최대</Button3>
      </div>
      <div className='button-line'>
        <Button
          disabled={false}
          onClick={() => handleClickButton2(100000)}
        >10만원</Button>
      </div>
      <div className='button-line'>
        <Button
          disabled={false}
          onClick={() => handleClickButton2(10000)}
        >1만원</Button>
      </div>
      <div className='button-line'>
        <Button
          disabled={false}
          onClick={() => handleClickButton2(1000)}
        >1천원</Button>
      </div>
      <div className='button-line'>
        <Button2
          onClick={() => handleClickButton2(-2)}
        >최소</Button2>
      </div>
    </div>
    <Gauge>
      <Fill ref={fillRef}/>
      <Thumb ref={thumbRef}>
        <div className="chip"/>
      </Thumb>
    </Gauge>
  </Wrapper>;
}

export default BetGaugeVertical;
