import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {requestMyInfo, requestGetMemoList, requestReadMemoList, requestAllReadMemoList, requestAllDeleteMemoList, requestOneDeleteMemoList} from "../api";
import {MyInfo} from "../dataset";
import moment from "moment";
import useScreenOrientation, {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";
import {unreadMemoCount} from "../recoil/Memo";
import {useRecoilState, useSetRecoilState} from "recoil";
import {globalLoadingState} from "../recoil/Loading";
import _ from 'lodash';

const CustomerCenterWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: #151515;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media ${MEDIA_DESKTOP} {
    top: 60px;
    height: calc(100% - 60px);
    background: white;
  }
  > .buttonBeside {
    background: #transparent;
    width: 100%;
    @media ${MEDIA_DESKTOP} {
      background: #f5f5f5;
    }
    > .buttonWrap {
      width: 100%;
      max-width: 1400px;
      padding: 0px;
      gap: 0px;
      background: #transparent;
      margin: 0 auto;
      display: flex;
      justify-content: right;
      padding-top: 10px;
      padding-bottom: 8px;
      padding-right: 5px;
      @media ${MEDIA_DESKTOP} {
        width: 90%;
        background: #f5f5f5;
        padding-right: 0;
      }
  
      .button {
        cursor: pointer;
        width: 110px;
        height: 30px;
        border-radius: 6px;
        background: var(--Gradient-Red, linear-gradient(180deg, #DF3535 0%, #A51818 100%));
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        color: rgb(255, 255, 255);
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        &+.button {
          margin-left: 6px;
        }
      }
      .button2 {
        background: var(--Gradient-Red, linear-gradient(180deg, #df7d35 0%, #a54318 100%));
      }
    }
  }
  > .content-wrapper {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    @media ${MEDIA_DESKTOP} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
      background: #F5F5F5;
    }
    
    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0;
      padding: 0;
      @media ${MEDIA_DESKTOP} {
        width: 90%;
        max-width: 1400px;
        gap: 0;
        background: #fff;
        justify-content: flex-start;
        flex-grow: 1;
      }
      >.memoHead {
        display: flex;
        align-items: center;
        width: 100%;
        height: 35px;
        background: #232323;
        color: #fff;
        font-size: 15px;
        >.line1 {
          @media ${MEDIA_DESKTOP} {
            width: 10%;
            text-align: center;
          }
          >.title {
            >.check {
              font-size: 13px;
              background: #ff2b1e;
              padding-left: 4px;
              padding-right: 4px;
              color: #fff;
              border-radius: 4px;
            }
            >.check.read {
              background: #686868;
            }
            >.text {
              margin-left: 6px;
              font-size: 16px;
              font-weight: bolder;
              color: #fff;
              @media ${MEDIA_DESKTOP} {
                color: #000;
              }
            }
          }
          
        }
        >.line2 {
          font-size: 13px;
          color: #bfbfbf;
          @media ${MEDIA_DESKTOP} {
            color: #fff;
            width: 50%;
            text-align: center;
          }
        }
        >.line3 {
          font-size: 13px;
          color: #bfbfbf;
          @media ${MEDIA_DESKTOP} {
            color: #fff;
            width: 20%;
            text-align: center;
          }
        }
        >.line4 {
          font-size: 13px;
          color: #bfbfbf;
          @media ${MEDIA_DESKTOP} {
            color: #fff;
            width: 20%;
            text-align: center;
          }
        }
        >.line5 {
          font-size: 13px;
          color: #bfbfbf;
          @media ${MEDIA_DESKTOP} {
            color: #fff;
            width: 10%;
            text-align: center;
            >div {
              cursor: pointer;
              width: 45px;
              height: 25px;
              border-radius: 6px;
              background: var(--Gradient-Red, linear-gradient(180deg, #DF3535 0%, #A51818 100%));
              display: flex;
              flex-direction: row;
              -webkit-box-align: center;
              align-items: center;
              -webkit-box-pack: center;
              justify-content: center;
              color: rgb(255, 255, 255);
              text-align: center;
              font-family: Pretendard;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
              letter-spacing: -0.5px;
            }
          }
        }
      }
      >.nomessage {
        padding-top: 100px;
        text-align: center;
        >img {
          width: 100px;
        }
        >div {
          font-size: 15px;
          padding-top: 10px;
          color: #fff;
          @media ${MEDIA_DESKTOP} {
            color: #000;
          }
        }
      }
      >.memoWrap {
        width: 100%;
        >.memoContrainer {
          cursor: pointer;
          width: 100%;
          border-bottom: 1px solid #393939;
          padding: 15px 10px;
          @media ${MEDIA_DESKTOP} {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 14px;
            border-bottom: 1px solid #ddd;
          }
          >.line1 {
            @media ${MEDIA_DESKTOP} {
              width: 10%;
              text-align: center;
            }
            >.title {
              display: inline-block;
              margin-right: 7px;
              >.check {
                font-size: 13px;
                background: #ff2b1e;
                padding-left: 4px;
                padding-right: 4px;
                color: #fff;
                border-radius: 4px;
              }
              >.check.read {
                background: #686868;
              }
              >.text {
                margin-left: 6px;
                font-size: 16px;
                font-weight: bolder;
                color: #fff;
                @media ${MEDIA_DESKTOP} {
                  color: #000;
                }
              }
            }
            >.content-title {
              display: inline-block;
              font-size: 14px;
              color: #bfbfbf;
              
              @media ${MEDIA_DESKTOP} {
                color: #424242;
              }
            }
          }
          >.line2 {
            font-size: 13px;
            color: #bfbfbf;
            padding-top: 3px;
            @media ${MEDIA_DESKTOP} {
              color: #292929;
              width: 50%;
              text-align: center;
              padding-top: 0;
            }
          }
          >.line3 {
            font-size: 13px;
            color: #bfbfbf;
            padding-top: 3px;
            @media ${MEDIA_DESKTOP} {
              color: #292929;
              width: 20%;
              text-align: center;
              text-align: center;
              padding-top: 0;
            }
          }
          >.line4 {
            font-size: 13px;
            color: #bfbfbf;
            @media ${MEDIA_DESKTOP} {
              color: #292929;
              width: 20%;
              text-align: center;
            }
          }
          >.line5 {
            font-size: 13px;
            color: #bfbfbf;
            @media ${MEDIA_DESKTOP} {
              color: #292929;
              width: 10%;
              display: flex;
              justify-content: center;
              >div {
                cursor: pointer;
                width: 45px;
                height: 25px;
                border-radius: 6px;
                background: var(--Gradient-Red, linear-gradient(180deg, #DF3535 0%, #A51818 100%));
                display: flex;
                flex-direction: row;
                -webkit-box-align: center;
                align-items: center;
                -webkit-box-pack: center;
                justify-content: center;
                color: rgb(255, 255, 255);
                text-align: center;
                font-family: Pretendard;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                letter-spacing: -0.5px;
              }
            }
          }
        }
        >.memoContentContrainer {
          background: #080809;
          padding: 15px;
          font-size: 14px;
          border-bottom: 1px solid #393939;
          color: #fff;
          @media ${MEDIA_DESKTOP} {
            background: #f5f5f5;
            border-bottom: 1px solid #ddd;
            color: #000;
            text-align: center;
          }
          >.date {
            text-align: right;
            color: #bfbfbf;
            margin-bottom: 10px;
            font-size: 13px;
            @media ${MEDIA_DESKTOP} {
              color: #292929;
            }
          }
        }
      }
    }
  }
  .
`
const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  @media ${MEDIA_DESKTOP} {
    border-bottom: 1px solid #DDD;
    background: #F5F5F5;
    height: 57px;
  }

  > .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
  }

  > .title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media ${MEDIA_DESKTOP} {
      position: relative;
      width: 470px;
      color: var(--Black, #181818);
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 20px */
    }

    > .close {
      display: none;
      @media ${MEDIA_DESKTOP} {
        cursor: pointer;
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }
    }
  }
`

interface MemoViewProps {
  onClose?: () => void;
}

interface memoInterface {
  uid:number;
  title:string;
  content:string;
  addedAt:string;
  readAt:string;
  isReaded:number;
  isOpended:number;
}

const MemoView = ({onClose}: MemoViewProps) => {
  const [myInfo, setMyInfo] = useState<MyInfo|null>(null);
  const chatDiv = useRef<HTMLDivElement>(null);
  const [memoList, setMemoList] = useState<memoInterface[]>([]);
  const [MemoCount, setMemoCount] = useRecoilState(unreadMemoCount);
  const orientation = useScreenOrientation();
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [isLoading1, setIsLoading1] = useState<number>(1);

  let isAlive = false;

  console.log('memoList');
  console.log(memoList);
  function merge(a:any, b:any) {
    if (a.length === 0) {
        return [...b];
    }

    if (b.length === 0) {
      return [];
    }

    b.forEach((itemB:any) => {
      itemB.uid

      for (let i = 0; i < a.length; i++) {
        if (itemB.uid === a[i].uid) {
          if (a[i].isOpended) {
            itemB.isOpended = true;
          }
        }
      }
    });

    return b;
  }

  useEffect(() => {
    isAlive = true;

    setGlobalLoading(true);
    setIsLoading1(1);
    
    requestGetMemoList().then((res)=> {
      setGlobalLoading(false);
      setIsLoading1(0);
      setMemoList(res.list);

      if (res.list.length >= 0) {
        const count = _.sumBy(res.list, obj => obj.isReaded === 0 ? 1 : 0);
        setMemoCount(count);
      }
    });

    return () => {
      isAlive = false;
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      requestGetMemoList().then((res)=> {
        setGlobalLoading(false);
        setIsLoading1(0);
        
        const list = merge(memoList, res.list)
        setMemoList(list);
  
        if (list.length >= 0) {
          const count = _.sumBy(list, (obj:any) => obj.isReaded === 0 ? 1 : 0);
          setMemoCount(count);
        }
      });
    }, 10000);

    return () => {
      clearInterval(interval);
    }
  }, [memoList]);

  const replaceLinks = (text:string) => {
    const rtext = text.replace(/\n/g, '<br/>')
    const regex = /(https?:\/\/[^\s]+)/g;
    const replacedText = rtext.replace(regex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
    return replacedText;
  };

  const shortContent = (text:string) => {
    const rtext = text.replace(/\n/g, ' ');
    return rtext.length > 15 ? rtext.substr(0, 15) : rtext;
  };

  const onClickMemoToggle = (index:number) => {
    const copy = JSON.parse(JSON.stringify(memoList));
    if (!copy[index].isOpended && !copy[index].isReaded) {
      copy[index].isReaded = 1; 
      copy[index].readAt = moment().format('YY-MM-DD hh:mm');
      requestReadMemoList(copy[index].uid).then((v)=> {});
      setMemoCount(MemoCount - 1 <= 0 ? 0 : MemoCount - 1);
    }

    copy[index].isOpended = !copy[index].isOpended;
    setMemoList(copy);
  };

  const onClickMemoReadAll = () => {
    const copy = JSON.parse(JSON.stringify(memoList));
    
    for (let c of copy) {
      c.isReaded = 1; 
    }

    setMemoList(copy);
    setMemoCount(0);
    requestAllReadMemoList().then((v)=> {});
  }

  const onClickMemoDeleteAll = () => {
    setMemoList([]);
    setMemoCount(0);
    requestAllDeleteMemoList().then((v)=> {});
  };

  const onClickDeleteMemo = (index:number) => {
    requestOneDeleteMemoList(memoList[index].uid).then((v)=>{
      const copy = JSON.parse(JSON.stringify(memoList));
      if (!copy[index].isReaded) {
        setMemoCount(MemoCount - 1 <= 0 ? 0 : MemoCount - 1);
      }
      copy.splice(index, 1);
      setMemoList(copy);
    });
  };

  return <CustomerCenterWrapper>
    <Header>
      <img className='close' src='/images/ic_close.png' onClick={() => onClose && onClose()}/>
      <div className='title'>
        <img onClick={() => onClose && onClose()} className={'close'} src='/images/pc/Back.png'/>쪽지함
      </div>
    </Header>
    <div className="buttonBeside">
      <div className='buttonWrap'>
        <div className="button button2" onClick={onClickMemoReadAll}>
          <span>전체읽기</span>
        </div>
        <div className="button" onClick={onClickMemoDeleteAll}>
          <span>전체삭제</span>
        </div>
      </div>
    </div>
    <div className='content-wrapper' ref={chatDiv}>
      <div className='content'>
        {
          orientation !== 'portrait' ? (
            <div className='memoHead'>
              <div className='line2'>제목</div>
              <div className='line3'>보낸날짜</div>
              <div className='line4'>읽은날짜</div>
              <div className='line5'>삭제</div>
            </div>
          ) : <></>
        }
        
        {
          !isLoading1 && memoList.length === 0 ? (
            <div className='nomessage'>
              <img src={`/images/nes/${orientation === 'portrait' ? 'nochatmobile.png' : 'nochatpc.png'}`}/>
              <div>받은 쪽지가 없습니다.</div>
            </div>
          ) : (
            memoList.map((item, index) => {
              return <div 
                className="memoWrap" 
                key={`memoList-${index}`}
              >
                {
                  orientation === 'portrait' ? (
                    <div className='memoContrainer' onClick={()=>{onClickMemoToggle(index)}}>
                      <div className='line1'>
                        <div className='title'>
                          <span className={`check ${item.isReaded ? 'read' : '' }`}>{item.isReaded ? '읽음' : '안읽음'}</span>
                        </div>
                        <div className='content-title'>
                          <span>{item.title}</span>
                        </div>
                      </div>
                      <div className='line2'>
                        보낸날짜 {item.addedAt}
                      </div>
                      <div className='line2'>
                        읽은날짜 {item.readAt}
                      </div>
                    </div>
                  ) : (
                    <div className='memoContrainer' >
                      <div className='line2' onClick={()=>{onClickMemoToggle(index)}}>{item.title}</div>
                      <div className='line3' onClick={()=>{onClickMemoToggle(index)}}>{item.addedAt}</div>
                      <div className='line4' onClick={()=>{onClickMemoToggle(index)}}>{item.readAt}</div>
                      <div className='line5' >
                      {
                        item.isReaded ? (
                          <div onClick={()=>{onClickDeleteMemo(index)}}>삭제</div>
                        ) : <></>
                      }
                      </div>
                    </div>
                  )
                }
                {
                  item.isOpended ? (
                    <div className='memoContentContrainer'>
                        <span dangerouslySetInnerHTML={{
                          __html: replaceLinks(
                            item.content
                          ),
                        }}/>
                    </div>
                  ) : <></>
                }
              </div>
            })
          )
        }
      </div>
    </div>
  </CustomerCenterWrapper>
}
export default MemoView;
