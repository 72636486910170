import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {winnerListModel} from "../dataset";
import {requestGetWinnerList} from "../api";

const WinnerListModalWrapper = styled.div`{
  width: 311px;
  max-height: 505px;
  border-radius: 12px;
  background: #17171B;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 24px 16px 16px;

  > .title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }

  > .content {
    margin-top: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    > .list {
      flex: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2px;
      margin-bottom: 16px;
    }

    > .prize-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      overflow-y: auto;
      max-height: 236px;

      > table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0 5px;

        thead {
          background: #17171B;
          position: sticky;
          top: 5px;
          z-index: 3;
        }

        tr {
          height: 18px;

          th {
            padding-bottom: 7px;
            border-bottom: 1px solid #2D2D2D;
          }

          .nickname {
            width: 90px;
          }

          .grade {
            width: 40px;
          }

          .prize {
            width: 133px;
          }

          td {
            &:first-child {
              text-align: left;
            }
          }
        }

        .title {
          opacity: 0.5;
          color: #FFF;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.2px;
        }

        .value {
          color: #FFF;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.2px;
        }

        .fixed {
          text-align: center;
        }

        .level {
          width: 40px;
          text-align: left;
        }

        .blind {
          width: 140px;
          text-align: center;
        }

        .entity {
          width: 60px;
          text-align: center;
        }

        .minute {
          width: 60px;
          text-align: center;
        }

      }
    }
  }
}`;

const WinnerListItem = styled.div<{
  background: string
}>`{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  width: 100%;
  height: 36px;
  background-image: url(${p => p.background});
  background-position: center;
  background-size: contain;

  > .grade {
    width: 24px;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }

  > .nickname {
    width: 80px;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }

  > .prize {
    width: 130px;
    text-align: center;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.20);
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.2px;
  }

}`;
const CloseButton = styled.div`{
  margin-top: 18px;
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background: #2D2D2D;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  leading-trim: both;
  text-edge: cap;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.2px;
  cursor: pointer;
}`;

interface WinnerListModalProps {
  groupId: number;
  prizeData: number[];
  onClose: () => void;
}

const WinnerListModal = (
  {
    prizeData,
    groupId,
    onClose
  }: WinnerListModalProps
) => {
  const [winnerList, setWinnerList] = useState<winnerListModel[]>([]);

  useEffect(() => {
    requestGetWinnerList(groupId).then((data) => {
      setWinnerList(data.list);
    });
  }, [groupId])

  return <WinnerListModalWrapper>
    <div className='title'>
      우승자 리스트
    </div>
    <div className='content'>
      <div className='list'>
        {
          winnerList && winnerList.slice(0, 3).map((item, index: number) => {
            return <WinnerListItem key={index} background={`/images/winnerlist_grade_${index + 1}.png`}>
              <div className='grade'>{index + 1}등</div>
              <div className='nickname'>{item.nickname}</div>
              <div className='prize'>{prizeData[index].toLocaleString()}</div>
            </WinnerListItem>
          })
        }
      </div>
      <div className='prize-list'>
        <table>
          <thead>
          <tr className='title bottom'>
            <th className='grade'>등수</th>
            <th className='nickname'>닉네임</th>
            <th className='prize'>상금</th>
          </tr>
          </thead>
          <tbody>
          {
            winnerList && winnerList.map((item, index: number) => {
              return (
                <tr key={index}>
                  <td className='title'>{index + 1}</td>
                  <td className='fixed value'>{item.nickname}</td>
                  <td className='fixed value'>{prizeData[index].toLocaleString()}</td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
      </div>
      <CloseButton onClick={() => onClose()}>
        닫기
      </CloseButton>
    </div>
  </WinnerListModalWrapper>
}

export default WinnerListModal;
