import {useRecoilValue} from "recoil";
import {gameOptionState} from "../recoil/GameOption";
import {useCallback, useEffect} from "react";
import {playBGM, playSFX, Sounds, stopBGM} from "../utils/sound";
import {debounce, throttle} from "lodash";

function useBGM(sound: string, playing: boolean) {
  const gameOption = useRecoilValue(gameOptionState)
  const innerPlayBGM = useCallback(throttle((sound)=>{
    playBGM(sound);
  },100),[]);
  useEffect(() => {
    if (!gameOption?.backgroundMusic?.muted && playing) {
      innerPlayBGM(sound);
    } else {
      stopBGM();
    }
  }, [sound, playing, gameOption?.backgroundMusic?.muted, gameOption?.backgroundMusic?.volume]);
}

export default useBGM;
