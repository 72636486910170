import styled from "styled-components";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {
  requestGetHandCuttingList
} from '../../api';
import {MEDIA_DESKTOP, MEDIA_MOBILE_LANDSCAPE} from "../../hooks/useScreenOrientation";
import {
  GamePlayer,
  MyInfo
} from '../../dataset';
import {requestMyInfo} from "../../api";

const Wrapper = styled.div`
  width: 240px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(24,36,49,0.85);
  color: #FFF;
  border-radius: 4px;

  @media ${MEDIA_DESKTOP} {
    width: 420px;
  }
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    width: 600px;
  }
`;


const Content = styled.div`
  flex: 1;
  overflow: auto;

  .profile-wrapper {
    padding: 12px;
    > .title-wrapper {
      font-size: 18px;
      @media ${MEDIA_DESKTOP} {
        font-size: 21px;
      }
    }
    > .money-wrapper {
      font-size: 11px;
      @media ${MEDIA_DESKTOP} {
        font-size: 13px;
      }
      .t {
        margin-left: 2px;
      }
      >.left {
        color: #ffe523;
      }
      >.orange {
        color: rgb(255 146 35);
      }
      >.right {
        color: #20ff56;
      }
      >.stack {
        color: #d9d9d9;
      }
      .minus {
        color: #ff4343;
      }
      .plus {
        color: #27d4ff;
      }
      .previous {
        color: rgb(255 251 67);
      }
    }
  }
  .button-wrapper {
    @media ${MEDIA_MOBILE_LANDSCAPE} {
      border-top: 1px solid #080d10;
    }
  }
`;

const LeaveButton = styled.span`
  color: white;
  width: 100%;
  border-radius: 0px;
  cursor: pointer;
  padding: 0 12px;
  position: relative;
  display: block;
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    display: inline-block;
    width: initial;
    border-left: 1px solid #000;
    padding-right: 22px;
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 1px solid #000;
    }
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.5;
  }

  &:disabled {
    text-decoration: line-through;
  }

  > dd {
    position: absolute;
    width: 20px;
    height: 20px;
    background: #ff3232;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    font-size: 11px;
    font-weight: bolder;
    @media ${MEDIA_MOBILE_LANDSCAPE} {
      font-size: 10px;
      right: 9px;
      top: 15px;
      width: 12px;
      height: 12px;
    }
  }
  > div {
    display: flex;
    align-items: center;
    height: 44px;
    position: relative;
    border-top: 1px solid #b7b7b8;
    &::before {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      top: 0;
      background: #000;
      @media ${MEDIA_MOBILE_LANDSCAPE} {
        display: none;
      }
    }
    @media ${MEDIA_MOBILE_LANDSCAPE} {
      border-top: 0;
    }
  }

  > div > img {
    width: 38px;
    height: 38px;
    @media ${MEDIA_DESKTOP} {
      width: 42px;
      height: 42px;
    }
  }
  > div > span {
    margin-left: 3px;
    font-size: 16px;
    background: -webkit-linear-gradient(#aeafaf, #f1f1f1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.1px;
    font-weight: 500;
    @media ${MEDIA_DESKTOP} {
      font-size: 19px;
    }
  }
  > div > span.on {
    background: -webkit-linear-gradient(top, rgb(255 170 170), rgb(255 71 60)) text;
  }
`;

function GameMenu(
  {
    onClose,
    onClickCS,
    onClickHelp,
    onClickInstantSeat,
    onClickStandup,
    onClickAddionalBuyin,
    onClickOtherRingStatus,
    onClickGameStatusBoard,
    onRoomLeave,
    thisRef,
    ChatCount,
    SeatedMyInfo,
    isTournament,
    isStandingUp
  }: {
    onClose?: () => void;
    onClickCS?: () => void;
    onClickHelp?: () => void;
    onClickInstantSeat?: () => void;
    onRoomLeave?: () => void;
    onClickStandup?: () => void;
    onClickAddionalBuyin?: () => void;
    onClickOtherRingStatus?: () => void;
    onClickGameStatusBoard?: () => void;
    thisRef: any;
    ChatCount: number;
    SeatedMyInfo: any;
    isTournament: boolean;
    isStandingUp: boolean;
  }
) {
  const [myInfo, setMyInfo] = useState<MyInfo>();
  const [handCuttingList, setHandCuttingList] = useState<any[]>();

  useEffect(() => {
    requestMyInfo().then((v: any) => {
      setMyInfo(v.info)
    });
    
    (async () => {
      const res = await requestGetHandCuttingList();
      setHandCuttingList(res.list);
    })();
  }, []);

  const printCurrentRollingRate = useCallback(()=>{
    if (!(myInfo))
      return '로딩중';

    let rate = 0;
    
    if (myInfo.rollingAchieve === 0) {
      if (myInfo.rollingMoney === 0)
        rate = 0;
      else
        rate = 100;
    }
    else {
      rate = Math.round(myInfo.rollingMoney / myInfo.rollingAchieve * 100);
    }

    if (rate > 100)
      rate = 100;
    
    return `${myInfo.rollingMoney.toLocaleString()}(${rate}%)`;
  }, [myInfo]);

  const plusPrinter = (num:number) => {
    if (num < 0)  
      return num;
    else
      return `+${num}`;
  };

  const printNickname = (info:MyInfo|undefined) => {
    if (myInfo && myInfo.nickname)
      return `${myInfo.nickname}님`;
    else
      return '로딩중';
  };
  
  const printHasMoney = (info:MyInfo|undefined) => {
    if (myInfo && myInfo.money >= 0)
      return `${myInfo.money.toLocaleString()}원`;
    else
      return '로딩중';
  };

  const printCalculateProfit = useCallback((SeatedMyInfo:any) => {
    if (handCuttingList && SeatedMyInfo && SeatedMyInfo.isCalculateAfter) {
        let buyin = SeatedMyInfo.leftBuyin;
        let winAmount = SeatedMyInfo.winAmount;
        let handsCount = SeatedMyInfo.handsCount;
        let maxHands = 0;

        if (winAmount > 0) {
          let r = handCuttingList?.sort((a, b) => {
            return a.handCount - b.handCount
          });
          
          if (r)
            maxHands = r[r.length - 1].handCount;

          let recover = buyin, profit = 0, loss = 0;
          
          for (let v of handCuttingList) {
            if (handsCount <= v.handCount) {
              loss = Math.floor(winAmount * (v.lossPercentage / 100) );
              recover = recover - loss;
              profit = loss;
              break;
            }
          }

          return recover.toLocaleString() + '원';
        }
        else {
          return buyin.toLocaleString() + '원';
        }
    }
    else
      return '';
  }, [handCuttingList]);

  
  return <Wrapper>
    <Content>
      <div className={`profile-wrapper`}>
        <div className={`title-wrapper`}>
          <span><strong>{SeatedMyInfo ? printNickname(SeatedMyInfo.nickname): ''}</strong></span>
        </div>
        <div className={`money-wrapper`}>
          <div className={`left`}>
            <span>보유 금액</span>
            <span className={'t'}>{printHasMoney(myInfo)}</span>
          </div>
          {
            typeof SeatedMyInfo !== 'undefined' &&
            SeatedMyInfo.type !== 'spectator' && (
            <>
              <div className={`right`}>
                <span>바이인한 금액</span>
                <span className={'t'}>{`${SeatedMyInfo.buyin.toLocaleString()}원`}</span>
              </div>
              <div className={`stack`}>
                <span>현재 스택</span>
                <span className={'t'}>{`${SeatedMyInfo.stackSize.toLocaleString()}원`}</span>
                <span className={`t2 ${(SeatedMyInfo.stackSize / SeatedMyInfo.buyin * 100) - 100 < 0 ? 'minus' : 'plus'}`}>{`(${plusPrinter(Math.round((SeatedMyInfo.stackSize / SeatedMyInfo.buyin * 100 * 100) - 10000) / 100)}%)`}</span>
              </div>
            </>
            )
          }
          <div className={`orange`}>
            <span>롤링율</span>
            <span className={'t roll'}>{printCurrentRollingRate()}</span>
          </div>
          {
            typeof SeatedMyInfo !== 'undefined' &&
            SeatedMyInfo.type === 'spectator' &&
            SeatedMyInfo.isCalculateAfter && (
              <>
                <div className={`right minus`}>
                  <span>미정산금액</span>
                  <span className={'t'}>{printCalculateProfit(SeatedMyInfo)} (나가면 정산됩니다)</span>
                </div>
              </>
            )
          }
          {
            typeof SeatedMyInfo !== 'undefined' &&
            SeatedMyInfo.type === 'spectator' &&
            SeatedMyInfo.isMovedRoom &&
            SeatedMyInfo.winAmount > 0 && (
              <>
                <div className={`right previous`}>
                  <span>이전방 수익금</span>
                  <span className={'t'}>{SeatedMyInfo.winAmount.toLocaleString()}원</span>
                </div>
              </>
            )
          }
          {
            typeof SeatedMyInfo !== 'undefined' &&
            SeatedMyInfo.type === 'players' &&
            SeatedMyInfo.additionalWinAmount > 0 && (
              <>
                <div className={`right previous`}>
                  <span>이전방수익</span>
                  <span className={'t'}>{SeatedMyInfo.additionalWinAmount.toLocaleString()}</span>
                </div>
              </>
            )
          }
        </div>

      </div>
      <div className={'button-wrapper'}>
        <LeaveButton 
        onClick={()=>{
          if (thisRef) {
            onClickHelp && onClickHelp();
            thisRef.current.close();
          }
        }}>
          <div>
            <img alt="help" src='/images/quickHelp.png'/>
            <span>도움말</span>
          </div>
        </LeaveButton>
        <LeaveButton onClick={()=>{
          if (thisRef) {
            onClickGameStatusBoard && onClickGameStatusBoard();
            thisRef.current.close();
          }
        }}>
          {
            isTournament ? (
              <div>
                <img alt="status" src='/images/nes/tournament2.png' />
                <span>토너먼트 상황보기</span>
              </div>
            ) : (
              <div>
                <img alt="status" src='/images/nes/game_status.png' />
                <span>게임방 상황보기</span>
              </div>
            )
          }
        </LeaveButton>
        {
          !isTournament ? (
            <LeaveButton 
              onClick={()=>{
                if (thisRef) {
                  onClickOtherRingStatus && onClickOtherRingStatus();
                  thisRef.current.close();
                }
              }}>
                <div>
                  <img alt="help" src='/images/nes/others.png'/>
                  <span>테이블 이동하기</span>
                </div>
              </LeaveButton>
          ) : <></>
        }
        {
          typeof SeatedMyInfo !== 'undefined' &&
          SeatedMyInfo.type !== 'spectator' ?
          <>
            {
            !isTournament ? (
              <LeaveButton 
              onClick={()=>{
                if (thisRef) {
                  onClickAddionalBuyin && onClickAddionalBuyin();
                  thisRef.current.close();
                }
              }}>
                <div>
                  <img alt="buyin" src='/images/newBuyin.png'/>
                  <span>추가바이인</span>
                </div>
              </LeaveButton>
            ) : <></>
            }
            {
              !isStandingUp ? (
                <LeaveButton 
                  onClick={()=>{
                    if (thisRef) {
                      onClickStandup && onClickStandup();
                      thisRef.current.close();
                    }
                  }}>
                    <div>
                      <img alt="standup" src='/images/standup.png'/>
                      <span>자리비우기</span>
                    </div>
                  </LeaveButton>
              ) : <></>
            }
            </> :
            <>
              <LeaveButton 
              onClick={()=>{
                if (thisRef) {
                  onClickInstantSeat && onClickInstantSeat();
                  thisRef.current.close();
                }
              }}>
                <div>
                  <img alt="quickJoin" src='/images/quickJoin.png'/>
                  <span>아무자리에 앉기</span>
                </div>
              </LeaveButton>
            </>
        }
        
        <LeaveButton onClick={()=>{
          if (thisRef) {
            onClickCS && onClickCS();
            thisRef.current.close();
          }
        }}>
          <div>
            <img alt="out" src={`/images/customer${ChatCount ? 'on' : 'off'}.png`}/>
            <span className={ChatCount ? 'on' : 'off'}>고객센터</span>
          </div>
          {ChatCount ? (
            <dd>
              {ChatCount}
            </dd>
          ) : <></>}
        </LeaveButton>
       
        <LeaveButton onClick={()=>{
          if (thisRef) {
            thisRef.current.close();
            onRoomLeave && onRoomLeave();
          }
        }}>
          <div>
            <img alt="out" src='/images/exitButton.png' />
            <span>나가기</span>
          </div>
        </LeaveButton>
      </div>
    </Content>
  </Wrapper>;
}

export default GameMenu;
