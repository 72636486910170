import { useEffect, useState } from "react";
import moment from "moment";

interface AccountVirtualPopupProps {
  bank: any;
  accountNo: any;
  amount: any;
  accountName: any;
  closeWindow: any;
  addedAt: any;
}
const VACC_TIME_SEC = 5 * 60;

const AccountVirtualPopup = ({bank, accountNo, amount, accountName, closeWindow, addedAt}: AccountVirtualPopupProps) => {
  const [currentStatus, setCurrentStatus] = useState<number>(-1) //-1: 로딩, 0: 정상, 1: 시간초과
  const [timeText, setTimeText] = useState<string>('');

  const processTimeTask = (timer:any|null) => {
    const now = moment().unix();
    const startTime = moment(addedAt).unix()

    if (now - startTime >= VACC_TIME_SEC) {
      //이미 입금시간을 초과한 상태입니다.
      clearInterval(timer);
      setCurrentStatus(1);
    } else {
      //입금가능 시간입니다.
      const time = moment.duration(VACC_TIME_SEC - (now - startTime), 'seconds')
      const timeText = `${time.minutes().toString().padStart(2, '0')}:${time.seconds().toString().padStart(2, '0')} 남음`
      setTimeText(timeText);
      setCurrentStatus(0);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      processTimeTask(timer);
    }, 1000)

    processTimeTask(null);

    return () => {
      clearInterval(timer)
    }
  }, [])
  
  return <div>
      { currentStatus === -1 ? (
        <div style={{
          textAlign: 'center',
          paddingTop: 40
        }}>입금계좌 로딩중..</div>
      ) : currentStatus === 0 ? (
        <div style={{ padding: '10px', fontFamily: 'sans-serif' }}>
        <div
          style={{
            borderBottom: '2px solid #ddd',
            marginBottom: '10px',
            paddingBottom: '5px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              fontWeight: 'bold',
              fontSize: '20px'
            }}
          >
            입금계좌
          </div>
          <div
            style={{
              color: 'red',
              fontWeight: 'bold',
              fontSize: '18px'
            }}
          >
            {timeText}
          </div>
        </div>
        <div style={{ display: 'flex', borderBottom: '1px solid #eee', padding: '10px 0px' }}>
          <div style={{ width: '80px', fontWeight: '400', minWidth: 80 }}>은행명:</div>
          <div>{bank}</div>
        </div>
        <div style={{ display: 'flex', borderBottom: '1px solid #eee', padding: '10px 0px' }}>
          <div style={{ width: '80px', fontWeight: '400', minWidth: 80 }}>계좌번호:</div>
          <div>{accountNo}</div>
        </div>
        <div style={{ display: 'flex', borderBottom: '1px solid #eee', padding: '10px 0px' }}>
          <div style={{ width: '80px', fontWeight: '400', minWidth: 80 }}>예금주:</div>
          <div>{accountName}</div>
        </div>
        <div style={{ display: 'flex', padding: '10px 0px', marginBottom: 10 }}>
          <div style={{ width: '80px', fontWeight: '400', minWidth: 80 }}>금액:</div>
          <div>
            {
              amount === -1 ? '발급대기중..' : amount.toLocaleString()
            }
          </div>
        </div>
        <button
          style={{
            width: '100%',
            height: '30px',
            cursor: 'pointer',
            fontSize: 15,
          }}
          onClick={closeWindow}
        >
          닫기
        </button>
      </div>
      ) : (
        <div style={{
          textAlign: 'center',
          paddingTop: 40
        }}>
          <img src="/images/nes/warn1.png" alt="" style={{width: '200px', height: '200px'}} />
          <div style={{paddingTop: 40, fontSize: '18px'}}>시간초과가 되어 입금하실 수 없습니다.</div>
          <div style={{fontSize: '18px'}}>고객센터로 문의 부탁드리겠습니다.</div>
        </div>
      )
    }
  </div>
};
export default AccountVirtualPopup;
