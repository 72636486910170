import styled, {keyframes} from 'styled-components';
import {useCallback, useEffect, useMemo, useState} from "react";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import {ceremonyCardsState, shiningCardsState} from "../../recoil/Game";
import {CARD_INFO} from "../../dataset";
import useScreenOrientation, {MEDIA_MOBILE_LANDSCAPE, MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const rabbitHuntIconAnimation = keyframes`
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(0.9);
  }
`;

const rabbitTransformAnimationMOBILE = keyframes`
  0% {
    transform: translateY(0);
  }

  15% {
    transform: translateY(-15px);
  }

  100% {
    transform: translateY(35px);
  }
`;

const rabbitTransformAnimationPC = keyframes`
  0% {
    transform: translateY(0);
  }

  15% {
    transform: translateY(-15px);
  }

  100% {
    transform: translateY(75px);
  }
`;

const Wrapper = styled.div<{isCommunityCard?:boolean; isRabbitHunting?:boolean; show: boolean; me: boolean; isFlipped: boolean;}>`
  ${p => p.isCommunityCard ? `
  opacity: ${p.show ? 1: 0} !important;
  ` : ``}

  position: relative;
  ${p => p.isFlipped ? 'z-index: 3;' : 'z-index: 2;'}
  ${p => !p.me ? 'pointer-events: none;' : ''}
  perspective: 1000px;
  display: inline-block;
  transition: all 0.1s linear;

  &[data-ceremony="true"] {
    filter: brightness(0.5);
    
    &[data-zoomed="true"] {
      filter: none;
      zoom: 1.1;
      box-shadow: 0px 2.2px 2.2px 0px rgba(0, 0, 0, 0.20), 0px 0px 6.6px 0px #FFD764;
    }
  }

  .rabitIcon {
    z-index: 4;
    transform: scale(0.9);
    animation: ${rabbitHuntIconAnimation} 2s infinite;
  }

  >.osdfsdf {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: #000000b5;
    left: 2px;
    right: 2px;
    border-radius: 4px;
    font-size: 1vh;
    padding-top: 2px;
    padding-bottom: 2px;
    @media ${MEDIA_DESKTOP} {
      font-size: 1.6vh;
    }
  }
`;

const Card = styled.div<{isCommunityCard?:boolean; isRabbitHunting?:boolean; isMeOpend?:boolean}>`
  width: 100%;
  height: 100%;
  position: relative;
  ${p => p.isMeOpend ? `
  transition: all 0.2s linear;
  ` : `
  transition: all 0.3s linear;
  `}
  
  transform-style: preserve-3d;
  ${p => p.isCommunityCard ? `
    will-change: transform;
  ` : ``}

  &[data-flipped="true"] {
    ${p => p.isCommunityCard ? 
      p.isMeOpend ? `transform: rotateY(-180deg)translateY(-10px);` : 
      `transform: rotateY(-180deg);` : 
      ''
    }

    .sdfnsnd {
      animation: ${rabbitTransformAnimationMOBILE} 0.6s forwards;
      @media ${MEDIA_DESKTOP} {
        animation: ${rabbitTransformAnimationPC} 0.6s forwards;
      }
    }

    .rabitIcon {
      animation-duration: 0s !important;
    }
  }

  > img {
    width: 100%;
    height: 100%;
    ${p => p.isCommunityCard ? `
      backface-visibility: hidden;
    ` : ``}
    
    &:nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      ${p => p.isCommunityCard ? `
      transform: rotateY(-180deg);
      ` : ``}
    }
    &:nth-child(3) {
      position: absolute;
      top: 0;
      left: 0;
      ${p => p.isCommunityCard ? `
      transform: rotateY(-180deg);
      ` : ``}
    }
  }

  >.sdfnsnd {
    position: relative;
    z-index: 3;
    transition: all 0.3s linear;
    border-radius: 8px;
    will-change: opacity, transform;
    width: 100%;
    height: 100%;
    
    > img {
      width: 100%;
      height: 100%;
      ${p => p.isCommunityCard ? `
        backface-visibility: hidden;
      ` : ``}
      
      &:nth-child(2) {
        position: absolute;
        top: 0;
        left: 0;
        ${p => p.isCommunityCard ? `
        transform: rotateY(-180deg);
        ` : ``}
      }
      &:nth-child(3) {
        position: absolute;
        top: 0;
        left: 0;
        ${p => p.isCommunityCard ? `
        transform: rotateY(-180deg);
        ` : ``}
      }
    }
  }
`;

const ShineEffect = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.3, 1.2);
`;

function PokerCard(
  {
    isCommunityCard,
    isRabbitHunting,
    isPlayerCard,
    isOpend,
    isFold,
    isWinOpenAvailable,
    card,
    flip,
    delay = 0,
    startRemoveAnimation,
    tableStyle,
    onClickOpenCard,
    me,
    ...p
  }: {
    isCommunityCard?: boolean,
    isRabbitHunting?: boolean,
    isPlayerCard?: boolean,
    isOpend?: boolean,
    me?: boolean,
    card?: number,
    flip?: boolean,
    delay?: number,
    isFold?: boolean,
    isWinOpenAvailable?: boolean,
    startRemoveAnimation?: number,
    tableStyle: number,
    onClickOpenCard?: () => void,
    [k: string]: any
  }
) {
  const gameOption = useRecoilValue(gameOptionState);
  const shiningCards = useRecoilValue(shiningCardsState);
  const ceremonyCards = useRecoilValue(ceremonyCardsState);
  const [flipped, setFlipped] = useState<boolean>(Boolean(delay === -1 && flip));
  const [shine, setShine] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(true);
  const isZoomed = useMemo(() => {
    return card !== undefined && ceremonyCards.includes(CARD_INFO[card])
  }, [card, ceremonyCards]);

  useEffect(() => {
    if (card !== undefined && shiningCards.includes(CARD_INFO[card])) {
      setTimeout(() => {
        setShine(true);
      }, 2000);
    }
  }, [card, shiningCards]);

  useEffect(() => {
    if (flip) {
      setTimeout(() => {
        setFlipped(true);
      }, delay || 10);
    } else {
      setFlipped(false);
    }
  }, [flip, delay, isRabbitHunting]);
  useEffect(()=>{
    if(startRemoveAnimation === undefined) return;
    setTimeout(() => {
      setShow(false)
    }, startRemoveAnimation)
  }, [startRemoveAnimation])

  const handleClickCard = useCallback(async () => {
    if (isWinOpenAvailable && !isOpend && me) {
      onClickOpenCard && onClickOpenCard();
    }
  }, [isFold, isWinOpenAvailable, isOpend, me]);


  return <Wrapper 
  isRabbitHunting={isRabbitHunting} 
  isCommunityCard={isCommunityCard} 
  isFlipped={flipped}
  me={me ? true : false}
  show={show} 
  {...p} 
  data-ceremony={ceremonyCards.length > 0} 
  data-zoomed={isZoomed}
  onClick={handleClickCard}
  >
    <Card 
      isMeOpend={isOpend}
      isRabbitHunting={isRabbitHunting} 
      isCommunityCard={isCommunityCard || isPlayerCard} 
      data-flipped={flipped}
    >
      {isRabbitHunting ? (
        <div className='sdfnsnd'>
          <img className='gsdfs' src={`/images/nes/rabbitCard5.png`}/>
          <img className='rabitIcon' src={`/images/nes/rabbitIcon.png`} />
        </div>
      ) : (
        <img src={`/images/nes/card_back_${tableStyle}.png`}/>
      )}
      {
        card !== undefined && card !== null && card !== -1 && (
          <img src={`/images/nes/card/style-${gameOption.cardStyle}/${card}.svg`}/>
        )
      }
      {
        shine && (
          <ShineEffect src="/images/effect/made.png" />
        )
      }
    </Card>
    {
      (isWinOpenAvailable) && !isOpend && me && (
        <div className="osdfsdf">Open</div>
      )
    }
  </Wrapper>;
}

export default PokerCard;
