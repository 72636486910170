import styled from "styled-components";
import {useEffect, useState} from "react";
import * as PIXI from 'pixi.js';
import * as particles from '@pixi/particle-emitter';
import {upgradeConfig} from "@pixi/particle-emitter";
import {useRecoilState} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import useScreenOrientation from "../../hooks/useScreenOrientation";

const ParticleBackgroundWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;

  > .background {
    object-fit: contain;
    width: 110%;
    height: 110%;
    position: absolute;
    padding-top: 30px;
    padding-bottom: 100px;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    opacity: 1;
  }

  > canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    top: 0;
    left: 0;
  }
`

interface ParticleBackgroundProps {
  tableStyle: number;
}
const ParticleBackground = ({tableStyle}: ParticleBackgroundProps) => {
  const orientation = useScreenOrientation();
  const [setting, setSetting] = useRecoilState(gameOptionState);
  const [refresh, setRefresh] = useState(new Date().getTime());
  let app: PIXI.Application | null = null;
  useEffect(() => {
    //화면 렌더링이 끝났을때 호출
    const gameTable = document.getElementsByClassName('game-table');
    const innerSize = document.getElementsByClassName('inner');

    const resizeListener = () =>  {
      const rect = gameTable[0].getBoundingClientRect()
      const innerRect = innerSize[0].getBoundingClientRect()
      const tableHeight = rect.height;
      if(tableHeight === 0){
        setTimeout(() => {
          setRefresh(new Date().getTime())
          //로딩이 덜되었으므로, 100ms 뒤에 다시 호출
        }, 100)
        return;
      };
      const container = document.getElementById('particle_background');
      if (!container) return;
      if(app){
        app.renderer.resize(Math.min(rect.width, innerRect.width),innerRect.height);
      }
    }

    if (gameTable.length > 0 && innerSize.length > 0) {
      const rect = gameTable[0].getBoundingClientRect()
      const innerRect = innerSize[0].getBoundingClientRect()
      let tableHeight = rect.height;
      let tableWidth = rect.width;

      if(orientation === 'landscape'){
        //ratio = 1626 / 950
        //table이 가질수있는 최대값
        const tmpWidth = tableHeight * 1.712631578947368;
        const tmpHeight = tableWidth / 1.712631578947368;
        if(tmpWidth < tableWidth){
          tableWidth = tmpWidth;
        }
        if(tmpHeight < tableHeight){
          tableHeight = tmpHeight;
        }
        console.log('landscape', tableWidth, tableHeight)
      }
      if(tableHeight === 0){
        setTimeout(() => {
          setRefresh(new Date().getTime())
          //로딩이 덜되었으므로, 100ms 뒤에 다시 호출
        }, 100)
        return;
      };
      if(tableWidth === 0){
        setTimeout(() => {
          setRefresh(new Date().getTime())
          //로딩이 덜되었으므로, 100ms 뒤에 다시 호출
        }, 100)
        return;
      }
      const spawnPoint = tableHeight * 0.7;
      //const spawnWidthPoint = tableWidth * 0.789;
      const spawnWidthPoint = (tableWidth/2) * 0.65;
      const realTableTop = gameTable[0].getBoundingClientRect().y + tableHeight * 0.1;
      const realTableBottom = gameTable[0].getBoundingClientRect().y + tableHeight - tableHeight * 0.1;
      const container = document.getElementById('particle_background');
      if (!container) return;
      // Basic PIXI Setup
      // 배경 투명으로
      app = new PIXI.Application({
        width: Math.min(rect.width, innerRect.width),
        height: innerRect.height,
        resolution: window.devicePixelRatio || 1,
        backgroundAlpha: 0,
      });
      if(app){
        container.appendChild(app.view as HTMLCanvasElement);

        let particleJson = {
          "alpha": {
            "start": 1,
            "end": 0.1
          },
          "scale": {
            "start": 0.5,
            "end": 0.1,
            "minimumScaleMultiplier": 0.5
          },
          "color": {
            "start": "#ffffff",
            "end": "#ffffff"
          },
          "speed": {
            "start": 60,
            "end": 20,
            "minimumSpeedMultiplier": 1
          },
          "acceleration": {
            "x": 0,
            "y": 0
          },
          "maxSpeed": -50,
          "startRotation": {
            "min": 0,
            "max": 360
          },
          "noRotation": false,
          "rotationSpeed": {
            "min": 0,
            "max": 0
          },
          "lifetime": {
            "min": 1,
            "max": 6
          },
          "blendMode": "normal",
          "frequency": 0.001,
          "emitterLifetime": -1,
          "maxParticles": 40,
          "pos": {
            "x": 0,
            "y": 0
          },
          "addAtBack": false,
          "spawnType": "circle",
          "spawnCircle": {
            "x": 0,
            "y": 0,
            "r": 0
          }
        }
        let particleJson2 = {
          "alpha": {
            "start": 0.5,
            "end": 0.05
          },
          "scale": {
            "start": 0.5,
            "end": 0.3,
            "minimumScaleMultiplier": 0.5
          },
          "color": {
            "start": "#ffffff",
            "end": "#ffffff"
          },
          "speed": {
            "start": 60,
            "end": 20,
            "minimumSpeedMultiplier": 1
          },
          "acceleration": {
            "x": 0,
            "y": 0
          },
          "maxSpeed": -100,
          "startRotation": {
            "min": 0,
            "max": 360
          },
          "noRotation": false,
          "rotationSpeed": {
            "min": 0,
            "max": 0
          },
          "lifetime": {
            "min": 1,
            "max": 6
          },
          "blendMode": "normal",
          "frequency": 0.0005,
          "emitterLifetime": -1,
          "maxParticles": 40,
          "pos": {
            "x": 0,
            "y": 0
          },
          "addAtBack": false,
          "spawnType": "circle",
          "spawnCircle": {
            "x": 0,
            "y": 0,
            "r": 0
          }
        }

        /*
        * particleJson.speed.start = 100;
          particleJson.speed.end = 40;
          particleJson.scale.start = 1.2;
          particleJson.scale.end = 0.5;
          particleJson.maxParticles = 30;
        * */



        if(orientation === 'landscape'){
          particleJson.speed.start = 60;
          particleJson.speed.end = 20;
          particleJson.scale.start = 0.8;
          particleJson.scale.end = 0.5;
          particleJson.maxParticles = 30;

          particleJson.pos.x = (innerRect.width/2) - spawnWidthPoint;
          particleJson.pos.y = innerRect.height / 2;
        }else{
          particleJson.maxParticles = 20;
          particleJson.pos.x = innerRect.width / 2;
          particleJson.pos.y = realTableTop + spawnPoint + 20;
        }
        const emitter1 = new particles.Emitter(
          app.stage,
          //upgradeConfig(particleJson, [PIXI.Texture.from(`/images/game/particle_${setting.tableStyle}.png`)])
          upgradeConfig(particleJson, [PIXI.Texture.from(`/images/game/particle_white.png`)])
        )
        if(orientation === 'landscape'){
          particleJson.pos.x = (innerRect.width/2) + spawnWidthPoint;
          particleJson.pos.y = innerRect.height / 2;
        }else{
          particleJson.pos.x = innerRect.width / 2;
          particleJson.pos.y = realTableBottom - spawnPoint;
        }
        const emitter2 = new particles.Emitter(
          app.stage,
          //upgradeConfig(particleJson, [PIXI.Texture.from(`/images/game/particle_${setting.tableStyle}.png`)])
          upgradeConfig(particleJson, [PIXI.Texture.from(`/images/game/particle_white.png`)])
        )
        var elapsed = Date.now();

// Update function every frame
        var update = function () {
          // Update the next frame
          requestAnimationFrame(update);

          var now = Date.now();

          // The emitter requires the elapsed
          // number of seconds since the last update
          const speed = 0.0003
          emitter1.update((now - elapsed) * speed);
          emitter2.update((now - elapsed) * speed);
          elapsed = now;
        };

// Start emitting
        emitter1.emit = true;
        emitter2.emit = true;

// Start the update
        update();


        //움직이는 BG
        // 배경을 추가합니다.
        const background = new PIXI.Graphics();
        background.beginFill(0x000000); // 검은색 배경
        background.drawRect(0, 0, app.screen.width, app.screen.height);
        background.endFill();
        app.stage.addChild(background as any);

        const circles: any[] = [];
        const numCircles = 0; //Math.floor(Math.random() * 11) + 20;

        for (let i = 0; i < numCircles; i++) {
          const circleSize = Math.floor(Math.random() * 40) + 20;
          const blurAmount = Math.random() * 0.2 + 0.7; // 70%에서 90% 블러 효과
          const maxTransparency = Math.random() * 0.3 + 0.4; // 0.4에서 0.7 사이의 max 투명도
          const startOpacity = maxTransparency; // 시작 투명도를 max 값으로 설정
          const endOpacity = maxTransparency; // 최종 투명도
          const circle = new PIXI.Graphics();

          let circleColor = 0xFFFFFF;
          if(tableStyle === 1){
            circleColor = 0x0000FF;
          }else if (tableStyle === 2) {
            circleColor = 0xFFFF00;
          }else if(tableStyle === 3){
            circleColor = 0xFF0000;
          }

          circle.beginFill(circleColor, startOpacity); // 빨간색 원과 시작 투명도 설정
          circle.drawCircle(0, 0, circleSize);
          circle.endFill();

          // 초기 위치 설정 (랜덤)
          circle.x = Math.random() * app.screen.width;
          circle.y = Math.random() * app.screen.height;

          // 방향 및 속도 설정 (화면 중앙에서 바깥으로 퍼지는 방향)
          const direction = Math.atan2(app.screen.height / 2 - circle.y, app.screen.width / 2 - circle.x) + Math.PI;
          const speed = (Math.random() * 0.1 + 0.1) * 3; // 더 느리게

          // 원을 추가하고 배열에 저장
          app.stage.addChild(circle as any);
          circles.push({ circle, direction, speed, blurAmount, maxTransparency, startOpacity, endOpacity });
        }

        app.ticker.add((delta) => {
          circles.forEach((item) => {
            // 원의 투명도를 ease하여 증가
            item.circle.alpha += (item.endOpacity - item.startOpacity) * 0.02;

            // 원을 이동시키고 방향을 랜덤하게 변경
            item.circle.x += Math.cos(item.direction) * item.speed;
            item.circle.y += Math.sin(item.direction) * item.speed;

            // 화면 가장자리에 도달하면 다시 랜덤한 위치에서 시작
            if (
              item.circle.x > app!.screen.width + item.circle.width ||
              item.circle.x < -item.circle.width ||
              item.circle.y > app!.screen.height + item.circle.height ||
              item.circle.y < -item.circle.height
            ) {
              item.circle.x = Math.random() * app!.screen.width;
              item.circle.y = Math.random() * app!.screen.height;
            }

            // 블러 효과 및 투명도 업데이트
            //퍼포먼스 저하의 주범
            //item.circle.filters = [new PIXI.filters.BlurFilter(item.blurAmount * 30, 10)]; // quality를 높여 더 부드럽게
            item.circle.alpha = item.circle.alpha > item.maxTransparency ? item.maxTransparency : item.circle.alpha;
          });
        });

        window.addEventListener('resize', resizeListener);
      }
    }else {
      console.log('no game table, retrying...')
      setTimeout(() => {
        setRefresh(new Date().getTime())
        //로딩이 덜되었으므로, 100ms 뒤에 다시 호출
      }, 100)
      return;
    }
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [setting, refresh, orientation])


  return <ParticleBackgroundWrapper id='particle_background'>
    <canvas id="c2" style={{
      opacity: 1,
      background: '#000'
    }}/>
  </ParticleBackgroundWrapper>
}

export default ParticleBackground;
