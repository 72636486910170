import styled from "styled-components";
import {HorizontalBar} from "../Trade";
import React, {useEffect, useState} from "react";
import {requestSetNewPassword} from "../../api";
import {AttendanceHistoryInfo, UserMoneyUpdateType} from "../../dataset";
import moment from "moment";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {sha256} from "js-sha256";

const PointHistoryWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: #151515;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .fsafasfa {
    margin-right: 4px;
    color: red;
  }
  .daffnas {
    color: #32ff00;
  }
  @media ${MEDIA_DESKTOP} {
    top: 60px;
    height: calc(100% - 60px);
    background: white;
  }
  > .content-wrapper {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    @media ${MEDIA_DESKTOP} {
      max-width: 470px;
      padding-top: 24px;
    }
    > .content {
      width: 100%;
      padding: 16px 20px;
      gap: 20px;
      @media ${MEDIA_DESKTOP} {
        padding-left: 0;
        padding-right: 0;
        gap: 0;
      }
      >.empty {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        >.wrapper {
          position: relative;
          >.center {
            width: 84px;
            height: 84px;
            object-fit: contain;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          >.bottom {
            width: 100%;
            position: absolute;
            bottom: 3px;
            left: 50%;
            transform: translateX(-50%);
            color: rgba(255, 255, 255, 0.8);
            text-align: center;
            font-family: Pretendard;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            letter-spacing: -0.2px;
          }
          >.background {
            width: 220px;
            height: 220px;
            object-fit: contain;
          }
        }
      }
    }
  }

  .card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    -webkit-box-pack: start;
    justify-content: flex-start;
    gap: 16px;
    >.title {
      color: rgb(255, 255, 255);
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
    }
    >.money-input-wrapper {
      width: 100%;
      @media ${MEDIA_DESKTOP} {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
      }
      >.input-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        border-radius: 6px;
        border: 1px solid rgb(37, 38, 45);
        height: 42px;
        padding-left: 10px;
        padding-right: 12px;
        @media ${MEDIA_DESKTOP} {
          border-radius: 4px;
          border: 1px solid rgba(24, 24, 24, 0.30);
          height: 44px;
        }

        >.point {
          width: 24px;
          height: 24px;
          object-fit: contain;
          flex-shrink: 0;
        }
        >input {
          -webkit-box-flex: 1;
          flex-grow: 1;
          outline: none;
          background: none;
          color: rgb(255, 255, 255);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: -0.2px;
          border: none;
          text-align: right;
          @media ${MEDIA_DESKTOP} {
            color: #000;
          }
        }
      }
    }
  }

  .two {
    margin-top: 20px;
    border-top: 1px solid #252525;
    padding-top: 20px;
    @media ${MEDIA_DESKTOP} {
      margin-top: 30px;
      padding-top: 0;
      border-top: 1px solid rgb(185 185 185);
    }
  }
  .thr {
    margin-top: 10px;
    padding-bottom: 20px;
  }

  .button {
    cursor: pointer;
    width: 100%;
    height: 36px;
    border-radius: 6px;
    background: var(--Gradient-Red, linear-gradient(180deg, #DF3535 0%, #A51818 100%));
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    @media ${MEDIA_DESKTOP} {
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
      width: 100%;
      height: 44px;
      border-radius: 22px;
      background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
    }
  }

  .button.trade-money {
    background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
  }

  .button.selected {
    border-radius: 6px;
    border: 1px solid var(--Point-Red, #E31D1D);
    background: rgba(227, 29, 29, 0.50);
  }

  .button.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .asfasf {
    color: #ff2e1f;
    font-size: 13px;
    margin-top: 2px;
  }

  .result-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 8px;
    padding: 12px 0px;
    border-radius: 8px;
    background: rgb(37, 38, 45);
    color: #fff;
    margin-bottom: 20px;

    >.icon {

    }
    img {
      width: 60px;
      height: 60px;
    }
    span {
      font-size: 14px;
    }
    span.error {
      color: #ff4338;
    }
  }
`

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  @media ${MEDIA_DESKTOP} {
    border-bottom: 1px solid #DDD;
    background: #F5F5F5;
    height: 57px;
  }

  > .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
  }

  > .title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media ${MEDIA_DESKTOP} {
      position: relative;
      width: 470px;
      color: var(--Black, #181818);
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 20px */
    }

    > .close {
      display: none;
      @media ${MEDIA_DESKTOP} {
        cursor: pointer;
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }
    }
  }
`

interface PointHistoryProps {
  onClose: () => void;
}

const handleInputChange = (e: any) => {
  const input = document.getElementById('money') as HTMLInputElement;
  const money = parseInt(input.value.replace(/,/g, ''))
  if (isNaN(money)) {
    input.value = 0 + ''
  } else if (money < 0) {
    input.value = 0 + ''
  } else {
    input.value = money.toLocaleString()
  }
}

const PasswordChange = ({onClose}: PointHistoryProps) => {
  const [valueCurrentPassword, setValueCurrentPassword] = useState<string>("");
  const [valueNewPassword1, setValueNewPassword1] = useState<string>("");
  const [valueNewPassword2, setValueNewPassword2] = useState<string>("");
  const [valueDisable2, setValueDisable2] = useState<number>(0);
  const [valueError, setValueError] = useState<string>("");
  const [valueResult, setValueResult] = useState<string>("");

  useEffect(() => {
    setValueDisable2(0);
    setValueError("");
    setValueResult("");
  }, []);

  const handleClickPasswordChange = async () => {
    if (valueNewPassword1 === '' || valueNewPassword2 === '') {
      return setValueDisable2(2);
    }

    if (valueNewPassword1 !== valueNewPassword2) {
      return setValueDisable2(1);
    }

    if (valueNewPassword1.length <= 5) {
      return setValueError("새 비밀번호 길이는 최소 6글자 이상부터 사용하실 수 있습니다.");
    }

    setValueError("");
    setValueResult("");

    const rsnp = await requestSetNewPassword(sha256(valueCurrentPassword), sha256(valueNewPassword1), sha256(valueNewPassword2));
  
    if (rsnp) {
      setValueDisable2(0);

      if (rsnp.error.length > 0) {
        setValueError(rsnp.error);
      }
      else if (rsnp.result) {
        setValueResult('비밀번호 변경에 성공하였습니다.');
        setValueCurrentPassword("");
        setValueNewPassword1("");
        setValueNewPassword2("");
      }
    }
  };

  return <PointHistoryWrapper>
    <Header>
      <img className='close' src='/images/ic_arrow_back.png' onClick={() => onClose()}/>
      <div className='title'><img onClick={() => onClose()} className={'close'} src='/images/pc/Back.png'/>비밀번호 변경</div>
    </Header>
    <div className='content-wrapper'>
      <div className='content'>
        {(valueError.length > 0 || valueResult.length > 0) ? (
          <div className={"result-wrapper"}>
            <div className={"icon"}>
              {
                valueError.length > 0 ? (
                  <img src="/images/iconError.png" alt="성공"/>
                ) : (
                  <img src="/images/iconSuccess.png" alt="성공"/>
                )
              }
            </div>
            <span className={`${valueError.length > 0 ? 'error' : ''}`}>{
              valueError.length > 0 ? valueError : valueResult
            }</span>
          </div>
        ) : <></>}

        <div className="card">
          <div className='title'>
          현재 비밀번호
          </div>
          <div className='money-input-wrapper'>
            <div className='input-wrapper'>
              <img className='point' src='/images/iconPassword2.png'/>
              <input 
                type='password' 
                className='input'
                placeholder={"현재 비밀번호를 입력해주세요."}
                value={valueCurrentPassword}
                onChange={(e)=> {
                  setValueCurrentPassword(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="card two">
          <div className='title'>
          새 비밀번호
          </div>
          <div className='money-input-wrapper'>
            <div className='input-wrapper'>
              <img className='point' src='/images/iconPassword3.png'/>
              <input 
                type='password' 
                className='input'
                placeholder={"새 비밀번호를 입력해주세요."}
                value={valueNewPassword1}
                onChange={(e)=> {
                  setValueNewPassword1(e.target.value);
                }}
              />
            </div>
            {valueDisable2 === 1 ? (
              <div className={'asfasf'}>비밀번호가 일치하지 않습니다.</div>
            ) : valueDisable2 === 2 ? 
              <div className={'asfasf'}>새 비밀번호를 입력해주세요.</div>
            : <></>}
          </div>
        </div>
        <div className="card thr">
          <div className='title'>
          새 비밀번호 확인
          </div>
          <div className='money-input-wrapper'>
            <div className='input-wrapper'>
              <img className='point' src='/images/iconPassword3.png'/>
              <input 
                type='password' 
                className='input'
                placeholder={"새 비밀번호를 다시 입력해주세요."}
                value={valueNewPassword2}
                onChange={(e)=> {
                  setValueNewPassword2(e.target.value);
                }}
              />
            </div>
            {valueDisable2 === 1 ? (
              <div className={'asfasf'}>비밀번호가 일치하지 않습니다.</div>
            ) : valueDisable2 === 2 ? 
              <div className={'asfasf'}>새 비밀번호를 입력해주세요.</div>
            : <></>}
          </div>
        </div>
        <div className='button' onClick={handleClickPasswordChange}>
          비밀번호 변경
        </div>
      </div>
    </div>
  </PointHistoryWrapper>
}

export default PasswordChange;
