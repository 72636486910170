import styled from "styled-components";

const Modal = styled.div`
  width: 100%;
  min-width: 311px;
  border-radius: 12px;
  background: #17171B;
  padding: 16px;
`;

export default Modal;