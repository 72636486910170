import styled from "styled-components";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {MyInfo} from "../dataset";
import {requestGetKeepAwayMyList, useSocketIsConnect} from "../api";
import {startLoading, stopLoading} from "./Loading";
import {playSFX, Sounds} from "../utils/sound";
import useScreenOrientation, {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";
import {globalLoadingState} from "../recoil/Loading";
import {useRecoilState, useSetRecoilState} from 'recoil';
import {
  hasCacheGameDataState
} from "../recoil/Game";
import {gameOptionState} from '../recoil/GameOption';
import {
  requestJoinRingGroup,
  requestPayBackKeepAwayRoom,
} from "../api";
import useDialog from "../hooks/useDialog";
import {useNavigate, useLocation} from "react-router-dom";
import LeaveSettlement from "../components/game/LeaveSettlement";

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  background: #181818;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  @media ${MEDIA_DESKTOP} {
    width: 800px;
    background: white;
  }
`;

const BorderRect = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 1px solid var(--Point-Red, #E31D1D);
  background: transparent;
  box-shadow: 0px 0px 16px 2px #E31D1D inset;
  @media ${MEDIA_DESKTOP}{
    display: none;
  }
`

const Title = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 52px;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.2px;
  background: rgba(227, 29, 29, 0.3);
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset;
  @media ${MEDIA_DESKTOP}{
    color: var(--Black, #181818);
    text-align: center;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
    background: #F5F5F5;
    border-bottom: 1px solid #DDD;
  }
  >.pc {
    display: none;
    @media ${MEDIA_DESKTOP}{
      display: block;
    }
  }
  >.mo {
    display: block;
    @media ${MEDIA_DESKTOP}{
      display: none;
    }
  }
  > .close-wrapper {
    z-index: 8;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;

    > .background {
      width: 32px;
      height: 32px;
      padding-top: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      object-fit: contain;
      transform: translate(-50%, -50%);
    }

    > .close {
      width: 24px;
      height: 24px;
      object-fit: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0;
  
  >.list-wrapper {
    color: #e5e5e5;
    width: 100%;
    overflow-y: scroll;
    max-height: 500px;
    @media ${MEDIA_DESKTOP} {
      color: #000;
    }
    >.list {
      width: 100%;
      &+.list {
        border-top: 1px solid #434343;
        padding-top: 12px;
        margin-top: 12px;
        @media ${MEDIA_DESKTOP} {
          border-top: 1px solid #a1a1a1;
          padding-top: 8px;
          margin-top: 8px;
        }
      }
      >.list-inner {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        >.profile {
          position: relative;
          overflow: hidden;
          width: 26.1vw;
          max-width: 60px;
          aspect-ratio: 94 / 128;
          flex-shrink: 0;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          border-width: 3px;
          border-style: solid;
          border-color: transparent;
          @media ${MEDIA_DESKTOP} {
            max-width: 90px;
          }

          >img {
            width: 103%;
            object-fit: contain;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 0;
          }
          >.top {
            width: 100%;
            -webkit-box-flex: 1;
            flex-grow: 1;
            z-index: 1;
            color: #fff;
            font-size: 9px;
            text-align: left;
            padding: 4px;
            @media ${MEDIA_DESKTOP} {
              padding: 8px;
              font-size: 12px;
            }
          }
          >.bottom {
            width: 100%;
            position: relative;
            height: 26px;
            color: #fff;
            z-index: 1;
            display: flex;
            align-items: center;
            font-size: 8px;
            justify-content: center;
            @media ${MEDIA_DESKTOP} {
              height: 40px;
              font-size: 13px;
            }
          }
        }
        >.detail {
          width: calc(100% - 75px);
          text-align: left;
          margin-left: 15px;

          @media ${MEDIA_DESKTOP} {
            width: calc(100% - 30% - 90px);
            text-align: left;
            margin-left: 10px;
          }
          &.two {
            @media ${MEDIA_DESKTOP} {
              width: calc(100% - 90px);
            } 
          }
          & span {
            font-weight: 500;
            font-size: 11px;
            @media ${MEDIA_DESKTOP} {
              font-weight: 500;
              font-size: 14px;
            }
          }
          & .val {
            font-size: 15px;
            font-weight: bold;
            @media ${MEDIA_DESKTOP} {
              font-size: 18px;
            }
          }
          & .green {
            color: rgb(32 255 32);
            font-size: 10px;
            margin-left: 3px;
            @media ${MEDIA_DESKTOP} {
              font-size: 13px;
              color: #00a100;
            }  
          }
          & .grey {
            color: #c1c1c1;
            font-size: 10px;
            @media ${MEDIA_DESKTOP} {
              font-size: 13px;
              font-weight: 500;
              color: #4b4b4b;
            }  
          }
          & .red {
            color: rgb(255 123 123);
            
            font-size: 10px;
            margin-left: 3px;
            @media ${MEDIA_DESKTOP} {
              color: rgb(161 0 0);
              font-size: 13px;
            }  
          }
          & .yellow {
            color: rgb(255 235 39);
            font-size: 10px;
            margin-left: 3px;
            @media ${MEDIA_DESKTOP} {
              color: rgb(143 130 0);
              font-size: 13px;
            }  
          }
          & .border {
            border-top: 1px solid #434343;
            padding-top: 6px;
            margin-top: 6px;
            width: 90%;

            @media ${MEDIA_DESKTOP} {
              border-top: 1px solid #a1a1a1;
            }
          }
        }
      }
      & .tail {
        width: 30%;
        display: flex;
        justify-content: flex-end;
        gap: 9px;
        width: 100%;
        padding-top: 16px;
        align-items: end;
        @media ${MEDIA_DESKTOP} {
          width: 30%;
          align-items: initial;
        }

        >.play {
          position: relative;
          cursor: pointer;
          width: 60%;
          display: flex;
          flex-direction: column;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          border-radius: 21px;
          background: var(--Point-Mint, #169E7A);
          color: rgb(255, 255, 255);
          height: 24px;
          font-size: 11px;
          font-family: Pretendard;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          &:hover {
            background: var(--Point-Mint, #169E7A);  
            @media ${MEDIA_DESKTOP} {
              background: #0f755a;
            }
          }
          @media ${MEDIA_DESKTOP} {
            height: 32px;
            font-size: 14px;
          }
        }
        >.calcNow {
          position: relative;
          cursor: pointer;
          width: 40%;
          display: flex;
          flex-direction: column;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
          border-radius: 21px;
          background: #ff5858;
          color: rgb(255, 255, 255);
          font-family: Pretendard;
          height: 24px;
          font-size: 11px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          &:hover {
            background: #ff5858;
            @media ${MEDIA_DESKTOP} {
              background: rgb(213 73 73);
            }
          }
          @media ${MEDIA_DESKTOP} {
            height: 32px;
            font-size: 14px;
          }
        }
      }
    }
  }
  >.empty-wrapper { 
    padding-top: 40px;
    padding-bottom: 40px;
    img {
      width: 75px;
      height: 80px;
      padding-bottom: 20px;
    }
    >.title {
      color: #525252;
    }
  }
  >.point-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    >.title {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
      opacity: 0.4;
      @media ${MEDIA_DESKTOP}{
        color: #181818;
        font-size: 14px;
        opacity: 0.7;
      }
    }
    >.value{
      color: var(--Point-Red, #E31D1D);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 28px */
      letter-spacing: -0.5px;
      @media ${MEDIA_DESKTOP}{
        color: #169E7A;
      }
    }
  }
  .input-wrapper {
    width: calc(100% - 50px);
    padding: 12px;
    border: 0;
    background: none;
    outline: none;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    &::placeholder {
      opacity: 0.5;
    }
    @media ${MEDIA_DESKTOP}{
      color: #181818;
      &::placeholder {
        opacity: 0.5;
      }
    }
  }
  >.description {
    margin-top: 12px;
    margin-bottom: 16px;
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    opacity: 0.4;
    @media ${MEDIA_DESKTOP}{
      color: #181818;
      font-size: 14px;
      opacity: 0.7;
    }
  }
`

interface KeepAwayManagerModal {
  onClose: () => void;
}

function KeepAwayManagerModal(
  {
    onClose
  }: KeepAwayManagerModal
) {
  const [conn] = useSocketIsConnect();
  const [list, setList] = useState<any>(null);
  const [handCuttingList, setHandCuttingList] = useState<any>(null);
  const setGlobalLoading = useSetRecoilState(globalLoadingState);
  const [setting, setSetting] = useRecoilState(gameOptionState);
  const [hasCacheGameData, setHasCacheGameData] = useRecoilState(hasCacheGameDataState);
  const navigate = useNavigate();
  const {openDialog} = useDialog();
  const orientation = useScreenOrientation();

  useEffect(() => {
    if (!conn.isLogin) {
      setList([]);
      return;
    }
    
    const loading = startLoading();
    requestGetKeepAwayMyList().then((res) => {
      if (res.result) {
        setList(res.list);
        setHandCuttingList(res.handCuttingList);
      }
      else {
        setList([]);
      }
    }).finally(()=>{
      stopLoading(loading)
    });

  }, [conn.isLogin]);

  const handleClickNowLeave = useCallback(async function (groupId: number) {
    if (!conn.isLogin) {
      return false;
    }

    let ring = list.filter((e:any) => e.groupId == groupId);
    if (ring.length === 0) {
      return false;
    }
    else {
      ring = ring[0];
    }

    const winMoney = (ring.amount - ring.lastTotalBuyin);
    let lossPercentage = 0;

    for (const v of handCuttingList) {
      if (ring.lastHandsCount <= v.handCount) {
        lossPercentage = v.lossPercentage
        break;
      }
    }

    const leave = await new Promise<boolean>((r) => {
      playSFX(Sounds.SFX_WINDOW_OPEN);
      openDialog({
        title: "미정산내역 지금 정산하기",
        component: <LeaveSettlement
          hands={ring.lastHandsCount}
          stack={ring.amount}
          buyin={ring.lastTotalBuyin}
          winAmount={winMoney}
          additionalWinAmount={0}
          lossPercentage={lossPercentage}
          settle={ring.amount}
          maxHands={0}
          lack={false}
          isStandup={false}
        />,
        forceMobile: true,
        type:'custom',
        confirm: true,
        confirmText: '정산하기',
        cancelText: '취소',
        onConfirm: () => {
          playSFX(Sounds.SFX_WINDOW_CLOSE)
          r(true);
        },
        onCancel: () => {
          playSFX(Sounds.SFX_WINDOW_CLOSE)
          r(false);
        }
      });
    });

    if (!leave) {
      return;
    }

    setGlobalLoading(true);
    const data = await requestPayBackKeepAwayRoom(ring.idx);
    setGlobalLoading(false);
    
    if (data.result == -1) {
      openDialog({
        title: '오류',
        text: '현재 진행중인 게임방에 접속해있습니다.</br>게임이 완전히 종료된 상태에서만 정산이 가능합니다.',
        onConfirm: () => {
          location.reload();
        }
      });
    }
    else if (data.result === -2) {
      openDialog({
        title: '오류',
        text: '현재 진행중인 게임방에 접속해있습니다.</br>게임이 완전히 종료된 상태에서만 정산이 가능합니다.',
        onConfirm: () => {
          location.reload();
        }
      });
    } 
    else if (data.result === -3) {
      openDialog({
        title: '오류',
        text: '요청하신 미정산 내역이 존재하지 않습니다.',
        onConfirm: () => {
          location.reload();
        }
      });
    } 
    else if (data.result === -4) {
      openDialog({
        title: '오류',
        text: '이미 정산처리 된 요청입니다.',
        onConfirm: () => {
          location.reload();
        }
      });
    }
    else if (data.result === 1) {
      openDialog({
        title: '오류',
        text: '정산금액으로 보유머니 지급이 완료되었습니다.',
        onConfirm: () => {
          location.reload();
        }
      });
    }
    else {
      openDialog({
        title: '오류',
        text: '알 수 없는 오류입니다. 고객센터로 문의주세요.',
        onConfirm: () => {
          location.reload();
        }
      });
    } 
  }, [conn.isLogin, list]);

  const handleClickJoin = useCallback(async function (groupId: number) {
    if (!conn.isLogin) {
      return false;
    }

    let ring = list.filter((e:any) => e.groupId == groupId);
    if (ring.length === 0) {
      return false;
    }
    else {
      ring = ring[0];
    }

    let sele = 1;

    for (const val of [1000, 10000, 100000]) {
      if (ring.bigBlind <= val) {
        break;
      }

      sele++;
    }

    if (sele === 1 && setting.tableStyle_LV1) {
      sele = setting.tableStyle_LV1;
    }
    else if (sele === 2 && setting.tableStyle_LV2) {
      sele = setting.tableStyle_LV2;
    }
    else if (sele === 3 && setting.tableStyle_LV3) {
      sele = setting.tableStyle_LV3;
    }

    if (hasCacheGameData !== sele) {
      setHasCacheGameData(0);
    }

    setGlobalLoading(true);
    let r = await requestJoinRingGroup(groupId, 0, 0, false);
    setGlobalLoading(false);
    
    if (typeof (r.result) == "number") {
      if (r.result == -1) {
        openDialog({
          title: '오류',
          text: '현재 진행중인 게임이 있어 입장할 수 없습니다.',
          onConfirm: () => {
            location.reload();
          }
        });
      }
      else if (r.result === -2) {
        openDialog({
          title: '오류',
          text: '인원이 꽉 찬 방에는 입장할 수 없습니다.',
          onConfirm: () => {
            location.reload();
          }
        });
      } 
      else if (r.result === -3) {
        openDialog({
          title: '오류',
          text: '입장은 가능한 상태이나 자리앉기 요청에 실패하였습니다.',
          onConfirm: () => {
            location.reload();
          }
        });
      } 
      else
        navigate("/game?id=" + r.result);
    }
  }, [conn.isLogin, list, hasCacheGameData]);

  return <Wrapper>
    <BorderRect/>
    <Title>
      미정산 내역 리스트
      <div className='close-wrapper mo'>
        <img className='background' src='/images/close-bg.png'/>
        <img className='close' src='/images/Close.png' onClick={onClose}/>
      </div>
      <div className='close-wrapper pc'>
        <img src='/images/pc/Close.png' className='close' onClick={onClose}/>
      </div>
    </Title>
    <Content>
      {
        handCuttingList === null && list === null ? <></> : 
        list.length === 0 ? (
          <div className='empty-wrapper'>
            <div>
              <img src="/images/nes/empty5.png"/>
            </div>
            <div className='title'>미정산 내역이 없습니다.</div>
          </div>
        ) : (
          <div className="list-wrapper">
            {
              list.map((e:any, index:number) => {
                const winMoney = (e.amount - e.lastTotalBuyin);
                let lossPercentage = 0;

                for (const v of handCuttingList) {
                  if (e.lastHandsCount <= v.handCount) {
                    lossPercentage = v.lossPercentage
                    break;
                  }
                }

                const lossAmount = winMoney < 0 ? 0 : Math.floor(winMoney * (lossPercentage / 100));
                let sele = 1;

                for (const val of [1000, 10000, 100000]) {
                  if (e.bigBlind <= val) {
                    break;
                  }

                  sele++;
                }

                if (sele >= 4)
                  sele = 1;

                return <div className="list" key={`keep-away-list-${index}`}>
                  <div className="list-inner">
                    <div className="profile">
                      {sele === 1 && <img className='background' src='/images/BG_Pattern/BLUE.png'/>}
                      {sele === 2 && <img className='background' src='/images/BG_Pattern/YELLOW.png'/>}
                      {sele === 3 && <img className='background' src='/images/BG_Pattern/RED.png'/>}
                      <div className="top">{e.groupId}</div>
                      <div className="bottom">
                        {e.smallBlind}-{e.bigBlind}
                      </div>
                    </div>
                    <div className={`detail${e.isTempItem ? ' two': ''}`}>
                      {
                        e.isTempItem ? (
                          <div>
                            <div className="val">미정산내역 처리대기 중입니다.</div>
                            <div className="grey">링게임 방의 플레이가 아직 종료되지 않았습니다.</div>
                            <div className="grey">추가 수익금이 있을 수 있어, 한 플레이가 완전히 종료 된 후 이용가능합니다.</div>
                          </div>
                        ) : (
                          <div>
                            <div>
                              <span>미정산 금액: </span>
                              <span className="val">{e.amount.toLocaleString()}</span>
                              {
                                winMoney !== 0 ? (
                                  <span className={winMoney > 0 ? "green" : 'red'}>({winMoney > 0 ? '+' : ''}{winMoney.toLocaleString()})</span>
                                ) : <></>
                              }
                            </div>
                            <div>
                              <span>바이인 금액: </span>
                              <span>{e.lastTotalBuyin.toLocaleString()}</span>
                            </div>
                            <div>
                              <span>핸드 카운트: </span>
                              <span>{e.lastHandsCount.toLocaleString()}</span>
                              {
                                lossPercentage > 0 ? (
                                  <span className="red">(수익금 차감 {lossPercentage}% 적용)</span>
                                ) : <></>
                              }
                            </div>
                            <div className="border">
                              <span>나의 수익금: </span>
                              <span>{winMoney.toLocaleString()}</span>
                            </div>
                            <div>
                              <span>수수료: </span>
                              <span>{lossAmount.toLocaleString()}</span>
                              {
                                winMoney > 0 ? (
                                  <span className="yellow">(= {winMoney.toLocaleString()} * {lossPercentage} / 100)</span>
                                ) : <></>
                              }
                            </div>
                            <div>
                              <span>정산금액: </span>
                              <span className="val">{(e.amount - lossAmount).toLocaleString()}</span>
                            </div>
                          </div>
                        )
                      }
                    </div>
                    {
                      orientation !== 'portrait' && !e.isTempItem && (
                        <div className="tail">
                          <div className="play" onClick={()=>{ handleClickJoin(e.groupId) }}>이어서 게임하기</div>
                          <div className="calcNow" onClick={()=>{ handleClickNowLeave(e.groupId) }}>정산하기</div>
                        </div>
                      )
                    }
                  </div>
                  {
                      orientation === 'portrait' && !e.isTempItem && (
                        <div className="tail">
                          <div className="play" onClick={()=>{ handleClickJoin(e.groupId) }}>이어서 게임하기</div>
                          <div className="calcNow" onClick={()=>{ handleClickNowLeave(e.groupId) }}>정산하기</div>
                        </div>
                      )
                    }
                </div>
              })
            }
          </div>
        )
      }
    </Content>
  </Wrapper>
}

export default KeepAwayManagerModal
