import styled from "styled-components";
import {HorizontalBar} from "../Trade";

const RNGCertificationWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: #151515;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > .content-wrapper {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;

    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
  }
`
const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  > .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    cursor: pointer;
  }

  > .title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`

interface RNGCertificationProps {
  onClose: () => void;
}

const RNGCertification = ({onClose}: RNGCertificationProps) => {
  return <RNGCertificationWrapper>
    <Header>
      <img className='close' src='/images/ic_arrow_back.png' onClick={() => onClose()}/>
      <div className='title'>RNG 인증서</div>
    </Header>
    <div className='content-wrapper'>
      <div className='content'>
        <div className='' style={{
          width: '100%',
          height: '500px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'gray'
        }}>
          인증서
        </div>
      </div>
    </div>
  </RNGCertificationWrapper>
}

export default RNGCertification;
