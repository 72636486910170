import React, {MouseEventHandler} from "react";
import styled from "styled-components";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import GameHistoryGaugeHorizontal from "./GameHistoryGaugeHorizontal";


const Wrapper = styled.div<{
  prev: boolean,
  next: boolean
}>`
  width: 100%;
  margin-top: 11px;

  @media ${MEDIA_DESKTOP} {
    margin-top: 0;
  }
  > .pageWrapper {
    width: 100%;
    display: flex;
    align-items: center;

    @media ${MEDIA_DESKTOP} {
      width: auto;
    }
  }
  > div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @media ${MEDIA_DESKTOP} {
      flex: initial;
      
      &.prev, &.next {
        padding: 12px;
      }
    }

    &.prev {
      ${p => !p.prev && `
        opacity: 0.5;
        pointer-events: none;
        cursor: initial;
      `}
    }

    &.next {
      ${p => !p.next && `
        opacity: 0.5;
        pointer-events: none;
        cursor: initial;
      `}
    }
  }

  .page-indicator {
    font-size: 14px;
    font-weight: 500;
    cursor: initial;
    
    @media ${MEDIA_DESKTOP} {
      font-size: 18px;
      font-weight: 500;
    }
    
    > span {
      &:first-of-type {
        margin-right: 2px;
      }

      &:last-of-type {
        color: #E31D1D;
        margin-left: 2px;
      }
    }
  }
`;

const SliderWrapper = styled.div`
  padding: 10px 16px 20px;
  padding-top: 0px;
  width: 100%;
  height: 78px;
  display: flex;
  z-index: 8;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .plus-minus-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      object-fit: contain;
    }
  }

  > .slider {
    height: 28px;
    width: 240px;
  }
`

function HistoryPagination(
  {
    current,
    total,
    onClickPrev,
    onClickNext,
    onClickLatest,
  }: {
    current: number;
    total: number;
    onClickPrev: () => void;
    onClickNext: () => void;
    onClickLatest: () => void;
  }
) {
  const orientation = useScreenOrientation();

  return <Wrapper prev={current > 1} next={total > current}>
    <div className='pageWrapper'>
      <div className="prev" onClick={onClickPrev}>
        <img src="/images/ic_result_prev.svg"/>
      </div>
      <div className="page-indicator" onClick={onClickLatest}>
        {
          orientation === 'portrait' ? <>
            <span>{current}</span>/<span>{total}</span>
          </> : <>
            <span>{current}</span>번째 게임
          </>
        }
      </div>
      <div className="next" onClick={onClickNext}>
        <img src="/images/ic_result_next.svg"/>
      </div>
    </div>
    {orientation === 'portrait' ? (
      <SliderWrapper>
        <div className='slider'>
          <GameHistoryGaugeHorizontal 
            min={1} 
            max={total} 
            value={current} 
            onChange={(e:number)=>{
              if (e > current) {
                onClickNext();
              }
              else if (e !== current) {
                onClickPrev()
              }
            }}
          />
        </div>
    </SliderWrapper>
    ) : <></>
    }
  </Wrapper>
}

export default HistoryPagination;
