import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Home from "../../Home";
import Holdem from "../../Holdem";
import Trade from "../../Trade";
import MyPage from "../../MyPage";
import {playSFX, Sounds} from "../../../utils/sound";
import ModalContainer from "../../../components/common/ModalContainer";
import LoginModal from "./LoginModal";
import SignUpModal from "./SignUpModal";
import FindIDModal from "./FindIDModal";
import FindPwModal from "./FindPwModal";
import {requestMyInfo, requestPopupNotice, useSocketIsConnect} from "../../../api";
import {MyInfo} from "../../../dataset";
import TradePointModal from "../../../components/TradePointModal";
import CustomerCenter from "../../CustomerCenter";
import useBGM from "../../../hooks/useBGM";
import moment from "moment";
import {unreadChatCount} from "../../../recoil/Chat";
import {unreadMemoCount} from "../../../recoil/Memo";
import {useRecoilState, useRecoilValue} from "recoil";
import {gameOptionState} from '../../../recoil/GameOption';

const PCMainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000;

  > .content {
    padding-top: 60px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    > .inner {
      width: 100%;
      max-width: 996px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }
`

const Menu = styled.div<{ selected: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 6px;
  ${p => p.selected ? `
  border-bottom: 4px solid #E31D1D;
  ` : `
  opacity: 0.5;
  border-bottom: 4px solid transparent;
  `}
  transition: all .3s;

  &:hover {
    opacity: 1;
    border-bottom: 4px solid #E31D1D;
  }

`

const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: var(--Background, #151515);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 10;

  > .inner {
    padding: 0 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1400px;
    height: 100%;

    > .logo-wrapper {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      > .logo {
        flex-shrink: 0;
        cursor: pointer;
        width: 137.5px;
      }

      > .wrapper {
        flex-grow: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding-right: 20px;
        @media (max-width: 1280px) {
          padding-right: 0;
        }
        > .menu-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 40px;
          @media (max-width: 1160px) {
            gap: 20px;
          }
        }
      }
    }

    > .button-wrapper {
      flex-shrink: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      >.mute-wrapper {
        width: 24px;
        height: 24px;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        cursor: pointer;
        margin-left: 8px;
        >img {
          width: 100%;
          height: 100%;
        }
      }
      >.nickname-print-wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;
        width: fit-content;
        height: fit-content;
        gap: 3px;
        >img {
          width: 24px;
          height: 24px;
        }
        >span {
          color: rgb(255, 255, 255);
          text-align: center;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 72px;
        }
      }
      > .money-point-wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
        width: fit-content;
        height: fit-content;
        gap: 12px;
        margin-left: 16px;
        > .money-wrapper {
          cursor: pointer;
          width: 148px;
          height: 32px;
          border-radius: 21px;
          border: 1px solid #FFF;
          padding-left: 4px;
          padding-right: 12px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          text-align: right;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.2px;
          @media (max-width: 1280px) {
            width: 110px;
            font-size: 12px;
          }

          > .money {
            color: #169E7A;
          }

          > .point {
            color: #FBD200;
          }

          > .icon {
            width: 24px;
            height: 24px;
          }
        }

        > .money-wrapper.point {
          cursor: pointer;
        }
      }

      > .white-button {
        cursor: pointer;
        display: flex;
        width: 120px;
        height: 32px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 21px;
        border: 1px solid #FFF;
      }

      > .login {
        color: #FFF;
        text-align: right;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.2px;
      }

      > .green-button {
        position: relative;
        cursor: pointer;
        width: 120px;
        height: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 21px;
        background: var(--Point-Mint, #169E7A);
        color: #FFF;
        leading-trim: both;
        text-edge: cap;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-left: 12px;
        @media (max-width: 1280px) {
          width: 90px;
        }
        > .badge {
          position: absolute;
          top: 5px;
          right: 12px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: rgb(227, 29, 29);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 8px;
        }
      }
    }
  }
`

const PopupNoticeWrapper = styled.div<{ index: number; hasLink: boolean }>`
  z-index: 9999;
  width: 460px;
  position: fixed;
  ${p => p.index === 0 ? `
  left: 16px;
  ` : ``};
  ${p => p.index === 1 ? `
  right: 16px;
  ` : ``};
  top: 100px;
  background: rgb(85, 75, 50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    > .close {
      margin: 4px;
      width: 24px;
      height: 24px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  > .content {
    ${p => p.hasLink ? `cursor: pointer;` : ``};
    width: 100%;
    object-fit: contain;
  }

  > .button {
    margin: 4px;
    cursor: pointer;
    width: 130px;
    padding: 4px;
    border-radius: 15px;
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    background: rgb(23, 12, 15);

  }

`

const PCMain = () => {
  const [conn] = useSocketIsConnect();
  const params: { type?: string } = useParams();
  const type = params?.type;
  const navigate = useNavigate()
  const [showCustomerCenter, setShowCustomerCenter] = useState<boolean>(false);
  const [myInfo, setMyInfo] = useState<MyInfo | null>(null);
  const [refresh, setRefresh] = useState<number>(new Date().getTime());
  const [showTradePointModal, setShowTradePointModal] = useState<boolean>(false);
  const [unreadChat, setUnreadChat] = useState<number>(0);
  const [userId, setUserId] = useState<number>(0);
  const [section, setSection] = useState<'home' | 'holdem' | 'trade' | 'mypage' | null>(null);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showFindID, setShowFindID] = useState(false);
  const [showFindPW, setShowFindPW] = useState(false);
  const [popupDataList, setPopupDataList] = useState<any[]>([]);
  const ChatCount = useRecoilValue(unreadChatCount);
  const MemoCount = useRecoilValue(unreadMemoCount);
  const [setting, setSetting] = useRecoilState(gameOptionState);
  const [showGuestCs, setShowGuestCs] = useState(false);

  useEffect(() => {
    requestPopupNotice().then((res: any) => {
      const key = Object.keys(res).filter(x => x !== 'code')
      const data = key.map(x => res[x])
      setPopupDataList(data)
    })
  }, []);

  useBGM(Sounds.BGM_WAIT, true)

  useEffect(() => {
    if (
      type && (type === 'home' || type === 'holdem' || type === 'trade' || type === 'mypage')
    ) {
      setSection(type)
    } else {
      setSection('home')
      navigate(`/main/home`)
    }
    window.scrollTo(0, 0)
    setShowCustomerCenter(false)
  }, [window.location.href])

  useEffect(() => {
    if (!conn.isLogin) return;
    requestMyInfo().then((res) => {
      setMyInfo(res.info);
      setUserId(res.info.userId)
    })
  }, [refresh, window.location.href, conn.isLogin]);

  useEffect(() => {
    setUnreadChat(ChatCount);
  }, [ChatCount]);

  return <PCMainWrapper>
    {
      popupDataList.filter((popupdata) => {
        const popupNotice = window.localStorage.getItem('popupNotice');
        const setArr = popupNotice ? JSON.parse(popupNotice) : [];
        const isExist = setArr.find((x: any) => x.popupId === popupdata.popupId)
        if (isExist) {
          //expired check
          if (moment().isAfter(isExist.expire)) {
            return true
          }else{
            return false
          }
        }else{
          return true
        }

      }).map((popupData, index) => {
        return <PopupNoticeWrapper hasLink={popupData.link !== null && popupData.link !== undefined} key={index} index={index}>
          <div className='row'>
            <img className='close' src='/images/ic_close.png' onClick={() => {
              setPopupDataList(popupDataList.filter((_, i) => i !== index))
            }}/>
          </div>
          <img className='content' src={popupData.pcImg} onClick={() => {
            if(popupData.link){
              window.open(popupData.link, '_blank')
            }
          }}/>
          <div className='button' onClick={() => {
            const currentSet = window.localStorage.getItem('popupNotice')
            let setArr = currentSet ? JSON.parse(currentSet) : [];
            const prevIdx = setArr.findIndex((x: any) => x.popupId === popupData.popupId)
            if (prevIdx > -1) {
              setArr[prevIdx].expire = moment().utc().add(1, 'days').toISOString()
            } else {
              setArr.push({
                popupId: popupData.popupId,
                expire: moment().utc().add(1, 'days').toISOString()
              })
            }
            window.localStorage.setItem('popupNotice', JSON.stringify(setArr))
            setPopupDataList(popupDataList.filter((_, i) => i !== index))
          }}>
            오늘 하루 열지 않음
          </div>
        </PopupNoticeWrapper>
      })
    }
    {
      showCustomerCenter && <CustomerCenter onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        setShowCustomerCenter(false);
        setRefresh(new Date().getTime())
      }}/>
    }
    <ModalContainer align={'top'} show={showSignUp}>
      <SignUpModal
        onClose={() => setShowSignUp(false)}
      />
    </ModalContainer>
    <ModalContainer align={'top'} show={showFindID}>
      <FindIDModal
        onFindPW={() => setShowFindPW(true)}
        onClose={() => setShowFindID(false)}
      />
    </ModalContainer>
    <ModalContainer align={'top'} show={showFindPW}>
      <FindPwModal
        onClose={() => setShowFindPW(false)}
      />
    </ModalContainer>
    <ModalContainer align={'top'} show={showLoginModal}>
      <LoginModal
        onClose={() => setShowLoginModal(false)}
        openSignUp={() => setShowSignUp(true)}
        openFindID={() => setShowFindID(true)}
        openFindPW={() => setShowFindPW(true)}
      />
    </ModalContainer>
    <Header>
      <div className='inner'>
        <div className='logo-wrapper'>
          <img onClick={() => {
            playSFX(Sounds.SFX_CLICK, true)
            setSection('home')
            navigate(`/main/home`)
          }} className='logo' src='/images/logo1.png' alt='logo'/>
          <div className='wrapper'>
            <div className='menu-wrapper'>
              <Menu
                onClick={() => {
                  playSFX(Sounds.SFX_CLICK, true)
                  setSection('home')
                  navigate(`/main/home`)
                }}
                selected={
                  section === 'home'
                }>
                HOME
              </Menu>
              <Menu onClick={() => {
                playSFX(Sounds.SFX_CLICK, true)
                setSection('holdem')
                navigate(`/main/holdem`)
              }} selected={
                section === 'holdem'
              }>
                HOLD’EM
              </Menu>
              <Menu
                onClick={() => {
                  if (!conn.isLogin) {
                    setShowLoginModal(true)
                  } else {
                    playSFX(Sounds.SFX_CLICK, true)
                    setSection('trade')
                    navigate(`/main/trade`)
                  }
                }}
                selected={
                  section === 'trade'
                }>
                RECHARGE / EXCHANGE
              </Menu>
              <Menu
                onClick={() => {
                  if (!conn.isLogin) {
                    setShowLoginModal(true)
                  } else {
                    playSFX(Sounds.SFX_CLICK, true)
                    setSection('mypage')
                    navigate(`/main/mypage`)
                  }
                }}
                selected={
                  section === 'mypage'
                }>
                INFORMATION
              </Menu>
            </div>
          </div>
        </div>
        <div className='button-wrapper'>
          
          {
            conn.isLogin ? <>
              <div className='nickname-print-wrapper' onClick={() => {
                playSFX(Sounds.SFX_CLICK, true)
                setSection('mypage')
                navigate(`/main/mypage`)
              }}>
                <img src='/images/ic_user2.png'/>
                <span>{myInfo && myInfo.nickname}</span>
              </div>
              <div className='money-point-wrapper'>
                <div className='money-wrapper' onClick={() => {
                  setRefresh(new Date().getTime());
                  playSFX(Sounds.SFX_CLICK, true)
                  setSection('trade')
                  navigate(`/main/trade`);
                }}>
                  <img className='icon' src='/images/ic_money.png'/>
                  <div className='money'>{myInfo ? myInfo.money.toLocaleString() : 'loading...'}</div>
                </div>
                <div className='money-wrapper point' onClick={() => {
                  playSFX(Sounds.SFX_WINDOW_OPEN, true);
                  setShowTradePointModal(true)
                }}>
                  <img className='icon' src='/images/ic_point.png'/>
                  <div
                    className='point'>{myInfo ? (myInfo.point < 0 ? 0 : myInfo.point).toLocaleString() : 'loading...'}</div>
                </div>
              </div>
              <div className='green-button' onClick={() => {
                playSFX(Sounds.SFX_WINDOW_OPEN, true);
                navigate('/main/mypage/cs');
              }}>
                고객센터
                {ChatCount ? (<div className='badge'>{ChatCount}</div>) : <></>}
              </div>
              <div className='green-button' style={{width: '70px'}} onClick={() => {
                playSFX(Sounds.SFX_WINDOW_OPEN, true);
                navigate('/main/mypage/memo');
              }}>
                쪽지
                {MemoCount ? (<div className='badge' style={{right: '3px'}}>{MemoCount}</div>) : <></>}
              </div>
            </> : <>
              <div className='white-button login' onClick={() => setShowLoginModal(true)}>
                LOG IN
              </div>
              <div className='green-button' onClick={() => setShowSignUp(true)}>
                SIGN UP
              </div>
              <div className='green-button' onClick={() => setShowGuestCs(true)}>
                비회원 고객센터
              </div>
            </>
          }
          <div className='mute-wrapper' onClick={()=> {
            const isMuteded = setting.backgroundMusic.muted && setting.effectSound.muted;

            setSetting({
              ...setting,
              effectSound: {
                ...setting.effectSound,
                muted: !isMuteded
              },
              backgroundMusic: {
                ...setting.backgroundMusic,
                muted: !isMuteded
              }
            });
          }}
          >
            <img alt='mute' src={`/images/Icon/mute1${setting.backgroundMusic.muted && setting.effectSound.muted ? 'on' : 'off'}.png`}/>
          </div>
        </div>

      </div>
    </Header>
    <div className='content'>
      {
        section === 'home' && <Home/>
      }
      {
        section === 'holdem' && <div className='inner'>
          <Holdem showLogin={() => {
            setShowLoginModal(true)
          }}/>
        </div>
      }
      {
        section === 'trade' && <Trade showCustomerCenter={() => setShowCustomerCenter(true)}/>
      }
      {
        section === 'mypage' && <MyPage/>
      }
    </div>
    <ModalContainer show={showTradePointModal}>
      <TradePointModal onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        setShowTradePointModal(false)
        setRefresh(new Date().getTime())
      }}/>
    </ModalContainer>
    {showGuestCs && <CustomerCenter onClose={() => {
      setShowGuestCs(false);
    }} />}
  </PCMainWrapper>
}

export default PCMain;
