import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {requestLogin, requestPopupNotice, useSocketIsConnect} from "../api";
import {useNavigate} from "react-router-dom";
import useDialog from "../hooks/useDialog";
import {startLoading, stopLoading} from "../components/Loading";
import {sha256} from "js-sha256";
import FindID from "./FindID";
import FindPW from "./FindPW";
import Signup from "./Signup";
import useScreenOrientation, {MEDIA_MOBILE_LANDSCAPE} from "../hooks/useScreenOrientation2";
import moment from "moment/moment";
import CustomerCenter from "./CustomerCenter";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background: #151515;
  overflow-y: scroll;
  > .logo {
    margin-top: 80px;
    width: 340px;
    object-fit: contain;
    z-index: 2;
  }

  > .login-wrapper {
    width: 100%;
    max-width: 306px;
    padding: 0;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > .login-box {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }

    > .lost-and-found {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      margin-top: 16px;
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 12px */
      opacity: 0.5;
      gap: 7px;

      > .button {
        cursor: pointer;
      }
    }
  }
`
const InputWrapper = styled.div`
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  background: #1A1A1A;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > input {
    flex-grow: 1;
    color: #FFF;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    border: none;
    background: none;
    text-align: left;
    outline: none;

    &::placeholder {
      opacity: 0.5;
    }
  }

  > img {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
`
const LoginButton = styled.div`
  cursor: pointer;
  margin-top: 24px;
  width: 100%;
  max-width: 300px;
  height: 48px;
  color: #FFF;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
`
const HelpButton = styled.div`
  position: absolute;
  bottom: 32px;
  right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;

  > span {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 12px */
  }

  > img {
    width: 20px;
    height: 20px;
  }
`
const SignUpButton = styled.div`
  cursor: pointer;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`
const GuestButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid #4c4c4c;
  width: 200px;
  font-size: 13px;
  height: 40px;
  background: #1e1e1e;
  margin-top: 10px;
  img {
    width: 26px;
    height: 26px;
    margin-right: 6px;
  }
`


const PopupNoticeWrapper = styled.div<{  hasLink: boolean }>`
  z-index: 10;
  width: 300px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(85, 75, 50);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    top: 0;
    transform: translateX(-50%);
    height: 100%
  }

  > .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    > .close {
      margin: 4px;
      width: 24px;
      height: 24px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  > .content {
    ${p => p.hasLink ? `cursor: pointer;` : ``};
    width: 100%;
    object-fit: contain;

    @media ${MEDIA_MOBILE_LANDSCAPE} {
      object-fit: initial;
      height: calc(100% - 54px);
    }
  }

  > .button {
    margin: 4px;
    cursor: pointer;
    width: 130px;
    padding: 4px;
    border-radius: 15px;
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    background: rgb(23, 12, 15);
    @media ${MEDIA_MOBILE_LANDSCAPE} {
      height: 30px;
    }
  }

`
const Welcome = () => {
  const [conn] = useSocketIsConnect();
  const navigate = useNavigate();
  const {openDialog} = useDialog();
  const [showSignUp, setShowSignUp] = useState(false);
  const [showFindID, setShowFindID] = useState(false);
  const [showFindPW, setShowFindPW] = useState(false);
  const [showGuestCs, setShowGuestCs] = useState(false);
  const {orientation, device} = useScreenOrientation();
  const [popupDataList, setPopupDataList] = useState<any[]>([]);

  useEffect(() => {
    requestPopupNotice().then((res: any) => {
      const key = Object.keys(res).filter(x => x !== 'code')
      const data = key.map(x => res[x])
      setPopupDataList(data)
    })
  }, []);

  useEffect(() => {
    //만약 PC면 무조건 main으로 이동
    if(orientation === 'landscape' && device === 'pc'){
      navigate("/main");
    }
  }, [orientation, device]);

  useEffect(() => {
    //로그인 되어있는 상태면 Welcome이 아니라, Main으로 이동
    if (conn.isLogin) {
      navigate("/main");
    }
  }, [conn])
  const idRef = useRef<HTMLInputElement>(null);
  const pwRef = useRef<HTMLInputElement>(null);
  const handleLogin = () => {
    const ID = idRef.current?.value;
    const PW = pwRef.current?.value;
    if (!ID || !PW) {
      openDialog({
        title: '로그인 실패',
        text: '아이디와 비밀번호를 입력해주세요.',
      })
      return;
    }
    const loading = startLoading();
    requestLogin(ID, sha256(PW))
      .then((res) => {
        stopLoading(loading);
        if (res.login_token) {
          setTimeout(() => {
            navigate('/main');
          }, 200)
        } else {
          const innerRes = (res as any)
          if (innerRes.message) {
            if (innerRes.message === 'WAITING CONFIRM') {
              openDialog({
                title: '로그인 실패',
                text: '가입 승인 대기중입니다.',
              })
            } else if (innerRes.message === 'REJECT JOIN') {
              openDialog({
                title: '로그인 실패',
                text: '가입이 거절되었습니다.',
              })
            } else if (innerRes.message === 'BANNED USER') {
              openDialog({
                title: '로그인 실패',
                text: '접속이 거절되었습니다.',
              })
            } else if(innerRes.message.length > 0) {
              openDialog({
                title: '로그인 실패',
                text: innerRes.message,
              })
            } else {
              openDialog({
                title: '로그인 실패',
                text: '아이디와 비밀번호를 확인해주세요.',
              })
            }
          } else {
            openDialog({
              title: '로그인 실패',
              text: '아이디와 비밀번호를 확인해주세요.',
            })
          }
        }
      })
      .catch((err) => {
        stopLoading(loading);
        console.log(err);
      }).finally(() => {

    })
  }

  return (orientation === 'landscape' && device === 'pc') ? <></> : <Wrapper>
    {
      popupDataList.filter((popupdata) => {
        const popupNotice = window.localStorage.getItem('popupNotice');
        const setArr = popupNotice ? JSON.parse(popupNotice) : [];
        const isExist = setArr.find((x: any) => x.popupId === popupdata.popupId)
        if (isExist) {
          //expired check
          if (moment().isAfter(isExist.expire)) {
            return true
          }else{
            return false
          }
        }else{
          return true
        }

      }).map((popupData, index) => {
        return <PopupNoticeWrapper hasLink={popupData.link !== null && popupData.link !== undefined} key={index}>
          <div className='row'>
            <img className='close' src='/images/ic_close.png' onClick={() => {
              setPopupDataList(popupDataList.filter((_, i) => i !== index))
            }}/>
          </div>
          <img className='content' src={popupData.pcImg} onClick={() => {
            if(popupData.link){
              window.open(popupData.link, '_blank')
            }
          }}/>
          <div className='button' onClick={() => {
            const currentSet = window.localStorage.getItem('popupNotice')
            let setArr = currentSet ? JSON.parse(currentSet) : [];
            const prevIdx = setArr.findIndex((x: any) => x.popupId === popupData.popupId)
            if (prevIdx > -1) {
              setArr[prevIdx].expire = moment().utc().add(1, 'days').toISOString()
            } else {
              setArr.push({
                popupId: popupData.popupId,
                expire: moment().utc().add(1, 'days').toISOString()
              })
            }
            window.localStorage.setItem('popupNotice', JSON.stringify(setArr))
            setPopupDataList(popupDataList.filter((_, i) => i !== index))
          }}>
            오늘 하루 열지 않음
          </div>
        </PopupNoticeWrapper>
      })
    }
    <LoadingWrapper>
      {
        showSignUp && <Signup onClose={() => setShowSignUp(false)}/>
      }
      {
        showFindID && <FindID onFindPW={() => {
          setShowFindID(false)
          setShowFindPW(true)
        }} onClose={() => setShowFindID(false)}/>
      }
      {
        showFindPW && <FindPW onClose={() => setShowFindPW(false)}/>
      }
      <img className='logo' src='/images/logo1.png'/>
      <div className='login-wrapper'>
        <div className='login-box'>
          <InputWrapper>
            <input ref={idRef} placeholder='아이디' onChange={e => {
              e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
            }}/>
            <img src='/images/ID.png'/>
          </InputWrapper>
          <InputWrapper>
            <input type='password' ref={pwRef} placeholder='비밀번호'/>
            <img src='/images/PW.png'/>
          </InputWrapper>
        </div>
        <div className='lost-and-found'>
          <div className='button' onClick={() => setShowFindID(true)}>아이디 찾기</div>
          <div>|</div>
          <div className='button' onClick={() => setShowFindPW(true)}>비밀번호 찾기</div>
        </div>
        <LoginButton onClick={handleLogin}>로그인</LoginButton>
        <SignUpButton onClick={() => setShowSignUp(true)}>
          회원가입
        </SignUpButton>
        <GuestButton onClick={() => setShowGuestCs(!showGuestCs)}>
          <img src="/images/guest.png" alt="비회원"/>
          <span>비회원 고객센터</span>
        </GuestButton>
        <HelpButton  onClick={()=>{
          window.open('https://t.me/deeppoker0', '_blank')
        }}>
          <span>문의사항이 있으신가요?</span>
          <img src='/images/ic_telegram.png'/>
        </HelpButton>
      </div>
    </LoadingWrapper>

    {showGuestCs && <CustomerCenter onClose={() => {
      setShowGuestCs(false);
    }} />}
  </Wrapper>
}

export default Welcome;
