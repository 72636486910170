import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

interface PopupWindowProps {
  children: any;
  closeWindowPortal: any;
  windowInstance: any;
  title: any;
}

const PopupWindow = ({children, closeWindowPortal, windowInstance, title}: PopupWindowProps) => {
  const externalWindow = useRef(windowInstance);
  const containerEl = document.createElement('div');

  function sendResizeMessage() {
    const { innerWidth, innerHeight } = window;
    if (window.opener) {
      window.opener.postMessage({ type: 'RESIZE', width: innerWidth, height: innerHeight }, '*');
    }
  }

  useEffect(() => {
    const currentWindow = externalWindow.current;

    window.addEventListener('load', sendResizeMessage);
    window.addEventListener('resize', sendResizeMessage);
    
    return () => {
      currentWindow.close();
      window.removeEventListener('load', sendResizeMessage);
      window.removeEventListener('resize', sendResizeMessage);
    }
  }, []);

  externalWindow.current.document.title = title ? title : '입금 계좌 문의';
  let meta = document.createElement('meta');
  meta.content = 'width=device-width, initial-scale=1';
  meta.name = 'viewport';
  externalWindow.current.document.head.appendChild(meta);
  externalWindow.current.document.body.appendChild(containerEl);

  externalWindow.current.addEventListener('beforeunload', () => {
    closeWindowPortal();
  });

  return ReactDOM.createPortal(children, containerEl);
};

export default PopupWindow;
