import {atom} from "recoil";

export interface GameOptionProps {
  cardStyle: 1 | 2 | 3
  alwaysBB: boolean
  effectSound:{
    volume: number,
    muted: boolean
  },
  backgroundMusic:{
    volume: number,
    muted: boolean
  },
  voiceEffect: boolean,
  tableStyle_LV1: 1 | 2 | 3,
  tableStyle_LV2: 1 | 2 | 3
  tableStyle_LV3: 1 | 2 | 3
}

const localStorageEffect =
  (key: string) =>
    ({ setSelf, onSet }: any) => {
      const savedValue = localStorage.getItem(key);
      // setSelf -> Callbacks to set or reset the value of the atom.
      if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
      }

      // onSet -> Subscribe to changes in the atom value.
      onSet((newValue: any, _: any, isReset: boolean) => {
        console.log('newValue');
        console.log(newValue);
        console.log(isReset);
        
        isReset
          ? localStorage.removeItem(key)
          : localStorage.setItem(key, JSON.stringify(newValue));
      });
    };

export const gameOptionState = atom<GameOptionProps>({
  key: 'gameOptionState',
  default: {
    cardStyle: 1,
    alwaysBB: false,
    effectSound:{
      volume: 100,
      muted: false
    },
    backgroundMusic:{
      volume: 100,
      muted: false
    },
    voiceEffect: false,
    tableStyle_LV1: 1,
    tableStyle_LV2: 2,
    tableStyle_LV3: 3
  },
  effects: [localStorageEffect('gameOptionState')],
});
