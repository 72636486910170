import styled from "styled-components";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  background: #181818;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  @media ${MEDIA_DESKTOP} {
    width: 600px;
    background: white;
  }
`;

const BorderRect = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 1px solid var(--Point-Red, #E31D1D);
  background: transparent;
  box-shadow: 0px 0px 16px 2px #E31D1D inset;
  @media ${MEDIA_DESKTOP}{
    display: none;
  }
`

const Title = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 52px;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.2px;
  background: rgba(227, 29, 29, 0.3);
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset;
  @media ${MEDIA_DESKTOP}{
    color: var(--Black, #181818);
    text-align: center;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
    background: #F5F5F5;
    border-bottom: 1px solid #DDD;
  }
  >.pc {
    display: none;
    @media ${MEDIA_DESKTOP}{
      display: block;
    }
  }
  >.mo {
    display: block;
    @media ${MEDIA_DESKTOP}{
      display: none;
    }
  }
  > .close-wrapper {
    z-index: 8;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;

    > .background {
      width: 32px;
      height: 32px;
      padding-top: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      object-fit: contain;
      transform: translate(-50%, -50%);
    }

    > .close {
      width: 24px;
      height: 24px;
      object-fit: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0;
  
  >.contentWrapper {
    color: #e5e5e5;
    width: 100%;
    font-size: 12px;
    overflow-y: scroll;
    max-height: 500px;
    @media ${MEDIA_DESKTOP} {
      color: #000;
      font-size: 14px;
    }
    >.border {
      border-top: 1px solid #434343;
      padding-top: 8px;
      margin-top: 8px;
      @media ${MEDIA_DESKTOP} {
        border-top: 1px solid #a1a1a1;
      }
    }
    & .mt20 {
      margin-top: 20px;
    }
    & img {
      width: 100%;
      height: 100%;
    }
  }
`

interface KeepAwayManagerInformModalProps {
  onClose: () => void;
}

function KeepAwayManagerInformModal(
  {
    onClose
  }: KeepAwayManagerInformModalProps
) {
  return <Wrapper>
    <BorderRect/>
    <Title>
      자리비움 안내
      <div className='close-wrapper mo'>
        <img className='background' src='/images/close-bg.png'/>
        <img className='close' src='/images/Close.png' onClick={onClose}/>
      </div>
      <div className='close-wrapper pc'>
        <img src='/images/pc/Close.png' className='close' onClick={onClose}/>
      </div>
    </Title>
    <Content>
      <div className="contentWrapper">
        <div>
          <div>미정산 내역은 <strong>홀덤 대기실</strong>, <strong>충환전 페이지</strong>에서 조회가 가능합니다.</div>
          <div className="mt20">
            <img src="/images/nes/inform1.png"/>
          </div>
          <div>
            <img src="/images/nes/inform2.png"/>
          </div>
        </div>
      </div>
    </Content>
  </Wrapper>
}

export default KeepAwayManagerInformModal;
