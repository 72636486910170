import styled from "styled-components";
import React, {useEffect, useMemo, useState} from "react";
import {CARD_INFO, handHistoryModel} from "../../dataset";
import {requestGetHandHistory} from "../../api";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import {toEasternNumeral} from "../../utils/common";
// @ts-ignore
import {Hand} from "pokersolver";
import {reverseCardInfo} from "../../utils/client_common";
import {MEDIA_MOBILE_LANDSCAPE} from "../../hooks/useScreenOrientation";

const HandDescModalWrapper = styled.div`
  width: 310px;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  background: #181818;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  max-height: 480px;
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    width: 80%;
    min-width: initial;
    min-height: initial;
    overflow-y: scroll;
    margin: 0px auto;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    border-radius: 12px;
    border: 1px solid var(--Point-Red, #E31D1D);
  }
`
const BorderRect = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 1px solid var(--Point-Red, #E31D1D);
  background: transparent;
  box-shadow: 0px 0px 16px 2px #E31D1D inset;
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    display: none;
  }
`

const Title = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 52px;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.2px;
  background: rgba(227, 29, 29, 0.3);
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset;

  > .close-wrapper {
    z-index: 8;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;

    > .background {
      width: 32px;
      height: 32px;
      padding-top: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      object-fit: contain;
      transform: translate(-50%, -50%);
    }

    > .close {
      width: 24px;
      height: 24px;
      object-fit: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const CommunityCardsWrapper = styled.div`
  flex-shrink: 0;
  width: 100%;
  height: 60px;
  background: linear-gradient(0deg, #25262D 0%, rgba(37, 38, 45, 0.00) 100%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 36px;

  > .title {
    width: 50px;
    text-align: left;
    color: #FFF;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }

  > .value {
    margin-left: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 2px;

    > .item {
      width: 20px;
      height: 28px;
      object-fit: contain;
    }
  }
`
const ResultWrapper = styled.div`
  width: 100%;
  height: 400px;
  overflow-y: scroll;
  z-index: 0;
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    height: initial;
    overflow-y: scroll;
  }
  > .inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    > .item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      padding: 8px 18px;
      height: 60px;
      position: relative;

      > .cards {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 2px;

        > .card {
          width: 25px;
          height: 35px;
        }
      }

      > .desc {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 4px;

        > .title {
          color: #FFBE43;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.2px;
        }

        > .value {
          color: #FFF;
          font-family: Pretendard;
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.2px;
        }
      }


      > .bottom-line {
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 1px;
        background: linear-gradient(90deg, rgba(37, 38, 45, 0.00) 0%, #969696 49.5%, rgba(37, 38, 45, 0.00) 100%);
      }
    }
  }
`

interface GameHistoryModalProps {
  onClose: () => void;
}


const cardData = [
  {
    cards: [
      {
        card: 'Th',
        opacity: 1
      },
      {
        card: 'Jh',
        opacity: 1
      },
      {
        card: 'Qh',
        opacity: 1
      },
      {
        card: 'Kh',
        opacity: 1
      },
      {
        card: 'Ah',
        opacity: 1
      }
    ],
    title: '1.  ROYAL FLUSH',
    value: '10, J, Q, K, A 가 같은 모양 '
  },
  {
    cards: [
      {
        card: '5h',
        opacity: 1
      },
      {
        card: '6h',
        opacity: 1
      },
      {
        card: '7h',
        opacity: 1
      },
      {
        card: '8h',
        opacity: 1
      },
      {
        card: '9h',
        opacity: 1
      }
    ],
    title: '2. STRAIGHT FLUSH',
    value: '연속되는 숫자 5장이 같은 모양'
  },
  {
    cards: [
      {
        card: 'Ac',
        opacity: 1
      },
      {
        card: 'Ah',
        opacity: 1
      },
      {
        card: 'As',
        opacity: 1
      },
      {
        card: 'Ad',
        opacity: 1
      },
      {
        card: 'Kc',
        opacity: 0.2
      }
    ],
    title: '3. FOUR OF A KIND',
    value: '같은 숫자 4장'
  },
  {
    cards: [
      {
        card: 'Ac',
        opacity: 1
      },
      {
        card: 'Ah',
        opacity: 1
      },
      {
        card: 'As',
        opacity: 1
      },
      {
        card: 'Kd',
        opacity: 1
      },
      {
        card: 'Kc',
        opacity: 1
      }
    ],
    title: '4. FULL HOUSE',
    value: '같은 숫자 3장 + 같은 숫자 2장'
  },
  {
    cards: [
      {
        card: 'Ts',
        opacity: 1
      },
      {
        card: '6s',
        opacity: 1
      },
      {
        card: '8s',
        opacity: 1
      },
      {
        card: '3s',
        opacity: 1
      },
      {
        card: 'Qs',
        opacity: 1
      }
    ],
    title: '5. FLUSH',
    value: '같은 모양 5장'
  },
  {
    cards: [
      {
        card: 'Ac',
        opacity: 1
      },
      {
        card: '2h',
        opacity: 1
      },
      {
        card: '3s',
        opacity: 1
      },
      {
        card: '4d',
        opacity: 1
      },
      {
        card: '5c',
        opacity: 1
      }
    ],
    title: '6. STRAIGHT',
    value: '연속되는 숫자 5장'
  },
  {
    cards: [
{
        card: 'Ac',
        opacity: 1
      },
      {
        card: 'Ah',
        opacity: 1
      },
      {
        card: 'As',
        opacity: 1
      },
      {
        card: 'Kd',
        opacity: 0.2
      },
      {
        card: 'Qc',
        opacity: 0.2
      }
    ],
    title: '7. THREE OF A KIND',
    value: '같은 숫자 3장'
  },
  {
    cards: [
      {
        card: 'Ac',
        opacity: 1
      },
      {
        card: 'Ah',
        opacity: 1
      },
      {
        card: 'Ks',
        opacity: 1
      },
      {
        card: 'Kd',
        opacity: 1
      },
      {
        card: 'Qc',
        opacity: 0.2
      }
    ],
    title: '8. TWO PAIR',
    value: '같은 숫자 2장 + 같은 숫자 2장'
  },
  {
    cards: [
      {
        card: 'Ac',
        opacity: 1
      },
      {
        card: 'Ah',
        opacity: 1
      },
      {
        card: 'Ks',
        opacity: 0.2
      },
      {
        card: 'Qd',
        opacity: 0.2
      },
      {
        card: 'Jc',
        opacity: 0.2
      }

    ],
    title: '9. PAIR',
    value: '같은 숫자 2장'
  },
  {
    cards: [
      {
        card: 'Ac',
        opacity: 1
      },
      {
        card: 'Kh',
        opacity: 0.2
      },
      {
        card: 'Qs',
        opacity: 0.2
      },
      {
        card: 'Jd',
        opacity: 0.2
      },
      {
        card: '9c',
        opacity: 0.2
      }
    ],
    title: '10. HIGH CARD',
    value: '같은 숫자없이, 높은 카드'
  },
]

const GameHistoryModal = ({
                            onClose
                          }: GameHistoryModalProps) => {
  const gameOption = useRecoilValue(gameOptionState);


  return <HandDescModalWrapper>
    <BorderRect/>
    <Title>핸드 랭킹
      <div className='close-wrapper'>
        <img className='background' src='/images/close-bg.png'/>
        <img className='close' src='/images/Close.png' onClick={onClose}/>
      </div>
    </Title>

    <ResultWrapper>
      <div className='inner'>
        {
          cardData.map((_, i) => {
            return <div className='item' key={i}>
              <div className='cards'>
                {
                  _.cards.map((card, index) => {
                    return <img key={index} className='card' style={{
                      opacity: card.opacity
                    }} src={`/images/card/style-${gameOption.cardStyle}/${reverseCardInfo(card.card)}.svg`}/>
                  })
                }
              </div>
              <div className='desc'>
                <div className='title'>
                  {_.title}
                </div>
                <div className='value'>
                  {_.value}
                </div>
              </div>
              <div className='bottom-line'/>
            </div>
          })
        }
      </div>
    </ResultWrapper>

  </HandDescModalWrapper>
}

export default GameHistoryModal;
