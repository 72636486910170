import styled from "styled-components";
import PCMain from "./PC";
import MobileMain from "./Mobile";
import useScreenOrientation from "../../hooks/useScreenOrientation2";

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
`

const Main = () => {
  const { orientation, device } = useScreenOrientation();
  return <MainWrapper>
    {
      (orientation === 'landscape' && device === 'pc') ? <PCMain/> : <MobileMain/>
    }
  </MainWrapper>
}

export default Main;
