import React, {useEffect, useRef, useState} from 'react';
import {client, requestMyInfo, requestUnreadMemoCount, useSocketIsConnect} from "../api";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {unreadChatCount} from "../recoil/Chat";
import {unreadMemoCount} from "../recoil/Memo";
import {myInfoState} from "../recoil/MyInfo";
import {useLocation} from 'react-router-dom';

import {
  setAckListener as updateChatListener
} from "../api/from_server_ping_updateChat"

import {
  setAckListener as clearUnreadChatCountListener
} from "../api/from_server_ping_clearUnreadChatCount"

const UnreadChatChecker: React.FC = () => {
  const [conn] = useSocketIsConnect();
  const location = useLocation();
  const myProfileInfo = useRecoilValue(myInfoState);
  const [ChatCount, setChatCount] = useRecoilState(unreadChatCount);
  const [MemoCount, setMemoCount] = useRecoilState(unreadMemoCount);
  const prevPathnameRef = useRef('');

  useEffect(() => {
    if (!conn.isLogin) return;
    if (myProfileInfo && myProfileInfo.userId) {
      updateChatListener((data:{ userId: number; newId: number; })=>{
        const newCount = ChatCount === 0 ? 1 : ChatCount + 1;
        setChatCount(newCount);
      });

      clearUnreadChatCountListener((data:{ count: number })=>{
        setChatCount(0);
      });
    }

    return ()=>{
      updateChatListener(null);
      clearUnreadChatCountListener(null);
    }
  }, [window.location.href, myProfileInfo, conn.isLogin, ChatCount, setChatCount]);

  useEffect(() => {
    if (!conn.isLogin) return;

    requestMyInfo().then((res) => {
      if (res.info.unreadChat) {
        setChatCount(res.info.unreadChat);
      }

      requestUnreadMemoCount().then((res) => {
        if (res.count || res.count === 0) {
          setMemoCount(res.count);
        }
      });
    })
  }, [conn.isLogin]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!conn.isLogin) return;
      if (myProfileInfo && myProfileInfo.userId) {
        requestUnreadMemoCount().then((res) => {
          if (res.count || res.count === 0) {
            setMemoCount(res.count);
          }
        });
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const prePathname = prevPathnameRef.current !== '' ? prevPathnameRef.current.substr(0,5) : '';

    if (prePathname === '/game') {
      if (!conn.isLogin) return;
      if (myProfileInfo && myProfileInfo.userId) {
        requestUnreadMemoCount().then((res) => {
          if (res.count || res.count === 0) {
            setMemoCount(res.count);
          }
        });
      }
    }

    prevPathnameRef.current = location.pathname;
  }, [location.pathname]);

  return <></>;
};

export default UnreadChatChecker;