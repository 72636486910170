import React from "react";
import styled from "styled-components";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";

const Wrapper = styled.div`
  color: #2F99FB;
  font-size: 12px;
  font-weight: 500;

  > img {
    margin-right: 2px;
  }

  @media ${MEDIA_DESKTOP} {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    
    > img {
      width: 16px;
      margin-right: 4px;
    }
  }
`;

function PlayerCount({count, max}: {count: number, max?: number}) {
  return <Wrapper className="player-count">
    <img src="/images/ic_game_blind_players.svg"/>
    {count}{max ? `/${max}` : ''}
  </Wrapper>;
}

export default PlayerCount;