import {useRecoilState, useSetRecoilState} from "recoil";
import {myInfoState} from "./recoil/MyInfo";
import {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {client, requestMyInfo, requestRegisterOrJoinTournament, USER_TOKEN, useSocketIsConnect} from "./api";
import useDialog from "./hooks/useDialog";
import ModalContainer from "./components/common/ModalContainer";
import Dialog from "./components/Dialog";

import {setAckListener as setEndOfTournamentListener} from "./api/from_server_game_endOfTournament";
import {setAckListener as setMoveRoomClientListener} from "./api/from_server_game_moveRoomClient";
import {
  setAckListener as setMoveRoomWithTableBalancingListener
} from "./api/from_server_game_moveRoomWithTableBalancing";
import useQueryParams from "./hooks/useQueryParams";
import {DialogProps} from "./recoil/Dialog";
import {globalLoadingState} from "./recoil/Loading";
import Loading from "./components/common/Loading";
import {useSnackbar} from "notistack";
import {initSounds, playSFX, Sounds} from "./utils/sound";
import styled from "styled-components";
import { setAckListener as setUserMoneyUpdateListener } from "./api/from_server_ping_userMoneyUpdate";
import useScreenOrientation, {MEDIA_MOBILE_LANDSCAPE, MEDIA_DESKTOP} from "./hooks/useScreenOrientation2";

const ContainerWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background:#1C1E29;
  >.inner {
    width: 100%;
    max-width: 480px;
    @media ${MEDIA_DESKTOP} {
      max-width: 100%;
    }
    @media ${MEDIA_MOBILE_LANDSCAPE} {
      max-width: 100%;
    }
    height: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

function Container({children}: {
  children: any
}) {
  const [conn] = useSocketIsConnect();
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState<boolean>(false);
  const location = useLocation();
  const queryParams = useQueryParams();
  const {enqueueSnackbar} = useSnackbar();
  const {orientation, device} = useScreenOrientation();

  const [myInfo, setMyInfo] = useRecoilState(myInfoState);

  const [globalLoading, setGlobalLoading] = useRecoilState(globalLoadingState);
  const {
    dialogs,
    openDialog,
  } = useDialog();

  useEffect(() => {
    if (initialized) {
      setGlobalLoading(false);
    }
  }, [initialized]);

  useEffect(()=>{
    if(initialized){
      if((orientation === 'landscape' && device === 'mobile') || orientation === 'portrait'){
        if(myInfo !== null){
          //로그인이 되어있는 상태면 무시
        }else{
          navigate("/welcome");
        }
      }else{
        if(myInfo !== null){
          //로그인이 되어있는 상태면 무시
        }else{
          navigate("/main");
        }
      }
    }
  }, [initialized, orientation, myInfo])

  useEffect(() => {
    if (typeof conn.isLogin === 'boolean') {
      (async () => {
        await initSounds();

        if (!conn.isLogin) {
          const accessToken = queryParams.get('access_token');
          if (accessToken) {
            await new Promise<void>((resolve) => {
              client.emit("set-token", accessToken, (resp: any) => {
                if (resp.success == 1) {
                  localStorage.setItem(USER_TOKEN, accessToken);
                  window.location.reload();
                } else if (resp.success == 2) {
                  openDialog({
                    title: "로그인 실패",
                    text: "동일한 계정으로 중복 접속을 할 수 없습니다."
                  })
                }
                resolve();
              });
            });
          } else {
            setInitialized(true);
            navigate("/welcome");
          }
        } else {
          const v = await requestMyInfo()
          setMyInfo(v.info);
          setInitialized(true);
        }
      })();
    }
  }, [conn]);

  useEffect(function () {
    setEndOfTournamentListener((data: any) => {
      playSFX(Sounds.SFX_TOURNAMENT_WIN);
      openDialog({
        title: '토너먼트 종료',
        text: `${data.name} 토너먼트에서 ${data.rank}위를 달성하여 ${data.prize.toLocaleString()} 의 머니를 획득하셨습니다.`,
        disableBackdropClick: true
      });
      navigate("/");
    });

    setMoveRoomWithTableBalancingListener(async (data: any) => {
      const roomId = Number(queryParams.get('id') || 0);
      if (location.pathname == '/game' && (roomId == 0 || roomId == data.prevRoomId)) {
        enqueueSnackbar("테이블 밸런싱 - 방을 이동했습니다.");
        let r = await requestRegisterOrJoinTournament(data.groupId);
        if (typeof (r.result) == "number") {
          navigate("/game?id=" + r.result);
        }
      }
    });

    setMoveRoomClientListener((data: {
      groupId: number,
      roomId: number
    }) => {
      const props: DialogProps = {
        title: '안내',
        text: '토너먼트가 시작되었습니다. 링 게임 중이라면 빠르게 정산하고 토너에 참가해주세요.',
        confirmText: '확인',
        disableBackdropClick: true,
      }

      const currentRoomId = Number(queryParams.get('id') || 0);
      if (!currentRoomId) {
        props.confirm = true;
        props.confirmText = '이동';
        props.cancelText = '닫기';
        props.onConfirm = async () => {
          let r = await requestRegisterOrJoinTournament(data.groupId);
          if (typeof (r.result) == "number") {
            navigate("/game?id=" + r.result);
          }
        }
      }

      openDialog(props);
    });

    return () => {
      setEndOfTournamentListener(null);
      setMoveRoomWithTableBalancingListener(null);
      setMoveRoomClientListener(null);
    }
  }, [queryParams]);

  useEffect(function(){
    setUserMoneyUpdateListener(async(data:any)=>{
      const v = await requestMyInfo()
      setMyInfo(v.info);
    });
  },[])

  const handleResize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  useEffect(()=>{
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    window.addEventListener('resize', ()=>handleResize)
    return ()=>{
      window.removeEventListener('resize', ()=>handleResize)
    }
  }, [])

  if (!initialized) {
    return null;
  }

  return <ContainerWrapper>
    <div className='inner'>
      {children}
      {
        dialogs.map((dialog) => (
          <ModalContainer key={dialog.id} show={dialog.visibility} onBackdropClick={dialog.onBackdropClick}>
            <Dialog dialog={dialog}/>
          </ModalContainer>
        ))
      }
      <ModalContainer show={globalLoading}>
        <Loading/>
      </ModalContainer>
    </div>
  </ContainerWrapper>;
};

export default Container;
