import {Moment} from "moment";
import moment from "moment/moment";

export const FMT_DATETIME = 'YYYY-MM-DD HH:mm:ss';

export const parseDatetime = (datetime: string): Moment => {
  return moment(datetime, FMT_DATETIME);
}

export const parseLocalDateTime = (utcDatetime: string): Moment => {
  return moment(utcDatetime, FMT_DATETIME).local();
}