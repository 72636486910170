import styled from "styled-components";
import {DialogProps} from "../recoil/Dialog";
import {useCallback} from "react";
import {MEDIA_MOBILE_LANDSCAPE, MEDIA_DESKTOP} from "../hooks/useScreenOrientation";

const Wrapper = styled.div<{forceMobile?: boolean}>`
  min-width: 311px;
  min-height: 180px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--Point-Mint, #169E7A);
  background: var(--Black, #181818);
  padding: 16px;
  display: flex;
  flex-direction: column;
  ${p => p.forceMobile ? ``: `
  @media ${MEDIA_DESKTOP} {
    border-radius: 4px;
    background: #FFF;
  }
  `}
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    width: 80%;
    min-width: initial;
    min-height: initial;
    overflow-y: scroll;
    margin: 0 auto;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`;

const Title = styled.div<{forceMobile?: boolean}>`
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  padding: 8px;
  word-break: break-word;
  ${p => p.forceMobile ? ``: `
    @media ${MEDIA_DESKTOP} {
     color: #181818;
    }  
  `}
`;

const Content = styled.div<{forceMobile?: boolean}>`
  flex: 1;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  word-break: break-word;
  ${p => p.forceMobile ? ``: `
    @media ${MEDIA_DESKTOP} {
      color: #181818;
    }
  `}
`;

const ButtonWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  gap: 11px;
`;

const Button = styled.div<{
  cancel?: boolean;
  forceMobile?: boolean;
}>`
  flex: 1;
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  padding: 16px;
  transition: all 0.1s linear;
  cursor: pointer;
  border-radius: 24px;
  background: var(--Point-Mint, #169E7A);
  ${p => p.cancel && `
    background: #25262D;
    ${p.forceMobile ? ``: `
    @media ${MEDIA_DESKTOP} {
      background: #FFF;
      color: #181818;
      border: 1px solid #181818;
    }
    `}
  `};

  &:hover {
    filter: brightness(1.2);
  }

  &:active {
    filter: brightness(0.7);
  }
`;

function Dialog({dialog}: {
  dialog: DialogProps
}) {
  const handleConfirm = useCallback(() => {
    dialog?.onConfirm && dialog.onConfirm();
  }, [dialog?.onConfirm]);

  return <Wrapper forceMobile={dialog.forceMobile}>
    <Title forceMobile={dialog.forceMobile}>{dialog.title}</Title>
    {
      dialog.component ? (
        <Content forceMobile={dialog.forceMobile}>{dialog.component}</Content>
      ) : (
        <Content forceMobile={dialog.forceMobile} dangerouslySetInnerHTML={{__html: dialog.text || ''}}></Content>
      )
    }
    <ButtonWrapper>
      {
        dialog.confirm && (
          <Button forceMobile={dialog.forceMobile} cancel onClick={dialog.onCancel}>{dialog.cancelText || '취소'}</Button>
        )
      }
      <Button forceMobile={dialog.forceMobile} onClick={handleConfirm}>{dialog.confirmText || '확인'}</Button>
    </ButtonWrapper>
  </Wrapper>;
}

export default Dialog;
