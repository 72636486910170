const HOST = process.env.REACT_APP_REST_API_HOST as string;

export const uploadProfileImg = async(file:File)=>{
    var data = new FormData()
    data.append('file', file)
    data.append('user', 'hubot')

    let resp = await fetch(HOST+'/api/uploadProfileImg', {
        method: 'POST',
        body: data
    })

    let r = await resp.json()
    return r.data;
}