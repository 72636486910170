//auto gen
import { io } from "socket.io-client";
import { useEffect, useState } from "react";

// export all server api for preventing duplicate api name, etc
export { default as useSocketGetRecommandUserList } from "./game_getRecommandUserList"
export { default as useSocketSignUpSMSCode } from "./game_signUpSMSCode"
export { default as useSocketCheckSMSCode } from "./game_checkSMSCode"
export { default as useSocketUserSignUp } from "./game_userSignUp"
export { default as useSocketFindIdSMSCode } from "./game_findIdSMSCode"
export { default as useSocketFindPasswordSMSCode } from "./game_findPasswordSMSCode"
export { default as useSocketFindId } from "./game_findId"
export { default as useSocketResetPassword } from "./game_resetPassword"
export { default as useSocketGetHandCuttingList } from "./game_getHandCuttingList"
export { default as useSocketRequestEmoji } from "./game_requestEmoji"
export { default as useSocketGetWinnerList } from "./game_getWinnerList"
export { default as useSocketSwapPoint } from "./game_swapPoint"
export { default as useSocketSwapRecommandPoint } from "./game_swapRecommandPoint"
export { default as useSocketGetNoticeDetail } from "./game_getNoticeDetail"
export { default as useSocketGetDWArticle } from "./game_getDWArticle"
export { default as useSocketGetNoticelist } from "./game_getNoticelist"
export { default as useSocketGetPointHistory } from "./game_getPointHistory"
export { default as useSocketGetAttendanceHistory } from "./game_getAttendanceHistory"
export { default as useSocketOpenRabbitHuntingCard } from "./game_openRabbitHuntingCard"
export { default as useSocketUseExtendTimeItem } from "./game_useExtendTimeItem"
export { default as useSocketUseOpenCard } from "./game_useOpenCard"
export { default as useSocketSetNewPassword } from "./game_setNewPassword"
export { default as useSocketRegisterOrJoinTournament } from "./game_registerOrJoinTournament"
export { default as useSocketCancelTournament } from "./game_cancelTournament"
export { default as useSocketRebuyinTournament } from "./game_rebuyinTournament"
export { default as useSocketReport } from "./game_report"
export { default as useSocketJoinLobby } from "./game_joinLobby"
export { default as useSocketLeaveLobby } from "./game_leaveLobby"
export { default as useSocketRingGroupList } from "./game_ringGroupList"
export { default as useSocketRingGroupListHandShake } from "./game_ringGroupListHandShake"
export { default as useSocketTournamentGroupList } from "./game_tournamentGroupList"
export { default as useSocketGetKeepAwayMyList } from "./game_getKeepAwayMyList"
export { default as useSocketGetTournamentRoomList } from "./game_getTournamentRoomList"
export { default as useSocketGetTournamentUserRanks } from "./game_getTournamentUserRanks"
export { default as useSocketGetTournamentGroup } from "./game_getTournamentGroup"
export { default as useSocketCurrentRooms } from "./game_currentRooms"
export { default as useSocketCurrentRoom } from "./game_currentRoom"
export { default as useSocketJoinRoom } from "./game_joinRoom"
export { default as useSocketGetBanners } from "./game_getBanners"
export { default as useSocketGetUserProfile } from "./game_getUserProfile"
export { default as useSocketModifyUserProfile } from "./game_modifyUserProfile"
export { default as useSocketModifyUserProfileMemo } from "./game_modifyUserProfileMemo"
export { default as useSocketToggleEmojiBan } from "./game_toggleEmojiBan"
export { default as useSocketSendChat } from "./game_sendChat"
export { default as useSocketClearChat } from "./game_clearChat"
export { default as useSocketGetChat } from "./game_getChat"
export { default as useSocketJoinRingGroup } from "./game_joinRingGroup"
export { default as useSocketSetBlindWait } from "./game_setBlindWait"
export { default as useSocketLeaveRoom } from "./game_leaveRoom"
export { default as useSocketKeepAwayRoom } from "./game_keepAwayRoom"
export { default as useSocketPayBackKeepAwayRoom } from "./game_payBackKeepAwayRoom"
export { default as useSocketStandUpRoom } from "./game_standUpRoom"
export { default as useSocketGetInfoTournamentRoom } from "./game_getInfoTournamentRoom"
export { default as useSocketAddonAccept } from "./game_addonAccept"
export { default as useSocketMoveRingReserve } from "./game_moveRingReserve"
export { default as useSocketMoveRingCancel } from "./game_moveRingCancel"
export { default as useSocketTryBet } from "./game_tryBet"
export { default as useSocketRoomInfo } from "./game_roomInfo"
export { default as useSocketMyStatusInRoom } from "./game_myStatusInRoom"
export { default as useSocketPlayersBetPot } from "./game_playersBetPot"
export { default as useSocketMyCards } from "./game_myCards"
export { default as useSocketBuyin } from "./game_buyin"
export { default as useSocketGetHandHistory } from "./game_getHandHistory"
export { default as useSocketLogin } from "./ping_login"
export { default as useSocketPopupNotice } from "./ping_popupNotice"
export { default as useSocketDirectLinkLogin } from "./ping_directLinkLogin"
export { default as useSocketPing } from "./ping_ping"
export { default as useSocketGetHQCode } from "./ping_getHQCode"
export { default as useSocketCheckNickname } from "./ping_checkNickname"
export { default as useSocketCheckUid } from "./ping_checkUid"
export { default as useSocketRecomPointHistory } from "./ping_recomPointHistory"
export { default as useSocketUnreadMemoCount } from "./ping_unreadMemoCount"
export { default as useSocketGetMemoList } from "./ping_getMemoList"
export { default as useSocketReadMemoList } from "./ping_readMemoList"
export { default as useSocketAllReadMemoList } from "./ping_allReadMemoList"
export { default as useSocketAllDeleteMemoList } from "./ping_allDeleteMemoList"
export { default as useSocketOneDeleteMemoList } from "./ping_oneDeleteMemoList"
export { default as useSocketMyInfo } from "./ping_myInfo"
export { default as useSocketRequestUserVerify } from "./vacc_requestUserVerify"
export { default as useSocketGetTransactionList } from "./vacc_getTransactionList"
export { default as useSocketRemoveAllTransactionList } from "./vacc_removeAllTransactionList"
export { default as useSocketRequestUserTransaction } from "./vacc_requestUserTransaction"
export { request as requestGetRecommandUserList } from "./game_getRecommandUserList"
export { request as requestSignUpSMSCode } from "./game_signUpSMSCode"
export { request as requestCheckSMSCode } from "./game_checkSMSCode"
export { request as requestUserSignUp } from "./game_userSignUp"
export { request as requestFindIdSMSCode } from "./game_findIdSMSCode"
export { request as requestFindPasswordSMSCode } from "./game_findPasswordSMSCode"
export { request as requestFindId } from "./game_findId"
export { request as requestResetPassword } from "./game_resetPassword"
export { request as requestGetHandCuttingList } from "./game_getHandCuttingList"
export { request as requestRequestEmoji } from "./game_requestEmoji"
export { request as requestGetWinnerList } from "./game_getWinnerList"
export { request as requestSwapPoint } from "./game_swapPoint"
export { request as requestSwapRecommandPoint } from "./game_swapRecommandPoint"
export { request as requestGetNoticeDetail } from "./game_getNoticeDetail"
export { request as requestGetDWArticle } from "./game_getDWArticle"
export { request as requestGetNoticelist } from "./game_getNoticelist"
export { request as requestGetPointHistory } from "./game_getPointHistory"
export { request as requestGetAttendanceHistory } from "./game_getAttendanceHistory"
export { request as requestOpenRabbitHuntingCard } from "./game_openRabbitHuntingCard"
export { request as requestUseExtendTimeItem } from "./game_useExtendTimeItem"
export { request as requestUseOpenCard } from "./game_useOpenCard"
export { request as requestSetNewPassword } from "./game_setNewPassword"
export { request as requestRegisterOrJoinTournament } from "./game_registerOrJoinTournament"
export { request as requestCancelTournament } from "./game_cancelTournament"
export { request as requestRebuyinTournament } from "./game_rebuyinTournament"
export { request as requestReport } from "./game_report"
export { request as requestJoinLobby } from "./game_joinLobby"
export { request as requestLeaveLobby } from "./game_leaveLobby"
export { request as requestRingGroupList } from "./game_ringGroupList"
export { request as requestRingGroupListHandShake } from "./game_ringGroupListHandShake"
export { request as requestTournamentGroupList } from "./game_tournamentGroupList"
export { request as requestGetKeepAwayMyList } from "./game_getKeepAwayMyList"
export { request as requestGetTournamentRoomList } from "./game_getTournamentRoomList"
export { request as requestGetTournamentUserRanks } from "./game_getTournamentUserRanks"
export { request as requestGetTournamentGroup } from "./game_getTournamentGroup"
export { request as requestCurrentRooms } from "./game_currentRooms"
export { request as requestCurrentRoom } from "./game_currentRoom"
export { request as requestJoinRoom } from "./game_joinRoom"
export { request as requestGetBanners } from "./game_getBanners"
export { request as requestGetUserProfile } from "./game_getUserProfile"
export { request as requestModifyUserProfile } from "./game_modifyUserProfile"
export { request as requestModifyUserProfileMemo } from "./game_modifyUserProfileMemo"
export { request as requestToggleEmojiBan } from "./game_toggleEmojiBan"
export { request as requestSendChat } from "./game_sendChat"
export { request as requestClearChat } from "./game_clearChat"
export { request as requestGetChat } from "./game_getChat"
export { request as requestJoinRingGroup } from "./game_joinRingGroup"
export { request as requestSetBlindWait } from "./game_setBlindWait"
export { request as requestLeaveRoom } from "./game_leaveRoom"
export { request as requestKeepAwayRoom } from "./game_keepAwayRoom"
export { request as requestPayBackKeepAwayRoom } from "./game_payBackKeepAwayRoom"
export { request as requestStandUpRoom } from "./game_standUpRoom"
export { request as requestGetInfoTournamentRoom } from "./game_getInfoTournamentRoom"
export { request as requestAddonAccept } from "./game_addonAccept"
export { request as requestMoveRingReserve } from "./game_moveRingReserve"
export { request as requestMoveRingCancel } from "./game_moveRingCancel"
export { request as requestTryBet } from "./game_tryBet"
export { request as requestRoomInfo } from "./game_roomInfo"
export { request as requestMyStatusInRoom } from "./game_myStatusInRoom"
export { request as requestPlayersBetPot } from "./game_playersBetPot"
export { request as requestMyCards } from "./game_myCards"
export { request as requestBuyin } from "./game_buyin"
export { request as requestGetHandHistory } from "./game_getHandHistory"
export { request as requestLogin } from "./ping_login"
export { request as requestPopupNotice } from "./ping_popupNotice"
export { request as requestDirectLinkLogin } from "./ping_directLinkLogin"
export { request as requestPing } from "./ping_ping"
export { request as requestGetHQCode } from "./ping_getHQCode"
export { request as requestCheckNickname } from "./ping_checkNickname"
export { request as requestCheckUid } from "./ping_checkUid"
export { request as requestRecomPointHistory } from "./ping_recomPointHistory"
export { request as requestUnreadMemoCount } from "./ping_unreadMemoCount"
export { request as requestGetMemoList } from "./ping_getMemoList"
export { request as requestReadMemoList } from "./ping_readMemoList"
export { request as requestAllReadMemoList } from "./ping_allReadMemoList"
export { request as requestAllDeleteMemoList } from "./ping_allDeleteMemoList"
export { request as requestOneDeleteMemoList } from "./ping_oneDeleteMemoList"
export { request as requestMyInfo } from "./ping_myInfo"
export { request as requestRequestUserVerify } from "./vacc_requestUserVerify"
export { request as requestGetTransactionList } from "./vacc_getTransactionList"
export { request as requestRemoveAllTransactionList } from "./vacc_removeAllTransactionList"
export { request as requestRequestUserTransaction } from "./vacc_requestUserTransaction"

const API_HOST = process.env.REACT_APP_API_HOST as string;
export const USER_TOKEN = "user_token";
export const USER_ID = "user_id";
export const IS_EXERCISE = "isexercise"
export const IS_EXERCISE_STOP = "isexercisestop";
export const GID_TOKEN = "gid_token";

export const IS_EXERCISE_RID = "IS_EXERCISE_RID";

export const IS_EXERCISE_ENERGY = "IS_EXERCISE_ENERGY";

export const IS_EXERCISE_MAX_ENERGY = "IS_EXERCISE_MAX_ENERGY";

export const IS_EXERCISE_USER_ENERGY = "IS_EXERCISE_USER_ENERGY";

export const IS_EXERCISE_DISTANCE = "IS_EXERCISE_DISTANCE";

export const IS_EXERCISE_NOW_DISTANCE = "IS_EXERCISE_NOW_DISTANCE";

export const IS_EXERCISE_EARN_TOKEN = "IS_EXERCISE_EARN_TOKEN";

export const IS_EXERCISE_RANDOMSPOT = "IS_EXERCISE_RANDOMSPOT";

export const IS_EXERCISE_RANDOMSPOT_ENTERED = "IS_EXERCISE_RANDOMSPOT_ENTERED";

export const IS_EXERCISE_RANDOMSPOT_ENTER_COUNT = "IS_EXERCISE_RANDOMSPOT_ENTER_COUNT";

export let client = io(API_HOST, { transports: ["websocket"] });

export interface SockClientOpt{
  useStore: boolean;
  useKey?: string;
}

client.on("connect",()=>{
  console.log("connected server")
  setConnect(true, null);

  let token = sessionStorage.getItem(USER_TOKEN) as string;
  if(token){
      setLogin(token)
  }else{
      setConnect(true, false);
  }
})
client.on("disconnect", (reason:any) => {
  setConnect(false, null);
})
client.on("force_disconnect", (a:any)=>{
  alert("중복 로그인을 감지하여 로그인이 풀립니다")
  setConnect(true,false);
  window.history.go(-2);
})
client.on("force_logout", (a:any)=>{
  setConnect(true,false);
  window.history.go(-2);
})

const setLogin = async function(token:string){
  client.emit("set-token", token, async function(resp:any){
      if(resp.success == 1){
        setConnect(true, true);
      }else if(resp.success == 2){
        alert("동일한 계정으로 중복 접속을 할 수 없습니다");
        setConnect(false, false);
      }else{
          setConnect(true, false);
      }
  })
}

const subscribeEvent:Array<(f:SockConnect)=>void> = []
function subscribe(func: (f:SockConnect)=>void) {
  subscribeEvent.push(func)
}
function unsubscribe(func: (f:SockConnect)=>void) {
  let idx = subscribeEvent.findIndex(e => e == func);
  if (idx >= 0) subscribeEvent.splice(idx, 1)
}

let _connect = false;
let _logined : boolean | null = null;
const setConnect = (c:boolean, l:boolean|null)=>{
  _connect = c;
  _logined = l;
  for (let evt of subscribeEvent) {
      evt({ isConnected:_connect, isLogin:_logined });
  }
}

export function connectionData(){
  return {
    connect:_connect,
    logined:_logined,
  }
}

interface SockConnect {
  isConnected:boolean, 
  isLogin:boolean|null
}

export const OnRecvData = async( data:any )=>{
if(data == null) return null;

if(data.code !== 0){
  console.error("error occurred")
}

if(!!data.login_token && _logined == false){
  setLogin(data.login_token); 
  sessionStorage.setItem(USER_TOKEN, data.login_token) 
}
return data;
}

export const useSocketIsConnect = ()=>{
  let [data,setData] = useState<SockConnect>({
      isConnected:_connect,
      isLogin:_logined,
  });

  useEffect(() => {
      const handleChange = (f:SockConnect)=>setData(f)

      subscribe(handleChange);
      return () => unsubscribe(handleChange);
  }, []);

  return [data]
}
