import styled from "styled-components";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {RoomInfo } from "../../dataset";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import moment from "moment";

import {
  requestGetInfoTournamentRoom
} from '../../api';

const Wrapper = styled.div`
  width: 264px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #101012;
  border-right: 1px solid #E31D1D;
  color: #FFF;

  @media ${MEDIA_DESKTOP} {
    width: 640px;
  }

  >.contentWrapper {
    overflow-y: scroll;
  }

  >.tournames {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    >.header {
      flex: 0 0 auto;
      display: flex;
      padding: 20px;
      justify-content: space-between;
      >.left {
        width: 20%;
        text-align: center;
      }
      >.center {
        width: 60%;
        text-align: center;
        >.totalPrize {
          padding-top: 10px;
        }
      }
      >.right {
        width: 20%;
        text-align: center;
      }
      .line {
        margin-top: 20px;
      }
    }
    >.middle {
      overflow-y: auto;
      flex: 1 1 auto;
      border-top: 1px solid #727272;
      position: relative;

      .key1 {
        width: 20%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        >img {
          width: 12px;
          height: 12px;
          margin-right: 2px;

          @media ${MEDIA_DESKTOP} {
            width: 16px;
            height: 16px;
            margin-right: 3px;
          }
        }
      }
      .key2 {
        width: 30%;
        text-align: center;
      }
      .key3 {
        width: 25%;
        text-align: center;
      }
      .key4 {
        width: 20%;
        text-align: center;
      }
      &::before {
        position: absolute;
        left: 0;
        right: 0;
        height: 1px;
        content: ' ';
        background: #000000;
      }
      >.header {
        display: flex;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;
        background: #161616;
        font-size: 12px;
        @media ${MEDIA_DESKTOP} {
          font-size: 15px;
        }
      }
      >.content {
        border-top: 1px solid #474747;
        position: relative;
        >.line {
          display: flex;
          align-items: center;
          font-size: 12px;
          padding-top: 6px;
          padding-bottom: 6px;
          &.disabled {
            color: #656565;
          }
          &.next {
            color: #a6a6a6;
          }
          @media ${MEDIA_DESKTOP} {
            font-size: 15px;
          }
        }
        >.lineRest {
          text-align: center;
          padding-top: 6px;
          padding-bottom: 6px;
          font-size: 15px;
          color: #9f9f9f;
        }
      }
    }
  }
`;

const Asd = styled.div`
  font-size: 10px;
  color: #aeaeae;
  @media ${MEDIA_DESKTOP} {
    font-size: 14px;
  }
`;

const Val1 = styled.div`
  font-size: 11px;
  padding-top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  >img {
    display: none;
    @media ${MEDIA_DESKTOP} {
      display: block;
      width: 30px;
      height: 30px;
      margin-right: 3px;
    }
  }
  @media ${MEDIA_DESKTOP} {
    font-size: 18px;
    padding-top: 6px;
  }
`;

const Val2 = styled.div`
  font-size: 16px;
  font-weight: 600;
  padding-top: 4px;
  @media ${MEDIA_DESKTOP} {
    font-size: 30px;
    padding-top: 6px;
  }
`;

const Title = styled.div`
  margin: 10px;
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 10px;
  margin-top: 20px;
  >strong {
    color: #ffdd0b;
  }
  @media ${MEDIA_DESKTOP} {
    font-size: 16px;
  }
`;

const Title2 = styled.div`
  padding: 30px;
  margin: 0;
  padding-left: 10px;
  padding-bottom: 0;
  font-size: 18px;
  letter-spacing: -0.4px;
  >strong {
    color: #ffdd0b;
    margin-left: 5px;
  }
  display: block;
  text-align: center;
  >span {
    display: block;
  }
  >strong {
    display: block;
  }
  @media ${MEDIA_DESKTOP} {
    justify-content: center;
    display: flex;
    font-size: 20px;
  }
`;
const Title3 = styled.div`
  padding: 30px;
  margin: 0;
  padding-left: 10px;
  padding-bottom: 0;
  font-size: 18px;
  letter-spacing: -0.4px;
  >strong {
    color: #ffdd0b;
    margin-left: 5px;
  }
  display: block;
  text-align: center;
  >span {
    display: block;
  }
  >strong {
    display: block;
  }
  @media ${MEDIA_DESKTOP} {
    justify-content: center;
    display: flex;
    font-size: 20px;
    padding-top: 4px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  background: rgba(227, 29, 29, 0.3);

  @media ${MEDIA_DESKTOP} {
    height: 72px;
  }

  > .back {
    width: 48px;
    height: 48px;
    padding: 12px;
    cursor: pointer;

    @media ${MEDIA_DESKTOP} {
      width: 72px;
      height: 72px;
      padding: 24px;
    }
  }

  > .title {
    flex: 1;
    font-size: 16px !important;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .gap {
    width: 48px;

    @media ${MEDIA_DESKTOP} {
      width: 72px;
    }
  }
`;

const InGameList = styled.div`
  margin: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background: rgb(22, 22, 24);
  margin-top: 0;
  >.header {
    align-items: center;
    height: 38px;
    background: #1d1d1d;
    font-size: 16px;
    font-weight: bolder;
    display: flex;
  }
  >.content {
    padding-top: 10px;
    padding-bottom: 10px;
    @media ${MEDIA_DESKTOP} {
      padding: 0;
    }
    >.item {
      display: flex;
      align-items: center;
      font-size: 14px;
      &+.item {
        padding-top: 10px;
        @media ${MEDIA_DESKTOP} {
          padding-top: 0;
        }
      }
      @media ${MEDIA_DESKTOP} {
        height: 36px;
        font-size: 15px;
      }
    }
    >.sdfnsndf {
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px;
      font-size: 14px;
      >img {
        width: 80px;
      }
    }
  }
  >.fsdf {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    >.item {
      height: initial;
      display: block;
      padding-top: 18px!important;
      padding-left: 12px;
      padding-right: 12px;
      display: block;
      padding-bottom: 8px;
      @media ${MEDIA_DESKTOP} {
        padding: 20px!important;
      }
      >.dsdfdf {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @media ${MEDIA_DESKTOP} {
          display: block;
        }
        >.asnfansf {
          width: 48px;
          height: 48px;
          position: absolute;
          z-index: 3;
          background: url(/images/nes/game_account_profile.png) 0% 0% / 100% 100% no-repeat;
          @media ${MEDIA_DESKTOP} {
            width: 70px;
            height: 70px;
          }
        }
        
        >img {
          width: 48px;
          height: 48px;
          border-radius: 70px;
          @media ${MEDIA_DESKTOP} {
            width: 70px;
            height: 70px;
          }
        }
      }
      >.dsfmsdf {
        text-align: center;
        font-size: 12px;
        padding-top: 2px;
        @media ${MEDIA_DESKTOP} {
          padding: 0;
          font-size: 14px;
        }
      }
    }
  }
  .line1 {
    width: 30%;
    text-align: center;
    font-size: 13px;
    >.dssdbnf {
      font-size: 12px;
      padding-top: 2px;
    }
    .dsdfdf {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      @media ${MEDIA_DESKTOP} {
        display: block;
      }
      >.asnfansf {
        width: 48px;
        height: 48px;
        position: absolute;
        z-index: 3;
        background: url(/images/nes/game_account_profile.png) 0% 0% / 100% 100% no-repeat;
        @media ${MEDIA_DESKTOP} {
          width: 70px;
          height: 70px;
        }
      }
      >img {
        width: 48px;
        height: 48px;
        border-radius: 70px;
        @media ${MEDIA_DESKTOP} {
          width: 70px;
          height: 70px;
        }
      }
    }
    
    @media ${MEDIA_DESKTOP} {
      font-size: 15px;
      width: 40%;
    }
  }
  .line2 {
    width: 35%;
    text-align: center;
    font-size: 13px;
    @media ${MEDIA_DESKTOP} {
      width: 40%;
      font-size: 15px;
    }
  }
  .line3 {
    width: 35%;
    text-align: center;
    font-size: 13px;
    @media ${MEDIA_DESKTOP} {
      width: 40%;
      font-size: 15px;
    }
  }
  .line4 {
    width: 35%;
    text-align: center;
    >.sdfsd {
      color: #ff2a2a;
    }
    >.sdfsd2 {
      color: #21ff21;
    }
    font-size: 13px;
    @media ${MEDIA_DESKTOP} {
      width: 40%;
      font-size: 15px;
    }
  }
    
`;

function GameStatusBoard(
  {
    room,
    onClose,
    isTournament,
    blindTimeNumber,
    currentLevel,
    nextLevel,
    blindsCurrentLevelTextTournament,
    blindsNextLevelTextTournament,
    isTournamentIngRest,
    opened
  }: {
    room?:RoomInfo|null|undefined
    onClose?: () => void;
    isTournament: boolean;
    blindTimeNumber: number;
    currentLevel: number;
    nextLevel: number;
    blindsCurrentLevelTextTournament: string;
    blindsNextLevelTextTournament: string;
    isTournamentIngRest: boolean;
    opened: boolean;
  }
) {
  const orientation = useScreenOrientation();
  const [totalRegisteredMember, setTotalRegisteredMember] = useState<number>(-1);
  const [currentPlayedMember, setCurrentPlayedMember] = useState<number>(-1);
  const [myBuyinCount, setMyBuyinCount] = useState<number>(-1);
  const [myRanking, setMyRanking] = useState<number>(-1);

  useEffect(() => {
    if (opened && room) {
      (async () => {
        const d = await requestGetInfoTournamentRoom(room.groupId);

        if (d.result) {
          setTotalRegisteredMember(d.totalRegisteredMember);
          setCurrentPlayedMember(d.currentPlayedMember);
          setMyBuyinCount(d.myBuyinCount)
          setMyRanking(d.myRanking);
        }
      })();
    }
    else {
      setTotalRegisteredMember(-1);
      setCurrentPlayedMember(-1);
      setMyBuyinCount(-1);
      setMyRanking(-1);
    }
  }, [opened, room])

  return <Wrapper>
     <Header>
      {
        orientation === 'portrait' ? <>
          <img className="back" src="/images/ic_header_back.svg" onClick={(e)=>{onClose && onClose()}}/>
          <div className="title">{isTournament ? '토너먼트 상황보기' : '게임방 상황보기'}</div>
          <div className="gap"/>
        </> : <>
          <div className="gap"/>
          <div className="title">{isTournament ? '토너먼트 상황보기' : '게임방 상황보기'}</div>
          <img className="back" src="/images/ic_header_close.svg" onClick={(e)=>{onClose && onClose()}}/>
        </>
      }
    </Header>
    {
      isTournament ? (
        <div className="tournames">
          <div className="header">
            <div className="left">
              <div className="intervalLevelup">
                <Asd>참가인원</Asd>
                <Val1>{currentPlayedMember.toLocaleString()}/{totalRegisteredMember.toLocaleString()}</Val1>
              </div>
              <div className="rank line">
                <Asd>나의 순위</Asd>
                {
                  myRanking !== -1 ? (
                    <Val1>{myRanking.toLocaleString()}/{currentPlayedMember.toLocaleString()}</Val1>
                  ) : (
                    <Val1>순위 집계 전</Val1>
                  ) 
                }
              </div>
              <div className="buyinCount line">
                <Asd>나의 바이인 횟수</Asd>
                <Val1>{myBuyinCount.toLocaleString()}회</Val1>
              </div>
              <div className="addonUsed line">
                <Asd>나의 애드온 사용여부</Asd>
                <Val1>사용안함</Val1>
              </div>
            </div>
            <div className="center">
              <div className="totalPrize">
                <div>
                  <img src="/images/nes/prize5.png" alt="prize" />
                </div>
                <Asd>총상금</Asd>
                <Val2>{room?.groupData.prize.reduce((accumulator:number, currentValue:number) => accumulator + currentValue, 0).toLocaleString()}</Val2>
              </div>
              <div className="nextLevelTimer line">
                <Asd>다음 레벨</Asd>
                {
                  blindTimeNumber === -1 ? (
                    <Val2>다음레벨 없음</Val2>
                  ) : (
                    <Val2>{moment.unix(blindTimeNumber).format('mm:ss')}{isTournamentIngRest ? ' (휴식중)' : ''}</Val2>
                  )
                }
              </div>
              <div className="currentLevel line">
                <Asd>현재 레벨: {currentLevel}</Asd>
                <Val1><img src="/images/nes/chip5.png" alt="chip" />{blindsCurrentLevelTextTournament}</Val1>
              </div>
              <div className="nextLevel line">
                <Asd>다음 레벨: {nextLevel === -1 ? '없음' : nextLevel}</Asd>
                <Val1>
                  {nextLevel !== -1 ? (
                    <img src="/images/nes/chip5.png" alt="chip" />
                  ) : <></> }
                  {blindsNextLevelTextTournament}</Val1>
              </div>
            </div>
            <div className="right">
              <div className="playTime">
                <Asd>플레이시간</Asd>
                <Val1>{room?.groupData.timeStructure.playTimeSeconds}초</Val1>
              </div>
              <div className="restTime line">
                <Asd>휴식시간</Asd>
                <Val1>{room?.groupData.timeStructure.restTimeSeconds}초</Val1>
              </div>
              <div className="addonLevel line">
                <Asd>애드온 사용가능 레벨</Asd>
                <Val1>{room?.groupData.addonOnLevel}레벨 휴식중</Val1>
              </div>
              <div className="addonLevel line">
                <Asd>등록마감레벨</Asd>
                <Val1>{room?.groupData.availableRegisterLevel} 레벨</Val1>
              </div>
              <div className="addonLevel line">
                <Asd>리바인마감레벨</Asd>
                <Val1>{room?.groupData.rebuyinLimitLevel} 레벨</Val1>
              </div>
            </div>
          </div>
          <div className="middle">
            <div className="header">
              <div className="key1">레벨</div>
              <div className="key2">블라인드</div>
              <div className="key3">앤티</div>
              <div className="key4">기간</div>
            </div>
            <div className="content">
              {room?.groupData.blindStructure.map((val:Array<number>, level:number) => {
                return <div 
                  className={`line ${
                    currentLevel > (level + 1) ? 
                    'disabled' : 
                    ''} ${
                    currentLevel < (level + 1) ? 
                    'next' : 
                    ''}`
                  } 
                  key={`blind-list-${level}`}
                >
                <div className="key1">
                  {
                    room?.groupData.availableRegisterLevel > (level + 1) ? (
                      <img src="/images/nes/buyin.png" alt="바이인가능"/>
                    ) : <></>
                  }
                  {
                    room?.groupData.rebuyinLimitLevel > (level + 1) ? (
                      <img src="/images/nes/rebuyin.png" alt="리바인가능"/>
                    ) : <></>
                  }
                  {
                    room?.groupData.addonOnLevel === (level + 1) ? (
                      <img src="/images/nes/addon5.png" alt="애드온"/>
                    ) : <></>
                  }
                  <span>{level+1}</span>
                </div>
                <div className="key2">{`${val[0].toLocaleString()}/${val[1].toLocaleString()}`}</div>
                <div className="key3">{val[2].toLocaleString()}</div>
                <div className="key4">{val[3].toLocaleString()}초</div>
              </div>
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="contentWrapper">
          <Title2><span>게임방 현재</span><strong>{room ? moment().diff(moment(room.closeTime.replace(".000Z", "").replace("T", " ")), 'hours') : 0}시간 게임중</strong></Title2>
          <Title3><span>현재 게임 방의 라운드</span><strong>{room ? room.handNumber : 0}회</strong></Title3>
          <Title>게임중인 유저({room ? room.players.length : 0}명)</Title>
          <InGameList>
            <div className="header">
              <div className="line1">
                <span>플레이어</span>
              </div>
              {
                orientation === 'portrait' ? 
                <div className="line2">
                  <span>바이인(핸드수)</span>
                </div> :
                <div className="line2">
                  <span>바이인</span>
                </div>
              }
              {
                orientation === 'portrait' ? <></> :
                <div className="line3">
                  <span>핸드</span>
                </div>
              }
              <div className="line4">
                <span>결과</span>
              </div>
            </div>
            <div className="content">
              {
                room && room.players.length === 0 ? (
                  <div className="sdfnsndf">
                    <img src="/images/nes/empty3.png" alt="" />
                    <div>플레이중인 회원이 없습니다.</div>
                  </div>
                ) : 
                  room && room.players.map((p, i) => (
                    <div className="item" key={`status-item-${i}`}>
                      {
                        orientation === 'portrait' ? (
                          <>
                          <div className="line1">
                            <div className="dsdfdf">
                              <div className="asnfansf"/>
                              <img src={p.profileImg} />
                            </div>
                            <div className="dssdbnf">{p.nickname}</div>
                          </div>
                          <div className="line2">
                            <span>{p.buyin.toLocaleString()} ({p.handsCount.toLocaleString()}회)</span>
                          </div>
                          <div className="line4">
                            {
                              p.stackSize - p.buyin < 0 ? (
                                <span className='sdfsd'>{(p.stackSize - p.buyin).toLocaleString()}</span>
                              ) : (
                                <span className='sdfsd2'>+{(p.stackSize - p.buyin).toLocaleString()}</span>
                              )
                            }
                          </div>
                          </>
                        ) : (
                          <>
                          <div className="line1">
                            <span>{p.nickname}</span>
                          </div>
                          <div className="line2">
                            <span>{p.buyin.toLocaleString()}</span>
                          </div>
                          <div className="line3">
                            <span>{p.handsCount.toLocaleString()}</span>
                          </div>
                          <div className="line4">
                            {
                              p.stackSize - p.buyin < 0 ? (
                                <span className='sdfsd'>{(p.stackSize - p.buyin).toLocaleString()}</span>
                              ) : (
                                <span className='sdfsd2'>+{(p.stackSize - p.buyin).toLocaleString()}</span>
                              )
                            }
                          </div>
                          </>
                        )
                      }
                    </div>
                  ))
              }
            </div>
          </InGameList>
          <Title>관전중인 유저({room ? room.spectator.length : 0}명)</Title>
          {
            room && room.spectator.length > 0 ? (
              <InGameList>
                <div className="content fsdf">
                {
                  room.spectator.map((p, i) => (
                    <div className="item" key={`statuass-item-${i}`}>
                      <div className="dsdfdf">
                        <div className="asnfansf"/>
                        <img src={p.profileImg} />
                      </div>
                      <div className="dsfmsdf">
                        <span>{p.nickname}</span>
                      </div>
                    </div>
                  ))
                }
                </div>
              </InGameList>
            ) : <></>
          }
        </div>
      )
    }
  </Wrapper>;
}

export default GameStatusBoard;
