import styled from "styled-components";
import React, {useEffect, useRef, useState} from "react";
import {requestGetChat, requestMyInfo, requestSendChat, requestClearChat} from "../api";
import {chatModel, MyInfo} from "../dataset";
import moment from "moment";
import useScreenOrientation, {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";
import {unreadChatCount} from "../recoil/Chat";
import {useRecoilValue} from "recoil";
import useDialog from "../hooks/useDialog";
import _ from 'lodash';

import {
  setAckListener as updateChatDeleteListener
} from "../api/from_server_ping_updateChatDelete"


const CustomerCenterWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: #151515;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media ${MEDIA_DESKTOP} {
    top: 60px;
    height: calc(100% - 60px);
    background: white;
  }
  > .content-wrapper {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    @media ${MEDIA_DESKTOP} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      flex-grow: 1;
    }
    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 16px;
      padding: 0 20px;
      @media ${MEDIA_DESKTOP} {
        width: 100%;
        max-width: 1440px;
        padding: 24px 20px;
        gap: 16px;
        background: #F5F5F5;
        justify-content: flex-start;
        flex-grow: 1;
      }
    }
  }
`
const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  @media ${MEDIA_DESKTOP} {
    border-bottom: 1px solid #DDD;
    background: #F5F5F5;
    
  }

  .wrap {
    width: 100%;
    height: 48px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @media ${MEDIA_DESKTOP} {
      max-width: 1440px;
      height: 57px;
    }
    > .clearHistory {
      color: rgb(80 80 80);
      cursor: pointer;
      border-radius: 6px;
      position: absolute;
      right: 5px;
      top: 0;
      display: flex;
      align-items: center;
      padding: 4px;
      padding-left: 10px;
      padding-right: 10px;
      background: transparent;
      width: 48px;
      height: 48px;
      >img {
        width: 24px;
        margin-right: 5px;
        @media ${MEDIA_DESKTOP} {
          width: 28px;
        }
      }
      @media ${MEDIA_DESKTOP} {
        width: initial;
        height: initial;
        top: 11px;
        right: 0px;
        background: #d9d9d9;
        &:hover {
          background: #c7c7c7;
        }
      }
    }
    > .close {
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      cursor: pointer;
      @media ${MEDIA_DESKTOP} {
        display: none;
      }
    }
  
    > .title {
      color: #FFF;
      text-align: center;
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      @media ${MEDIA_DESKTOP} {
        position: relative;
        width: 470px;
        color: var(--Black, #181818);
        text-align: center;
        font-family: Pretendard;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 100%; /* 20px */
      }
  
      > .close {
        display: none;
        @media ${MEDIA_DESKTOP} {
          cursor: pointer;
          display: block;
          width: 24px;
          height: 24px;
          position: absolute;
          top: 50%;
          left: 16px;
          transform: translateY(-50%);
        }
      }
    }
  }
`
const Footer = styled.div`
  width: 100%;
  height: 90px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #151515;

  > .input-wrapper {
    padding: 8px 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    @media ${MEDIA_DESKTOP} {
      max-width: 700px;
    }
   

    > input {
      flex-grow: 1;
      background: none;
      outline: none;
      border-radius: 20px;
      border: 1px solid #252525;
      color: #FFF;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 19.6px */
      letter-spacing: -0.7px;
      padding: 10px 12px;

      &::placeholder {
        color: #FFF;
        opacity: 0.5;
      }
      @media ${MEDIA_DESKTOP} {
        font-size: 16px;
        padding: 12px 16px;
        background: #494949;
      }
    }

    > .send-button {
      cursor: pointer;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      padding: 8px;
      border: 1px solid rgba(255, 255, 255, 0.4);
    }
  }
`
const AdminMessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 8px;

  > .title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;

    > .admin {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.165px;
      @media ${MEDIA_DESKTOP} {
        font-size: 14px;
        color: var(--Black, #181818);
      }
    }

    > .date {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.5px;
      opacity: 0.5;
      @media ${MEDIA_DESKTOP} {
        font-size: 14px;
        color: var(--Black, #181818);
      }
    }
  }

  > .message {
    max-width: 300px;
    color: #FFF;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.165px;
    border-radius: 8px;
    background: #25262D;
    padding: 12px;
    word-break: break-all;
    white-space: pre-line;
  }
`
const UserMessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 8px;

  > .message {
    max-width: 300px;
    padding: 12px;
    color: #FFF;
    text-align: right;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.165px;
    background: #8D7AFF;
    border-radius: 8px;
    word-break: break-all;
    white-space: pre-line;
  }

  > .date {
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.5px;
    opacity: 0.5;
    @media ${MEDIA_DESKTOP} {
      font-size: 14px;
      color: var(--Black, #181818);
    }
  }
`

interface CustomerCenterProps {
  onClose?: () => void;
}

const CustomerCenter = ({onClose}: CustomerCenterProps) => {
  const {openDialog} = useDialog()
  const [showSendButton, setShowSendButton] = useState<boolean>(false);
  const [chatList, setChatList] = useState<chatModel[]>([]);
  // const [pageInfo, setPageInfo] = useState<{currentPage: number, isNext: boolean}>({currentPage: 0, isNext: true});
  const [myInfo, setMyInfo] = useState<MyInfo|null>(null);
  const [previousChat, setPreviousChat] = useState<String>('');
  const chatDiv = useRef<HTMLDivElement>(null);
  const ChatCount = useRecoilValue(unreadChatCount);
  const orientation = useScreenOrientation();
  const inputRef = useRef<HTMLInputElement>(null);

  let isAlive = false;

  const loopGuestGetChat = () => {
    if (isAlive) {
      requestGetChat(0).then((response:any) => {
        if (isAlive) {
          const c = JSON.parse(JSON.stringify(response));
          setChatList(c.list);
    
          setTimeout(()=>{
            if (chatDiv.current) {
              chatDiv.current.scrollTop = chatDiv.current.scrollHeight+1000000;
            }
          }, 100);
  
          setTimeout(()=>{loopGuestGetChat()}, 3000);
        }
      })
    }
  };

  const detectMobileDevice = () => {
    const userAgent = navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    return isMobile;
  };

  useEffect(() => {
    isAlive = true;

    requestMyInfo().then((response) => {
      setMyInfo(response.info);

      requestGetChat(0).then((response) => {
        setChatList(response.list)
  
        setTimeout(()=>{
          if(chatDiv.current){
            chatDiv.current.scrollTop = chatDiv.current.scrollHeight+1000000;
          }
        },100);
      })

      if (response.info && response.info.userId === -1) {
        setTimeout(()=>{loopGuestGetChat()}, 3000);
      }
    })

    updateChatDeleteListener((data:{ userId: number; chatId: number; })=>{
      let copy = JSON.parse(JSON.stringify(chatList));
      let s = _.filter(copy, item => item['chatId'] !== data.chatId);
      s = s ? s : [];

      setChatList(s);
    });

    if (!detectMobileDevice() && inputRef.current) {
      inputRef.current.focus();
    }

    return () => {
      isAlive = false;
      updateChatDeleteListener(null);
    }
  }, []);

  useEffect(()=>{
    if (ChatCount) {
      requestGetChat(0).then((response:any) => {
        setChatList(prev =>{
          let arr = [...response.list, ...prev];
          return arr.filter((_, index) => {
            return arr.findIndex((_1) => _.chatId === _1.chatId) === index;
          }).sort((a, b) => {
            return a.chatId - b.chatId;
          });
        });

        setTimeout(()=>{
          if(chatDiv.current){
            chatDiv.current.scrollTop = chatDiv.current.scrollHeight+1000000;
          }
        },100);
      });
    }
  }, [ChatCount])

  const handleSendChat = () => {
    const message = document.getElementById('message-input') as HTMLInputElement;
    const messageText = message.value;

    if (messageText.length === 0) {
      return;
    }
    
    requestSendChat(messageText).then((response) => {
      if (response.result) {
        setChatList(prev => [...prev, {
          chatId: response.chatId,
          userId: myInfo?.userId as number,
          sendUserId: myInfo?.userId as number,
          message: messageText,
          addedAt: moment().format("YYYY-MM-DD HH:mm:ss"),
        }]);

        message.value = '';

        setTimeout(()=>{
          if(chatDiv.current) {
            chatDiv.current.scrollTop = chatDiv.current.scrollHeight+1000000;
          }
        },100);
      }
      else if (response.error && response.error !== '') {
        openDialog({
          text: response.error,
          disableBackdropClick: true
        })
        return
      }
    })
  }
  const onClickClearChat = () => {
    requestClearChat().then((v)=> {
      setChatList([]);
    });
  };
  const replaceLinks = (text:string) => {
    const regex = /(https?:\/\/[^\s]+)/g;
    const replacedText = text.replace(regex, (url) => {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
    return replacedText;
  };
  return <CustomerCenterWrapper>
    <Header>
      <div className='wrap'>
        <img className='close' src='/images/ic_close.png' onClick={() => onClose && onClose()}/>
        <div className='title'><img onClick={() => onClose && onClose()} className={'close'} src='/images/pc/Back.png'/>고객센터</div>
        <div className='clearHistory' onClick={onClickClearChat}>
          <img src={`/images/nes/${orientation !== 'portrait' ? 'clear' : 'cleanMobile'}.png`} />
          {
            orientation !== 'portrait' ? (
              <div>기록삭제</div>
            ) : <></>
          }
        </div>
      </div>
    </Header>
    <div className='content-wrapper' ref={chatDiv}>
      <div className='content'>
        {
        myInfo !== null &&
          chatList.map((_, index) => {
            if(_.sendUserId === myInfo.userId){ //유저
              return <UserMessageContainer key={`me-${index}`}>
                <div className='message' style={{whiteSpace:"pre-line"}}>{_.message}</div>
                <div className='date'>{_.addedAt.replace("T"," ").split(".")[0]}</div>
              </UserMessageContainer>
            }else{
              return <AdminMessageContainer key={`sent-${index}`}>
                <div className='title-row'>
                  <div className='admin'>관리자</div>
                  <div className='date'>{_.addedAt.replace("T"," ").split(".")[0]}</div>
                </div>
                <div className='message' style={{whiteSpace:"pre-line"}} 
                  dangerouslySetInnerHTML={{
                    __html: replaceLinks(
                      _.message
                    ),
                  }}
                />
              </AdminMessageContainer>
            }
          })
        }
      </div>
    </div>
    <Footer>
      <div className='input-wrapper'>
        
        <input id='message-input' ref={inputRef} type='text' placeholder='메시지를 입력하세요.' onKeyUp={
          (event) => {
            if (event.key === 'Enter') {
              event.preventDefault();

              const message = document.getElementById('message-input') as HTMLInputElement;
              if (previousChat !== message.value) {
                handleSendChat();
                setPreviousChat(message.value);
              }
            }
          }
        } onChange={(event) => {
          if (event.target.value.length > 0) {
            setShowSendButton(true);
          } else {
            setShowSendButton(false);
          }
        }}/>
        <img src='/images/ic_send.png' className='send-button' onClick={handleSendChat}/>
      </div>
    </Footer>
  </CustomerCenterWrapper>
}
export default CustomerCenter;
