/* eslint-disable */
//auto gen
import {
  BET_TYPE,
  GAME_STATUS,
  MyPlayStatus,
  ROOM_JOIN_STATUS,
  ROOM_STATUS,
  ROOM_TYPE,
  RoomInfo,
  RoomStatus,
  winnerModel,
  RAKE_TYPE,
  GroupInfo,
  emitAfterBetModel,
  requestBetModel,
  handHistoryModel,
  GamePlayer,
  userProfileModel,
  tournamentRankingModel,
  winnerListModel,
  TX_TYPE,
  SimpleTournamentUser,
  CreateRingRoomInterface,
  CreateTournamentRoomInterface,
  chatModel,
  UserTransactionData,
  VAccInfo,
  PointHistoryInfo,
  AttendanceHistoryInfo,
  UserMoneyUpdateType,
  NoticeSimpleInfo,
  NoticeInfo,
  BannerInfo,
  RecommendedUser,
  MainBannerInfo
} from '../dataset/common'
import { client, SockClientOpt, OnRecvData } from "./index";
import { useEffect, useState, useLayoutEffect } from "react";
const hash = require('object-hash');

let listener:Function[] = [];
let ackListner:Function|null = null;

export const setAckListener = function(func:Function|null){
  ackListner = func;
}
export const addListener = function(func:Function){
  if(listener.indexOf(func) == -1){
    listener.push(func)
  }
  return func
}

export const removeListener = function(func:Function){
  let idx = listener.indexOf(func);
  if( idx >= 0){
    listener.splice(idx, 1);
  }
}

client.off("from:game:refreshRoomInfo");
client.on("from:game:refreshRoomInfo", async function(data:RoomInfo, ack:Function){
  for(let f of listener){
    await f(data);
  }
  
  if(ackListner){
    let ret = await ackListner(data);
    await ack(ret)
  }else{
    await ack()
  }
})

export default function () : [RoomInfo | null] {
  const [Data, setData] = useState<RoomInfo | null>(null);

  useEffect(() => {
    const listener = async(data:RoomInfo)=>{
      setData(data);
    }

    client.on("from:game:refreshRoomInfo",listener)
    return () => {
      client.off("from:game:refreshRoomInfo",listener)
    };
  },[]);

  return [Data]
}