import styled from "styled-components";
import {useRef} from "react";
import {startLoading, stopLoading} from "../../../components/Loading";
import {requestLogin, useSocketIsConnect} from "../../../api";
import {sha256} from "js-sha256";
import useDialog from "../../../hooks/useDialog";
import {useNavigate} from "react-router-dom";

const LoginModalWrapper = styled.div`
  width: 470px;
  height: 468px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #FFF;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  > .header {
    position: relative;
    width: 100%;
    height: 57px;
    border-bottom: 1px solid #DDD;
    background: #F5F5F5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    > .title {
      color: var(--Black, #181818);
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 20px */
    }

    > .close {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 40px;

    > .login-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 16px;
      width: 100%;
      max-width: 374px;

      > .lost-and-found {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        color: #4A9981;
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%; /* 12px */
        gap: 7px;

        > .button {
          cursor: pointer;
        }
      }
    }
  }
`
const LoginButton = styled.div`
  cursor: pointer;
  margin-top: 24px;
  width: 100%;
  max-width: 300px;
  height: 48px;
  color: #FFF;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 26px;
  background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
`
const SignUpButton = styled.div`
  cursor: pointer;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #169E7A;
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const HelpButton = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;

  > span {
    color: var(--Black, #181818);
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
  }

  > img {
    width: 32px;
    height: 32px;
  }
`

const InputWrapper = styled.div`
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid rgba(24, 24, 24, 0.30);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > input {
    flex-grow: 1;
    color: #181818;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    border: none;
    background: none;
    text-align: left;
    outline: none;

    &::placeholder {
      opacity: 0.5;
    }
  }

  > img {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
  }
`

interface LoginModalProps {
  onClose: () => void;
  openSignUp: () => void;
  openFindID: () => void;
  openFindPW: () => void;
}

const LoginModal = ({
                      openFindID,
                      openFindPW,
                      openSignUp,
                      onClose
                    }: LoginModalProps) => {
  const navigate = useNavigate();
  const {openDialog} = useDialog();
  const idRef = useRef<HTMLInputElement>(null);
  const pwRef = useRef<HTMLInputElement>(null);
  const handleLogin = () => {
    const ID = idRef.current?.value;
    const PW = pwRef.current?.value;
    if (!ID || !PW) {
      openDialog({
        title: '로그인 실패',
        text: '아이디와 비밀번호를 입력해주세요.',
      })
      return;
    }
    const loading = startLoading();
    requestLogin(ID, sha256(PW))
      .then((res) => {
        stopLoading(loading);
        if (res.login_token) {

          onClose();
        } else {
          const innerRes = (res as any)
          if (innerRes.message) {
            if (innerRes.message === 'WAITING CONFIRM') {
              openDialog({
                title: '로그인 실패',
                text: '가입 승인 대기중입니다.',
              })
            } else if (innerRes.message === 'REJECT JOIN') {
              openDialog({
                title: '로그인 실패',
                text: '가입이 거절되었습니다.',
              })
            } else if (innerRes.message === 'BANNED USER') {
              openDialog({
                title: '로그인 실패',
                text: '접속이 거절되었습니다.',
              })
            } else if(innerRes.message.length > 10) {
              openDialog({
                title: '로그인 실패',
                text: innerRes.message,
              })
            } else {
              openDialog({
                title: '로그인 실패',
                text: '아이디와 비밀번호를 확인해주세요.',
              })
            }
          } else {
            openDialog({
              title: '로그인 실패',
              text: '아이디와 비밀번호를 확인해주세요.',
            })
          }
        }
      })
      .catch((err) => {
        stopLoading(loading);
        console.log(err);
      }).finally(() => {

    })
  }
  return <LoginModalWrapper>
    <div className='header'>
      <div className='title'>
        로그인
      </div>
      <img src='/images/pc/Close.png' className='close' onClick={onClose}/>
    </div>
    <div className='content'>
      <div className='login-wrapper'>
        <InputWrapper>
          <input ref={idRef} placeholder='아이디' onChange={e => {
            e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
          }}/>
          <img src='/images/pc/ID.png'/>
        </InputWrapper>
        <InputWrapper>
          <input type='password' ref={pwRef} placeholder='비밀번호'/>
          <img src='/images/pc/PW.png'/>
        </InputWrapper>
        <div className='lost-and-found'>
          <div className='button' onClick={() => {
            onClose();
            openFindID();
          }}>아이디 찾기
          </div>
          <div>|</div>
          <div className='button' onClick={()=>{
            onClose();
            openFindPW();
          }}>비밀번호 찾기</div>
        </div>
      </div>
      <LoginButton onClick={handleLogin}>
        로그인
      </LoginButton>
      <SignUpButton onClick={()=>{
        onClose();
        openSignUp();
      }}>
        회원가입
      </SignUpButton>
      <HelpButton  onClick={()=>{
        window.open('https://t.me/deeppoker0', '_blank')
      }}>
        <span>문의사항이 있으신가요?</span>
        <img src='/images/ic_telegram.png'/>
      </HelpButton>
    </div>
  </LoginModalWrapper>
}
export default LoginModal;
