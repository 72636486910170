import styled from "styled-components";
import {useMemo} from "react";

const Wrapper = styled.div`
  width: 100%;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  background: #181818;
`;
const HandWrapper = styled.div`
  background: linear-gradient(180deg, #25262D 0%, rgba(37, 38, 45, 0.00) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2px;
  padding: 20px 0 15px;

  > .title {
    color: #FFF;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }

  > .hand {
    color: #8D7AFF;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.2px;
  }
`;
const DescriptionWrapper = styled.div`
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  > .description {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 19.6px */
    letter-spacing: -0.2px;
    text-align: center;

    > .highlight {
      color: #8D7AFF;
    }
  }

  > .sub-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    width: 230px;
    padding: 10px;
    border-radius: 8px;
    background: #25262D;
    color: rgba(255, 255, 255, 0.5);
    text-align: center;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
  }
`;
const SettlementWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 16px 0;
  background: linear-gradient(180deg, #25262D 0%, rgba(37, 38, 45, 0.00) 100%);
  >.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2px;
    >.chip {
      padding-top: 4px;
      height: 24px;
      object-fit: contain;
      margin-right: 2px;
    }
    >.title {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      opacity: 0.5;
    }
    >.value {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      >.diff {
        margin-left: 4px;
        color: #FF3D3D;
      }
    }
  }
`;

function LeaveSettlement(
  {
    buyin,
    hands,
    stack,
    lossPercentage,
    settle,
    maxHands,
    winAmount,
    additionalWinAmount = 0,
    lack,
    isStandup
  }: {
    buyin: number,
    hands: number,
    stack: number,
    lossPercentage: number,
    settle: number,
    maxHands: number,
    winAmount: number,
    lack: boolean,
    isStandup: boolean,
    additionalWinAmount?: number
  }
) {
  const lossAmount = useMemo(() => {
    return Math.floor((winAmount + additionalWinAmount) * (lossPercentage / 100));
  }, [winAmount, additionalWinAmount, lossPercentage]);

  return <Wrapper>
    {isStandup && <div style={{paddingBottom: '10px', fontSize: '16px'}}>진행 중인 게임은 자동으로 폴드됩니다.</div>}
    
    <HandWrapper>
      <div className='title'>플레이 횟수</div>
      <div className='hand'>{hands.toLocaleString()}</div>
    </HandWrapper>
    <DescriptionWrapper>
      {isStandup ? (
        <div className='description'>
          일어서기를 이용 할 때는 정산이 차감되지 않지만,<br/>방을 나가는 경우 즉시 정산 금액이 차감됩니다.
        </div>
      ) : <></>}
      <div className='description'>
        {hands > 100 ? (
          <span>게임 횟수가 충족되어 수익에서<span className='highlight'>{lossPercentage}%</span><br/>
          제외되어 정산됩니다.</span>
        ) : (
          <span>게임 횟수가 부족하여 수익에서 <span className='highlight'>{lossPercentage}%</span><br/>
          제외되어 정산됩니다.</span>
        )}
      </div>
      <div className='sub-description'>
        게임 횟수별 수수료 차감 안내<br/>
        ~50회 : -25%<br/>
        51~100회 : -10%<br/>
        100회 초과 차감없음
      </div>
    </DescriptionWrapper>
    <SettlementWrapper>
      <div className='row'>
        <div className='title'>수익금</div>
        <div className='value'>{(winAmount).toLocaleString()}</div>
        {
          additionalWinAmount && additionalWinAmount > 0 ? (
            <>
              <div className='title'>+ 이전 방 수익금</div>
              <div className='value'>{additionalWinAmount.toLocaleString()}</div>
            </>
          ) : <></>
        }
      </div>
      {
        (winAmount + additionalWinAmount) !== 0 ? (
        <div className='row'>
          <div className='title'>보유 금액</div>
          <div className='value'>
            {settle.toLocaleString()}
            {
              lossAmount > 0 && (
                <span className="diff" data-loss="true">
                ({`-${lossAmount.toLocaleString()})`}
              </span>
              )
            }
          </div>
        </div>
        ) : <></>
      }
      <div className='row'>
        <span>=</span>
        <img src='/images/chips.png' className='chip'/>
        <div className='title'>정산 금액</div>
        <div className='value'>
          {(settle - (lossAmount > 0 ? lossAmount : 0)).toLocaleString()}
        </div>
      </div>
    </SettlementWrapper>
  </Wrapper>
}

export default LeaveSettlement;
