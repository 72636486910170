import styled from "styled-components";

import React, {useEffect, useState} from "react";
import {MyInfo, PointHistoryInfo, userProfileModel} from "../../dataset";
import {requestGetPointHistory, requestGetUserProfile, requestMyInfo} from "../../api";
import ModalContainer from "../../components/common/ModalContainer";
import HandDescModal from "../../components/game/HandDescModal";
import UserStatusHelpModal from "../../components/UserStatusHelpModal";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
const HorizontalBar = styled.div`
  width: 100%;
  height: 12px;
  background: #25262D;
  @media ${MEDIA_DESKTOP} {
    background: #F6F6F6;
  }
`
const UserDetailWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background: var(--Background, #151515);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media ${MEDIA_DESKTOP} {
    top: 60px;
    height: calc(100% - 60px);
    background: white;
  }

  > .content-wrapper {
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
    @media ${MEDIA_DESKTOP} {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
    > .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 16px 20px;
      gap: 20px;
    }
  }
`
const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  @media ${MEDIA_DESKTOP} {
    border-bottom: 1px solid #DDD;
    background: #F5F5F5;
    height: 57px;
  }

  > .close {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    cursor: pointer;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
  }

  > .title {
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    @media ${MEDIA_DESKTOP} {
      position: relative;
      width: 470px;
      color: var(--Black, #181818);
      text-align: center;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%; /* 20px */
    }

    > .close {
      display: none;
      @media ${MEDIA_DESKTOP} {
        cursor: pointer;
        display: block;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
      }
    }
  }
`

const InfoWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 20px 20px;
  gap: 8px;
  @media ${MEDIA_DESKTOP} {
    max-width: 470px;
    padding-top: 40px;
    padding-bottom: 32px;
  }
  > .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > .title {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
      opacity: 0.4;
      @media ${MEDIA_DESKTOP} {
        color: var(--Black, #181818);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
      }
    }

    > .value {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 16.8px */
      letter-spacing: -0.5px;
      @media ${MEDIA_DESKTOP} {
        color: var(--Black, #181818);
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.5px;
      }
    }
  }
`
const StatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20px;
  gap: 20px;
  @media ${MEDIA_DESKTOP} {
    width: 100%;
    max-width: 470px;
  }

  > .title {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    color: #FFF;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    gap: 5px;
    @media ${MEDIA_DESKTOP} {
      color: var(--Black, #181818);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.5px;
    }

    > .img-wrapper {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #262838;
      @media ${MEDIA_DESKTOP} {
        background: rgba(22, 158, 122, 0.10);
      }
      > img {
        width: 16px;
        height: 16px;
      }
    }
  }

  > .content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;

    > .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 8px;

      > .title {
        color: #FFF;
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 19.6px */
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          color: var(--Black, #181818);
          text-align: center;
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: 140%; /* 19.6px */
          letter-spacing: -0.5px;
        }
      }

      > .circle {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: gray;
        @media ${MEDIA_DESKTOP} {
          width: 80px;
          height: 80px;
        }
      }

      > .value {
        color: #FFF;
        text-align: center;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%; /* 16.8px */
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          color: var(--Point-Mint, #169E7A);
          text-align: center;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }
    }
  }
`
const ProgressCircleWrapper = styled.div<{ percent: number }>`
  width: 64px;
  height: 64px;
  position: relative;
  @media ${MEDIA_DESKTOP} {
    width: 80px;
    height: 80px;
  }

  > .percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #E31D1D;
    text-align: center;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    @media ${MEDIA_DESKTOP} {
      color: var(--Point-Mint, #169E7A);
      text-align: center;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }
`

interface ProgressCircleProps {
  percent: number;
  isPercent?: boolean;
}

const ProgressCircle = ({isPercent = true, percent}: ProgressCircleProps) => {
  const orientation = useScreenOrientation();
  percent = (percent ?? 0);
  if (isPercent) {
    percent = Math.floor(percent * 10000) / 100
  }
  const circumference = 2 * Math.PI * 90;
  const offset = ((isPercent ? percent : 100) / 100) * circumference;
  return <ProgressCircleWrapper percent={percent}>
    <svg viewBox="0 0 200 200">
      <circle
        cx="100"
        cy="100"
        r="90"
        fill="none"
        stroke={ orientation === 'portrait' ? "#181818" : '#DDDDDD'}
        strokeWidth="15"
      />
      <circle
        cx="100"
        cy="100"
        r="90"
        fill="none"
        stroke={ orientation === 'portrait' ? "#E31D1D" : '#169E7A'}
        strokeWidth="10"
        strokeLinecap="round"
        strokeDasharray={circumference}
        strokeDashoffset={offset - circumference}
        transform="rotate(-90 100 100)"
        id="progress"
      />
    </svg>
    <div className='percent'>
      {percent}{isPercent ? '%' : ''}
    </div>
  </ProgressCircleWrapper>
}

interface UserDetailProps {
  onClose: () => void;
}

const UserDetail = ({onClose}: UserDetailProps) => {
  const orientation = useScreenOrientation();
  const [userProfile, setUserProfile] = useState<userProfileModel | null>(null);
  const [showHelp, setShowHelp] = useState(false);
  useEffect(() => {
    requestMyInfo().then((res) => {
      const userId = res.info.userId
      requestGetUserProfile(userId).then((res) => {
        console.log(res)
        setUserProfile(res);
      })
    })
  }, []);
  return <UserDetailWrapper>
    <ModalContainer show={showHelp} onBackdropClick={() => setShowHelp(false)}>
      <UserStatusHelpModal
        onClose={() => setShowHelp(false)}
      />
    </ModalContainer>
    <Header>
      <img className='close' src='/images/ic_arrow_back.png' onClick={() => onClose()}/>
      <div className='title'><img onClick={() => onClose()} className={'close'} src='/images/pc/Back.png'/>유저 상세정보</div>
    </Header>
    {
      userProfile && <div className='content-wrapper'>
        <InfoWrapper>
          <div className='row'>
            <div className='title'>1게임 최대 수익</div>
            <div className='value'>{(userProfile.maxWin ?? 0).toLocaleString()}</div>
          </div>
          <div className='row'>
            <div className='title'>1게임 최대 손실</div>
            <div className='value'>{(userProfile.maxLoss ?? 0).toLocaleString()}</div>
          </div>
          <div className='row'>
            <div className='title'>최근 일주일 손수익</div>
            <div className='value'>{(userProfile.oneWeekProfit ?? 0).toLocaleString()}</div>
          </div>
        </InfoWrapper>
        <HorizontalBar/>
        <StatWrapper>
          <div className='title'>
            전적 <div className='img-wrapper' onClick={() => setShowHelp(true)}>
            {orientation === 'portrait' ? <img src='/images/Question.png'/> : <img src='/images/pc/Question.png'/>}
          </div>
          </div>
          <div className='content'>
            <div className='item'>
              <div className='title'>핸드</div>
              <ProgressCircle isPercent={false} percent={userProfile.handCount}/>
            </div>
            <div className='item'>
              <div className='title'>참여율 (VPIP)</div>
              <ProgressCircle percent={userProfile.vpip}/>
            </div>
            <div className='item'>
              <div className='title'>참여 승률</div>
              <ProgressCircle percent={userProfile.winRate}/>
            </div>
            <div className='item'>
              <div className='title'>PFR</div>
              <ProgressCircle percent={userProfile.pfr}/>
            </div>
            <div className='item'>
              <div className='title'>3BET</div>
              <ProgressCircle percent={userProfile.bet3}/>
            </div>
            <div className='item'>
              <div className='title'>ATS</div>
              <ProgressCircle percent={userProfile.ats}/>
            </div>
          </div>
        </StatWrapper>
      </div>
    }
  </UserDetailWrapper>
}

export default UserDetail;
