import {RAKE_TYPE, ROOM_TYPE} from "../../dataset";
import React, {useMemo} from "react";
import styled from "styled-components";
import {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import {
  RoomInfo
} from '../../dataset';

const Wrapper = styled.div<{isTournament: boolean}>`
  ${p => p.isTournament ? `
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 4px;
    color: #FFF;
    text-align: right;
    font-family: Pretendard;
    font-size: 9px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: -0.2px;
    text-align: center;
    width: 140px;
    height: 80px;

    @media ${MEDIA_DESKTOP} {
      color: rgb(255, 255, 255);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.2px;
      background: rgba(0, 0, 0, 0.4);
      padding: 8px;
      border-radius: 10px;
      opacity: 1;
      border: 2px solid #7e7e7e;
      width: 200px;
      max-height: 107px;
      height: initial;
    }
  ` : `
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 4px;
    color: #FFF;
    text-align: right;
    font-family: Pretendard;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;

    @media ${MEDIA_DESKTOP} {
      color: #FFF;
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.2px;
    }
  `};
`;

function StatusBoard(
  {
    room,
    roomType,
    SB,
    BB,
    ante,
    rakeType,
    rake,
    myRank,
    totalMember,
    straddle,
    timerText,
    blindUpTimerText,
    currentLevel,
    restTimeStatus,
    blindsTextTournament
  }: {
    room: RoomInfo;
    roomType: ROOM_TYPE;
    SB: number;
    BB: number;
    ante: number;
    rakeType: RAKE_TYPE;
    rake: number;
    myRank: number;
    totalMember: number;
    straddle: boolean;
    timerText: string;
    blindUpTimerText: string;
    currentLevel: number;
    restTimeStatus: number;
    blindsTextTournament: string;
  }
) {

  const tournamentStatusText = useMemo<string>(() => {
    return restTimeStatus === -1 ? 
    '' : restTimeStatus === 0 ? 
    '휴식까지: ' : '시작까지: ';
  }, [restTimeStatus]);

  const ringBetText = useMemo<string>(() => {
    let chunks = [];

    if (SB > 0 && BB > 0) {
      chunks.push(`SB ${SB.toLocaleString()} / BB ${BB.toLocaleString()}`);
    }
    if (ante > 0) {
      chunks.push(`앤티 ${ante.toLocaleString()}`);
    }

    return chunks.join(', ');
  }, [SB, BB, ante]);

  const rakeText = useMemo<string>(() => {
    if (rakeType === RAKE_TYPE.HAND_RAKE) {
      return `핸드레이크 ${rake.toLocaleString()}`;
    } else if (rakeType === RAKE_TYPE.POT_RAKE) {
      return `팟레이크 ${rake}%`;
    }

    return '';
  }, [rakeType, rake]);

  return <div>
    {
      roomType == ROOM_TYPE.TOURNAMENT && timerText && <>
        <Wrapper className="status-board" isTournament={true}>
          <div>현재 레벨: {currentLevel ? currentLevel : 1}</div>
          <div>{tournamentStatusText}{timerText}</div>
          <div>{blindUpTimerText}</div>
          {
            blindsTextTournament.length > 0 && (
              <div>{blindsTextTournament}</div>
            )
          }
          {
            Boolean(myRank && totalMember) && (
              <div>내 순위 {myRank} / {totalMember}</div>
            )
          }
        </Wrapper>
      </>
    }
    {
      roomType == ROOM_TYPE.RING && <>
        {
          ringBetText.length > 0 && (
            <Wrapper className="status-board" isTournament={false}>
              <div>{ringBetText}</div>
            </Wrapper>
          )
        }
        {/*{
          rakeText.length > 0 && (
            <div>{rakeText}</div>
          )
        }
        {
          Boolean(straddle) && (
            <div>스트래들 있음</div>
          )
        */}
      </>
    }
  </div>;
}

export default StatusBoard;
