import styled from "styled-components";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {handHistoryModel, CARD_INFO} from "../../dataset";
import {requestGetHandHistory} from "../../api";
import HistoryPlayer from "./HistoryPlayer";
import Flex from "../common/Flex";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
import HistoryPagination from "./HistoryPagination";
import {useRecoilValue} from "recoil";
import {gameOptionState} from "../../recoil/GameOption";
import {playSFX, Sounds} from "../../utils/sound";
import GameHistoryGaugeHorizontal from "./GameHistoryGaugeHorizontal";
import {toCardString} from "../../utils/common";

// @ts-ignore
import {Hand} from "pokersolver";

const Wrapper = styled.div`
  width: 264px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #101012;
  border-left: 1px solid #E31D1D;
  color: #FFF;

  @media ${MEDIA_DESKTOP} {
    width: 640px;
  }
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  background: rgba(227, 29, 29, 0.3);

  @media ${MEDIA_DESKTOP} {
    height: 72px;
  }

  > .back {
    width: 48px;
    height: 48px;
    padding: 12px;
    cursor: pointer;

    @media ${MEDIA_DESKTOP} {
      width: 72px;
      height: 72px;
      padding: 24px;
    }
  }

  > .title {
    flex: 1;
    font-size: 16px !important;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > .gap {
    width: 48px;

    @media ${MEDIA_DESKTOP} {
      width: 72px;
    }
  }
`;

const Content = styled.div`
  flex: 1;
  overflow: auto;
  padding: 0 12px;

  @media ${MEDIA_DESKTOP} {
    padding: 0;
  }
`;

const TimeHead = styled.div`
  background: rgb(54 15 16);
  padding-top: 6px;
  padding-bottom: 6px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 10px;
  justify-content: center;

  @media ${MEDIA_DESKTOP} {
    font-size: 13px;
  }
  >.left {
    display: flex;
    align-items: center;
    padding-left: 10px;
    >img {
      width: 16px;
      height: 16px;
      @media ${MEDIA_DESKTOP} {
        width: 20px;
        height: 20px;
      }
    }
    >span {
      padding-left: 4px;
    }
  }
  >.right {
    display: flex;
    align-items: center;
    padding-left: 10px;
    >img {
      width: 16px;
      height: 16px;
      @media ${MEDIA_DESKTOP} {
        width: 18px;
        height: 16px;
      }
    }
    >span {
      padding-left: 4px;
    }
  }
`;

const GameCards = styled.div`
  width: 100%;
  height: 48px;
  border-radius: 8px;
  background: #25262D;
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
  align-items: center;
  padding: 8px 12px;

  @media ${MEDIA_DESKTOP} {
    background: transparent;
    margin-top: 10px;
  }

  > .title {
    font-size: 10px;
    font-weight: 500;
    margin-right: 20px;
  }

  > .cards {
    flex: 1;
    display: flex;
    gap: 6px;
    @media ${MEDIA_DESKTOP} {
      gap: 6px;
    }
    > img {
      width: 24px;
      height: 32px;

      @media ${MEDIA_DESKTOP} {
        width: 40px !important;
        height: 56px;
      }
    }
  }
`;

const DefaultView = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${MEDIA_DESKTOP} {
    height: 346px;
  }

  .table {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    > .seats {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    > .dealer {
      position: absolute;
      width: 16px;
      left: -100%;
      top: -100%;
      z-index: 2;
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.40));
    }

    > .pot {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2px;

      img {
        width: 48px;
      }

      .sidepot {
        width: fit-content;
        padding: 2px 8px;
        font-size: 10px;
        transform: scale(0.9);
        font-weight: 600;
        border-radius: 15px;
        background: rgba(24, 26, 29, 0.50);
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12) inset;
      }

      .box {
        position: relative;
        padding: 2px 4px 2px 18px;
        >.background{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          background: rgba(24, 24, 24, 0.50);
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12) inset;
          z-index: 1;
        }
        >.amount {
          position: relative;
          text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.30);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: -0.2px;
          background: linear-gradient(180deg, #FFF 0%, #EAEAEA 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          z-index: 2;
        }
        > .chips {
          width: 17px;
          height: 100%;
          position: absolute;
          top: 2px;
          left: -13px;
          z-index: 2;
          img {
            position: absolute;
            width: 17px;
            height: 14px;

            &:nth-child(1) {
              top: 4px;
            }

            &:nth-child(2) {
              top: 2px;
            }

            &:nth-child(3) {
              top: 0px;
            }
          }
        }
      }

      > div:last-child {
        color: #2F99FB;
        font-weight: 700;
      }

      @media ${MEDIA_DESKTOP} {
        font-size: 14px;

        > div:first-child {
          display: none;
        }

        > div:last-child {
          color: #FFF;
        }
      }
    }
  }
}
`;

const NoHistory = styled(DefaultView)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DetailView = styled.div`
  color: #FFF;

  @media ${MEDIA_DESKTOP} {
    padding: 12px 16px;
  }

  > .round-item {
    font-size: 10px;

    @media ${MEDIA_DESKTOP} {
      display: flex;
      gap: 12px;
      padding: 12px 0;
      border-bottom: 1px solid #2D2D2D;
      font-size: 12px;
      align-items: flex-start;

      > div {
        flex: 1;
      }
    }

    > .community {
      display: flex;
      align-items: center;
      gap: 12px;
      padding: 12px 0;
      border-top: 1px solid #2D2D2D;
      border-bottom: 1px solid #2D2D2D;

      @media ${MEDIA_DESKTOP} {
        border: none;
        font-size: 12px;
      }

      > div {
        display: inline-flex;
        align-items: center;
      }

      > .round-name {
        width: 34px;
        color: #FFF;
        font-family: Pretendard;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;

        @media ${MEDIA_DESKTOP} {
          width: 44px;
        }

      }

      > .round-cards {
        display: flex;
        gap: 4px;

        > img {
          width: 15px;
          height: 20px;

          @media ${MEDIA_DESKTOP} {
            width: 24px;
            height: 32px;
          }

        }
      }

      > .count {
        > .chips {
          margin-left: 2px;
          width: 12px;
          height: 100%;
          display: flex;
          gap: 2px;
          position: relative;

          > img {
            position: absolute;
            width: 12px;
            height: 12px;
            left: 50%;
            transform: translateX(-50%);

            &:nth-child(1) {
              top: -2px;
            }

            &:nth-child(2) {
              top: -4px;
            }

            &:nth-child(3) {
              top: -6px;
            }

          }
        }

        > img {
          width: 12px;
          height: 12px;
          margin-right: 2px;

          @media ${MEDIA_DESKTOP} {
            width: 16px;
            height: 16px;
            margin-right: 4px;
          }
        }

        > &:last-child {
          opacity: 0.7;
        }
      }
    }

    > .bets {
      display: flex;
      flex-direction: column;
      padding: 12px 0;
      gap: 8px;

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 4px;

        > .position {
          width: 40px;
          padding: 2px 4px;
          font-size: 9px;
          font-weight: 500;
          text-align: center;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.10);
          overflow: hidden;

          @media ${MEDIA_DESKTOP} {
            width: 48px;
            font-size: 12px;
          }
        }

        > .nickname {
          color: #FFF;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.2px;

          @media ${MEDIA_DESKTOP} {
            font-size: 14px;
          }
        }

        > .amount {
          color: #FFF;
          font-family: Pretendard;
          font-size: 10px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.2px;
          padding-bottom: 2px;
        }

        > .action {
          padding: 2px 4px;
          font-size: 9px;
          font-weight: 500;
          text-align: center;
          border-radius: 2px;
          background: #17171B;


          &[data-action="FOLD"] {
            color: #FFF;
            background: #7E7E7E;
          }

          &[data-action="SB"], &[data-action="BB"], &[data-action="STR"] {
            color: #181818;
            background: #FFF;
          }

          &[data-action="CHECK"] {
            color: #FFF;
            background: #00A7EE;
          }

          &[data-action="CALL"] {
            color: #FFF;
            background: #FE7C10;
          }

          &[data-action="RAISE"] {
            color: #FFF;
            background: #04D733;
          }

          &[data-action="BET"] {
            color: #FFF;
            background: #7A04D7;
          }

          &[data-action="ALLIN"] {
            color: #FFFFFF;
            background: #DB0007;
          }
        }
      }
    }
  }
`;

const ShowDownView = styled.div`
  color: rgb(255 255 255);
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;

  @media ${MEDIA_DESKTOP} {
    padding: 12px 16px;
    padding-top: 0;
  }

  >.title {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 4px;
    padding: 12px 0px;
    border-top: 1px solid rgb(45, 45, 45);
    border-bottom: 1px solid rgb(45, 45, 45);
    >img {
      width: 16px;
      height: 16px;
    }
    @media ${MEDIA_DESKTOP} {
      border-top: 0;
      padding-top: 0;
    }
  }
  >.wrap {
    padding: 12px 0px;
    >.bets {
      display: flex;
      flex-direction: column;
      padding: 8px 0px;
      &:first-child { 
        padding-top: 0;
      }
      >.player {
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        gap: 6px;
        >.position {
          width: 40px;
          padding: 2px 4px;
          font-size: 9px;
          font-weight: 500;
          text-align: center;
          border-radius: 8px;
          background: rgba(255, 255, 255, 0.1);
          overflow: hidden;
          @media ${MEDIA_DESKTOP} {
            width: 48px;
            font-size: 12px;
          }
        }
        >.playerInfo {
          >.nickname {
            @media ${MEDIA_DESKTOP} {
              font-size: 14px;
            }
          }
          >.showCards {
            display: flex;
            >.playerCards {
              display: flex;
              gap: 4px;
              >img {
                width: 15px;
                height: 20px;
                filter: brightness(0.5);
                &.highlight {
                  filter: none;
                  box-shadow: 0px 2.2px 2.2px 0px rgba(0, 0, 0, 0.20), 0px 0px 6.6px 0px #FFD764;
                }
                @media ${MEDIA_DESKTOP} {
                  width: 24px;
                  height: 32px;
                }
              }
            }
            >.community {
              padding-left: 15px;
              display: flex;
              gap: 4px;
              >img {
                width: 15px;
                height: 20px;
                filter: brightness(0.5);
                &.highlight {
                  filter: none;
                  box-shadow: 0px 2.2px 2.2px 0px rgba(0, 0, 0, 0.20), 0px 0px 6.6px 0px #FFD764;
                }
                @media ${MEDIA_DESKTOP} {
                  width: 24px;
                  height: 32px;
                }
              }
            }
          }
          >.pedigree {
            text-align: center;
            padding-top: 4px;
            height: 10px;
            >span {
              border: 1px solid #fff;
              border-radius: 10px;
              padding: 1px;
              padding-left: 8px;
              padding-right: 7px;
              display: inline-block;
              font-size: 9px;
            }
            @media ${MEDIA_DESKTOP} {
              height: 18px;
            }
          }
        }
        >.profits {
          margin-left: auto;
          &.green {
            color: #08ff08;
          }
          &.red {
            color: #ff3f3f;
          }
          @media ${MEDIA_DESKTOP} {
            font-size: 13px;
            padding-bottom: 2px;
          }
        }
      }
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  background: linear-gradient(180deg, #25262D 0%, rgba(37, 38, 45, 0.00) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
`;

const ChangeViewButton = styled.div`
  font-size: 12px;
  font-weight: 500;
  padding: 8px 16px;
  border-radius: 8px;
  background: var(--Gradient-Red, linear-gradient(180deg, #DF3535 0%, #A51818 100%));
`;

const SliderWrapper = styled.div`
  background: #1b1b1b;
  padding: 10px 16px 20px;
  width: 100%;
  height: 78px;
  display: flex;
  z-index: 8;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > .plus-minus-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    > img {
      width: 24px;
      height: 24px;
      flex-shrink: 0;
      object-fit: contain;
    }
  }

  > .slider {
    height: 28px;
    width: 360px;
  }
`

function GameHistory(
  {
    groupId,
    roomId,
    handNumber,
    maxTableMember,
    onClose,
    currentRound,
    isOpen
  }: {
    groupId: number;
    roomId: number;
    handNumber: number;
    maxTableMember: number;
    onClose?: () => void;
    currentRound?: string;
    isOpen: boolean;
  }
) {
  const orientation = useScreenOrientation();
  const seatsRef = useRef<HTMLDivElement>(null);
  const dealerButtonRef = useRef<HTMLImageElement>(null);
  const gameOption = useRecoilValue(gameOptionState);
  const [currentPage, setCurrentPage] = useState<number>(handNumber - 1);
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [handHistory, setHandHistory] = useState<handHistoryModel[]>([]);
  const [curRoundBlind, setCurRoundBlind] = useState<Array<number>>([]);
  const [curRoundEndDate, setCurRoundEndDate] = useState<string>('');

  const endPage = useMemo(() => {
    if (handNumber > 0 && (currentRound === undefined || currentRound === null)) {
      return handNumber
    } else {
      return handNumber - 1;
    }
  }, [handNumber, currentRound]);

  useEffect(() => {
    setCurrentPage(endPage)
  }, [isOpen])

  const lastGameData = useMemo(() => {
    if (!handHistory || handHistory.length === 0) {
      return;
    }

    return handHistory.slice(-1)[0].lastGameData;
  }, [handHistory]);

  const isShowDownRound = useMemo(() => {
    if (lastGameData) {
      for (let i = 0; i < lastGameData.cards.length; i++) {
        let a:any = lastGameData.cards[i];

        if (a.isShow) {
          return true;
        }
      }
    }

    return false;
  }, [lastGameData]);

  const dealerSeat = useMemo<number>(() => {
    let player;
    player = handHistory.find(x => x.position === 'dealer');
    if (!player) {
      // 딜러 포지션이 없으면 1:1 상황으로 판단하고 bb를 딜러로 설정한다.
      player = handHistory.find(x => x.position === 'bb');
    }

    return !player ? -1 : player.seat;
  }, [handHistory]);

  const players = useMemo(() => {
    const arr: {
      userId: number;
      seat: number;
      nickname: string;
      profileImg: string;
      isFold: boolean;
      position: string;
    }[] = [];

    for (let row of handHistory) {
      const player = arr.find(x => x.userId === row.userId);
      if (player) {
        player.isFold = row.fold;
      } else {
        arr.push({
          userId: row.userId,
          seat: row.seat,
          nickname: row.nickname,
          profileImg: row.profileImg,
          isFold: row.fold,
          position: row.position
        });
      }
    }

    return arr;
  }, [handHistory]);

  const showDownData = useMemo(() => {
    const retFormat: {
      communityCards: number[];
      players: {
        userId: number;
        nickname: string;
        position: string;
        profits: number;
        cards: number[];
        pedigree: string;
        isShow: boolean;
        ceremonyCards: string[];
      }[]
    } = {
      communityCards: [],
      players: []
    };

    if (isShowDownRound) {
      retFormat.communityCards = lastGameData!.communityCards;

      players.map((player) => {
        const cardObj:any = lastGameData!.cards.find(x => x.userId == player.userId);
        const profitsObj = lastGameData!.changeStackSizeList.find(x => x.userId == player.userId);
        let solved = null;
        let ceremonyCards: string[] = [];
        let position = player.position;

        if (position === 'dealer') {
          position = 'BT';
        }

        if (cardObj && cardObj.cards.length === 2) {
          solved = Hand.solve([
            ...cardObj.cards.map((x:any) => CARD_INFO[x]),
            ...retFormat.communityCards.map((x:any) => CARD_INFO[x])
          ])

          const targetCards = solved.cards.map((x: any) => toCardString(x.value, x.suit));
          for (let card of targetCards) {
            if (!ceremonyCards.includes(card)) {
              ceremonyCards.push(card);
            }
          }
        }

        retFormat.players.push({
          userId: player.userId,
          nickname: player.nickname,
          position: position.toUpperCase(),
          profits: (profitsObj!.stackSize - profitsObj!.prevStackSize),
          cards: cardObj.cards,
          pedigree: solved ? solved.descr : '',
          isShow: cardObj.isShow,
          ceremonyCards: ceremonyCards
        })
      });
    }

    return retFormat;
  }, [isShowDownRound, lastGameData, players]);

  const betHistory = useMemo(() => {
    if (handHistory.length === 0 || !lastGameData) {
      return [];
    }

    const playersPosition: {
      [userId: number]: string
    } = {};
    const arr: {
      round: number,
      cards: number[],
      players: number[],
      pot: number,
      bets: any[]
    }[] = [];
    const usersPosition: any = {}
    for (let row of handHistory) {
      const round = row.round;
      if (round === 0) {
        let position = row.position
        if (position === 'dealer') {
          position = 'BT';
        }
        usersPosition[row.userId] = position.toUpperCase()
      }
      if (!arr[round]) {
        arr[round] = {
          round: round,
          cards: lastGameData.communityCards.slice(0, round + 2),
          players: [],
          pot: 0,
          bets: [],
        };
      }

      if (arr[round].players.indexOf(row.userId) === -1) {
        arr[round].players.push(row.userId);
      }

      if (!playersPosition[row.userId]) {
        let position = row.position;
        if (position === 'dealer') {
          position = 'BT';
        }
        playersPosition[row.userId] = position.toUpperCase();
      }

      arr[round].pot += row.bet;
      arr[round].bets.push({
        betId: row.betId,
        userId: row.userId,
        nickname: row.nickname,
        bet: row.bet,
        position: usersPosition[row.userId],
        action: ['', 'CHECK', 'CALL', 'FOLD', 'RAISE', 'ALLIN', 'BET', 'SB', 'BB', 'STR'][row.type]
      });
    }

    return arr;
  }, [handHistory, lastGameData]);

  const totalPot = useMemo(() => {
    if (!lastGameData) {
      return 0;
    }

    return lastGameData.pots.reduce((a, v) => a + v.amount, 0);
  }, [lastGameData]);

  const arrangeLayout = useCallback(() => {
    if (!seatsRef.current) {
      return;
    }

    function position(arr: any, idx: number, left: number, top: number) {
      try {
        arr[idx].style.left = (left - arr[idx].offsetWidth / 2) + "px";
        arr[idx].style.top = (top - arr[idx].offsetHeight / 2) + "px";
      } catch (err) {
      }
    }

    const isPortrait = orientation === 'portrait';
    const seatRects = isPortrait ? [
      [0.2, 0.95],
      [0.08, 0.7],
      [0.08, 0.45],
      [0.08, 0.2],
      [0.5, 0.01],
      [0.92, 0.2],
      [0.92, 0.45],
      [0.92, 0.7],
      [0.8, 0.95]
    ] : [
      [0.25, 0.99],
      [0.03, 0.76],
      [0.03, 0.32],
      [0.25, 0.03],
      [0.5, 0.03],
      [0.75, 0.03],
      [0.97, 0.32],
      [0.97, 0.76],
      [0.75, 0.99]
    ];

    const buttonRects = isPortrait ? [
      [0.3, 0.83],
      [0.3, 0.7],
      [0.3, 0.45],
      [0.3, 0.2],
      [0.5, 0.12],
      [0.65, 0.2],
      [0.65, 0.45],
      [0.65, 0.7],
      [0.65, 0.83]
    ] : [
      [0.36, 0.79],
      [0.15, 0.65],
      [0.15, 0.35],
      [0.25, 0.24],
      [0.5, 0.24],
      [0.75, 0.24],
      [0.83, 0.35],
      [0.83, 0.65],
      [0.63, 0.79]
    ];

    let deleteSeatIndices: number[] = [];
    if (maxTableMember === 5) {
      deleteSeatIndices = isPortrait ? [6, 5, 4, 3] : [8, 7, 6, 5];
    } else if (maxTableMember === 6) {
      deleteSeatIndices = isPortrait ? [5, 4, 3] : [7, 6, 5];
    } else if (maxTableMember === 8) {
      if (isPortrait) {
        // 8자리일 때는 모바일에서만 최상단 한 자리 삭제
        seatRects[4][0] = 0.5;
        buttonRects[4][0] = 0.5;
      }
    }

    for (let seatIndex of deleteSeatIndices) {
      seatRects.splice(seatIndex, 1);
      buttonRects.splice(seatIndex, 1);
    }

    const width = seatsRef.current.offsetWidth;
    const height = seatsRef.current.offsetHeight;
    const left = seatsRef.current.offsetLeft;
    const top = seatsRef.current.offsetTop;

    const seats = seatsRef.current.querySelectorAll('.history-player');
    for (let i = 0; i < maxTableMember; i++) {
      position(seats, i, left + width * seatRects[i][0], top + height * seatRects[i][1]);
    }

    if (dealerButtonRef.current && dealerSeat !== -1) {
      const dealerButton = dealerButtonRef.current;
      position([dealerButton], 0, left + width * buttonRects[dealerSeat][0], top + height * buttonRects[dealerSeat][1]);
    }
  }, [players, dealerSeat]);

  const toggleView = useCallback(() => {
    setShowDetail((b) => !b);
  }, []);

  const handlePrev = useCallback(() => {
    if (currentPage - 1 > 0) {
      playSFX(Sounds.SFX_TOGGLE, true)
      setCurrentPage(currentPage - 1);
    }
  }, [currentPage]);

  const handleLatest = useCallback(() => {
    setCurrentPage(endPage);
    playSFX(Sounds.SFX_TOGGLE, true)
  }, [endPage]);

  const handleNext = useCallback(() => {
    if (currentPage + 1 <= endPage) {
      playSFX(Sounds.SFX_TOGGLE, true)
      setCurrentPage(currentPage + 1);
    }
  }, [currentPage, endPage]);

  useEffect(() => {
    if (currentPage < 0) {
      return;
    }

    requestGetHandHistory(groupId, roomId, currentPage).then(({result, blind, endDate}) => {
      const sorted = result.sort((a: any, b: any) => a.betId - b.betId);
      setHandHistory(sorted);
      setCurRoundBlind(blind);
      setCurRoundEndDate(endDate);
    });
  }, [roomId, currentPage]);

  useEffect(() => {
    if (currentPage < 1 && handNumber >= 2) {
      setCurrentPage(1);
    }
  }, [handNumber, currentPage]);

  useEffect(() => {
    arrangeLayout();
  }, [arrangeLayout]);

  useEffect(()=>{
    if(showDetail){
      playSFX(Sounds.SFX_WINDOW_OPEN, true);
    }else{
      playSFX(Sounds.SFX_WINDOW_CLOSE, true);
    }
  }, [showDetail])


  return <Wrapper>
    <Header>
      {
        orientation === 'portrait' ? <>
          <img className="back" src="/images/ic_header_back.svg" onClick={onClose}/>
          <div className="title">이전 핸드 결과</div>
          <div className="gap"/>
        </> : <>
          <div className="gap"/>
          {
            currentPage > 0 ? (
              <div className="title">
                <HistoryPagination
                  current={currentPage}
                  total={endPage}
                  onClickPrev={handlePrev}
                  onClickNext={handleNext}
                  onClickLatest={handleLatest}
                />
              </div>
            ) : (
              <Flex/>
            )
          }
          <img className="back" src="/images/ic_header_close.svg" onClick={onClose}/>
        </>
      }
    </Header>
    {
      curRoundBlind && curRoundBlind.length > 0 && (
      <TimeHead>
        <div className="left">
          <img src="/images/nes/chip2.png" alt=""/>
          <span>{curRoundBlind.length > 0 ? curRoundBlind[0] : '로딩중'}/{curRoundBlind.length > 0 ? curRoundBlind[1] : '로딩중'}</span>
        </div>
        <div className="right">
          <img src="/images/nes/date2.png" alt=""/>
          <span>{curRoundEndDate} </span>
        </div>
      </TimeHead>
      )
    }
    {
      orientation !== 'portrait' ? (

        <SliderWrapper>
          <div className='slider'>
            <GameHistoryGaugeHorizontal 
            min={1} 
            max={endPage} 
            value={currentPage} 
            onChange={(e:number)=>{
              if (e > currentPage) {
                handleNext();
              }
              else if (e !== currentPage) {
                handlePrev()
              }
            }}
            />
          </div>
        </SliderWrapper>
      ) : <></>
    }
    {
      handNumber > 1 ? <>
        <Content>
          {
            orientation === 'portrait' && (
              <GameCards>
                <div className='title'>테이블 카드 :</div>
                <div className="cards">
                  {
                    (lastGameData?.communityCards ?? []).map((card, i) => (
                      <img key={i} src={`/images/card/style-${gameOption.cardStyle}/${card}.svg`}/>
                    ))
                  }
                </div>
              </GameCards>
            )
          }
          {
            (orientation === 'landscape' || !showDetail) && (
              <DefaultView>
                <div className="table">
                  <img onLoad={arrangeLayout}
                       src={`/images/table/history_${orientation}_blue.svg`}/>
                  <div ref={seatsRef} className="seats">

                    {
                      Array.from({length: maxTableMember}).map((_, i) => {
                        const player = players.find(x => x.seat == i);
                        if (!player) {
                          return <div key={i} className="history-player" data-seat={i}/>;
                        }
                        const playerGameData = (lastGameData?.changeStackSizeList ?? []).find(x => x.userId === player.userId);
                        const stackSize = playerGameData?.stackSize || 0;
                        const prevStackSize = playerGameData?.prevStackSize || 0;

                        const winner = lastGameData?.winners.find(x => x.userId === player.userId);
                        const isWinner = winner !== undefined;
                        const prize = isWinner ? winner.amount : 0;
                        const cards = lastGameData?.cards.find(x => x.userId === player.userId);
                        return <HistoryPlayer
                          key={i}
                          userId={player.userId}
                          seat={player.seat}
                          nickname={player.nickname}
                          profileImg={player.profileImg}
                          folded={player.isFold}
                          winner={isWinner}
                          prize={prize}
                          cards={cards?.cards || []}
                          changeStackSize={stackSize - prevStackSize}
                        />;
                      })
                    }
                  </div>
                  <img ref={dealerButtonRef} className="dealer" src="/images/nes/cash.png"/>
                  {
                    (lastGameData && lastGameData.pots) && (
                      <div className="pot">
                        {
                          <>
                            {
                              lastGameData.pots.map((pot, i) => (
                                <div key={i} className="sidepot">{pot.amount.toLocaleString()}</div>
                              ))
                            }
                          </>
                        }
                        <div className="box">
                          <div className="background"/>
                          <div className='chips'>
                            <img src='/images/chips/Blue.png'/>
                            <img src='/images/chips/Blue.png'/>
                            <img src='/images/chips/Blue.png'/>
                          </div>
                          <div className='amount'>{totalPot.toLocaleString()}</div>
                        </div>
                        {
                          orientation === 'landscape' && (
                            <GameCards>
                              <div className="cards">
                                {
                                  (lastGameData?.communityCards ?? []).map((card, i) => (
                                    <img key={i} src={`/images/card/style-${gameOption.cardStyle}/${card}.svg`}/>
                                  ))
                                }
                              </div>
                            </GameCards>
                          )
                        }
                      </div>
                    )
                  }
                </div>
              </DefaultView>
            )
          }
          {
            (orientation === 'landscape' || showDetail) && (
              <>
                <DetailView>
                  {
                    betHistory.map((x, i) => (
                      <div key={i} className="round-item">
                        <div className="community">
                          <div className="round-name">{['프리플랍', '플랍', '턴', '리버'][x.round]}</div>
                          <div className="round-cards">
                            {
                              x.round === 0 ? <>
                                <img src={`/images/game/card_back_3.png`}/>
                                <img src={`/images/game/card_back_3.png`}/>
                              </> : <>
                                {
                                  x.cards.map((card, i) => (
                                    <img key={i} src={`/images/card/style-${gameOption.cardStyle}/${card}.svg`}/>
                                  ))
                                }
                              </>
                            }
                          </div>
                          <Flex/>
                          <div className="count">
                            <img src="/images/ic_player_white.svg"/>
                            <div>{x.players.length.toLocaleString()}</div>
                          </div>
                          <div className="count">
                            <div>{x.pot.toLocaleString()}</div>
                            <div className='chips'>
                              <img src="/images/chips/Blue.png"/>
                              <img src="/images/chips/Blue.png"/>
                              <img src="/images/chips/Blue.png"/>
                            </div>
                          </div>
                        </div>
                        <div className="bets">
                          {
                            x.bets.filter(_ => _.action !== undefined).map((bet) => (
                              <div key={bet.betId}>
                                <div className="position">{bet.position}</div>
                                <div className="nickname">{bet.nickname}</div>
                                <Flex/>
                                <div className="amount">{bet.bet.toLocaleString()}</div>
                                <div className="action" data-action={bet.action}>{bet.action}</div>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    ))
                  }
                </DetailView>
                {
                  isShowDownRound && (
                  <ShowDownView>
                    <div className="title">
                      <img src="/images/nes/showdown.png" alt=""/>
                      <span>쇼다운</span>
                    </div>
                    <div className="wrap">
                      {
                        showDownData.players.map((player, i) => (
                          <div className="bets" key={`showdonw-${i}`}>
                            <div className="player">
                              <div className="position">{player.position}</div>
                              <div className="playerInfo">
                                <div className="nickname">{player.nickname}</div>
                                <div className="showCards">
                                  <div className="playerCards">
                                    {
                                      player.cards.length === 2 ? (
                                        <>
                                          <img 
                                            className={player.ceremonyCards.includes(CARD_INFO[player.cards[0]]) ? 'highlight' : ''}
                                            src={`/images/card/style-${gameOption.cardStyle}/${player.cards[0]}.svg`}
                                          />
                                          <img 
                                            className={player.ceremonyCards.includes(CARD_INFO[player.cards[1]]) ? 'highlight' : ''}
                                            src={`/images/card/style-${gameOption.cardStyle}/${player.cards[1]}.svg`}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <img src={`/images/game/card_back_3.png`}/>
                                          <img src={`/images/game/card_back_3.png`}/>
                                        </>
                                      )
                                    }
                                  </div>
                                  <div className="community">
                                    {
                                      (showDownData.communityCards).map((card, i) => (
                                        <img 
                                          className={player.ceremonyCards.includes(CARD_INFO[card]) ? 'highlight' : ''}
                                          key={`showDownData-communityCards-${i}`} 
                                          src={`/images/card/style-${gameOption.cardStyle}/${card}.svg`}
                                        />
                                      ))
                                    }
                                  </div>
                                </div>
                                <div className="pedigree">
                                  {
                                    player.pedigree.length !== 0 && (
                                      <span>{player.pedigree}</span>
                                    )
                                  }
                                </div>
                              </div>
                              <div className={`profits ${player.profits > 0 ? 'green' : player.profits < 0 ? 'red' : ''}`}>{player.profits > 0 ? `+${player.profits.toLocaleString()}` : player.profits.toLocaleString()}</div>
                            </div>
                          </div>
                        ))
                      }
                    </div>
                  </ShowDownView>
                  )
                }
              </>
            )
          }
        </Content>
        {
          orientation === 'portrait' && (
            <Footer>
              <div>
                <ChangeViewButton onClick={toggleView}>
                  {showDetail ? '기본보기' : '상세보기'}
                </ChangeViewButton>
              </div>
              <HistoryPagination
                current={currentPage}
                total={endPage}
                onClickPrev={handlePrev}
                onClickNext={handleNext}
                onClickLatest={handleLatest}
              />
            </Footer>
          )
        }
      </> : <>
        <Content>
          <NoHistory>진행된 게임이 없습니다.</NoHistory>
        </Content>
      </>
    }
  </Wrapper>;
}

export default GameHistory;
