import React, {useEffect, useState} from "react";
import styled from "styled-components";
import UserDetail from "./MyPage/UserDetail";
import StyleSetting from "./MyPage/StyleSetting";
import PointHistory from "./MyPage/PointHistory";
import AttendanceHistory from "./MyPage/AttendanceHistory";
import NoteHistory from "./MyPage/NoteHistory";
import RNGCertification from "./MyPage/RNGCertification";
import Referral from "./MyPage/Referral";
import {requestGetUserProfile, requestModifyUserProfile, requestMyInfo, USER_TOKEN, useSocketIsConnect} from "../api";
import {MyInfo, userProfileModel} from "../dataset";
import {uploadProfileImg} from "../utils/fileUpload";
import {startLoading, stopLoading} from "../components/Loading";
import {copyClipboard} from "../utils/common";
import useDialog from "../hooks/useDialog";
import {playSFX, Sounds} from "../utils/sound";
import {useParams, useNavigate} from "react-router-dom";
import useScreenOrientation, {MEDIA_MOBILE_LANDSCAPE, MEDIA_DESKTOP} from "../hooks/useScreenOrientation2";
import PasswordChange from "./MyPage/PasswordChange";
import CustomerCenter from "./CustomerCenter";
import MemoView from "./MemoView";
import ModalContainer from "../components/common/ModalContainer";
import ProfileDetail from "../components/ProfileDetail";

const MypageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  @media ${MEDIA_DESKTOP} {
    background: #fff;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  > .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    @media ${MEDIA_DESKTOP} {
      width: 100%;
      max-width: 470px;
    }
  }

  .pc {
    display: none;
    @media ${MEDIA_DESKTOP} {
      display: block;
    }
  }

  .mo {
    display: block;
    @media ${MEDIA_DESKTOP} {
      display: none;
    }
  }
`
const LogoutButton = styled.div` 
  display: flex;
  width: 240px;
  height: 44px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  color: #FFF;
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 22px;
  background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
  margin-top: 40px;
`
const MyInfoWrapper = styled.div`
  padding: 0 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;

  > .profile-wrapper {
    width: fit-content;
    height: fit-content;
    display: inline-block;
    background: black;
    border-radius: 70px;
    border: 2px solid rgb(168 168 168);
    position: relative;

    > .profile {
      width: 60px;
      height: 60px;
      object-fit: contain;
      border-radius: 50%;
      cursor: pointer;
    }

    > .profileChangeButton {
      bottom: -8px;
      right: -8px;
      position: absolute;
      width: 32px;
      cursor: pointer;
      height: 32px;
      border-radius: 50%;
      background: #25262D;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
  }

  > .info-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 8px;

    > .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;

      > .title {
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        opacity: 0.4;
        @media ${MEDIA_DESKTOP} {
          color: var(--Black, #181818);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }

      > .value {
        color: #FFF;
        font-family: Pretendard;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          color: var(--Black, #181818);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }

      > .copy {
        cursor: pointer;
        padding: 2px;
        border-radius: 4px;
        background: #262838;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: #AAA;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          border-radius: 4px;
          background: rgba(1, 189, 156, 0.10);
          color: var(--Point-Mint, #169E7A);
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
        }

        > img {
          width: 16px;
          height: 16px;
          object-fit: contain;
        }

      }
      > .profile {
        cursor: pointer;
        padding: 2px;
        border-radius: 4px;
        background: rgba(255,126,126,0.3);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: rgb(255 99 99);
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        padding-left: 4px;
        padding-right: 4px;
        @media ${MEDIA_DESKTOP} {
          border-radius: 4px;
          background: rgba(255,0,0,0.1);
          color: #9e1616;
          font-family: Pretendard;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
        }

        > img {
          width: 14px;
          height: 14px;
          object-fit: contain;
          margin-right: 3px;
        }
      }
    }
  }
`
const MyStatusWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #25262D;
  padding: 12px 20px;
  gap: 4px;
  @media ${MEDIA_DESKTOP} {
    width: 100%;
    border-radius: 8px;
    background: #F6F6F6;
    padding: 24px 0;
  }

  > .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;

    > .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      gap: 4px;

      > .title {
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.5px;
        opacity: 0.4;
        @media ${MEDIA_DESKTOP} {
          color: var(--Black, #181818);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }

      > .value {
        color: #FFF;
        font-family: Pretendard;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.5px;
        @media ${MEDIA_DESKTOP} {
          color: var(--Black, #181818);
          font-family: Pretendard;
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.5px;
        }
      }
    }
  }
`
const MenuListWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 32px;
  padding: 0 16px 16px;

  > .menu {
    cursor: pointer;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid #25262D;
    border-bottom: 1px solid #25262D;
    padding: 20px 0;
    @media ${MEDIA_DESKTOP} {
      border-color: #DDDDDD;
      border-top: none;
    }

    &:first-child {
      border-top: none;
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    > .icon {
      width: 24px;
      height: 24px;
      object-fit: contain;
      margin-right: 12px;
    }

    > .title {
      color: #FFF;
      leading-trim: both;
      text-edge: cap;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      flex-grow: 1;
      @media ${MEDIA_DESKTOP} {
        color: var(--Black, #181818);
        leading-trim: both;
        text-edge: cap;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .value-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 4px;
      color: #AAA;
      leading-trim: both;
      text-edge: cap;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      @media ${MEDIA_DESKTOP} { {
        color: var(--Point-Red, #E31D1D);
        leading-trim: both;
        text-edge: cap;
        font-family: Pretendard;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      }
    }
`
const Arrow = () => {
  return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M10 16.2631L15.25 11.5L10 6.73682" stroke="#AAAAAA" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
}
const MyPage = () => {
  const {orientation, device} = useScreenOrientation();
  const params: {parameter?: string} = useParams();
  const parameter = params?.parameter;

  const {openDialog} = useDialog();
  const [conn] = useSocketIsConnect();
  const navigate = useNavigate();
  useEffect(() => {
    if (conn.isLogin) {

    } else {
      navigate('/main')
    }
  }, [conn]);
  const [myInfo, setMyInfo] = useState<MyInfo | null>(null);
  const [userProfile, setUserProfile] = useState<userProfileModel | null>(null);
  const [showPage, setShowPage] = useState<null | 'userDetail' | 'styleSetting' | 'point' | 'note' | 'rng' | 'referral' | 'attendance' | 'password' | 'cs' | 'memo'>(null);
  const [showProfileImageDetailSet, setShowProfileImageDetailSet] = useState(false);

  useEffect(() => {
    if (
      parameter && (
        parameter === 'userDetail' || 
        parameter === 'styleSetting' || 
        parameter === 'point' || 
        parameter === 'attendance' ||
        parameter === 'referral' ||
        parameter === 'password' ||
        parameter === 'cs' ||
        parameter === 'memo' 
      )
    ) {
      setShowPage(parameter);
    } else {
      setShowPage(null);
    }

    window.scrollTo(0, 0);
  }, [window.location.href])

  useEffect(() => {
    requestMyInfo().then(res => {
      setMyInfo(res.info);
      requestGetUserProfile(res.info.userId).then(res => {
        setUserProfile(res);
      })
    })
  }, []);
  const handleClickProfileUpload = async () => {
    const input = document.getElementById('profileImageInput');
    if (!input) return;
    input.click();
  }
  const handleProfileUpload = async (event: any) => {
    const input = event.target;
    if (!input.files) return;
    const file = input.files[0];
    if (!file) return;
    const loading = startLoading();
    const profileRes = await uploadProfileImg(file) as string;
    //const profileRes = '';
    if (!profileRes) {
      stopLoading(loading);
      return;
    }
    if (myInfo) {
      requestModifyUserProfile(myInfo.nickname, profileRes).then(res => {
        if (res.result) {
          if (userProfile) {
            setUserProfile({
              ...userProfile,
              profileImg: profileRes
            });
          }
        }
      }).finally(() => {
        stopLoading(loading);
      })
    }
  }
  const handleLogout = (event: any) => {
    openDialog({
      title: '로그아웃',
      text: '정말 로그아웃 하시겠습니까?',
      confirm: true,
      confirmText: '로그아웃',
      onConfirm: () => {
        playSFX(Sounds.SFX_WINDOW_OPEN, true);
        event.preventDefault();
        sessionStorage.removeItem(USER_TOKEN);
        conn.isLogin = false
        window.location.href = '/';
      },
      onCancel: () => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
      }
    });
  }
  
  return <>
    {
      showPage === 'memo' && <MemoView onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        navigate('/main/mypage');
      }}/>
    }
    {
      showPage === 'cs' && <CustomerCenter onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        navigate('/main/mypage');
      }}/>
    }
    {
      showPage === 'userDetail' && <UserDetail onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        navigate('/main/mypage');
      }}/>
    }
    {
      showPage === 'styleSetting' && <StyleSetting onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        navigate('/main/mypage');
      }}/>
    }
    {
      showPage === 'point' && <PointHistory onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        navigate('/main/mypage');
      }}/>
    }
    {
      showPage === 'attendance' && <AttendanceHistory onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        navigate('/main/mypage');
      }}/>
    }
    {
      showPage === 'password' && <PasswordChange onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        navigate('/main/mypage');
      }}/>
    }
    {
      showPage === 'referral' && <Referral onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        navigate('/main/mypage');
      }}/>
    }
    {
      showPage === 'rng' && <RNGCertification onClose={() => {
        playSFX(Sounds.SFX_WINDOW_CLOSE, true);
        navigate('/main/mypage');
      }}/>
    }
    <ModalContainer
      show={showProfileImageDetailSet} onBackdropClick={() => setShowProfileImageDetailSet(false)}>
      <ProfileDetail 
      onClose={() => {
        setShowProfileImageDetailSet(false);
      }}
      onUpdateProfileImage={(imageUrl)=> {
        const a = userProfile;

        if (a) {
          setUserProfile({
            ...a,
            profileImg: imageUrl
          });
        }

        setShowProfileImageDetailSet(false);
      }}/>
    </ModalContainer>

    {
      (myInfo && userProfile) && <MypageWrapper>
        <div className='inner'>
          <MyInfoWrapper>
            <div className='profile-wrapper'>
              <img className='profile' src={userProfile.profileImg} onClick={()=> {
                playSFX(Sounds.SFX_TOGGLE, true)
                setShowProfileImageDetailSet(true)
              }}/>
              <div className='profileChangeButton' onClick={handleClickProfileUpload}>
                <img src='/images/Upload.png'/>
              </div>
              <input id='profileImageInput' type='file' accept='image/*' onChange={handleProfileUpload} style={{
                display: 'none'
              }}/>
            </div>
            <div className='info-wrapper'>
              <div className='row'>
                <div className='title'>닉네임</div>
                <div className='value'>{myInfo.nickname}</div>
              </div>
              <div className='row'>
                <div className='title'>나의 지인추천코드</div>
                <div className='value'>고객센터 문의</div>
                <div className='profile' onClick={()=> {
                  playSFX(Sounds.SFX_TOGGLE, true)
                  setShowProfileImageDetailSet(true)
                }}>
                  <img className='mo' src='/images/nes/shuffle.png'/>
                  <img className='pc' src='/images/nes/shuffle.png'/>프로필 변경
                </div>
              </div>
            </div>
          </MyInfoWrapper>
          <MyStatusWrapper>
            <div className='row'>
              <div className='item'>
                <div className='title'>
                  오늘 전적
                </div>
                <div className='value'>
                  {myInfo.todayGameResult.winCount + myInfo.todayGameResult.loseCount}전 {myInfo.todayGameResult.winCount}승 {myInfo.todayGameResult.loseCount}패
                </div>
              </div>
              <div className='item'>
                <div className='title'>
                  총 누적 전적
                </div>
                <div className='value'>
                  {myInfo.allGameResult.winCount + myInfo.allGameResult.loseCount}전 {myInfo.allGameResult.winCount}승 {myInfo.todayGameResult.loseCount}패
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='item'>
                <div className='title'>
                  오늘 수익/손실
                </div>
                <div className='value'>
                  {(myInfo.todayGain.loseCount === 0 ? 0 : myInfo.todayGain.loseCount * -1).toLocaleString()}
                </div>
              </div>
            </div>
          </MyStatusWrapper>
          <MenuListWrapper>
            <div className='menu' onClick={() => {
              playSFX(Sounds.SFX_WINDOW_OPEN, true);
              navigate('/main/mypage/userDetail');
            }}>
              <img className='icon mo' src='/images/ic_mypage_user.png'/>
              <img className='icon pc' src='/images/pc/Line/User.png'/>
              <div className='title'>유저 상세정보</div>
              <Arrow/>
            </div>
            <div className='menu' onClick={() => {
              playSFX(Sounds.SFX_WINDOW_OPEN, true);
              navigate('/main/mypage/styleSetting');
            }}>
              <img className='icon mo' src='/images/ic_mypage_style.png'/>
              <img className='icon pc' src='/images/pc/Line/Style.png'/>
              <div className='title'>스타일 설정</div>
              <Arrow/>
            </div>
            <div className='menu' onClick={() => {
              playSFX(Sounds.SFX_WINDOW_OPEN, true);
              navigate('/main/mypage/point');
            }}>
              <img className='icon mo' src='/images/ic_mypage_point.png'/>
              <img className='icon pc' src='/images/pc/Line/Point.png'/>
              <div className='title'>포인트 지급 내역</div>
              <Arrow/>
            </div>
            <div className='menu' onClick={() => {
              playSFX(Sounds.SFX_WINDOW_OPEN, true);
              navigate('/main/mypage/attendance');
            }}>
              <img className='icon mo' src='/images/iconAttendance.png'/>
              <img className='icon pc' src='/images/iconAttendancep.png'/>
              <div className='title'>출석체크 내역</div>
              <Arrow/>
            </div>
            <div className='menu' onClick={() => {
              playSFX(Sounds.SFX_WINDOW_OPEN, true);
              navigate('/main/mypage/referral');
            }}>
              <img className='icon mo' src='/images/Recpoint.png'/>
              <img className='icon pc' src='/images/pc/Line/Recpoint.png'/>
              <div className='title'>추천코드 포인트</div>
              <div className='value-row'>
                <div className='point'>
                  {myInfo.recomPoint.toLocaleString()} 보유
                </div>
                <Arrow/>
              </div>
            </div>
            <div className='menu' onClick={() => {
              playSFX(Sounds.SFX_WINDOW_OPEN, true);
              navigate('/main/mypage/password');
            }}>
              <img className='icon mo' src='/images/iconPassword.png'/>
              <img className='icon pc' src='/images/iconPasswordp.png'/>
              <div className='title'>비밀번호 변경</div>
              <Arrow/>
            </div>
            {/*<div className='menu' onClick={() => {
              playSFX(Sounds.SFX_WINDOW_OPEN, true);
              setShowPage('rng')
            }}>
              <img className='icon' src='/images/ic_mypage_rng.png'/>
              <div className='title'>RNG인증서 보기</div>
              <Arrow/>
            </div>*/}
            {
              (orientation === 'portrait' || (orientation === 'landscape' && device === 'mobile')) && <div className='menu'>
                <div className='title' onClick={handleLogout}>로그아웃</div>
              </div>
            }
          </MenuListWrapper>
          {
            (orientation === 'landscape' && device === 'pc') && <LogoutButton onClick={handleLogout}>
              로그아웃
            </LogoutButton>
          }
        </div>
      </MypageWrapper>
    }
  </>
}

export default MyPage;
