import styled from "styled-components";
import Flex1 from "./common/Flex";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {MyInfo} from "../dataset";
import {requestMyInfo, requestSwapPoint, useSocketIsConnect} from "../api";
import BuyInGaugeHorizontal from "./game/BuyInGaugeHorizontal";
import {debounce} from "lodash";
import {startLoading, stopLoading} from "./Loading";
import useDialog from "../hooks/useDialog";
import {playSFX, Sounds} from "../utils/sound";
import {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";

const Wrapper = styled.div`
  width: 310px;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  background: #181818;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  @media ${MEDIA_DESKTOP}{
    background: white;
  }
`;

const BorderRect = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  border: 1px solid var(--Point-Red, #E31D1D);
  background: transparent;
  box-shadow: 0px 0px 16px 2px #E31D1D inset;
  @media ${MEDIA_DESKTOP}{
    display: none;
  }
`

const Title = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 52px;
  color: #FFF;
  text-align: center;
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.2px;
  background: rgba(227, 29, 29, 0.3);
  box-shadow: 0px 4px 4px 0px rgba(255, 255, 255, 0.25) inset;
  @media ${MEDIA_DESKTOP}{
    color: var(--Black, #181818);
    text-align: center;
    font-family: Pretendard;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 20px */
    background: #F5F5F5;
    border-bottom: 1px solid #DDD;
  }
  >.pc {
    display: none;
    @media ${MEDIA_DESKTOP}{
      display: block;
    }
  }
  >.mo {
    display: block;
    @media ${MEDIA_DESKTOP}{
      display: none;
    }
  }
  > .close-wrapper {
    z-index: 8;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;

    > .background {
      width: 32px;
      height: 32px;
      padding-top: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      object-fit: contain;
      transform: translate(-50%, -50%);
    }

    > .close {
      width: 24px;
      height: 24px;
      object-fit: contain;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0;
  
  >.point-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    >.title {
      color: #FFF;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
      opacity: 0.4;
      @media ${MEDIA_DESKTOP}{
        color: #181818;
        font-size: 14px;
        opacity: 0.7;
      }
    }
    >.value{
      color: var(--Point-Red, #E31D1D);
      font-family: Pretendard;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 28px */
      letter-spacing: -0.5px;
      @media ${MEDIA_DESKTOP}{
        color: #169E7A;
      }
    }
  }
  .input-wrapper {
    width: calc(100% - 50px);
    padding: 12px;
    border: 0;
    background: none;
    outline: none;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 14px */
    &::placeholder {
      opacity: 0.5;
    }
    @media ${MEDIA_DESKTOP}{
      color: #181818;
      &::placeholder {
        opacity: 0.5;
      }
    }
  }
  >.description {
    margin-top: 12px;
    margin-bottom: 16px;
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    opacity: 0.4;
    @media ${MEDIA_DESKTOP}{
      color: #181818;
      font-size: 14px;
      opacity: 0.7;
    }
  }
`

const InputBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 12px;
  border-radius: 6px;
  border: 1px solid rgb(37, 38, 45);
  margin-top: 12px;
  width: 100%;
  overflow: hidden;
  >div {
    width: 50px;
    color: rgb(177,178,177);
    padding-top: 12px;
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: none;
    background: #222222;
    cursor: pointer;
    @media ${MEDIA_DESKTOP}{
      background: rgb(171 62 62);
      color: #fff;
    }
  }
  @media ${MEDIA_DESKTOP}{
    color: rgb(24, 24, 24);
    border: 1px solid rgb(221, 221, 221);
    background: rgb(245, 245, 245);
  }
`;

const TradeButton = styled.div<{
  disabled?: boolean
}>`
  z-index: 8;
  width: 100%;
  height: 48px;
  color: #FFF;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: var(--Gradient-Red, linear-gradient(180deg, #DF3535 0%, #A51818 100%));
  ${p => p.disabled && `
    color: rgba(255, 255, 255, 0.5);
    background: #25262D;
    cursor: initial;
  `};
  @media ${MEDIA_DESKTOP}{
    border-radius: 24px;
    background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
      ${p => p.disabled && `
        opacity: 0.5;
      `};
  }
`;


interface TradePointModalProps {
  onClose: () => void;
}

function TradePointModal(
  {
    onClose
  }: TradePointModalProps
) {
  const [conn] = useSocketIsConnect();
  const {openDialog} = useDialog();
  const [amount, setAmount] = useState<number>(0);
  const [myInfo, setMyInfo] = useState<MyInfo|null>(null);
  useEffect(() => {
    if(!conn.isLogin){
      return;
    }
    const loading = startLoading();
    requestMyInfo().then((res) => {
      setMyInfo(res.info);
    }).finally(()=>{
      stopLoading(loading)
    })
  }, [conn.isLogin]);
  const handleClickSwap = () => {
    if(amount < 10000){
      return openDialog({
        title: '전환 실패',
        text: '신청 가능한 최소 전환 금액은 10,000원 입니다.'
      });
    }else if (myInfo && myInfo.point < amount) {
      return openDialog({
        title: '전환 실패',
        text: '보유 적립금이 부족합니다.'
      });
    }else{
      playSFX(Sounds.SFX_CLICK)
      const loading = startLoading();
      const amount2 = amount;
      setAmount(0);
      requestSwapPoint(amount2).then((res) => {
        openDialog({
          title: '전환 성공',
          text: `${amount2.toLocaleString()} 포인트 적립금 전환에 성공했습니다.`,
        })
        onClose();
      }).catch((e) => {
        openDialog({
          title: '전환 실패',
          text: e.message,
        })
      }).finally(()=>{
        stopLoading(loading)
      })
    }
  }
  const handleClickMax = () => {
    if (myInfo && myInfo.point >= 10000) {
      setAmount(myInfo.point);
    }
  };

  return myInfo !== null ? <Wrapper>
    <BorderRect/>
    <Title>
      적립금 전환
      <div className='close-wrapper mo'>
        <img className='background' src='/images/close-bg.png'/>
        <img className='close' src='/images/Close.png' onClick={onClose}/>
      </div>
      <div className='close-wrapper pc'>
        <img src='/images/pc/Close.png' className='close' onClick={onClose}/>
      </div>
    </Title>
    <Content>
      <div className='point-wrapper'>
        <div className='title'>현재 보유 적립금</div>
        <div className='value'>{((myInfo.point ?? 0) < 0 ? 0 : (myInfo.point ?? 0)).toLocaleString()} P</div>
      </div>
      <InputBox>
        <input 
          type='number' 
          className='input-wrapper' 
          placeholder={'전환할 적립금을 입력하세요'} 
          value={amount} 
          onChange={(e)=>{
          setAmount(parseInt(e.target.value))
          }}
        />
        <div onClick={handleClickMax}>최대</div>
      </InputBox>
      <div className='description'>
        * 10,000 포인트 이상부터 전환 가능합니다.
      </div>

      <TradeButton disabled={amount< 10000 || ((myInfo.point ?? 0) < amount)} onClick={handleClickSwap}>적립머니 전환하기</TradeButton>
    </Content>
  </Wrapper> : <></>
}

export default TradePointModal
