import {BET_TYPE, GamePlayer, RoomInfo} from "../../dataset";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import InGameButton from "../common/InGameButton";
import BetGaugeVertical from "./BetGaugeVertical";
import styled from "styled-components";
import useScreenOrientation, {MEDIA_DESKTOP, MEDIA_MOBILE_LANDSCAPE} from "../../hooks/useScreenOrientation";
import BetGaugeHorizontal from "./BetGaugeHorizontal";
import Flex from "../common/Flex";
import {useTranslation} from "react-i18next";
import {playSFX, Sounds} from "../../utils/sound";
import ModalContainer from "../common/ModalContainer";
import DetailAmountModal from "../DetailAmountModal";

const Wrapper = styled.div<{show: boolean}>`
  transition: opacity 0.3s ease-in-out;
  opacity: ${p => p.show ? `1`: `0`};
  pointer-events: ${p => p.show ? `auto`: `none`};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 20px 24px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 6;
  @media (max-width: 390px) {
    padding: 15px 15px;
  }
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    padding: 6px 10px;
  }
  @media ${MEDIA_DESKTOP} {
    padding: 0;
  }
`;

const ButtonWrapper = styled.div<{show: boolean}>`
  transition: opacity 0.3s ease-in-out;
  opacity: ${p => p.show ? `1`: `0`};
  pointer-events: ${p => p.show ? `auto`: `none`};
  width: 100%;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2;
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    justify-content: flex-end;
  }
  @media ${MEDIA_DESKTOP} {
    gap: 14px;
    justify-content: flex-end;
    padding-right: 60px;
    padding-bottom: 20px;
  }
`;

const BetGaugeWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 75px;
  background: linear-gradient(180deg, rgba(16, 16, 18, 0.00) 0%, #101012 100%);
  @media ${MEDIA_DESKTOP} {
    background: none;
  }
  z-index: 2;
`;

const RaiseWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  position: relative;
`;

const WideRaiseWrapper = styled.div`
  position: relative;
  width: 580px;
  height: 44px;
  display: flex;
  gap: 4px;
  background: rgba(24, 26, 29, 0.5);
  border-radius: 12px;
  padding: 4px;
  color: #FFF;

  > .button {
    width: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
    border-radius: 8px;
    border: 1px solid #242424;
    background: radial-gradient(102.77% 100% at 50% 0%, rgba(168, 168, 168, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%), #1F1F1F;
    box-shadow: 0px 2px 4px 0px rgba(16, 16, 18, 0.20);
    cursor: pointer;

    &[data-disabled="true"] {
      user-select: none;
      pointer-events: none;
      opacity: 0.4;
      cursor: initial;
    }
  }

  > input {
    width: 120px;
    border-radius: 8px;
    border: 1px solid #8D8D8D;
    background: #1F1F1F;
    outline: none;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    padding: 12px 11px;

    &:focus {
      outline: none;
    }
  }
`;

const OpenGaugeButton = styled.div`
  width: 32px;
  height: 32px;
  background-image: url(/images/btn_open_gauge.svg);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  filter: drop-shadow(0 2px 2px rgba(16, 16, 18, 0.20));
  position: absolute;
  left: -50%;
  bottom: 20px;
  transform: translate(50%, -100%);

  &:hover {
    filter: brightness(1.5);
  }

  &:active {
    filter: brightness(0.7);
  }
`;

const DetailsButton = styled.div`
  position: relative;
  text-align: center;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 50px;
  height: 36px;
  padding: 0px;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
  border-radius: 20px;
  cursor: pointer;
  transition: filter 0.1s linear 0s;
  white-space: nowrap;
  opacity: 1;
  color: rgb(255, 255, 255);
  border: 3px solid rgb(2 192 250);
  background: linear-gradient(rgb(0 109 247) 23.26%, rgb(84 207 255) 88.19%);
  box-shadow: rgba(255, 255, 255, 0.6) 0px 0.5px 0.3px 0px inset;
  @media (max-width: 390px) {
    margin-left: 0;
    width: 36px;
  }
  @media ${MEDIA_DESKTOP} {
    width: 160px;
    height: 66px;
    color: #FFF;
    text-align: center;
    font-family: Pretendard;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.165px;
    border-radius: 33px;
    border: 6px solid rgb(2 192 250);
  }
}
`;

function ActionButtons(
  {
    show,
    onClickTryBet,
    legalActs,
    room,
    myInfo,
    totalPot,
    bb
  }: {
    show: boolean;
    onClickTryBet: Function,
    legalActs: string[],
    room: RoomInfo,
    myInfo: GamePlayer,
    totalPot: number,
    bb: number
  }
): JSX.Element {
  const {t} = useTranslation();
  const orientation = useScreenOrientation();
  const [showGauge, setShowGauge] = useState<boolean>( true);
  const [raiseAmount, setRaiseAmount] = useState<number>(0);
  const [detailAmount, setDetailAmount] = useState<number>(-1);

  const wrapperTransformRef = useRef<string>('');

  const raiseAmountText = useMemo<string>(() => {
    return raiseAmount.toLocaleString();
  }, [raiseAmount]);

  const myBet = useMemo(() => {
    return myInfo?.bet ?? 0;
  }, [myInfo?.bet]);


  const currentBet = useMemo<number>(() => {
    return (room?.currentBet ?? 0) ;
  }, [room?.currentBet]);
  const [minRaise, stackSize] = useMemo<[number, number]>(() => {
    const ret: [number, number] = [room.minRaise ?? 0, myInfo?.stackSize ?? 0]
    if (ret[0] > ret[1]) {
      ret[0] = ret[1];
    }
    return ret;
  }, [currentBet, room?.minRaise, myInfo?.stackSize]);


  const callAmount = useMemo<number>(() => {
    const amt = currentBet - myBet
    return amt > 0 ? amt : 0;
  }, [currentBet, myBet]);

  useEffect(() => {
    if(show){
      setShowGauge(true);
    }else{
      setShowGauge(false);
      setDetailAmount(-1);
    }
  }, [show]);

  useEffect(() => {
    setRaiseAmount(Math.min(currentBet + minRaise - myBet, stackSize));
  }, [currentBet, minRaise, myBet, stackSize]);

  useEffect(() => {
    const wrapper = document.querySelector('.ingame-button-wrapper') as HTMLElement;
    if (!wrapper) {
      return;
    }

    if (showGauge) {
      wrapperTransformRef.current = wrapper.style.transform;
      wrapper.style.transform = 'none';
      wrapper.style.zIndex = '10';
    }

    return () => {
      if (wrapperTransformRef.current) {
        wrapper.style.transform = wrapperTransformRef.current;
        wrapperTransformRef.current = ''
        wrapper.style.zIndex = '';
      }
    }
  }, [showGauge]);


  if(myInfo?.stackSize === undefined){
    return <></>;
  }
  const AllInButton = () => {
    return <InGameButton mode={'bet'} callAmount={myInfo.stackSize} checked={true} onClick={() => onClickTryBet(BET_TYPE.RAISE, myInfo?.stackSize)}>{
      "올인"
    }</InGameButton>
  }

  return <Wrapper show={show}>
    {
      (legalActs.includes("raise") || legalActs.includes("bet")) && myInfo.stackSize >= (minRaise + (currentBet - myBet)) && 
      <BetGaugeWrapper>
        <BetGaugeVertical
          bb={bb}
          stack={stackSize}
          min={Math.min(currentBet + minRaise - myBet, stackSize)}
          max={stackSize}
          pot={totalPot}
          raiseAmount={raiseAmount}
          onChange={(amount) => setRaiseAmount(amount)}
          onCancel={() => setShowGauge(false)}
          callAmount={callAmount}
          currentBet={currentBet}
          showGauge={showGauge}
        />
      </BetGaugeWrapper>
    }
    <ButtonWrapper show={show}>
      {
        legalActs.length > 0 && <InGameButton mode={'bet'} onClick={() => onClickTryBet(BET_TYPE.FOLD)}>폴드</InGameButton>
      }
      {
        legalActs.includes("check") && (
          <InGameButton mode={'bet'} onClick={() => onClickTryBet(BET_TYPE.CHECK)}>체크</InGameButton>
        )
      }
      {
        legalActs.includes("call") && (myInfo.stackSize > (currentBet-myBet)) && (
          <InGameButton callAmount={Math.min(myInfo.stackSize, (currentBet - myBet))} mode={'bet'}  onClick={() => onClickTryBet(BET_TYPE.CALL)}>{
            '콜'
          }</InGameButton>
        )
      }
      {(legalActs.includes("raise") || legalActs.includes("bet")) && myInfo.stackSize >= (minRaise + (currentBet - myBet)) ?
        <>
          <InGameButton callAmount={showGauge ? raiseAmount : undefined} mode={'bet'} checked={true} onClick={() => {
            if(showGauge){
              onClickTryBet(BET_TYPE.RAISE, raiseAmount);
              //setShowGauge(false);
            }else{
              playSFX(Sounds.SFX_CLICK_INGAME);
              setShowGauge(true);
            }
          }}>
            {showGauge ? '완료' : '완료'}
          </InGameButton> 
          <DetailsButton
            onClick={() => setDetailAmount(1)}
          >상세</DetailsButton>
        </> : <></>}
      {(legalActs.includes("raise") || legalActs.includes("bet")) && myInfo.stackSize < (minRaise + (currentBet - myBet)) ? <RaiseWrapper>
        <AllInButton/>
      </RaiseWrapper> : <></>}
    </ButtonWrapper>

    <ModalContainer
      show={detailAmount !== -1} onBackdropClick={() => setDetailAmount(-1)}>
      <DetailAmountModal 
        show={detailAmount !== -1}
        min={Math.min(currentBet + minRaise - myBet, stackSize)}
        max={stackSize}
        onChange={(amount) => {
          setRaiseAmount(amount);
          onClickTryBet(BET_TYPE.RAISE, amount);
        }}
        onClose={()=> setDetailAmount(-1)}
      />
    </ModalContainer>
  </Wrapper>;
}

export default ActionButtons;
