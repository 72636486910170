import {useNavigate, useParams} from "react-router-dom";
import TournamentDetail from "../components/lobby/TournamentDetail";
import {useEffect} from "react";
import useDialog from "../hooks/useDialog";

function Tournament() {
  const navigate = useNavigate();
  const {groupId} = useParams<{
    groupId: string
  }>();
  const {openDialog} = useDialog();

  useEffect(() => {
    if (!groupId) {
      openDialog({
        title: '오류',
        text: '잘못된 접근입니다.',
        onConfirm: () => {
          navigate('/main/holdem?type=4');
        }
      });
      
      return;
    }
  }, [groupId])

  if (!groupId) {
    return null;
  }

  return <TournamentDetail groupId={Number(groupId)} />
}

export default Tournament;