import React, {useEffect, useRef, useState} from 'react';
import {setAckListener as userDepositTxAccepted} from "../api/from_server_ping_userAcceptDepositTx"
import ModalContainer from "./common/ModalContainer";
import styled, {keyframes} from 'styled-components';
import {
  setAckListener as updateNoticeAlarm
} from "../api/from_server_ping_noticeAlarm"
import useScreenOrientation, {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";
const FromServerToastWrapper = styled.div`
  .noticeAlarm {
    color: #fff;
    background: url(/images/popupNotice.png) no-repeat;
    background-size: 100% 100%;
    padding: 20px;
    width: 100%;
    width: 300px;
    height: 300px;
    @media ${MEDIA_DESKTOP} {
      width: 400px;
    }
    >.head {
      text-align: center;
      font-size: 15px;
    }
    >.content {
      height: 105px;
      font-size: 14px;
      width: 100%;
      overflow-y: scroll;
      padding-top: 0px;
      margin-top: 140px;
      word-wrap: break-word;
    }
  }
`;

const FromServerToast: React.FC = () => {
  const [noticeMessage, setNoticeMessage] = useState<string>('')

  useEffect(()=>{
    updateNoticeAlarm((data:any)=>{
      if (data && data.text) {
        setNoticeMessage(data.text.message);
      }
    });

    return ()=>updateNoticeAlarm(null);
  }, []);

  return <FromServerToastWrapper>
    <ModalContainer
      show={noticeMessage.length > 0} onBackdropClick={() => { setNoticeMessage(''); }}
    >
      <div className={"noticeAlarm"}>
        <div className={"head"}></div>
        <div className={"content"} dangerouslySetInnerHTML={{__html: noticeMessage}} />
      </div>
    </ModalContainer>
  </FromServerToastWrapper>;
};

export default FromServerToast;