import {useCallback, useLayoutEffect, useState} from "react";

export const MIN_WIDTH_DESKTOP = 1080;
export const MIN_HEIGHT_DESKTOP = 680;

export const MEDIA_DESKTOP = `(min-width: ${MIN_WIDTH_DESKTOP}px) and (min-height: ${MIN_HEIGHT_DESKTOP}px)`;
export const MEDIA_LANDSCAPE = `(orientation: landscape)`;
export const MEDIA_MOBILE_LANDSCAPE = `(pointer: coarse) and (orientation: landscape)`;

function useScreenOrientation() {
  const [orientation, setOrientation] = useState<'portrait'|'landscape'>('portrait');
  const [device, setDevice] = useState<'pc'|'mobile'>('pc');

  const detectMobileDevice = () => {
    const userAgent = navigator.userAgent;
    const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    return isMobile;
  };

  const handleResize = useCallback(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const isMobile = detectMobileDevice();

    setOrientation((width >= MIN_WIDTH_DESKTOP && height >= MIN_HEIGHT_DESKTOP) || (isMobile && (width > height)) ? 'landscape' : 'portrait');
    setDevice(isMobile ? 'mobile' : 'pc');
  }, []);

  useLayoutEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [handleResize]);

  return {
    orientation,
    device
  };
}

export default useScreenOrientation;
