import styled from "styled-components";
import {useEffect, useState} from "react";
import {playSFX, playSFXIgnoreMute, Sounds} from "../utils/sound";
import {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";

const ToggleSwitchWrapper = styled.div<{ isChecked: boolean }>`{
  cursor: pointer;
  flex-shrink: 0;
  width: 64px;
  height: 24px;
  position: relative;
  border-radius: 4px;
  background: #25262D;
  @media ${MEDIA_DESKTOP}{
    border-radius: 12px;
    background: rgba(22, 158, 122, 0.10);
  }

  > .toggle {
    background: linear-gradient(180deg, #EDEDED 0%, #BEBEBE 100%);
    position: absolute;
    top: 50%;
    ${p => p.isChecked ? `
      transform: translate(38px, -50%); 
    ` : `
      transform: translate(0px, -50%);
    `}
    transition: all 0.2s ease-in-out;
    width: 26px;
    height: 24px;
    border-radius: 4px;
    z-index: 1;
    @media ${MEDIA_DESKTOP}{
      width: 24px;
      border-radius: 12px;
      background: var(--Gradient-Mint, linear-gradient(180deg, #16AF84 0%, #108D69 100%));
    }
  }

  > .on {
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    color: #E31D1D;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    z-index: 0;
    @media ${MEDIA_DESKTOP}{ 
      color: var(--Point-Mint, #169E7A);
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
    }
  }

  > .off {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 6px;
    color: #FFF;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.5px;
    opacity: 0.4;
    z-index: 0;
    @media ${MEDIA_DESKTOP}{
      color: #181818;
      font-family: Pretendard;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.5px;
      opacity: 0.4;
    }
  }
}`;

interface ToggleSwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const ToggleSwitch = ({checked, onChange}: ToggleSwitchProps) => {
  const [isChecked, setIsChecked] = useState(checked);
  useEffect(() => {
    setIsChecked(checked)
  }, [checked])
  return <ToggleSwitchWrapper isChecked={isChecked} onClick={() => {
    playSFXIgnoreMute(Sounds.SFX_TOGGLE)
    onChange(!isChecked);
    setIsChecked(!isChecked)
  }}>
    <div className='toggle'/>
    <span className='on'>ON</span>
    <span className='off'>OFF</span>
  </ToggleSwitchWrapper>
}

export default ToggleSwitch;
