import styled from "styled-components";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {playSFX, Sounds} from "../utils/sound";
import {MEDIA_DESKTOP} from "../hooks/useScreenOrientation";

const Wrapper = styled.div`
  width: 310px;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  background: #181818;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border: 3px solid rgba(255, 255, 255, 0.4);
  @media (max-width: 318px) {
    width: 240px;
  }
  >.header {
    width: 100%;
    >input {
      width: 100%;
      height: 46px;
      background: #2b2b2b;
      border: 0;
      border-radius: 8px;
      text-align: center;
      font-size: 19px;
      font-weight: bolder;
      color: #cdb491;
      @media ${MEDIA_DESKTOP} {
        font-size: 21px;
      }
    }
    >input::-webkit-input-placeholder {
      font-weight: 500;
    }
    >input:-ms-input-placeholder {
      font-weight: 500;
    }
    >input:placeholder {
      font-weight: 500;
    }
    >.cancel {
      position: absolute;
      right: 5px;
      top: 5px;
      cursor: pointer;
      >img {
        width: 28px;
        height: 28px;
      }
    }
  }
  >.content {
    width: 100%;
    padding-top: 6px;
    >.line {
      display: flex;
    }
    >.error {
      padding-bottom: 6px;
      font-size: 11px;
      color: #ff8181;
      @media ${MEDIA_DESKTOP} {
        font-size: 13px;
      }
    }
  }
`;

const Button = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 48px;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border: 2px solid rgb(24 24 24);
  background: linear-gradient(#5f5e59, #23221e);
  box-shadow: rgba(255, 255, 255, 0.05) 0px 4px 10px 0px inset;
  text-align: center;
  font-family: Pretendard;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
  transition: transform 0.25s ease 0s;
  color: rgb(202 186 153);
  text-shadow: 0 0 3px rgba(0,0,0,0.6);
  &:active {
    transform: scale(.96);
  }
  >img {
    width: 40px;
    height: 40px;
  }
}
`;

const Button2 = styled.div`
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 48px;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  border: 2px solid rgb(24 24 24);
  background: linear-gradient(#be9c6c, #6d4c2b);
  color: rgb(248 228 188);
  box-shadow: rgba(255, 255, 255, 0.05) 0px 4px 10px 0px inset;
  text-align: center;
  font-family: Pretendard;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
  transition: transform 0.25s ease 0s;
  text-shadow: 0 0 3px rgba(0,0,0,0.6);
  &:active {
    transform: scale(.96);
  }
}
`;


interface DetailAmountModalProps {
  show: boolean,
  min:number,
  max:number,
  onChange: (amount: number) => void;
  onClose: () => void;
}

function DetailAmountModal(
  {
    show,
    min,
    max,
    onChange,
    onClose
  } : DetailAmountModalProps
) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputAmount, setInputAmount] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isMobile, setIsMobile] = useState<boolean>(false);
  
  const handleEscKey = (e:any) => {
    if (e.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    const mobileKeywords = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone'];
    const userAgent = window.navigator.userAgent;

    setIsMobile(mobileKeywords.some(keyword => userAgent.includes(keyword)));
  }, []);
  
  useEffect(() => {
    if(show){
      if (inputRef.current) {
        inputRef.current.focus();
      }

      document.addEventListener('keydown', handleEscKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [show, onChange]);

  const handleClickButton = useCallback((amount: number) => {
    let newInputAmount = inputAmount;

    if (amount === -1) { //clear
      newInputAmount = 0;

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
    else {
      if (newInputAmount === 0) {
        newInputAmount = amount;
      }
      else {
        newInputAmount = parseInt(`${newInputAmount}${amount}`);
      }
    }

    if (max <= newInputAmount)
      newInputAmount = max;

    playSFX(Sounds.SFX_CLICK_INGAME);
    setInputAmount(newInputAmount);
  }, [min, max, inputAmount]);

  const handleClickConfirm = useCallback(() => {
    if (inputAmount < min) {
      playSFX(Sounds.SFX_GAME_OUT);
      return setErrorMessage(`최소 레이즈 가능 금액은 ${printDoller(min)} 입니다.`);
    }
    if (inputAmount > max) {
      playSFX(Sounds.SFX_GAME_OUT);
      return setErrorMessage(`최대 레이즈 가능 금액은 ${printDoller(max)} 입니다.`);
    }

    playSFX(Sounds.SFX_WINDOW_CLOSE);
    onChange(inputAmount);
    onClose();
  }, [min, max, onChange, inputAmount]);

  const handleKeyPress = useCallback((e:any) => {
    if (e.key === 'Enter') {
      if (inputAmount < min) {
        playSFX(Sounds.SFX_GAME_OUT);
        return setErrorMessage(`최소 레이즈 가능 금액은 ${printDoller(min)} 입니다.`);
      }
      if (inputAmount > max) {
        playSFX(Sounds.SFX_GAME_OUT);
        return setErrorMessage(`최대 레이즈 가능 금액은 ${printDoller(max)} 입니다.`);
      }
  
      playSFX(Sounds.SFX_WINDOW_CLOSE);
      onChange(inputAmount);
      onClose();
    }
  }, [min, max, onChange, inputAmount]);

  const handleChange = useCallback((e: any) => {
    let inputValue = parseInt(e.target.value.replace(/\D/g, ''));

    if (max <= inputValue)
      inputValue = max;

    setInputAmount(inputValue);
  }, [max]);

  const printAmount = (amount:any) => {
    if (amount === 0) {
      return '';
    }
    
    const inputValue = amount.toString().replace(/\D/g, '');
    const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return formattedValue;
  };

  const printDoller = (amount:number) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  return <Wrapper>
    <div className='header'>
      <input 
        ref={inputRef}
        type="text" 
        placeholder={`최소 금액: ${printDoller(min)}`} 
        value={printAmount(inputAmount)} 
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        disabled={isMobile}
      />
      <div className='cancel' onClick={onClose}>
        <img src="/images/ic_cancel2.png"/>
      </div>
    </div>
    <div className='content'>
      {
        errorMessage.length > 0 && (
          <div className='error'>{errorMessage}</div>
        )
      }
      <div className='line'>
        <Button onClick={() => handleClickButton(1)}>1</Button>
        <Button onClick={() => handleClickButton(2)}>2</Button>
        <Button onClick={() => handleClickButton(3)}>3</Button>
      </div>
      <div className='line'>
        <Button onClick={() => handleClickButton(4)}>4</Button>
        <Button onClick={() => handleClickButton(5)}>5</Button>
        <Button onClick={() => handleClickButton(6)}>6</Button>
      </div>
      <div className='line'>
        <Button onClick={() => handleClickButton(7)}>7</Button>
        <Button onClick={() => handleClickButton(8)}>8</Button>
        <Button onClick={() => handleClickButton(9)}>9</Button>
      </div>
      <div className='line'>
        <Button onClick={() => handleClickButton(-1)}>정정</Button>
        <Button onClick={() => handleClickButton(0)}>0</Button>
        <Button2 onClick={handleClickConfirm}>확인</Button2>
      </div>
    </div>
  </Wrapper>
}

export default DetailAmountModal;
