/* eslint-disable */
//auto gen
import { MyInfo, RecommendPointSwapHistory, RoomStatus } from "../dataset/common"
import { client, SockClientOpt, OnRecvData } from "./index";
import { useEffect, useState, useLayoutEffect } from "react";
const hash = require('object-hash');

let listener:Function[] = [];
let ackListner:Function|null = null;

export const setAckListener = function(func:Function|null){
  ackListner = func;
}
export const addListener = function(func:Function){
  if(listener.indexOf(func) == -1){
    listener.push(func)
  }
  return func
}

export const removeListener = function(func:Function){
  let idx = listener.indexOf(func);
  if( idx >= 0){
    listener.splice(idx, 1);
  }
}

client.off("from:ping:updateChatDelete");
client.on("from:ping:updateChatDelete", async function(data:{ userId: number; chatId: number; }, ack:Function){
  for(let f of listener){
    await f(data);
  }
  
  if(ackListner){
    let ret = await ackListner(data);
    await ack(ret)
  }else{
    await ack()
  }
})

export default function () : [{ userId: number; chatId: number; } | null] {
  const [Data, setData] = useState<{ userId: number; chatId: number; } | null>(null);

  useEffect(() => {
    const listener = async(data:{ userId: number; chatId: number; })=>{
      setData(data);
    }

    client.on("from:ping:updateChatDelete",listener)
    return () => {
      client.off("from:ping:updateChatDelete",listener)
    };
  },[]);

  return [Data]
}