import styled from "styled-components";
import Flex1 from "./common/Flex";
import {useCallback, useEffect, useMemo, useState} from "react";
import {MyInfo} from "../dataset";
import BuyInGaugeHorizontal from "./game/BuyInGaugeHorizontal";
import {debounce} from "lodash";
import useDialog from "../hooks/useDialog";
import {MEDIA_MOBILE_LANDSCAPE} from "../hooks/useScreenOrientation";
import {requestModifyUserProfile, requestMyInfo} from "../api";
import {startLoading, stopLoading} from "../components/Loading";
import {playSFX, Sounds} from "../utils/sound";

const Wrapper = styled.div`
  width: 310px;
  text-align: center;
  color: #FFF;
  font-size: 14px;
  font-weight: 600;
  background: #181818;
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  padding: 20px;
  @media ${MEDIA_MOBILE_LANDSCAPE} {
    width: 80%;
    min-width: initial;
    min-height: initial;
    overflow-y: scroll;
    margin: 0px auto;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    border-radius: 12px;
    border: 1px solid var(--Point-Red, #E31D1D);
  }

  .header {
    text-align: left;
    padding-bottom: 20px;
    >h1 {
      text-align: left;
      font-size: 15px;
    }
    >span {
      text-align: left;
      font-size: 12px;
      color: #989898;
    }
  }
  .tail {
    padding-top: 20px;
    
    >span {
      background: #4b4b4b;
      width: 40%;
      border-radius: 5px;
      height: 35px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background: #3e3e3e;
      }
    }
    >.sdfsdf {
      background: #2e6baa;
      margin-left: 10px;
      &:hover {
        background: #225181;
      }
    }
  }
  .fsdfds {
    width: 54px;
    display: inline-block;
    height: 54px;
    background: black;
    border-radius: 70px;
    overflow: hidden;
    margin-left: 8px;
    margin-top: 8px;
    border: 2px solid rgb(192 192 192);
    position: relative;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    >img {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 0;
      object-fit: contain;
    }
  }
  .fasczxc {
    border: 2px solid #58baff;
  }
  .svsxvxvc {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0,0,0,0.5)
  }
  .svsxvxvc2 {
    position: absolute!important;
    width: 40%!important;
    height: 40%!important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

interface BuyInModalProps {
  onClose: () => void;
  onUpdateProfileImage: (val:string) => void;
}

function ProfileDetail(
  {
    onClose,
    onUpdateProfileImage
  }: BuyInModalProps
) {
  const defaultProfileImagesRange: [number, number] = [1, 15];
  const [initImages, setInitImages] = useState<number[]>(generateArrayFromRange(defaultProfileImagesRange));
  const [activeIndex, setActiveIndex] = useState(-1);
  const [myInfo, setMyInfo] = useState<MyInfo | null>(null);
  const {openDialog} = useDialog();
  
  function generateArrayFromRange(range: [number, number]): number[] {
    const [start, end] = range;
    return Array.from({ length: end - start + 1 }, (_, index) => start + index);
  }

  function getFileName(filePath:any) {
    const fileNameWithExtension = filePath.split('/').pop().split('\\').pop();
    const fileName = fileNameWithExtension.replace(/\.[^/.]+$/, ""); 
    return fileName;
  }

  useEffect(() => {
    requestMyInfo().then(res => {
      setMyInfo(res.info);

      if (res.info.profileImg) {
        const name = getFileName(res.info.profileImg)
        const a = Number(name);

        if (!isNaN(a) && a <= defaultProfileImagesRange[1] && a>= defaultProfileImagesRange[0]) {
          setActiveIndex(a);
        }
        else if (name === 'default_profile') {
          setActiveIndex(0);
        }
      }
    })
  }, []);

  function handleClickProfile(item:number) {
    setActiveIndex(item);
  }

  function handleClickSubmit() {
    if (activeIndex === -1) {
      return openDialog({
        title: '안내',
        text: '프로필 이미지를 선택해주세요.',
        onConfirm: () => {
          playSFX(Sounds.SFX_WINDOW_CLOSE)
        }
      });
    }
    const loading = startLoading();
    const profileRes = activeIndex === 0 ? '/images/default_profile.png' : `/images/nes/profile/${activeIndex}.png`;

    let isSuccessed = false;

    requestModifyUserProfile(String(myInfo?.nickname), profileRes).then(res => {
      if (!res.result) {
        playSFX(Sounds.SFX_WINDOW_OPEN);
        openDialog({
          title: '안내',
          text: '기본프로필이미지 변경에 실패하였습니다. 잠시후 다시 시도해주세요.',
          onConfirm: () => {
            playSFX(Sounds.SFX_WINDOW_CLOSE)
          }
        });
      }
      else {
        isSuccessed = true;
      }
    }).finally(() => {
      stopLoading(loading);

      if (isSuccessed) {
        onUpdateProfileImage(profileRes);
      }
    })
  }

  return <Wrapper>
    <div className="header">
      <h1>기본 프로필 변경</h1>
      <span>프로필 이미지</span>
    </div>
    <div className={`fsdfds ${activeIndex === 0 ? 'fasczxc' : ''}`} onClick={()=>handleClickProfile(0)}>
      <img src={`/images/default_profile.png`} alt={"default"}/>
      {activeIndex === 0 ? (
        <>
          <div className="svsxvxvc"/>
          <img className="svsxvxvc2" src="/images/nes/confirm.png" alt="confirm"/>
        </>
      ) : <></>}
    </div>
    {initImages.map(item => (
      <div className={`fsdfds ${activeIndex === item ? 'fasczxc' : ''}`} key={item} onClick={()=>handleClickProfile(item)}>
        <img src={`/images/nes/profile/${item}.png`} alt={String(item)}/>
        {activeIndex === item ? (
          <>
            <div className="svsxvxvc"/>
            <img className="svsxvxvc2" src="/images/nes/confirm.png" alt="confirm"/>
          </>
        ) : <></>}
      </div>
    ))}
    <div className="tail">
      <span onClick={()=>{
        playSFX(Sounds.SFX_WINDOW_CLOSE)
        onClose()
      }}>취소</span>
      <span className="sdfsdf" onClick={handleClickSubmit}>완료</span>
    </div>
  </Wrapper>
}

export default ProfileDetail
