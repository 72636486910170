import {atom, selectorFamily} from "recoil";

// export const winningRatesState = atom<{
//   [userId: number]: number
// }>({
//   key: 'game/winningRate',
//   default: {}
// })
//
// export const betState = atom<requestBetModel | null>({
//   key: 'game/bet',
//   default: null
// })
//
export const hasCacheGameDataState = atom<number>({
  key: 'game/hasCacheGameData',
  default: 0
})

export const betLeftSecState = atom<number | undefined>({
  key: 'game/betLeftSec',
  default: undefined
})

export const communityCardsState = atom<number[]>({
  key: 'game/communityCards',
  default: []
})
export const myCardsState = atom<number[]>({
  key: 'game/myCardsState',
  default: []
})
export const shiningCardsState = atom<string[]>({
  key: 'game/shiningCardsState',
  default: []
})

export const ceremonyCardsState = atom<string[]>({
  key: 'game/ceremonyCardsState',
  default: []
})

export const ceremonyRankingState = atom<string>({
  key: 'game/ceremonyRankingState',
  default: ''
})
//
// export const winnerCardsState = atom<{
//   [userId: number]: number[]
// }>({
//   key: 'game/winnerCards',
//   default: []
// })

export const emoticonState = atom<{
  [userId: number]: number
}>({
  key: 'game/emoticon',
  default: {}
})

export const emoticonByUserId = selectorFamily<number | undefined, number | undefined>({
  key: 'game/emoticonByUserId',
  get: (userId: number | undefined) => ({get}) => {
    if (!userId) {
      return undefined;
    }

    const state = get(emoticonState);
    return state[userId];
  }
})
