import styled from "styled-components";
import {useCallback, useMemo} from "react";
import useScreenOrientation, {MEDIA_DESKTOP} from "../../hooks/useScreenOrientation";
const Wrapper = styled.div<{ checked?:boolean; mode?: string; dark?: boolean, raise?: boolean, disabled?:boolean }>`
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 96px;
  height: 36px;
  padding: 0;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.165px;
  border-radius: 20px;
  
  cursor: pointer;
  transition: filter 0.1s linear;
  white-space: nowrap;
  filter: ${p=>p.disabled == true ? "grayscale(1)":""};
  opacity: ${p=>p.disabled == true ? "0.5":"1"};
  @media ${MEDIA_DESKTOP} {
    width: 238px;
    height: 84px;
    font-size: 24px;
    border-radius: 42px;
  }
  ${p => p.mode === 'bet' && `
    color: #FFF;
    ${p.checked ? `
      border: 3px solid #FA7702;
      background: linear-gradient(180deg, #F76000 23.26%, #FFBB54 88.19%);
      box-shadow: 0px 0.5px 0.3px 0px rgba(255, 255, 255, 0.60) inset;
    `: `
      border: 3px solid #C90D18;
      background: linear-gradient(180deg, #A90912 5.56%, #F43E34 100%);
      box-shadow: 0px 0.5px 0.3px 0px rgba(255, 255, 255, 0.60) inset;
    `}
     @media ${MEDIA_DESKTOP} {
      ${p.checked ? `
        border: 8px solid #FA7702;
        background: linear-gradient(180deg, #F76000 23.26%, #FFBB54 88.19%);
        box-shadow: 0px 0.5px 0.3px 0px rgba(255, 255, 255, 0.60) inset;
      `: `
        border: 8px solid #C90D18;
        background: linear-gradient(180deg, #A90912 5.56%, #F43E34 100%);
        box-shadow: 0px 0.5px 0.3px 0px rgba(255, 255, 255, 0.60) inset;
      `};
     }
  `}
  ${p => p.mode === 'prebet' && `
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 36px;
    padding: 0;
    font-family: Pretendard;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.165px;
    border-radius: 20px;
    @media ${MEDIA_DESKTOP} {
      width: 238px;
      height: 84px;
    }
    ${p.checked ? `
      color: #181818;
      border: 3px solid #FA7702;
      background: linear-gradient(180deg, #F76000 23.26%, #FFBB54 88.19%);
      box-shadow: 0px 0.5px 0.3px 0px rgba(255, 255, 255, 0.60) inset;
    `: `
      color: #FFF;
      border: 1px solid #FFF;
      background: rgba(24, 24, 24, 0.80);
      @media ${MEDIA_DESKTOP} {
        border-width: 3px;
      }
    `}
  
  `};
  


  .inner {
    gap: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .call-amount {
    width: 100%;
    height: 36px;
    padding: 0px;
    position: absolute;
    fill: #181818;
    stroke-width: 0.5px;
    stroke: #FFF;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    padding-left: 8px;
    padding-right: 8px;
    @media ${MEDIA_DESKTOP} {
      width: 100%;
      height: 85px;
      bottom: 42px;
      padding-left: 4px;
      padding-right: 4px;
    }
    >.amountBackground {
      background: #0e0f14;
      border: 2px solid #83848a;
      border-radius: 10px;
      height: 100%;
      padding-top: 1px;
      @media ${MEDIA_DESKTOP} {
        border: 3px solid #83848a;
        padding-top: 8px;
      }
    }
    >.background {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    span {
      position: relative;
      color: #FFF;
      text-align: center;
      text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.30);
      font-family: Pretendard;
      font-size: 10px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.2px;
      @media ${MEDIA_DESKTOP} {
        color: #FFF;
        text-align: center;
        text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.30);
        font-family: Pretendard;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.2px;
      }
    }
  }
`;



const Raise = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;
  color: #2F99FB;
  font-size: 10px;
  font-weight: 500;
`;


function InGameButton({mode, children, callAmount, checked, subTitle, disabled, raise, bet, dark, onChecked, onClick}: {
  mode?: 'prebet' | 'bet',
  children: any
  checked?: boolean
  raise?: boolean
  subTitle?: string
  bet?: boolean
  disabled?: boolean
  dark?: boolean
  onChecked?: (e: any) => void
  onClick?: (e: any) => void
  callAmount?: number;
}) {
  const orientation = useScreenOrientation();
  if(callAmount){
    callAmount = Math.floor(callAmount)
  }
  const isCheckbox = useMemo(() => {
    return onChecked
  }, [onChecked]);

  const handleClick = useCallback((e: any) => {
    if(disabled) {
      return;
    } else if (onChecked) {
      onChecked(e);
    } else if (onClick) {
      onClick(e)
    }
  }, [isCheckbox, onClick, onChecked, disabled]);
  return <Wrapper mode={mode} checked={checked} dark={dark || (isCheckbox && !checked)} raise={raise || bet} onClick={handleClick} disabled={disabled}>
    <Raise>{subTitle}</Raise>
    {/* { bet && (<Raise>bb</Raise>) }
    { raise && (<Raise>pot</Raise>) } */}
    <div className='inner'>
      {children}
    </div>
    {
      (callAmount !== undefined && callAmount> 0) && (
        <div className='call-amount'>
          <div className='amountBackground'>
            <span>
              {callAmount.toLocaleString()}
            </span>
          </div>
        </div>
      )
    }
  </Wrapper>
}

export default InGameButton
